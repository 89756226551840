@charset "UTF-8";

/**
 * material-design-lite - Material Design Components in CSS, JS and HTML
 * @version v1.3.0
 * @license Apache-2.0
 * @copyright 2015 Google, Inc.
 * @link https://github.com/google/material-design-lite
 */

@charset "UTF-8";
html {
    color: rgba(0, 0, 0, 0.87);
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

.hidden {
    display: none !important;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.invisible {
    visibility: hidden;
}

.clearfix:before,
.clearfix:after {
    content: ' ';
    display: table;
}

.clearfix:after {
    clear: both;
}

@media print {
    *,
    *:before,
    *:after,
    *:first-letter {
        background: transparent !important;
        color: #000 !important;
        box-shadow: none !important;
    }
    a,
    a:visited {
        text-decoration: underline;
    }
    a[href]:after {
        content: ' ('attr(href) ')';
    }
    abbr[title]:after {
        content: ' ('attr(title) ')';
    }
    a[href^='#']:after,
    a[href^='javascript:']:after {
        content: '';
    }
    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }
    thead {
        display: table-header-group;
    }
    tr,
    img {
        page-break-inside: avoid;
    }
    img {
        max-width: 100% !important;
    }
    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }
    h2,
    h3 {
        page-break-after: avoid;
    }
}

a,
.mdl-accordion,
.mdl-button,
.mdl-card,
.mdl-checkbox,
.mdl-dropdown-menu,
.mdl-icon-toggle,
.mdl-item,
.mdl-radio,
.mdl-slider,
.mdl-switch,
.mdl-tabs__tab {
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

html {
    width: 100%;
    height: 100%;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}

body {
    width: 100%;
    min-height: 100%;
}

main {
    display: block;
}

*[hidden] {
    display: none !important;
}

html,
body {
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    padding: 0;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.35;
    letter-spacing: -0.02em;
    opacity: 0.54;
    font-size: 0.6em;
}

h1 {
    font-size: 56px;
    line-height: 1.35;
    letter-spacing: -0.02em;
    margin: 24px 0;
}

h1,
h2 {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
}

h2 {
    font-size: 45px;
    line-height: 48px;
}

h2,
h3 {
    margin: 24px 0;
}

h3 {
    font-size: 34px;
    line-height: 40px;
}

h3,
h4 {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
}

h4 {
    font-size: 24px;
    line-height: 32px;
    -moz-osx-font-smoothing: grayscale;
    margin: 24px 0 16px;
}

h5 {
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.02em;
}

h5,
h6 {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    margin: 24px 0 16px;
}

h6 {
    font-size: 16px;
    letter-spacing: 0.04em;
}

h6,
p {
    font-weight: 400;
    line-height: 24px;
}

p {
    font-size: 14px;
    letter-spacing: 0;
    margin: 0 0 16px;
}

a {
    color: rgb(255, 64, 129);
    font-weight: 500;
}

blockquote {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    position: relative;
    font-size: 24px;
    font-weight: 300;
    font-style: italic;
    line-height: 1.35;
    letter-spacing: 0.08em;
}

blockquote:before {
    position: absolute;
    left: -0.5em;
    content: 'â€œ';
}

blockquote:after {
    content: 'â€';
    margin-left: -0.05em;
}

mark {
    background-color: #f4ff81;
}

dt {
    font-weight: 700;
}

address {
    font-size: 12px;
    line-height: 1;
    font-style: normal;
}

address,
ul,
ol {
    font-weight: 400;
    letter-spacing: 0;
}

ul,
ol {
    font-size: 14px;
    line-height: 24px;
}

.mdl-typography--display-4,
.mdl-typography--display-4-color-contrast {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 112px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: -0.04em;
}

.mdl-typography--display-4-color-contrast {
    opacity: 0.54;
}

.mdl-typography--display-3,
.mdl-typography--display-3-color-contrast {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 56px;
    font-weight: 400;
    line-height: 1.35;
    letter-spacing: -0.02em;
}

.mdl-typography--display-3-color-contrast {
    opacity: 0.54;
}

.mdl-typography--display-2,
.mdl-typography--display-2-color-contrast {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 45px;
    font-weight: 400;
    line-height: 48px;
}

.mdl-typography--display-2-color-contrast {
    opacity: 0.54;
}

.mdl-typography--display-1,
.mdl-typography--display-1-color-contrast {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 34px;
    font-weight: 400;
    line-height: 40px;
}

.mdl-typography--display-1-color-contrast {
    opacity: 0.54;
}

.mdl-typography--headline,
.mdl-typography--headline-color-contrast {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    -moz-osx-font-smoothing: grayscale;
}

.mdl-typography--headline-color-contrast {
    opacity: 0.87;
}

.mdl-typography--title,
.mdl-typography--title-color-contrast {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.02em;
}

.mdl-typography--title-color-contrast {
    opacity: 0.87;
}

.mdl-typography--subhead,
.mdl-typography--subhead-color-contrast {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.04em;
}

.mdl-typography--subhead-color-contrast {
    opacity: 0.87;
}

.mdl-typography--body-2,
.mdl-typography--body-2-color-contrast {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0;
}

.mdl-typography--body-2-color-contrast {
    opacity: 0.87;
}

.mdl-typography--body-1,
.mdl-typography--body-1-color-contrast {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
}

.mdl-typography--body-1-color-contrast {
    opacity: 0.87;
}

.mdl-typography--body-2-force-preferred-font,
.mdl-typography--body-2-force-preferred-font-color-contrast {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
}

.mdl-typography--body-2-force-preferred-font-color-contrast {
    opacity: 0.87;
}

.mdl-typography--body-1-force-preferred-font,
.mdl-typography--body-1-force-preferred-font-color-contrast {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
}

.mdl-typography--body-1-force-preferred-font-color-contrast {
    opacity: 0.87;
}

.mdl-typography--caption,
.mdl-typography--caption-force-preferred-font {
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0;
}

.mdl-typography--caption-force-preferred-font {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.mdl-typography--caption-color-contrast,
.mdl-typography--caption-force-preferred-font-color-contrast {
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0;
    opacity: 0.54;
}

.mdl-typography--caption-force-preferred-font-color-contrast,
.mdl-typography--menu {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.mdl-typography--menu {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0;
}

.mdl-typography--menu-color-contrast {
    opacity: 0.87;
}

.mdl-typography--menu-color-contrast,
.mdl-typography--button,
.mdl-typography--button-color-contrast {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0;
}

.mdl-typography--button,
.mdl-typography--button-color-contrast {
    text-transform: uppercase;
}

.mdl-typography--button-color-contrast {
    opacity: 0.87;
}

.mdl-typography--text-left {
    text-align: left;
}

.mdl-typography--text-right {
    text-align: right;
}

.mdl-typography--text-center {
    text-align: center;
}

.mdl-typography--text-justify {
    text-align: justify;
}

.mdl-typography--text-nowrap {
    white-space: nowrap;
}

.mdl-typography--text-lowercase {
    text-transform: lowercase;
}

.mdl-typography--text-uppercase {
    text-transform: uppercase;
}

.mdl-typography--text-capitalize {
    text-transform: capitalize;
}

.mdl-typography--font-thin {
    font-weight: 200 !important;
}

.mdl-typography--font-light {
    font-weight: 300 !important;
}

.mdl-typography--font-regular {
    font-weight: 400 !important;
}

.mdl-typography--font-medium {
    font-weight: 500 !important;
}

.mdl-typography--font-bold {
    font-weight: 700 !important;
}

.mdl-typography--font-black {
    font-weight: 900 !important;
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: 400;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    word-wrap: normal;
    -moz-font-feature-settings: 'liga';
    font-feature-settings: 'liga';
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

.mdl-color-text--red {
    color: #f44336 !important;
}

.mdl-color--red {
    background-color: #f44336 !important;
}

.mdl-color-text--red-50 {
    color: #ffebee !important;
}

.mdl-color--red-50 {
    background-color: #ffebee !important;
}

.mdl-color-text--red-100 {
    color: #ffcdd2 !important;
}

.mdl-color--red-100 {
    background-color: #ffcdd2 !important;
}

.mdl-color-text--red-200 {
    color: #ef9a9a !important;
}

.mdl-color--red-200 {
    background-color: #ef9a9a !important;
}

.mdl-color-text--red-300 {
    color: #e57373 !important;
}

.mdl-color--red-300 {
    background-color: #e57373 !important;
}

.mdl-color-text--red-400 {
    color: #ef5350 !important;
}

.mdl-color--red-400 {
    background-color: #ef5350 !important;
}

.mdl-color-text--red-500 {
    color: #f44336 !important;
}

.mdl-color--red-500 {
    background-color: #f44336 !important;
}

.mdl-color-text--red-600 {
    color: #e53935 !important;
}

.mdl-color--red-600 {
    background-color: #e53935 !important;
}

.mdl-color-text--red-700 {
    color: #d32f2f !important;
}

.mdl-color--red-700 {
    background-color: #d32f2f !important;
}

.mdl-color-text--red-800 {
    color: #c62828 !important;
}

.mdl-color--red-800 {
    background-color: #c62828 !important;
}

.mdl-color-text--red-900 {
    color: #b71c1c !important;
}

.mdl-color--red-900 {
    background-color: #b71c1c !important;
}

.mdl-color-text--red-A100 {
    color: #ff8a80 !important;
}

.mdl-color--red-A100 {
    background-color: #ff8a80 !important;
}

.mdl-color-text--red-A200 {
    color: #ff5252 !important;
}

.mdl-color--red-A200 {
    background-color: #ff5252 !important;
}

.mdl-color-text--red-A400 {
    color: #ff1744 !important;
}

.mdl-color--red-A400 {
    background-color: #ff1744 !important;
}

.mdl-color-text--red-A700 {
    color: #d50000 !important;
}

.mdl-color--red-A700 {
    background-color: #d50000 !important;
}

.mdl-color-text--pink {
    color: #e91e63 !important;
}

.mdl-color--pink {
    background-color: #e91e63 !important;
}

.mdl-color-text--pink-50 {
    color: #fce4ec !important;
}

.mdl-color--pink-50 {
    background-color: #fce4ec !important;
}

.mdl-color-text--pink-100 {
    color: #f8bbd0 !important;
}

.mdl-color--pink-100 {
    background-color: #f8bbd0 !important;
}

.mdl-color-text--pink-200 {
    color: #f48fb1 !important;
}

.mdl-color--pink-200 {
    background-color: #f48fb1 !important;
}

.mdl-color-text--pink-300 {
    color: #f06292 !important;
}

.mdl-color--pink-300 {
    background-color: #f06292 !important;
}

.mdl-color-text--pink-400 {
    color: #ec407a !important;
}

.mdl-color--pink-400 {
    background-color: #ec407a !important;
}

.mdl-color-text--pink-500 {
    color: #e91e63 !important;
}

.mdl-color--pink-500 {
    background-color: #e91e63 !important;
}

.mdl-color-text--pink-600 {
    color: #d81b60 !important;
}

.mdl-color--pink-600 {
    background-color: #d81b60 !important;
}

.mdl-color-text--pink-700 {
    color: #c2185b !important;
}

.mdl-color--pink-700 {
    background-color: #c2185b !important;
}

.mdl-color-text--pink-800 {
    color: #ad1457 !important;
}

.mdl-color--pink-800 {
    background-color: #ad1457 !important;
}

.mdl-color-text--pink-900 {
    color: #880e4f !important;
}

.mdl-color--pink-900 {
    background-color: #880e4f !important;
}

.mdl-color-text--pink-A100 {
    color: #ff80ab !important;
}

.mdl-color--pink-A100 {
    background-color: #ff80ab !important;
}

.mdl-color-text--pink-A200 {
    color: #ff4081 !important;
}

.mdl-color--pink-A200 {
    background-color: #ff4081 !important;
}

.mdl-color-text--pink-A400 {
    color: #f50057 !important;
}

.mdl-color--pink-A400 {
    background-color: #f50057 !important;
}

.mdl-color-text--pink-A700 {
    color: #c51162 !important;
}

.mdl-color--pink-A700 {
    background-color: #c51162 !important;
}

.mdl-color-text--purple {
    color: #9c27b0 !important;
}

.mdl-color--purple {
    background-color: #9c27b0 !important;
}

.mdl-color-text--purple-50 {
    color: #f3e5f5 !important;
}

.mdl-color--purple-50 {
    background-color: #f3e5f5 !important;
}

.mdl-color-text--purple-100 {
    color: #e1bee7 !important;
}

.mdl-color--purple-100 {
    background-color: #e1bee7 !important;
}

.mdl-color-text--purple-200 {
    color: #ce93d8 !important;
}

.mdl-color--purple-200 {
    background-color: #ce93d8 !important;
}

.mdl-color-text--purple-300 {
    color: #ba68c8 !important;
}

.mdl-color--purple-300 {
    background-color: #ba68c8 !important;
}

.mdl-color-text--purple-400 {
    color: #ab47bc !important;
}

.mdl-color--purple-400 {
    background-color: #ab47bc !important;
}

.mdl-color-text--purple-500 {
    color: #9c27b0 !important;
}

.mdl-color--purple-500 {
    background-color: #9c27b0 !important;
}

.mdl-color-text--purple-600 {
    color: #8e24aa !important;
}

.mdl-color--purple-600 {
    background-color: #8e24aa !important;
}

.mdl-color-text--purple-700 {
    color: #7b1fa2 !important;
}

.mdl-color--purple-700 {
    background-color: #7b1fa2 !important;
}

.mdl-color-text--purple-800 {
    color: #6a1b9a !important;
}

.mdl-color--purple-800 {
    background-color: #6a1b9a !important;
}

.mdl-color-text--purple-900 {
    color: #4a148c !important;
}

.mdl-color--purple-900 {
    background-color: #4a148c !important;
}

.mdl-color-text--purple-A100 {
    color: #ea80fc !important;
}

.mdl-color--purple-A100 {
    background-color: #ea80fc !important;
}

.mdl-color-text--purple-A200 {
    color: #e040fb !important;
}

.mdl-color--purple-A200 {
    background-color: #e040fb !important;
}

.mdl-color-text--purple-A400 {
    color: #d500f9 !important;
}

.mdl-color--purple-A400 {
    background-color: #d500f9 !important;
}

.mdl-color-text--purple-A700 {
    color: #a0f !important;
}

.mdl-color--purple-A700 {
    background-color: #a0f !important;
}

.mdl-color-text--deep-purple {
    color: #673ab7 !important;
}

.mdl-color--deep-purple {
    background-color: #673ab7 !important;
}

.mdl-color-text--deep-purple-50 {
    color: #ede7f6 !important;
}

.mdl-color--deep-purple-50 {
    background-color: #ede7f6 !important;
}

.mdl-color-text--deep-purple-100 {
    color: #d1c4e9 !important;
}

.mdl-color--deep-purple-100 {
    background-color: #d1c4e9 !important;
}

.mdl-color-text--deep-purple-200 {
    color: #b39ddb !important;
}

.mdl-color--deep-purple-200 {
    background-color: #b39ddb !important;
}

.mdl-color-text--deep-purple-300 {
    color: #9575cd !important;
}

.mdl-color--deep-purple-300 {
    background-color: #9575cd !important;
}

.mdl-color-text--deep-purple-400 {
    color: #7e57c2 !important;
}

.mdl-color--deep-purple-400 {
    background-color: #7e57c2 !important;
}

.mdl-color-text--deep-purple-500 {
    color: #673ab7 !important;
}

.mdl-color--deep-purple-500 {
    background-color: #673ab7 !important;
}

.mdl-color-text--deep-purple-600 {
    color: #5e35b1 !important;
}

.mdl-color--deep-purple-600 {
    background-color: #5e35b1 !important;
}

.mdl-color-text--deep-purple-700 {
    color: #512da8 !important;
}

.mdl-color--deep-purple-700 {
    background-color: #512da8 !important;
}

.mdl-color-text--deep-purple-800 {
    color: #4527a0 !important;
}

.mdl-color--deep-purple-800 {
    background-color: #4527a0 !important;
}

.mdl-color-text--deep-purple-900 {
    color: #311b92 !important;
}

.mdl-color--deep-purple-900 {
    background-color: #311b92 !important;
}

.mdl-color-text--deep-purple-A100 {
    color: #b388ff !important;
}

.mdl-color--deep-purple-A100 {
    background-color: #b388ff !important;
}

.mdl-color-text--deep-purple-A200 {
    color: #7c4dff !important;
}

.mdl-color--deep-purple-A200 {
    background-color: #7c4dff !important;
}

.mdl-color-text--deep-purple-A400 {
    color: #651fff !important;
}

.mdl-color--deep-purple-A400 {
    background-color: #651fff !important;
}

.mdl-color-text--deep-purple-A700 {
    color: #6200ea !important;
}

.mdl-color--deep-purple-A700 {
    background-color: #6200ea !important;
}

.mdl-color-text--indigo {
    color: #3f51b5 !important;
}

.mdl-color--indigo {
    background-color: #3f51b5 !important;
}

.mdl-color-text--indigo-50 {
    color: #e8eaf6 !important;
}

.mdl-color--indigo-50 {
    background-color: #e8eaf6 !important;
}

.mdl-color-text--indigo-100 {
    color: #c5cae9 !important;
}

.mdl-color--indigo-100 {
    background-color: #c5cae9 !important;
}

.mdl-color-text--indigo-200 {
    color: #9fa8da !important;
}

.mdl-color--indigo-200 {
    background-color: #9fa8da !important;
}

.mdl-color-text--indigo-300 {
    color: #7986cb !important;
}

.mdl-color--indigo-300 {
    background-color: #7986cb !important;
}

.mdl-color-text--indigo-400 {
    color: #5c6bc0 !important;
}

.mdl-color--indigo-400 {
    background-color: #5c6bc0 !important;
}

.mdl-color-text--indigo-500 {
    color: #3f51b5 !important;
}

.mdl-color--indigo-500 {
    background-color: #3f51b5 !important;
}

.mdl-color-text--indigo-600 {
    color: #3949ab !important;
}

.mdl-color--indigo-600 {
    background-color: #3949ab !important;
}

.mdl-color-text--indigo-700 {
    color: #303f9f !important;
}

.mdl-color--indigo-700 {
    background-color: #303f9f !important;
}

.mdl-color-text--indigo-800 {
    color: #283593 !important;
}

.mdl-color--indigo-800 {
    background-color: #283593 !important;
}

.mdl-color-text--indigo-900 {
    color: #1a237e !important;
}

.mdl-color--indigo-900 {
    background-color: #1a237e !important;
}

.mdl-color-text--indigo-A100 {
    color: #8c9eff !important;
}

.mdl-color--indigo-A100 {
    background-color: #8c9eff !important;
}

.mdl-color-text--indigo-A200 {
    color: #536dfe !important;
}

.mdl-color--indigo-A200 {
    background-color: #536dfe !important;
}

.mdl-color-text--indigo-A400 {
    color: #3d5afe !important;
}

.mdl-color--indigo-A400 {
    background-color: #3d5afe !important;
}

.mdl-color-text--indigo-A700 {
    color: #304ffe !important;
}

.mdl-color--indigo-A700 {
    background-color: #304ffe !important;
}

.mdl-color-text--blue {
    color: #2196f3 !important;
}

.mdl-color--blue {
    background-color: #2196f3 !important;
}

.mdl-color-text--blue-50 {
    color: #e3f2fd !important;
}

.mdl-color--blue-50 {
    background-color: #e3f2fd !important;
}

.mdl-color-text--blue-100 {
    color: #bbdefb !important;
}

.mdl-color--blue-100 {
    background-color: #bbdefb !important;
}

.mdl-color-text--blue-200 {
    color: #90caf9 !important;
}

.mdl-color--blue-200 {
    background-color: #90caf9 !important;
}

.mdl-color-text--blue-300 {
    color: #64b5f6 !important;
}

.mdl-color--blue-300 {
    background-color: #64b5f6 !important;
}

.mdl-color-text--blue-400 {
    color: #42a5f5 !important;
}

.mdl-color--blue-400 {
    background-color: #42a5f5 !important;
}

.mdl-color-text--blue-500 {
    color: #2196f3 !important;
}

.mdl-color--blue-500 {
    background-color: #2196f3 !important;
}

.mdl-color-text--blue-600 {
    color: #1e88e5 !important;
}

.mdl-color--blue-600 {
    background-color: #1e88e5 !important;
}

.mdl-color-text--blue-700 {
    color: #1976d2 !important;
}

.mdl-color--blue-700 {
    background-color: #1976d2 !important;
}

.mdl-color-text--blue-800 {
    color: #1565c0 !important;
}

.mdl-color--blue-800 {
    background-color: #1565c0 !important;
}

.mdl-color-text--blue-900 {
    color: #0d47a1 !important;
}

.mdl-color--blue-900 {
    background-color: #0d47a1 !important;
}

.mdl-color-text--blue-A100 {
    color: #82b1ff !important;
}

.mdl-color--blue-A100 {
    background-color: #82b1ff !important;
}

.mdl-color-text--blue-A200 {
    color: #448aff !important;
}

.mdl-color--blue-A200 {
    background-color: #448aff !important;
}

.mdl-color-text--blue-A400 {
    color: #2979ff !important;
}

.mdl-color--blue-A400 {
    background-color: #2979ff !important;
}

.mdl-color-text--blue-A700 {
    color: #2962ff !important;
}

.mdl-color--blue-A700 {
    background-color: #2962ff !important;
}

.mdl-color-text--light-blue {
    color: #03a9f4 !important;
}

.mdl-color--light-blue {
    background-color: #03a9f4 !important;
}

.mdl-color-text--light-blue-50 {
    color: #e1f5fe !important;
}

.mdl-color--light-blue-50 {
    background-color: #e1f5fe !important;
}

.mdl-color-text--light-blue-100 {
    color: #b3e5fc !important;
}

.mdl-color--light-blue-100 {
    background-color: #b3e5fc !important;
}

.mdl-color-text--light-blue-200 {
    color: #81d4fa !important;
}

.mdl-color--light-blue-200 {
    background-color: #81d4fa !important;
}

.mdl-color-text--light-blue-300 {
    color: #4fc3f7 !important;
}

.mdl-color--light-blue-300 {
    background-color: #4fc3f7 !important;
}

.mdl-color-text--light-blue-400 {
    color: #29b6f6 !important;
}

.mdl-color--light-blue-400 {
    background-color: #29b6f6 !important;
}

.mdl-color-text--light-blue-500 {
    color: #03a9f4 !important;
}

.mdl-color--light-blue-500 {
    background-color: #03a9f4 !important;
}

.mdl-color-text--light-blue-600 {
    color: #039be5 !important;
}

.mdl-color--light-blue-600 {
    background-color: #039be5 !important;
}

.mdl-color-text--light-blue-700 {
    color: #0288d1 !important;
}

.mdl-color--light-blue-700 {
    background-color: #0288d1 !important;
}

.mdl-color-text--light-blue-800 {
    color: #0277bd !important;
}

.mdl-color--light-blue-800 {
    background-color: #0277bd !important;
}

.mdl-color-text--light-blue-900 {
    color: #01579b !important;
}

.mdl-color--light-blue-900 {
    background-color: #01579b !important;
}

.mdl-color-text--light-blue-A100 {
    color: #80d8ff !important;
}

.mdl-color--light-blue-A100 {
    background-color: #80d8ff !important;
}

.mdl-color-text--light-blue-A200 {
    color: #40c4ff !important;
}

.mdl-color--light-blue-A200 {
    background-color: #40c4ff !important;
}

.mdl-color-text--light-blue-A400 {
    color: #00b0ff !important;
}

.mdl-color--light-blue-A400 {
    background-color: #00b0ff !important;
}

.mdl-color-text--light-blue-A700 {
    color: #0091ea !important;
}

.mdl-color--light-blue-A700 {
    background-color: #0091ea !important;
}

.mdl-color-text--cyan {
    color: #00bcd4 !important;
}

.mdl-color--cyan {
    background-color: #00bcd4 !important;
}

.mdl-color-text--cyan-50 {
    color: #e0f7fa !important;
}

.mdl-color--cyan-50 {
    background-color: #e0f7fa !important;
}

.mdl-color-text--cyan-100 {
    color: #b2ebf2 !important;
}

.mdl-color--cyan-100 {
    background-color: #b2ebf2 !important;
}

.mdl-color-text--cyan-200 {
    color: #80deea !important;
}

.mdl-color--cyan-200 {
    background-color: #80deea !important;
}

.mdl-color-text--cyan-300 {
    color: #4dd0e1 !important;
}

.mdl-color--cyan-300 {
    background-color: #4dd0e1 !important;
}

.mdl-color-text--cyan-400 {
    color: #26c6da !important;
}

.mdl-color--cyan-400 {
    background-color: #26c6da !important;
}

.mdl-color-text--cyan-500 {
    color: #00bcd4 !important;
}

.mdl-color--cyan-500 {
    background-color: #00bcd4 !important;
}

.mdl-color-text--cyan-600 {
    color: #00acc1 !important;
}

.mdl-color--cyan-600 {
    background-color: #00acc1 !important;
}

.mdl-color-text--cyan-700 {
    color: #0097a7 !important;
}

.mdl-color--cyan-700 {
    background-color: #0097a7 !important;
}

.mdl-color-text--cyan-800 {
    color: #00838f !important;
}

.mdl-color--cyan-800 {
    background-color: #00838f !important;
}

.mdl-color-text--cyan-900 {
    color: #006064 !important;
}

.mdl-color--cyan-900 {
    background-color: #006064 !important;
}

.mdl-color-text--cyan-A100 {
    color: #84ffff !important;
}

.mdl-color--cyan-A100 {
    background-color: #84ffff !important;
}

.mdl-color-text--cyan-A200 {
    color: #18ffff !important;
}

.mdl-color--cyan-A200 {
    background-color: #18ffff !important;
}

.mdl-color-text--cyan-A400 {
    color: #00e5ff !important;
}

.mdl-color--cyan-A400 {
    background-color: #00e5ff !important;
}

.mdl-color-text--cyan-A700 {
    color: #00b8d4 !important;
}

.mdl-color--cyan-A700 {
    background-color: #00b8d4 !important;
}

.mdl-color-text--teal {
    color: #009688 !important;
}

.mdl-color--teal {
    background-color: #009688 !important;
}

.mdl-color-text--teal-50 {
    color: #e0f2f1 !important;
}

.mdl-color--teal-50 {
    background-color: #e0f2f1 !important;
}

.mdl-color-text--teal-100 {
    color: #b2dfdb !important;
}

.mdl-color--teal-100 {
    background-color: #b2dfdb !important;
}

.mdl-color-text--teal-200 {
    color: #80cbc4 !important;
}

.mdl-color--teal-200 {
    background-color: #80cbc4 !important;
}

.mdl-color-text--teal-300 {
    color: #4db6ac !important;
}

.mdl-color--teal-300 {
    background-color: #4db6ac !important;
}

.mdl-color-text--teal-400 {
    color: #26a69a !important;
}

.mdl-color--teal-400 {
    background-color: #26a69a !important;
}

.mdl-color-text--teal-500 {
    color: #009688 !important;
}

.mdl-color--teal-500 {
    background-color: #009688 !important;
}

.mdl-color-text--teal-600 {
    color: #00897b !important;
}

.mdl-color--teal-600 {
    background-color: #00897b !important;
}

.mdl-color-text--teal-700 {
    color: #00796b !important;
}

.mdl-color--teal-700 {
    background-color: #00796b !important;
}

.mdl-color-text--teal-800 {
    color: #00695c !important;
}

.mdl-color--teal-800 {
    background-color: #00695c !important;
}

.mdl-color-text--teal-900 {
    color: #004d40 !important;
}

.mdl-color--teal-900 {
    background-color: #004d40 !important;
}

.mdl-color-text--teal-A100 {
    color: #a7ffeb !important;
}

.mdl-color--teal-A100 {
    background-color: #a7ffeb !important;
}

.mdl-color-text--teal-A200 {
    color: #64ffda !important;
}

.mdl-color--teal-A200 {
    background-color: #64ffda !important;
}

.mdl-color-text--teal-A400 {
    color: #1de9b6 !important;
}

.mdl-color--teal-A400 {
    background-color: #1de9b6 !important;
}

.mdl-color-text--teal-A700 {
    color: #00bfa5 !important;
}

.mdl-color--teal-A700 {
    background-color: #00bfa5 !important;
}

.mdl-color-text--green {
    color: #4caf50 !important;
}

.mdl-color--green {
    background-color: #4caf50 !important;
}

.mdl-color-text--green-50 {
    color: #e8f5e9 !important;
}

.mdl-color--green-50 {
    background-color: #e8f5e9 !important;
}

.mdl-color-text--green-100 {
    color: #c8e6c9 !important;
}

.mdl-color--green-100 {
    background-color: #c8e6c9 !important;
}

.mdl-color-text--green-200 {
    color: #a5d6a7 !important;
}

.mdl-color--green-200 {
    background-color: #a5d6a7 !important;
}

.mdl-color-text--green-300 {
    color: #81c784 !important;
}

.mdl-color--green-300 {
    background-color: #81c784 !important;
}

.mdl-color-text--green-400 {
    color: #66bb6a !important;
}

.mdl-color--green-400 {
    background-color: #66bb6a !important;
}

.mdl-color-text--green-500 {
    color: #4caf50 !important;
}

.mdl-color--green-500 {
    background-color: #4caf50 !important;
}

.mdl-color-text--green-600 {
    color: #43a047 !important;
}

.mdl-color--green-600 {
    background-color: #43a047 !important;
}

.mdl-color-text--green-700 {
    color: #388e3c !important;
}

.mdl-color--green-700 {
    background-color: #388e3c !important;
}

.mdl-color-text--green-800 {
    color: #2e7d32 !important;
}

.mdl-color--green-800 {
    background-color: #2e7d32 !important;
}

.mdl-color-text--green-900 {
    color: #1b5e20 !important;
}

.mdl-color--green-900 {
    background-color: #1b5e20 !important;
}

.mdl-color-text--green-A100 {
    color: #b9f6ca !important;
}

.mdl-color--green-A100 {
    background-color: #b9f6ca !important;
}

.mdl-color-text--green-A200 {
    color: #69f0ae !important;
}

.mdl-color--green-A200 {
    background-color: #69f0ae !important;
}

.mdl-color-text--green-A400 {
    color: #00e676 !important;
}

.mdl-color--green-A400 {
    background-color: #00e676 !important;
}

.mdl-color-text--green-A700 {
    color: #00c853 !important;
}

.mdl-color--green-A700 {
    background-color: #00c853 !important;
}

.mdl-color-text--light-green {
    color: #8bc34a !important;
}

.mdl-color--light-green {
    background-color: #8bc34a !important;
}

.mdl-color-text--light-green-50 {
    color: #f1f8e9 !important;
}

.mdl-color--light-green-50 {
    background-color: #f1f8e9 !important;
}

.mdl-color-text--light-green-100 {
    color: #dcedc8 !important;
}

.mdl-color--light-green-100 {
    background-color: #dcedc8 !important;
}

.mdl-color-text--light-green-200 {
    color: #c5e1a5 !important;
}

.mdl-color--light-green-200 {
    background-color: #c5e1a5 !important;
}

.mdl-color-text--light-green-300 {
    color: #aed581 !important;
}

.mdl-color--light-green-300 {
    background-color: #aed581 !important;
}

.mdl-color-text--light-green-400 {
    color: #9ccc65 !important;
}

.mdl-color--light-green-400 {
    background-color: #9ccc65 !important;
}

.mdl-color-text--light-green-500 {
    color: #8bc34a !important;
}

.mdl-color--light-green-500 {
    background-color: #8bc34a !important;
}

.mdl-color-text--light-green-600 {
    color: #7cb342 !important;
}

.mdl-color--light-green-600 {
    background-color: #7cb342 !important;
}

.mdl-color-text--light-green-700 {
    color: #689f38 !important;
}

.mdl-color--light-green-700 {
    background-color: #689f38 !important;
}

.mdl-color-text--light-green-800 {
    color: #558b2f !important;
}

.mdl-color--light-green-800 {
    background-color: #558b2f !important;
}

.mdl-color-text--light-green-900 {
    color: #33691e !important;
}

.mdl-color--light-green-900 {
    background-color: #33691e !important;
}

.mdl-color-text--light-green-A100 {
    color: #ccff90 !important;
}

.mdl-color--light-green-A100 {
    background-color: #ccff90 !important;
}

.mdl-color-text--light-green-A200 {
    color: #b2ff59 !important;
}

.mdl-color--light-green-A200 {
    background-color: #b2ff59 !important;
}

.mdl-color-text--light-green-A400 {
    color: #76ff03 !important;
}

.mdl-color--light-green-A400 {
    background-color: #76ff03 !important;
}

.mdl-color-text--light-green-A700 {
    color: #64dd17 !important;
}

.mdl-color--light-green-A700 {
    background-color: #64dd17 !important;
}

.mdl-color-text--lime {
    color: #cddc39 !important;
}

.mdl-color--lime {
    background-color: #cddc39 !important;
}

.mdl-color-text--lime-50 {
    color: #f9fbe7 !important;
}

.mdl-color--lime-50 {
    background-color: #f9fbe7 !important;
}

.mdl-color-text--lime-100 {
    color: #f0f4c3 !important;
}

.mdl-color--lime-100 {
    background-color: #f0f4c3 !important;
}

.mdl-color-text--lime-200 {
    color: #e6ee9c !important;
}

.mdl-color--lime-200 {
    background-color: #e6ee9c !important;
}

.mdl-color-text--lime-300 {
    color: #dce775 !important;
}

.mdl-color--lime-300 {
    background-color: #dce775 !important;
}

.mdl-color-text--lime-400 {
    color: #d4e157 !important;
}

.mdl-color--lime-400 {
    background-color: #d4e157 !important;
}

.mdl-color-text--lime-500 {
    color: #cddc39 !important;
}

.mdl-color--lime-500 {
    background-color: #cddc39 !important;
}

.mdl-color-text--lime-600 {
    color: #c0ca33 !important;
}

.mdl-color--lime-600 {
    background-color: #c0ca33 !important;
}

.mdl-color-text--lime-700 {
    color: #afb42b !important;
}

.mdl-color--lime-700 {
    background-color: #afb42b !important;
}

.mdl-color-text--lime-800 {
    color: #9e9d24 !important;
}

.mdl-color--lime-800 {
    background-color: #9e9d24 !important;
}

.mdl-color-text--lime-900 {
    color: #827717 !important;
}

.mdl-color--lime-900 {
    background-color: #827717 !important;
}

.mdl-color-text--lime-A100 {
    color: #f4ff81 !important;
}

.mdl-color--lime-A100 {
    background-color: #f4ff81 !important;
}

.mdl-color-text--lime-A200 {
    color: #eeff41 !important;
}

.mdl-color--lime-A200 {
    background-color: #eeff41 !important;
}

.mdl-color-text--lime-A400 {
    color: #c6ff00 !important;
}

.mdl-color--lime-A400 {
    background-color: #c6ff00 !important;
}

.mdl-color-text--lime-A700 {
    color: #aeea00 !important;
}

.mdl-color--lime-A700 {
    background-color: #aeea00 !important;
}

.mdl-color-text--yellow {
    color: #ffeb3b !important;
}

.mdl-color--yellow {
    background-color: #ffeb3b !important;
}

.mdl-color-text--yellow-50 {
    color: #fffde7 !important;
}

.mdl-color--yellow-50 {
    background-color: #fffde7 !important;
}

.mdl-color-text--yellow-100 {
    color: #fff9c4 !important;
}

.mdl-color--yellow-100 {
    background-color: #fff9c4 !important;
}

.mdl-color-text--yellow-200 {
    color: #fff59d !important;
}

.mdl-color--yellow-200 {
    background-color: #fff59d !important;
}

.mdl-color-text--yellow-300 {
    color: #fff176 !important;
}

.mdl-color--yellow-300 {
    background-color: #fff176 !important;
}

.mdl-color-text--yellow-400 {
    color: #ffee58 !important;
}

.mdl-color--yellow-400 {
    background-color: #ffee58 !important;
}

.mdl-color-text--yellow-500 {
    color: #ffeb3b !important;
}

.mdl-color--yellow-500 {
    background-color: #ffeb3b !important;
}

.mdl-color-text--yellow-600 {
    color: #fdd835 !important;
}

.mdl-color--yellow-600 {
    background-color: #fdd835 !important;
}

.mdl-color-text--yellow-700 {
    color: #fbc02d !important;
}

.mdl-color--yellow-700 {
    background-color: #fbc02d !important;
}

.mdl-color-text--yellow-800 {
    color: #f9a825 !important;
}

.mdl-color--yellow-800 {
    background-color: #f9a825 !important;
}

.mdl-color-text--yellow-900 {
    color: #f57f17 !important;
}

.mdl-color--yellow-900 {
    background-color: #f57f17 !important;
}

.mdl-color-text--yellow-A100 {
    color: #ffff8d !important;
}

.mdl-color--yellow-A100 {
    background-color: #ffff8d !important;
}

.mdl-color-text--yellow-A200 {
    color: #ff0 !important;
}

.mdl-color--yellow-A200 {
    background-color: #ff0 !important;
}

.mdl-color-text--yellow-A400 {
    color: #ffea00 !important;
}

.mdl-color--yellow-A400 {
    background-color: #ffea00 !important;
}

.mdl-color-text--yellow-A700 {
    color: #ffd600 !important;
}

.mdl-color--yellow-A700 {
    background-color: #ffd600 !important;
}

.mdl-color-text--amber {
    color: #ffc107 !important;
}

.mdl-color--amber {
    background-color: #ffc107 !important;
}

.mdl-color-text--amber-50 {
    color: #fff8e1 !important;
}

.mdl-color--amber-50 {
    background-color: #fff8e1 !important;
}

.mdl-color-text--amber-100 {
    color: #ffecb3 !important;
}

.mdl-color--amber-100 {
    background-color: #ffecb3 !important;
}

.mdl-color-text--amber-200 {
    color: #ffe082 !important;
}

.mdl-color--amber-200 {
    background-color: #ffe082 !important;
}

.mdl-color-text--amber-300 {
    color: #ffd54f !important;
}

.mdl-color--amber-300 {
    background-color: #ffd54f !important;
}

.mdl-color-text--amber-400 {
    color: #ffca28 !important;
}

.mdl-color--amber-400 {
    background-color: #ffca28 !important;
}

.mdl-color-text--amber-500 {
    color: #ffc107 !important;
}

.mdl-color--amber-500 {
    background-color: #ffc107 !important;
}

.mdl-color-text--amber-600 {
    color: #ffb300 !important;
}

.mdl-color--amber-600 {
    background-color: #ffb300 !important;
}

.mdl-color-text--amber-700 {
    color: #ffa000 !important;
}

.mdl-color--amber-700 {
    background-color: #ffa000 !important;
}

.mdl-color-text--amber-800 {
    color: #ff8f00 !important;
}

.mdl-color--amber-800 {
    background-color: #ff8f00 !important;
}

.mdl-color-text--amber-900 {
    color: #ff6f00 !important;
}

.mdl-color--amber-900 {
    background-color: #ff6f00 !important;
}

.mdl-color-text--amber-A100 {
    color: #ffe57f !important;
}

.mdl-color--amber-A100 {
    background-color: #ffe57f !important;
}

.mdl-color-text--amber-A200 {
    color: #ffd740 !important;
}

.mdl-color--amber-A200 {
    background-color: #ffd740 !important;
}

.mdl-color-text--amber-A400 {
    color: #ffc400 !important;
}

.mdl-color--amber-A400 {
    background-color: #ffc400 !important;
}

.mdl-color-text--amber-A700 {
    color: #ffab00 !important;
}

.mdl-color--amber-A700 {
    background-color: #ffab00 !important;
}

.mdl-color-text--orange {
    color: #ff9800 !important;
}

.mdl-color--orange {
    background-color: #ff9800 !important;
}

.mdl-color-text--orange-50 {
    color: #fff3e0 !important;
}

.mdl-color--orange-50 {
    background-color: #fff3e0 !important;
}

.mdl-color-text--orange-100 {
    color: #ffe0b2 !important;
}

.mdl-color--orange-100 {
    background-color: #ffe0b2 !important;
}

.mdl-color-text--orange-200 {
    color: #ffcc80 !important;
}

.mdl-color--orange-200 {
    background-color: #ffcc80 !important;
}

.mdl-color-text--orange-300 {
    color: #ffb74d !important;
}

.mdl-color--orange-300 {
    background-color: #ffb74d !important;
}

.mdl-color-text--orange-400 {
    color: #ffa726 !important;
}

.mdl-color--orange-400 {
    background-color: #ffa726 !important;
}

.mdl-color-text--orange-500 {
    color: #ff9800 !important;
}

.mdl-color--orange-500 {
    background-color: #ff9800 !important;
}

.mdl-color-text--orange-600 {
    color: #fb8c00 !important;
}

.mdl-color--orange-600 {
    background-color: #fb8c00 !important;
}

.mdl-color-text--orange-700 {
    color: #f57c00 !important;
}

.mdl-color--orange-700 {
    background-color: #f57c00 !important;
}

.mdl-color-text--orange-800 {
    color: #ef6c00 !important;
}

.mdl-color--orange-800 {
    background-color: #ef6c00 !important;
}

.mdl-color-text--orange-900 {
    color: #e65100 !important;
}

.mdl-color--orange-900 {
    background-color: #e65100 !important;
}

.mdl-color-text--orange-A100 {
    color: #ffd180 !important;
}

.mdl-color--orange-A100 {
    background-color: #ffd180 !important;
}

.mdl-color-text--orange-A200 {
    color: #ffab40 !important;
}

.mdl-color--orange-A200 {
    background-color: #ffab40 !important;
}

.mdl-color-text--orange-A400 {
    color: #ff9100 !important;
}

.mdl-color--orange-A400 {
    background-color: #ff9100 !important;
}

.mdl-color-text--orange-A700 {
    color: #ff6d00 !important;
}

.mdl-color--orange-A700 {
    background-color: #ff6d00 !important;
}

.mdl-color-text--deep-orange {
    color: #ff5722 !important;
}

.mdl-color--deep-orange {
    background-color: #ff5722 !important;
}

.mdl-color-text--deep-orange-50 {
    color: #fbe9e7 !important;
}

.mdl-color--deep-orange-50 {
    background-color: #fbe9e7 !important;
}

.mdl-color-text--deep-orange-100 {
    color: #ffccbc !important;
}

.mdl-color--deep-orange-100 {
    background-color: #ffccbc !important;
}

.mdl-color-text--deep-orange-200 {
    color: #ffab91 !important;
}

.mdl-color--deep-orange-200 {
    background-color: #ffab91 !important;
}

.mdl-color-text--deep-orange-300 {
    color: #ff8a65 !important;
}

.mdl-color--deep-orange-300 {
    background-color: #ff8a65 !important;
}

.mdl-color-text--deep-orange-400 {
    color: #ff7043 !important;
}

.mdl-color--deep-orange-400 {
    background-color: #ff7043 !important;
}

.mdl-color-text--deep-orange-500 {
    color: #ff5722 !important;
}

.mdl-color--deep-orange-500 {
    background-color: #ff5722 !important;
}

.mdl-color-text--deep-orange-600 {
    color: #f4511e !important;
}

.mdl-color--deep-orange-600 {
    background-color: #f4511e !important;
}

.mdl-color-text--deep-orange-700 {
    color: #e64a19 !important;
}

.mdl-color--deep-orange-700 {
    background-color: #e64a19 !important;
}

.mdl-color-text--deep-orange-800 {
    color: #d84315 !important;
}

.mdl-color--deep-orange-800 {
    background-color: #d84315 !important;
}

.mdl-color-text--deep-orange-900 {
    color: #bf360c !important;
}

.mdl-color--deep-orange-900 {
    background-color: #bf360c !important;
}

.mdl-color-text--deep-orange-A100 {
    color: #ff9e80 !important;
}

.mdl-color--deep-orange-A100 {
    background-color: #ff9e80 !important;
}

.mdl-color-text--deep-orange-A200 {
    color: #ff6e40 !important;
}

.mdl-color--deep-orange-A200 {
    background-color: #ff6e40 !important;
}

.mdl-color-text--deep-orange-A400 {
    color: #ff3d00 !important;
}

.mdl-color--deep-orange-A400 {
    background-color: #ff3d00 !important;
}

.mdl-color-text--deep-orange-A700 {
    color: #dd2c00 !important;
}

.mdl-color--deep-orange-A700 {
    background-color: #dd2c00 !important;
}

.mdl-color-text--brown {
    color: #795548 !important;
}

.mdl-color--brown {
    background-color: #795548 !important;
}

.mdl-color-text--brown-50 {
    color: #efebe9 !important;
}

.mdl-color--brown-50 {
    background-color: #efebe9 !important;
}

.mdl-color-text--brown-100 {
    color: #d7ccc8 !important;
}

.mdl-color--brown-100 {
    background-color: #d7ccc8 !important;
}

.mdl-color-text--brown-200 {
    color: #bcaaa4 !important;
}

.mdl-color--brown-200 {
    background-color: #bcaaa4 !important;
}

.mdl-color-text--brown-300 {
    color: #a1887f !important;
}

.mdl-color--brown-300 {
    background-color: #a1887f !important;
}

.mdl-color-text--brown-400 {
    color: #8d6e63 !important;
}

.mdl-color--brown-400 {
    background-color: #8d6e63 !important;
}

.mdl-color-text--brown-500 {
    color: #795548 !important;
}

.mdl-color--brown-500 {
    background-color: #795548 !important;
}

.mdl-color-text--brown-600 {
    color: #6d4c41 !important;
}

.mdl-color--brown-600 {
    background-color: #6d4c41 !important;
}

.mdl-color-text--brown-700 {
    color: #5d4037 !important;
}

.mdl-color--brown-700 {
    background-color: #5d4037 !important;
}

.mdl-color-text--brown-800 {
    color: #4e342e !important;
}

.mdl-color--brown-800 {
    background-color: #4e342e !important;
}

.mdl-color-text--brown-900 {
    color: #3e2723 !important;
}

.mdl-color--brown-900 {
    background-color: #3e2723 !important;
}

.mdl-color-text--grey {
    color: #9e9e9e !important;
}

.mdl-color--grey {
    background-color: #9e9e9e !important;
}

.mdl-color-text--grey-50 {
    color: #fafafa !important;
}

.mdl-color--grey-50 {
    background-color: #fafafa !important;
}

.mdl-color-text--grey-100 {
    color: #f5f5f5 !important;
}

.mdl-color--grey-100 {
    background-color: #f5f5f5 !important;
}

.mdl-color-text--grey-200 {
    color: #eee !important;
}

.mdl-color--grey-200 {
    background-color: #eee !important;
}

.mdl-color-text--grey-300 {
    color: #e0e0e0 !important;
}

.mdl-color--grey-300 {
    background-color: #e0e0e0 !important;
}

.mdl-color-text--grey-400 {
    color: #bdbdbd !important;
}

.mdl-color--grey-400 {
    background-color: #bdbdbd !important;
}

.mdl-color-text--grey-500 {
    color: #9e9e9e !important;
}

.mdl-color--grey-500 {
    background-color: #9e9e9e !important;
}

.mdl-color-text--grey-600 {
    color: #757575 !important;
}

.mdl-color--grey-600 {
    background-color: #757575 !important;
}

.mdl-color-text--grey-700 {
    color: #616161 !important;
}

.mdl-color--grey-700 {
    background-color: #616161 !important;
}

.mdl-color-text--grey-800 {
    color: #424242 !important;
}

.mdl-color--grey-800 {
    background-color: #424242 !important;
}

.mdl-color-text--grey-900 {
    color: #212121 !important;
}

.mdl-color--grey-900 {
    background-color: #212121 !important;
}

.mdl-color-text--blue-grey {
    color: #607d8b !important;
}

.mdl-color--blue-grey {
    background-color: #607d8b !important;
}

.mdl-color-text--blue-grey-50 {
    color: #eceff1 !important;
}

.mdl-color--blue-grey-50 {
    background-color: #eceff1 !important;
}

.mdl-color-text--blue-grey-100 {
    color: #cfd8dc !important;
}

.mdl-color--blue-grey-100 {
    background-color: #cfd8dc !important;
}

.mdl-color-text--blue-grey-200 {
    color: #b0bec5 !important;
}

.mdl-color--blue-grey-200 {
    background-color: #b0bec5 !important;
}

.mdl-color-text--blue-grey-300 {
    color: #90a4ae !important;
}

.mdl-color--blue-grey-300 {
    background-color: #90a4ae !important;
}

.mdl-color-text--blue-grey-400 {
    color: #78909c !important;
}

.mdl-color--blue-grey-400 {
    background-color: #78909c !important;
}

.mdl-color-text--blue-grey-500 {
    color: #607d8b !important;
}

.mdl-color--blue-grey-500 {
    background-color: #607d8b !important;
}

.mdl-color-text--blue-grey-600 {
    color: #546e7a !important;
}

.mdl-color--blue-grey-600 {
    background-color: #546e7a !important;
}

.mdl-color-text--blue-grey-700 {
    color: #455a64 !important;
}

.mdl-color--blue-grey-700 {
    background-color: #455a64 !important;
}

.mdl-color-text--blue-grey-800 {
    color: #37474f !important;
}

.mdl-color--blue-grey-800 {
    background-color: #37474f !important;
}

.mdl-color-text--blue-grey-900 {
    color: #263238 !important;
}

.mdl-color--blue-grey-900 {
    background-color: #263238 !important;
}

.mdl-color--black {
    background-color: #000 !important;
}

.mdl-color-text--black {
    color: #000 !important;
}

.mdl-color--white {
    background-color: #fff !important;
}

.mdl-color-text--white {
    color: #fff !important;
}

.mdl-color--primary {
    background-color: rgb(63, 81, 181) !important;
}

.mdl-color--primary-contrast {
    background-color: rgb(255, 255, 255) !important;
}

.mdl-color--primary-dark {
    background-color: rgb(48, 63, 159) !important;
}

.mdl-color--accent {
    background-color: rgb(255, 64, 129) !important;
}

.mdl-color--accent-contrast {
    background-color: rgb(255, 255, 255) !important;
}

.mdl-color-text--primary {
    color: rgb(63, 81, 181) !important;
}

.mdl-color-text--primary-contrast {
    color: rgb(255, 255, 255) !important;
}

.mdl-color-text--primary-dark {
    color: rgb(48, 63, 159) !important;
}

.mdl-color-text--accent {
    color: rgb(255, 64, 129) !important;
}

.mdl-color-text--accent-contrast {
    color: rgb(255, 255, 255) !important;
}

.mdl-ripple {
    background: #000;
    border-radius: 50%;
    height: 50px;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 50px;
    overflow: hidden;
}

.mdl-ripple.is-animating {
    transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1), width 0.3s cubic-bezier(0, 0, 0.2, 1), height 0.3s cubic-bezier(0, 0, 0.2, 1), opacity 0.6s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1), width 0.3s cubic-bezier(0, 0, 0.2, 1), height 0.3s cubic-bezier(0, 0, 0.2, 1), opacity 0.6s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.mdl-ripple.is-visible {
    opacity: 0.3;
}

.mdl-animation--default,
.mdl-animation--fast-out-slow-in {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-animation--linear-out-slow-in {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.mdl-animation--fast-out-linear-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.mdl-badge {
    position: relative;
    white-space: nowrap;
    margin-right: 24px;
}

.mdl-badge:not([data-badge]) {
    margin-right: auto;
}

.mdl-badge[data-badge]:after {
    content: attr(data-badge);
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: -11px;
    right: -24px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 600;
    font-size: 12px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: rgb(255, 64, 129);
    color: rgb(255, 255, 255);
}

.mdl-button .mdl-badge[data-badge]:after {
    top: -10px;
    right: -5px;
}

.mdl-badge.mdl-badge--no-background[data-badge]:after {
    color: rgb(255, 64, 129);
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 1px gray;
}

.mdl-badge.mdl-badge--overlap {
    margin-right: 10px;
}

.mdl-badge.mdl-badge--overlap:after {
    right: -10px;
}

.mdl-button {
    background: 0 0;
    border: none;
    border-radius: 2px;
    color: #000;
    position: relative;
    height: 36px;
    margin: 0;
    min-width: 64px;
    padding: 0 16px;
    display: inline-block;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0;
    overflow: hidden;
    will-change: box-shadow;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    outline: none;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    line-height: 36px;
    vertical-align: middle;
}

.mdl-button::-moz-focus-inner {
    border: 0;
}

.mdl-button:hover {
    background-color: rgba(158, 158, 158, 0.2);
}

.mdl-button:focus:not(:active) {
    background-color: rgba(0, 0, 0, 0.12);
}

.mdl-button:active {
    background-color: rgba(158, 158, 158, 0.4);
}

.mdl-button.mdl-button--colored {
    color: rgb(63, 81, 181);
}

.mdl-button.mdl-button--colored:focus:not(:active) {
    background-color: rgba(0, 0, 0, 0.12);
}

input.mdl-button[type='submit'] {
    -webkit-appearance: none;
}

.mdl-button--raised {
    background: rgba(158, 158, 158, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mdl-button--raised:active {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
    background-color: rgba(158, 158, 158, 0.4);
}

.mdl-button--raised:focus:not(:active) {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36);
    background-color: rgba(158, 158, 158, 0.4);
}

.mdl-button--raised.mdl-button--colored {
    background: rgb(63, 81, 181);
    color: rgb(255, 255, 255);
}

.mdl-button--raised.mdl-button--colored:hover {
    background-color: rgb(63, 81, 181);
}

.mdl-button--raised.mdl-button--colored:active {
    background-color: rgb(63, 81, 181);
}

.mdl-button--raised.mdl-button--colored:focus:not(:active) {
    background-color: rgb(63, 81, 181);
}

.mdl-button--raised.mdl-button--colored .mdl-ripple {
    background: rgb(255, 255, 255);
}

.mdl-button--fab {
    border-radius: 50%;
    font-size: 24px;
    height: 56px;
    margin: auto;
    min-width: 56px;
    width: 56px;
    padding: 0;
    overflow: hidden;
    background: rgba(158, 158, 158, 0.2);
    box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
    position: relative;
    line-height: normal;
}

.mdl-button--fab .material-icons {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-12px, -12px);
    transform: translate(-12px, -12px);
    line-height: 24px;
    width: 24px;
}

.mdl-button--fab.mdl-button--mini-fab {
    height: 40px;
    min-width: 40px;
    width: 40px;
}

.mdl-button--fab .mdl-button__ripple-container {
    border-radius: 50%;
    -webkit-mask-image: -webkit-radial-gradient(circle, #fff, #000);
}

.mdl-button--fab:active {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
    background-color: rgba(158, 158, 158, 0.4);
}

.mdl-button--fab:focus:not(:active) {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36);
    background-color: rgba(158, 158, 158, 0.4);
}

.mdl-button--fab.mdl-button--colored {
    background: rgb(255, 64, 129);
    color: rgb(255, 255, 255);
}

.mdl-button--fab.mdl-button--colored:hover {
    background-color: rgb(255, 64, 129);
}

.mdl-button--fab.mdl-button--colored:focus:not(:active) {
    background-color: rgb(255, 64, 129);
}

.mdl-button--fab.mdl-button--colored:active {
    background-color: rgb(255, 64, 129);
}

.mdl-button--fab.mdl-button--colored .mdl-ripple {
    background: rgb(255, 255, 255);
}

.mdl-button--icon {
    border-radius: 50%;
    font-size: 24px;
    height: 32px;
    margin-left: 0;
    margin-right: 0;
    min-width: 32px;
    width: 32px;
    padding: 0;
    overflow: hidden;
    color: inherit;
    line-height: normal;
}

.mdl-button--icon .material-icons {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-12px, -12px);
    transform: translate(-12px, -12px);
    line-height: 24px;
    width: 24px;
}

.mdl-button--icon.mdl-button--mini-icon {
    height: 24px;
    min-width: 24px;
    width: 24px;
}

.mdl-button--icon.mdl-button--mini-icon .material-icons {
    top: 0;
    left: 0;
}

.mdl-button--icon .mdl-button__ripple-container {
    border-radius: 50%;
    -webkit-mask-image: -webkit-radial-gradient(circle, #fff, #000);
}

.mdl-button__ripple-container {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    overflow: hidden;
}

.mdl-button[disabled] .mdl-button__ripple-container .mdl-ripple,
.mdl-button.mdl-button--disabled .mdl-button__ripple-container .mdl-ripple {
    background-color: transparent;
}

.mdl-button--primary.mdl-button--primary {
    color: rgb(63, 81, 181);
}

.mdl-button--primary.mdl-button--primary .mdl-ripple {
    background: rgb(255, 255, 255);
}

.mdl-button--primary.mdl-button--primary.mdl-button--raised,
.mdl-button--primary.mdl-button--primary.mdl-button--fab {
    color: rgb(255, 255, 255);
    background-color: rgb(63, 81, 181);
}

.mdl-button--accent.mdl-button--accent {
    color: rgb(255, 64, 129);
}

.mdl-button--accent.mdl-button--accent .mdl-ripple {
    background: rgb(255, 255, 255);
}

.mdl-button--accent.mdl-button--accent.mdl-button--raised,
.mdl-button--accent.mdl-button--accent.mdl-button--fab {
    color: rgb(255, 255, 255);
    background-color: rgb(255, 64, 129);
}

.mdl-button[disabled][disabled],
.mdl-button.mdl-button--disabled.mdl-button--disabled {
    color: rgba(0, 0, 0, 0.26);
    cursor: default;
    background-color: transparent;
}

.mdl-button--fab[disabled][disabled],
.mdl-button--fab.mdl-button--disabled.mdl-button--disabled {
    background-color: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.26);
}

.mdl-button--raised[disabled][disabled],
.mdl-button--raised.mdl-button--disabled.mdl-button--disabled {
    background-color: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
}

.mdl-button--colored[disabled][disabled],
.mdl-button--colored.mdl-button--disabled.mdl-button--disabled {
    color: rgba(0, 0, 0, 0.26);
}

.mdl-button .material-icons {
    vertical-align: middle;
}

.mdl-card {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 16px;
    font-weight: 400;
    min-height: 200px;
    overflow: hidden;
    width: 330px;
    z-index: 1;
    position: relative;
    background: #fff;
    border-radius: 2px;
    box-sizing: border-box;
}

.mdl-card__media {
    background-color: rgb(255, 64, 129);
    background-repeat: repeat;
    background-position: 50% 50%;
    background-size: cover;
    background-origin: padding-box;
    background-attachment: scroll;
    box-sizing: border-box;
}

.mdl-card__title {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #000;
    display: block;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: stretch;
    -ms-flex-pack: stretch;
    justify-content: stretch;
    line-height: normal;
    padding: 16px;
    -webkit-perspective-origin: 165px 56px;
    perspective-origin: 165px 56px;
    -webkit-transform-origin: 165px 56px;
    transform-origin: 165px 56px;
    box-sizing: border-box;
}

.mdl-card__title.mdl-card--border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.mdl-card__title-text {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    color: inherit;
    display: block;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-size: 24px;
    font-weight: 300;
    line-height: normal;
    overflow: hidden;
    -webkit-transform-origin: 149px 48px;
    transform-origin: 149px 48px;
    margin: 0;
}

.mdl-card__subtitle-text {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
    margin: 0;
}

.mdl-card__supporting-text {
    color: rgba(0, 0, 0, 0.54);
    font-size: 1rem;
    line-height: 18px;
    overflow: hidden;
    padding: 16px;
    width: 90%;
}

.mdl-card__supporting-text.mdl-card--border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.mdl-card__actions {
    font-size: 16px;
    line-height: normal;
    width: 100%;
    background-color: transparent;
    padding: 8px;
    box-sizing: border-box;
}

.mdl-card__actions.mdl-card--border {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.mdl-card--expand {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.mdl-card__menu {
    position: absolute;
    right: 16px;
    top: 16px;
}

.mdl-checkbox {
    position: relative;
    z-index: 1;
    vertical-align: middle;
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    height: 24px;
    margin: 0;
    padding: 0;
}

.mdl-checkbox.is-upgraded {
    padding-left: 24px;
}

.mdl-checkbox__input {
    line-height: 24px;
}

.mdl-checkbox.is-upgraded .mdl-checkbox__input {
    position: absolute;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    -ms-appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
}

.mdl-checkbox__box-outline {
    position: absolute;
    top: 3px;
    left: 0;
    display: inline-block;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    margin: 0;
    cursor: pointer;
    overflow: hidden;
    border: 2px solid rgba(0, 0, 0, 0.54);
    border-radius: 2px;
    z-index: 2;
}

.mdl-checkbox.is-checked .mdl-checkbox__box-outline {
    border: 2px solid rgb(63, 81, 181);
}

fieldset[disabled] .mdl-checkbox .mdl-checkbox__box-outline,
.mdl-checkbox.is-disabled .mdl-checkbox__box-outline {
    border: 2px solid rgba(0, 0, 0, 0.26);
    cursor: auto;
}

.mdl-checkbox__focus-helper {
    position: absolute;
    top: 3px;
    left: 0;
    display: inline-block;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: transparent;
}

.mdl-checkbox.is-focused .mdl-checkbox__focus-helper {
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.1);
}

.mdl-checkbox.is-focused.is-checked .mdl-checkbox__focus-helper {
    box-shadow: 0 0 0 8px rgba(63, 81, 181, 0.26);
    background-color: rgba(63, 81, 181, 0.26);
}

.mdl-checkbox__tick-outline {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    -webkit-mask: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8ZGVmcz4KICAgIDxjbGlwUGF0aCBpZD0iY2xpcCI+CiAgICAgIDxwYXRoCiAgICAgICAgIGQ9Ik0gMCwwIDAsMSAxLDEgMSwwIDAsMCB6IE0gMC44NTM0Mzc1LDAuMTY3MTg3NSAwLjk1OTY4NzUsMC4yNzMxMjUgMC40MjkzNzUsMC44MDM0Mzc1IDAuMzIzMTI1LDAuOTA5Njg3NSAwLjIxNzE4NzUsMC44MDM0Mzc1IDAuMDQwMzEyNSwwLjYyNjg3NSAwLjE0NjU2MjUsMC41MjA2MjUgMC4zMjMxMjUsMC42OTc1IDAuODUzNDM3NSwwLjE2NzE4NzUgeiIKICAgICAgICAgc3R5bGU9ImZpbGw6I2ZmZmZmZjtmaWxsLW9wYWNpdHk6MTtzdHJva2U6bm9uZSIgLz4KICAgIDwvY2xpcFBhdGg+CiAgICA8bWFzayBpZD0ibWFzayIgbWFza1VuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgbWFza0NvbnRlbnRVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giPgogICAgICA8cGF0aAogICAgICAgICBkPSJNIDAsMCAwLDEgMSwxIDEsMCAwLDAgeiBNIDAuODUzNDM3NSwwLjE2NzE4NzUgMC45NTk2ODc1LDAuMjczMTI1IDAuNDI5Mzc1LDAuODAzNDM3NSAwLjMyMzEyNSwwLjkwOTY4NzUgMC4yMTcxODc1LDAuODAzNDM3NSAwLjA0MDMxMjUsMC42MjY4NzUgMC4xNDY1NjI1LDAuNTIwNjI1IDAuMzIzMTI1LDAuNjk3NSAwLjg1MzQzNzUsMC4xNjcxODc1IHoiCiAgICAgICAgIHN0eWxlPSJmaWxsOiNmZmZmZmY7ZmlsbC1vcGFjaXR5OjE7c3Ryb2tlOm5vbmUiIC8+CiAgICA8L21hc2s+CiAgPC9kZWZzPgogIDxyZWN0CiAgICAgd2lkdGg9IjEiCiAgICAgaGVpZ2h0PSIxIgogICAgIHg9IjAiCiAgICAgeT0iMCIKICAgICBjbGlwLXBhdGg9InVybCgjY2xpcCkiCiAgICAgc3R5bGU9ImZpbGw6IzAwMDAwMDtmaWxsLW9wYWNpdHk6MTtzdHJva2U6bm9uZSIgLz4KPC9zdmc+Cg==');
    mask: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8ZGVmcz4KICAgIDxjbGlwUGF0aCBpZD0iY2xpcCI+CiAgICAgIDxwYXRoCiAgICAgICAgIGQ9Ik0gMCwwIDAsMSAxLDEgMSwwIDAsMCB6IE0gMC44NTM0Mzc1LDAuMTY3MTg3NSAwLjk1OTY4NzUsMC4yNzMxMjUgMC40MjkzNzUsMC44MDM0Mzc1IDAuMzIzMTI1LDAuOTA5Njg3NSAwLjIxNzE4NzUsMC44MDM0Mzc1IDAuMDQwMzEyNSwwLjYyNjg3NSAwLjE0NjU2MjUsMC41MjA2MjUgMC4zMjMxMjUsMC42OTc1IDAuODUzNDM3NSwwLjE2NzE4NzUgeiIKICAgICAgICAgc3R5bGU9ImZpbGw6I2ZmZmZmZjtmaWxsLW9wYWNpdHk6MTtzdHJva2U6bm9uZSIgLz4KICAgIDwvY2xpcFBhdGg+CiAgICA8bWFzayBpZD0ibWFzayIgbWFza1VuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgbWFza0NvbnRlbnRVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giPgogICAgICA8cGF0aAogICAgICAgICBkPSJNIDAsMCAwLDEgMSwxIDEsMCAwLDAgeiBNIDAuODUzNDM3NSwwLjE2NzE4NzUgMC45NTk2ODc1LDAuMjczMTI1IDAuNDI5Mzc1LDAuODAzNDM3NSAwLjMyMzEyNSwwLjkwOTY4NzUgMC4yMTcxODc1LDAuODAzNDM3NSAwLjA0MDMxMjUsMC42MjY4NzUgMC4xNDY1NjI1LDAuNTIwNjI1IDAuMzIzMTI1LDAuNjk3NSAwLjg1MzQzNzUsMC4xNjcxODc1IHoiCiAgICAgICAgIHN0eWxlPSJmaWxsOiNmZmZmZmY7ZmlsbC1vcGFjaXR5OjE7c3Ryb2tlOm5vbmUiIC8+CiAgICA8L21hc2s+CiAgPC9kZWZzPgogIDxyZWN0CiAgICAgd2lkdGg9IjEiCiAgICAgaGVpZ2h0PSIxIgogICAgIHg9IjAiCiAgICAgeT0iMCIKICAgICBjbGlwLXBhdGg9InVybCgjY2xpcCkiCiAgICAgc3R5bGU9ImZpbGw6IzAwMDAwMDtmaWxsLW9wYWNpdHk6MTtzdHJva2U6bm9uZSIgLz4KPC9zdmc+Cg==');
    background: 0 0;
    transition-duration: 0.28s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: background;
}

.mdl-checkbox.is-checked .mdl-checkbox__tick-outline {
    background: rgb(63, 81, 181) url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8cGF0aAogICAgIGQ9Ik0gMC4wNDAzODA1OSwwLjYyNjc3NjcgMC4xNDY0NDY2MSwwLjUyMDcxMDY4IDAuNDI5Mjg5MzIsMC44MDM1NTMzOSAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IE0gMC4yMTcxNTcyOSwwLjgwMzU1MzM5IDAuODUzNTUzMzksMC4xNjcxNTcyOSAwLjk1OTYxOTQxLDAuMjczMjIzMyAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IgogICAgIGlkPSJyZWN0Mzc4MCIKICAgICBzdHlsZT0iZmlsbDojZmZmZmZmO2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lIiAvPgo8L3N2Zz4K');
}

fieldset[disabled] .mdl-checkbox.is-checked .mdl-checkbox__tick-outline,
.mdl-checkbox.is-checked.is-disabled .mdl-checkbox__tick-outline {
    background: rgba(0, 0, 0, 0.26) url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8cGF0aAogICAgIGQ9Ik0gMC4wNDAzODA1OSwwLjYyNjc3NjcgMC4xNDY0NDY2MSwwLjUyMDcxMDY4IDAuNDI5Mjg5MzIsMC44MDM1NTMzOSAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IE0gMC4yMTcxNTcyOSwwLjgwMzU1MzM5IDAuODUzNTUzMzksMC4xNjcxNTcyOSAwLjk1OTYxOTQxLDAuMjczMjIzMyAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IgogICAgIGlkPSJyZWN0Mzc4MCIKICAgICBzdHlsZT0iZmlsbDojZmZmZmZmO2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lIiAvPgo8L3N2Zz4K');
}

.mdl-checkbox__label {
    position: relative;
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
}

fieldset[disabled] .mdl-checkbox .mdl-checkbox__label,
.mdl-checkbox.is-disabled .mdl-checkbox__label {
    color: rgba(0, 0, 0, 0.26);
    cursor: auto;
}

.mdl-checkbox__ripple-container {
    position: absolute;
    z-index: 2;
    top: -6px;
    left: -10px;
    box-sizing: border-box;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(circle, #fff, #000);
}

.mdl-checkbox__ripple-container .mdl-ripple {
    background: rgb(63, 81, 181);
}

fieldset[disabled] .mdl-checkbox .mdl-checkbox__ripple-container,
.mdl-checkbox.is-disabled .mdl-checkbox__ripple-container {
    cursor: auto;
}

fieldset[disabled] .mdl-checkbox .mdl-checkbox__ripple-container .mdl-ripple,
.mdl-checkbox.is-disabled .mdl-checkbox__ripple-container .mdl-ripple {
    background: 0 0;
}

.mdl-chip {
    height: 32px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    line-height: 32px;
    padding: 0 12px;
    border: 0;
    border-radius: 16px;
    background-color: #dedede;
    display: inline-block;
    color: rgba(0, 0, 0, 0.87);
    margin: 2px 0;
    font-size: 0;
    white-space: nowrap;
}

.mdl-chip__text {
    font-size: 13px;
    vertical-align: middle;
    display: inline-block;
}

.mdl-chip__action {
    height: 24px;
    width: 24px;
    background: 0 0;
    opacity: 0.54;
    cursor: pointer;
    padding: 0;
    margin: 0 0 0 4px;
    font-size: 13px;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.87);
    border: none;
    outline: none;
}

.mdl-chip__action,
.mdl-chip__contact {
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    text-align: center;
}

.mdl-chip__contact {
    height: 32px;
    width: 32px;
    border-radius: 16px;
    margin-right: 8px;
    font-size: 18px;
    line-height: 32px;
}

.mdl-chip:focus {
    outline: 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mdl-chip:active {
    background-color: #d6d6d6;
}

.mdl-chip--deletable {
    padding-right: 4px;
}

.mdl-chip--contact {
    padding-left: 0;
}

.mdl-data-table {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-collapse: collapse;
    white-space: nowrap;
    font-size: 13px;
    background-color: #fff;
}

.mdl-data-table thead {
    padding-bottom: 3px;
}

.mdl-data-table thead .mdl-data-table__select {
    margin-top: 0;
}

.mdl-data-table tbody tr {
    position: relative;
    height: 48px;
    transition-duration: 0.28s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: background-color;
}

.mdl-data-table tbody tr.is-selected {
    background-color: #e0e0e0;
}

.mdl-data-table tbody tr:hover {
    background-color: #eee;
}

.mdl-data-table td {
    text-align: right;
}

.mdl-data-table th {
    padding: 0 18px 12px 18px;
    text-align: right;
}

.mdl-data-table td:first-of-type,
.mdl-data-table th:first-of-type {
    padding-left: 24px;
}

.mdl-data-table td:last-of-type,
.mdl-data-table th:last-of-type {
    padding-right: 24px;
}

.mdl-data-table td {
    position: relative;
    height: 48px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 12px 18px;
    box-sizing: border-box;
}

.mdl-data-table td,
.mdl-data-table td .mdl-data-table__select {
    vertical-align: middle;
}

.mdl-data-table th {
    position: relative;
    vertical-align: bottom;
    text-overflow: ellipsis;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0;
    height: 48px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
    padding-bottom: 8px;
    box-sizing: border-box;
}

.mdl-data-table th.mdl-data-table__header--sorted-ascending,
.mdl-data-table th.mdl-data-table__header--sorted-descending {
    color: rgba(0, 0, 0, 0.87);
}

.mdl-data-table th.mdl-data-table__header--sorted-ascending:before,
.mdl-data-table th.mdl-data-table__header--sorted-descending:before {
    font-family: 'Material Icons';
    font-weight: 400;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    word-wrap: normal;
    -moz-font-feature-settings: 'liga';
    font-feature-settings: 'liga';
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    content: '\e5d8';
    margin-right: 5px;
    vertical-align: sub;
}

.mdl-data-table th.mdl-data-table__header--sorted-ascending:hover,
.mdl-data-table th.mdl-data-table__header--sorted-descending:hover {
    cursor: pointer;
}

.mdl-data-table th.mdl-data-table__header--sorted-ascending:hover:before,
.mdl-data-table th.mdl-data-table__header--sorted-descending:hover:before {
    color: rgba(0, 0, 0, 0.26);
}

.mdl-data-table th.mdl-data-table__header--sorted-descending:before {
    content: '\e5db';
}

.mdl-data-table__select {
    width: 16px;
}

.mdl-data-table__cell--non-numeric.mdl-data-table__cell--non-numeric {
    text-align: left;
}

.mdl-dialog {
    border: none;
    box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.14), 0 11px 15px -7px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.2);
    width: 280px;
}

.mdl-dialog__title {
    padding: 24px 24px 0;
    margin: 0;
    font-size: 2.5rem;
}

.mdl-dialog__actions {
    padding: 8px 8px 8px 24px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.mdl-dialog__actions>* {
    margin-right: 8px;
    height: 36px;
}

.mdl-dialog__actions>*:first-child {
    margin-right: 0;
}

.mdl-dialog__actions--full-width {
    padding: 0 0 8px;
}

.mdl-dialog__actions--full-width>* {
    height: 48px;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    padding-right: 16px;
    margin-right: 0;
    text-align: right;
}

.mdl-dialog__content {
    padding: 20px 24px 24px;
    color: rgba(0, 0, 0, 0.54);
}

.mdl-mega-footer {
    padding: 16px 40px;
    color: #9e9e9e;
    background-color: #424242;
}

.mdl-mega-footer--top-section:after,
.mdl-mega-footer--middle-section:after,
.mdl-mega-footer--bottom-section:after,
.mdl-mega-footer__top-section:after,
.mdl-mega-footer__middle-section:after,
.mdl-mega-footer__bottom-section:after {
    content: '';
    display: block;
    clear: both;
}

.mdl-mega-footer--left-section,
.mdl-mega-footer__left-section,
.mdl-mega-footer--right-section,
.mdl-mega-footer__right-section {
    margin-bottom: 16px;
}

.mdl-mega-footer--right-section a,
.mdl-mega-footer__right-section a {
    display: block;
    margin-bottom: 16px;
    color: inherit;
    text-decoration: none;
}

@media screen and (min-width: 760px) {
    .mdl-mega-footer--left-section,
    .mdl-mega-footer__left-section {
        float: left;
    }
    .mdl-mega-footer--right-section,
    .mdl-mega-footer__right-section {
        float: right;
    }
    .mdl-mega-footer--right-section a,
    .mdl-mega-footer__right-section a {
        display: inline-block;
        margin-left: 16px;
        line-height: 36px;
        vertical-align: middle;
    }
}

.mdl-mega-footer--social-btn,
.mdl-mega-footer__social-btn {
    width: 36px;
    height: 36px;
    padding: 0;
    margin: 0;
    background-color: #9e9e9e;
    border: none;
}

.mdl-mega-footer--drop-down-section,
.mdl-mega-footer__drop-down-section {
    display: block;
    position: relative;
}

@media screen and (min-width: 760px) {
    .mdl-mega-footer--drop-down-section,
    .mdl-mega-footer__drop-down-section {
        width: 33%;
    }
    .mdl-mega-footer--drop-down-section:nth-child(1),
    .mdl-mega-footer--drop-down-section:nth-child(2),
    .mdl-mega-footer__drop-down-section:nth-child(1),
    .mdl-mega-footer__drop-down-section:nth-child(2) {
        float: left;
    }
    .mdl-mega-footer--drop-down-section:nth-child(3),
    .mdl-mega-footer__drop-down-section:nth-child(3) {
        float: right;
    }
    .mdl-mega-footer--drop-down-section:nth-child(3):after,
    .mdl-mega-footer__drop-down-section:nth-child(3):after {
        clear: right;
    }
    .mdl-mega-footer--drop-down-section:nth-child(4),
    .mdl-mega-footer__drop-down-section:nth-child(4) {
        clear: right;
        float: right;
    }
    .mdl-mega-footer--middle-section:after,
    .mdl-mega-footer__middle-section:after {
        content: '';
        display: block;
        clear: both;
    }
    .mdl-mega-footer--bottom-section,
    .mdl-mega-footer__bottom-section {
        padding-top: 0;
    }
}

@media screen and (min-width: 1024px) {
    .mdl-mega-footer--drop-down-section,
    .mdl-mega-footer--drop-down-section:nth-child(3),
    .mdl-mega-footer--drop-down-section:nth-child(4),
    .mdl-mega-footer__drop-down-section,
    .mdl-mega-footer__drop-down-section:nth-child(3),
    .mdl-mega-footer__drop-down-section:nth-child(4) {
        width: 24%;
        float: left;
    }
}

.mdl-mega-footer--heading-checkbox,
.mdl-mega-footer__heading-checkbox {
    position: absolute;
    width: 100%;
    height: 55.8px;
    padding: 32px;
    margin: -16px 0 0;
    cursor: pointer;
    z-index: 1;
    opacity: 0;
}

.mdl-mega-footer--heading-checkbox+.mdl-mega-footer--heading:after,
.mdl-mega-footer--heading-checkbox+.mdl-mega-footer__heading:after,
.mdl-mega-footer__heading-checkbox+.mdl-mega-footer--heading:after,
.mdl-mega-footer__heading-checkbox+.mdl-mega-footer__heading:after {
    font-family: 'Material Icons';
    content: '\E5CE';
}

.mdl-mega-footer--heading-checkbox:checked~.mdl-mega-footer--link-list,
.mdl-mega-footer--heading-checkbox:checked~.mdl-mega-footer__link-list,
.mdl-mega-footer--heading-checkbox:checked+.mdl-mega-footer--heading+.mdl-mega-footer--link-list,
.mdl-mega-footer--heading-checkbox:checked+.mdl-mega-footer__heading+.mdl-mega-footer__link-list,
.mdl-mega-footer__heading-checkbox:checked~.mdl-mega-footer--link-list,
.mdl-mega-footer__heading-checkbox:checked~.mdl-mega-footer__link-list,
.mdl-mega-footer__heading-checkbox:checked+.mdl-mega-footer--heading+.mdl-mega-footer--link-list,
.mdl-mega-footer__heading-checkbox:checked+.mdl-mega-footer__heading+.mdl-mega-footer__link-list {
    display: none;
}

.mdl-mega-footer--heading-checkbox:checked+.mdl-mega-footer--heading:after,
.mdl-mega-footer--heading-checkbox:checked+.mdl-mega-footer__heading:after,
.mdl-mega-footer__heading-checkbox:checked+.mdl-mega-footer--heading:after,
.mdl-mega-footer__heading-checkbox:checked+.mdl-mega-footer__heading:after {
    font-family: 'Material Icons';
    content: '\E5CF';
}

.mdl-mega-footer--heading,
.mdl-mega-footer__heading {
    position: relative;
    width: 100%;
    padding-right: 39.8px;
    margin-bottom: 16px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 23.8px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #e0e0e0;
}

.mdl-mega-footer--heading:after,
.mdl-mega-footer__heading:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 23.8px;
    height: 23.8px;
    background-size: cover;
}

.mdl-mega-footer--link-list,
.mdl-mega-footer__link-list {
    list-style: none;
    padding: 0;
    margin: 0 0 32px;
}

.mdl-mega-footer--link-list:after,
.mdl-mega-footer__link-list:after {
    clear: both;
    display: block;
    content: '';
}

.mdl-mega-footer--link-list li,
.mdl-mega-footer__link-list li {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 20px;
}

.mdl-mega-footer--link-list a,
.mdl-mega-footer__link-list a {
    color: inherit;
    text-decoration: none;
    white-space: nowrap;
}

@media screen and (min-width: 760px) {
    .mdl-mega-footer--heading-checkbox,
    .mdl-mega-footer__heading-checkbox {
        display: none;
    }
    .mdl-mega-footer--heading-checkbox+.mdl-mega-footer--heading:after,
    .mdl-mega-footer--heading-checkbox+.mdl-mega-footer__heading:after,
    .mdl-mega-footer__heading-checkbox+.mdl-mega-footer--heading:after,
    .mdl-mega-footer__heading-checkbox+.mdl-mega-footer__heading:after {
        content: '';
    }
    .mdl-mega-footer--heading-checkbox:checked~.mdl-mega-footer--link-list,
    .mdl-mega-footer--heading-checkbox:checked~.mdl-mega-footer__link-list,
    .mdl-mega-footer--heading-checkbox:checked+.mdl-mega-footer__heading+.mdl-mega-footer__link-list,
    .mdl-mega-footer--heading-checkbox:checked+.mdl-mega-footer--heading+.mdl-mega-footer--link-list,
    .mdl-mega-footer__heading-checkbox:checked~.mdl-mega-footer--link-list,
    .mdl-mega-footer__heading-checkbox:checked~.mdl-mega-footer__link-list,
    .mdl-mega-footer__heading-checkbox:checked+.mdl-mega-footer__heading+.mdl-mega-footer__link-list,
    .mdl-mega-footer__heading-checkbox:checked+.mdl-mega-footer--heading+.mdl-mega-footer--link-list {
        display: block;
    }
    .mdl-mega-footer--heading-checkbox:checked+.mdl-mega-footer--heading:after,
    .mdl-mega-footer--heading-checkbox:checked+.mdl-mega-footer__heading:after,
    .mdl-mega-footer__heading-checkbox:checked+.mdl-mega-footer--heading:after,
    .mdl-mega-footer__heading-checkbox:checked+.mdl-mega-footer__heading:after {
        content: '';
    }
}

.mdl-mega-footer--bottom-section,
.mdl-mega-footer__bottom-section {
    padding-top: 16px;
    margin-bottom: 16px;
}

.mdl-logo {
    margin-bottom: 16px;
    color: #fff;
}

.mdl-mega-footer--bottom-section .mdl-mega-footer--link-list li,
.mdl-mega-footer__bottom-section .mdl-mega-footer__link-list li {
    float: left;
    margin-bottom: 0;
    margin-right: 16px;
}

@media screen and (min-width: 760px) {
    .mdl-logo {
        float: left;
        margin-bottom: 0;
        margin-right: 16px;
    }
}

.mdl-mini-footer {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 32px 16px;
    color: #9e9e9e;
    background-color: #424242;
}

.mdl-mini-footer:after {
    content: '';
    display: block;
}

.mdl-mini-footer .mdl-logo {
    line-height: 36px;
}

.mdl-mini-footer--link-list,
.mdl-mini-footer__link-list {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row nowrap;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    list-style: none;
    margin: 0;
    padding: 0;
}

.mdl-mini-footer--link-list li,
.mdl-mini-footer__link-list li {
    margin-bottom: 0;
    margin-right: 16px;
}

@media screen and (min-width: 760px) {
    .mdl-mini-footer--link-list li,
    .mdl-mini-footer__link-list li {
        line-height: 36px;
    }
}

.mdl-mini-footer--link-list a,
.mdl-mini-footer__link-list a {
    color: inherit;
    text-decoration: none;
    white-space: nowrap;
}

.mdl-mini-footer--left-section,
.mdl-mini-footer__left-section {
    display: inline-block;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
}

.mdl-mini-footer--right-section,
.mdl-mini-footer__right-section {
    display: inline-block;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
}

.mdl-mini-footer--social-btn,
.mdl-mini-footer__social-btn {
    width: 36px;
    height: 36px;
    padding: 0;
    margin: 0;
    background-color: #9e9e9e;
    border: none;
}

.mdl-icon-toggle {
    position: relative;
    z-index: 1;
    vertical-align: middle;
    display: inline-block;
    height: 32px;
    margin: 0;
    padding: 0;
}

.mdl-icon-toggle__input {
    line-height: 32px;
}

.mdl-icon-toggle.is-upgraded .mdl-icon-toggle__input {
    position: absolute;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    -ms-appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
}

.mdl-icon-toggle__label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    height: 32px;
    width: 32px;
    min-width: 32px;
    color: #616161;
    border-radius: 50%;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    text-align: center;
    background-color: transparent;
    will-change: background-color;
    transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-icon-toggle__label.material-icons {
    line-height: 32px;
    font-size: 24px;
}

.mdl-icon-toggle.is-checked .mdl-icon-toggle__label {
    color: rgb(63, 81, 181);
}

.mdl-icon-toggle.is-disabled .mdl-icon-toggle__label {
    color: rgba(0, 0, 0, 0.26);
    cursor: auto;
    transition: none;
}

.mdl-icon-toggle.is-focused .mdl-icon-toggle__label {
    background-color: rgba(0, 0, 0, 0.12);
}

.mdl-icon-toggle.is-focused.is-checked .mdl-icon-toggle__label {
    background-color: rgba(63, 81, 181, 0.26);
}

.mdl-icon-toggle__ripple-container {
    position: absolute;
    z-index: 2;
    top: -2px;
    left: -2px;
    box-sizing: border-box;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(circle, #fff, #000);
}

.mdl-icon-toggle__ripple-container .mdl-ripple {
    background: #616161;
}

.mdl-icon-toggle.is-disabled .mdl-icon-toggle__ripple-container {
    cursor: auto;
}

.mdl-icon-toggle.is-disabled .mdl-icon-toggle__ripple-container .mdl-ripple {
    background: 0 0;
}

.mdl-list {
    display: block;
    padding: 8px 0;
    list-style: none;
}

.mdl-list__item {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.04em;
    line-height: 1;
    min-height: 48px;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding: 16px;
    cursor: default;
    color: rgba(0, 0, 0, 0.87);
    overflow: hidden;
}

.mdl-list__item,
.mdl-list__item .mdl-list__item-primary-content {
    box-sizing: border-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.mdl-list__item .mdl-list__item-primary-content {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex-grow: 2;
    -ms-flex-positive: 2;
    flex-grow: 2;
    text-decoration: none;
}

.mdl-list__item .mdl-list__item-primary-content .mdl-list__item-icon {
    margin-right: 32px;
}

.mdl-list__item .mdl-list__item-primary-content .mdl-list__item-avatar {
    margin-right: 16px;
}

.mdl-list__item .mdl-list__item-secondary-content {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: column;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    margin-left: 16px;
}

.mdl-list__item .mdl-list__item-secondary-content .mdl-list__item-secondary-action label {
    display: inline;
}

.mdl-list__item .mdl-list__item-secondary-content .mdl-list__item-secondary-info {
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0;
    color: rgba(0, 0, 0, 0.54);
}

.mdl-list__item .mdl-list__item-secondary-content .mdl-list__item-sub-header {
    padding: 0 0 0 16px;
}

.mdl-list__item-icon,
.mdl-list__item-icon.material-icons {
    height: 24px;
    width: 24px;
    font-size: 24px;
    box-sizing: border-box;
    color: #757575;
}

.mdl-list__item-avatar,
.mdl-list__item-avatar.material-icons {
    height: 40px;
    width: 40px;
    box-sizing: border-box;
    border-radius: 50%;
    background-color: #757575;
    font-size: 40px;
    color: #fff;
}

.mdl-list__item--two-line {
    height: 72px;
}

.mdl-list__item--two-line .mdl-list__item-primary-content {
    height: 36px;
    line-height: 20px;
    display: block;
}

.mdl-list__item--two-line .mdl-list__item-primary-content .mdl-list__item-avatar {
    float: left;
}

.mdl-list__item--two-line .mdl-list__item-primary-content .mdl-list__item-icon {
    float: left;
    margin-top: 6px;
}

.mdl-list__item--two-line .mdl-list__item-primary-content .mdl-list__item-secondary-content {
    height: 36px;
}

.mdl-list__item--two-line .mdl-list__item-primary-content .mdl-list__item-sub-title {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.54);
    display: block;
    padding: 0;
}

.mdl-list__item--three-line {
    height: 88px;
}

.mdl-list__item--three-line .mdl-list__item-primary-content {
    height: 52px;
    line-height: 20px;
    display: block;
}

.mdl-list__item--three-line .mdl-list__item-primary-content .mdl-list__item-avatar,
.mdl-list__item--three-line .mdl-list__item-primary-content .mdl-list__item-icon {
    float: left;
}

.mdl-list__item--three-line .mdl-list__item-secondary-content {
    height: 52px;
}

.mdl-list__item--three-line .mdl-list__item-text-body {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 18px;
    height: 52px;
    color: rgba(0, 0, 0, 0.54);
    display: block;
    padding: 0;
}

.mdl-menu__container {
    display: block;
    margin: 0;
    padding: 0;
    border: none;
    position: absolute;
    overflow: visible;
    height: 0;
    width: 0;
    visibility: hidden;
    z-index: -1;
}

.mdl-menu__container.is-visible,
.mdl-menu__container.is-animating {
    z-index: 999;
    visibility: visible;
}

.mdl-menu__outline {
    display: block;
    background: #fff;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 2px;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    will-change: transform;
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: -1;
}

.mdl-menu__container.is-visible .mdl-menu__outline {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    z-index: 999;
}

.mdl-menu__outline.mdl-menu--bottom-right {
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
}

.mdl-menu__outline.mdl-menu--top-left {
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
}

.mdl-menu__outline.mdl-menu--top-right {
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
}

.mdl-menu {
    position: absolute;
    list-style: none;
    top: 0;
    left: 0;
    height: auto;
    width: auto;
    min-width: 124px;
    padding: 8px 0;
    margin: 0;
    opacity: 0;
    clip: rect(0 0 0 0);
    z-index: -1;
}

.mdl-menu__container.is-visible .mdl-menu {
    opacity: 1;
    z-index: 999;
}

.mdl-menu.is-animating {
    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1), clip 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-menu.mdl-menu--bottom-right {
    left: auto;
    right: 0;
}

.mdl-menu.mdl-menu--top-left {
    top: auto;
    bottom: 0;
}

.mdl-menu.mdl-menu--top-right {
    top: auto;
    left: auto;
    bottom: 0;
    right: 0;
}

.mdl-menu.mdl-menu--unaligned {
    top: auto;
    left: auto;
}

.mdl-menu__item {
    display: block;
    border: none;
    color: rgba(0, 0, 0, 0.87);
    background-color: transparent;
    text-align: left;
    margin: 0;
    padding: 0 16px;
    outline-color: #bdbdbd;
    position: relative;
    overflow: hidden;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    text-decoration: none;
    cursor: pointer;
    height: 48px;
    line-height: 48px;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.mdl-menu__container.is-visible .mdl-menu__item {
    opacity: 1;
}

.mdl-menu__item::-moz-focus-inner {
    border: 0;
}

.mdl-menu__item--full-bleed-divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mdl-menu__item[disabled],
.mdl-menu__item[data-mdl-disabled] {
    color: #bdbdbd;
    background-color: transparent;
    cursor: auto;
}

.mdl-menu__item[disabled]:hover,
.mdl-menu__item[data-mdl-disabled]:hover {
    background-color: transparent;
}

.mdl-menu__item[disabled]:focus,
.mdl-menu__item[data-mdl-disabled]:focus {
    background-color: transparent;
}

.mdl-menu__item[disabled] .mdl-ripple,
.mdl-menu__item[data-mdl-disabled] .mdl-ripple {
    background: 0 0;
}

.mdl-menu__item:hover {
    background-color: #eee;
}

.mdl-menu__item:focus {
    outline: none;
    background-color: #eee;
}

.mdl-menu__item:active {
    background-color: #e0e0e0;
}

.mdl-menu__item--ripple-container {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    overflow: hidden;
}

.mdl-progress {
    display: block;
    position: relative;
    height: 4px;
    width: 500px;
    max-width: 100%;
}

.mdl-progress>.bar {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 0%;
    transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-progress>.progressbar {
    background-color: rgb(63, 81, 181);
    z-index: 1;
    left: 0;
}

.mdl-progress>.bufferbar {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), linear-gradient(to right, rgb(63, 81, 181), rgb(63, 81, 181));
    z-index: 0;
    left: 0;
}

.mdl-progress>.auxbar {
    right: 0;
}

@supports (-webkit-appearance: none) {
    .mdl-progress:not(.mdl-progress--indeterminate):not(.mdl-progress--indeterminate)>.auxbar,
    .mdl-progress:not(.mdl-progress__indeterminate):not(.mdl-progress__indeterminate)>.auxbar {
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), linear-gradient(to right, rgb(63, 81, 181), rgb(63, 81, 181));
        -webkit-mask: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+Cjxzdmcgd2lkdGg9IjEyIiBoZWlnaHQ9IjQiIHZpZXdQb3J0PSIwIDAgMTIgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxlbGxpcHNlIGN4PSIyIiBjeT0iMiIgcng9IjIiIHJ5PSIyIj4KICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9ImN4IiBmcm9tPSIyIiB0bz0iLTEwIiBkdXI9IjAuNnMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAvPgogIDwvZWxsaXBzZT4KICA8ZWxsaXBzZSBjeD0iMTQiIGN5PSIyIiByeD0iMiIgcnk9IjIiIGNsYXNzPSJsb2FkZXIiPgogICAgPGFuaW1hdGUgYXR0cmlidXRlTmFtZT0iY3giIGZyb209IjE0IiB0bz0iMiIgZHVyPSIwLjZzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgLz4KICA8L2VsbGlwc2U+Cjwvc3ZnPgo=');
        mask: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+Cjxzdmcgd2lkdGg9IjEyIiBoZWlnaHQ9IjQiIHZpZXdQb3J0PSIwIDAgMTIgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxlbGxpcHNlIGN4PSIyIiBjeT0iMiIgcng9IjIiIHJ5PSIyIj4KICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9ImN4IiBmcm9tPSIyIiB0bz0iLTEwIiBkdXI9IjAuNnMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAvPgogIDwvZWxsaXBzZT4KICA8ZWxsaXBzZSBjeD0iMTQiIGN5PSIyIiByeD0iMiIgcnk9IjIiIGNsYXNzPSJsb2FkZXIiPgogICAgPGFuaW1hdGUgYXR0cmlidXRlTmFtZT0iY3giIGZyb209IjE0IiB0bz0iMiIgZHVyPSIwLjZzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgLz4KICA8L2VsbGlwc2U+Cjwvc3ZnPgo=');
    }
}

.mdl-progress:not(.mdl-progress--indeterminate)>.auxbar,
.mdl-progress:not(.mdl-progress__indeterminate)>.auxbar {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(to right, rgb(63, 81, 181), rgb(63, 81, 181));
}

.mdl-progress.mdl-progress--indeterminate>.bar1,
.mdl-progress.mdl-progress__indeterminate>.bar1 {
    -webkit-animation-name: indeterminate1;
    animation-name: indeterminate1;
}

.mdl-progress.mdl-progress--indeterminate>.bar1,
.mdl-progress.mdl-progress__indeterminate>.bar1,
.mdl-progress.mdl-progress--indeterminate>.bar3,
.mdl-progress.mdl-progress__indeterminate>.bar3 {
    background-color: rgb(63, 81, 181);
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

.mdl-progress.mdl-progress--indeterminate>.bar3,
.mdl-progress.mdl-progress__indeterminate>.bar3 {
    background-image: none;
    -webkit-animation-name: indeterminate2;
    animation-name: indeterminate2;
}

@-webkit-keyframes indeterminate1 {
    0% {
        left: 0%;
        width: 0%;
    }
    50% {
        left: 25%;
        width: 75%;
    }
    75% {
        left: 100%;
        width: 0%;
    }
}

@keyframes indeterminate1 {
    0% {
        left: 0%;
        width: 0%;
    }
    50% {
        left: 25%;
        width: 75%;
    }
    75% {
        left: 100%;
        width: 0%;
    }
}

@-webkit-keyframes indeterminate2 {
    0%,
    50% {
        left: 0%;
        width: 0%;
    }
    75% {
        left: 0%;
        width: 25%;
    }
    100% {
        left: 100%;
        width: 0%;
    }
}

@keyframes indeterminate2 {
    0%,
    50% {
        left: 0%;
        width: 0%;
    }
    75% {
        left: 0%;
        width: 25%;
    }
    100% {
        left: 100%;
        width: 0%;
    }
}

.mdl-navigation {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    box-sizing: border-box;
}

.mdl-navigation__link {
    color: #424242;
    text-decoration: none;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    opacity: 0.87;
}

.mdl-navigation__link .material-icons {
    vertical-align: middle;
}

.mdl-layout {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    -webkit-overflow-scrolling: touch;
}

.mdl-layout.is-small-screen .mdl-layout--large-screen-only {
    display: none;
}

.mdl-layout:not(.is-small-screen) .mdl-layout--small-screen-only {
    display: none;
}

.mdl-layout__container {
    position: absolute;
    width: 100%;
    height: 100%;
}

.mdl-layout__title,
.mdl-layout-title {
    display: block;
    position: relative;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 20px;
    line-height: 1;
    letter-spacing: 0.02em;
    font-weight: 400;
    box-sizing: border-box;
}

.mdl-layout-spacer {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.mdl-layout__drawer {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    width: 240px;
    height: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    border-right: 1px solid #e0e0e0;
    background: #fafafa;
    -webkit-transform: translateX(-250px);
    transform: translateX(-250px);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    will-change: transform;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    color: #424242;
    overflow: visible;
    overflow-y: auto;
    z-index: 5;
}

.mdl-layout__drawer.is-visible {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.mdl-layout__drawer.is-visible~.mdl-layout__content.mdl-layout__content {
    overflow: hidden;
}

.mdl-layout__drawer>* {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.mdl-layout__drawer>.mdl-layout__title,
.mdl-layout__drawer>.mdl-layout-title {
    line-height: 64px;
    padding-left: 40px;
}

@media screen and (max-width: 1024px) {
    .mdl-layout__drawer>.mdl-layout__title,
    .mdl-layout__drawer>.mdl-layout-title {
        line-height: 56px;
        padding-left: 16px;
    }
}

.mdl-layout__drawer .mdl-navigation {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    padding-top: 16px;
}

.mdl-layout__drawer .mdl-navigation .mdl-navigation__link {
    display: block;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 16px 40px;
    margin: 0;
    color: #757575;
}

@media screen and (max-width: 1024px) {
    .mdl-layout__drawer .mdl-navigation .mdl-navigation__link {
        padding: 16px;
    }
}

.mdl-layout__drawer .mdl-navigation .mdl-navigation__link:hover {
    background-color: #e0e0e0;
}

.mdl-layout__drawer .mdl-navigation .mdl-navigation__link--current {
    background-color: #e0e0e0;
    color: #000;
}

@media screen and (min-width: 1025px) {
    .mdl-layout--fixed-drawer>.mdl-layout__drawer {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

.mdl-layout__drawer-button {
    display: block;
    position: absolute;
    height: 48px;
    width: 48px;
    border: 0;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    overflow: hidden;
    text-align: center;
    cursor: pointer;
    font-size: 26px;
    line-height: 56px;
    font-family: Helvetica, Arial, sans-serif;
    margin: 8px 12px;
    top: 0;
    left: 0;
    color: rgb(255, 255, 255);
    z-index: 4;
}

.mdl-layout__header .mdl-layout__drawer-button {
    position: absolute;
    color: rgb(255, 255, 255);
    background-color: inherit;
}

@media screen and (max-width: 1024px) {
    .mdl-layout__header .mdl-layout__drawer-button {
        margin: 4px;
    }
}

@media screen and (max-width: 1024px) {
    .mdl-layout__drawer-button {
        margin: 4px;
        color: rgba(0, 0, 0, 0.5);
    }
}

@media screen and (min-width: 1025px) {
    .mdl-layout__drawer-button {
        line-height: 54px;
    }
    .mdl-layout--no-desktop-drawer-button .mdl-layout__drawer-button,
    .mdl-layout--fixed-drawer>.mdl-layout__drawer-button,
    .mdl-layout--no-drawer-button .mdl-layout__drawer-button {
        display: none;
    }
}

.mdl-layout__header {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    box-sizing: border-box;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;
    min-height: 64px;
    max-height: 1000px;
    z-index: 3;
    background-color: rgb(63, 81, 181);
    color: rgb(255, 255, 255);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: max-height, box-shadow;
}

@media screen and (max-width: 1024px) {
    .mdl-layout__header {
        min-height: 56px;
    }
}

.mdl-layout--fixed-drawer.is-upgraded:not(.is-small-screen)>.mdl-layout__header {
    margin-left: 240px;
    width: calc(100% - 240px);
}

@media screen and (min-width: 1025px) {
    .mdl-layout--fixed-drawer>.mdl-layout__header .mdl-layout__header-row {
        padding-left: 40px;
    }
}

.mdl-layout__header>.mdl-layout-icon {
    position: absolute;
    left: 40px;
    top: 16px;
    height: 32px;
    width: 32px;
    overflow: hidden;
    z-index: 3;
    display: block;
}

@media screen and (max-width: 1024px) {
    .mdl-layout__header>.mdl-layout-icon {
        left: 16px;
        top: 12px;
    }
}

.mdl-layout.has-drawer .mdl-layout__header>.mdl-layout-icon {
    display: none;
}

.mdl-layout__header.is-compact {
    max-height: 64px;
}

@media screen and (max-width: 1024px) {
    .mdl-layout__header.is-compact {
        max-height: 56px;
    }
}

.mdl-layout__header.is-compact.has-tabs {
    height: 112px;
}

@media screen and (max-width: 1024px) {
    .mdl-layout__header.is-compact.has-tabs {
        min-height: 104px;
    }
}

@media screen and (max-width: 1024px) {
    .mdl-layout__header {
        display: none;
    }
    .mdl-layout--fixed-header>.mdl-layout__header {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
}

.mdl-layout__header--transparent.mdl-layout__header--transparent {
    background-color: transparent;
    box-shadow: none;
}

.mdl-layout__header--seamed,
.mdl-layout__header--scroll {
    box-shadow: none;
}

.mdl-layout__header--waterfall {
    box-shadow: none;
    overflow: hidden;
}

.mdl-layout__header--waterfall.is-casting-shadow {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mdl-layout__header--waterfall.mdl-layout__header--waterfall-hide-top {
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.mdl-layout__header-row {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 64px;
    margin: 0;
    padding: 0 40px 0 80px;
}

.mdl-layout--no-drawer-button .mdl-layout__header-row {
    padding-left: 40px;
}

@media screen and (min-width: 1025px) {
    .mdl-layout--no-desktop-drawer-button .mdl-layout__header-row {
        padding-left: 40px;
    }
}

@media screen and (max-width: 1024px) {
    .mdl-layout__header-row {
        height: 56px;
        padding: 0 16px 0 72px;
    }
    .mdl-layout--no-drawer-button .mdl-layout__header-row {
        padding-left: 16px;
    }
}

.mdl-layout__header-row>* {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.mdl-layout__header--scroll .mdl-layout__header-row {
    width: 100%;
}

.mdl-layout__header-row .mdl-navigation {
    margin: 0;
    padding: 0;
    height: 64px;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

@media screen and (max-width: 1024px) {
    .mdl-layout__header-row .mdl-navigation {
        height: 56px;
    }
}

.mdl-layout__header-row .mdl-navigation__link {
    display: block;
    color: rgb(255, 255, 255);
    line-height: 64px;
    padding: 0 24px;
}

@media screen and (max-width: 1024px) {
    .mdl-layout__header-row .mdl-navigation__link {
        line-height: 56px;
        padding: 0 16px;
    }
}

.mdl-layout__obfuscator {
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 4;
    visibility: hidden;
    transition-property: background-color;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-layout__obfuscator.is-visible {
    background-color: rgba(0, 0, 0, 0.5);
    visibility: visible;
}

@supports (pointer-events: auto) {
    .mdl-layout__obfuscator {
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition-property: opacity;
        visibility: visible;
        pointer-events: none;
    }
    .mdl-layout__obfuscator.is-visible {
        pointer-events: auto;
        opacity: 1;
    }
}

.mdl-layout__content {
    -ms-flex: 0 1 auto;
    position: relative;
    display: inline-block;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    z-index: 1;
    -webkit-overflow-scrolling: touch;
}

.mdl-layout--fixed-drawer>.mdl-layout__content {
    margin-left: 240px;
}

.mdl-layout__container.has-scrolling-header .mdl-layout__content {
    overflow: visible;
}

@media screen and (max-width: 1024px) {
    .mdl-layout--fixed-drawer>.mdl-layout__content {
        margin-left: 0;
    }
    .mdl-layout__container.has-scrolling-header .mdl-layout__content {
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.mdl-layout__tab-bar {
    height: 96px;
    margin: 0;
    width: calc(100% - 112px);
    padding: 0 0 0 56px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    background-color: rgb(63, 81, 181);
    overflow-y: hidden;
    overflow-x: scroll;
}

.mdl-layout__tab-bar::-webkit-scrollbar {
    display: none;
}

.mdl-layout--no-drawer-button .mdl-layout__tab-bar {
    padding-left: 16px;
    width: calc(100% - 32px);
}

@media screen and (min-width: 1025px) {
    .mdl-layout--no-desktop-drawer-button .mdl-layout__tab-bar {
        padding-left: 16px;
        width: calc(100% - 32px);
    }
}

@media screen and (max-width: 1024px) {
    .mdl-layout__tab-bar {
        width: calc(100% - 60px);
        padding: 0 0 0 60px;
    }
    .mdl-layout--no-drawer-button .mdl-layout__tab-bar {
        width: calc(100% - 8px);
        padding-left: 4px;
    }
}

.mdl-layout--fixed-tabs .mdl-layout__tab-bar {
    padding: 0;
    overflow: hidden;
    width: 100%;
}

.mdl-layout__tab-bar-container {
    position: relative;
    height: 48px;
    width: 100%;
    border: none;
    margin: 0;
    z-index: 2;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    overflow: hidden;
}

.mdl-layout__container>.mdl-layout__tab-bar-container {
    position: absolute;
    top: 0;
    left: 0;
}

.mdl-layout__tab-bar-button {
    display: inline-block;
    position: absolute;
    top: 0;
    height: 48px;
    width: 56px;
    z-index: 4;
    text-align: center;
    background-color: rgb(63, 81, 181);
    color: transparent;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.mdl-layout--no-desktop-drawer-button .mdl-layout__tab-bar-button,
.mdl-layout--no-drawer-button .mdl-layout__tab-bar-button {
    width: 16px;
}

.mdl-layout--no-desktop-drawer-button .mdl-layout__tab-bar-button .material-icons,
.mdl-layout--no-drawer-button .mdl-layout__tab-bar-button .material-icons {
    position: relative;
    left: -4px;
}

@media screen and (max-width: 1024px) {
    .mdl-layout__tab-bar-button {
        width: 60px;
    }
}

.mdl-layout--fixed-tabs .mdl-layout__tab-bar-button {
    display: none;
}

.mdl-layout__tab-bar-button .material-icons {
    line-height: 48px;
}

.mdl-layout__tab-bar-button.is-active {
    color: rgb(255, 255, 255);
}

.mdl-layout__tab-bar-left-button {
    left: 0;
}

.mdl-layout__tab-bar-right-button {
    right: 0;
}

.mdl-layout__tab {
    margin: 0;
    border: none;
    padding: 0 24px;
    float: left;
    position: relative;
    display: block;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    text-decoration: none;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.6);
    overflow: hidden;
}

@media screen and (max-width: 1024px) {
    .mdl-layout__tab {
        padding: 0 12px;
    }
}

.mdl-layout--fixed-tabs .mdl-layout__tab {
    float: none;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 0;
}

.mdl-layout.is-upgraded .mdl-layout__tab.is-active {
    color: rgb(255, 255, 255);
}

.mdl-layout.is-upgraded .mdl-layout__tab.is-active::after {
    height: 2px;
    width: 100%;
    display: block;
    content: ' ';
    bottom: 0;
    left: 0;
    position: absolute;
    background: rgb(255, 64, 129);
    -webkit-animation: border-expand 0.2s cubic-bezier(0.4, 0, 0.4, 1) 0.01s alternate forwards;
    animation: border-expand 0.2s cubic-bezier(0.4, 0, 0.4, 1) 0.01s alternate forwards;
    transition: all 1s cubic-bezier(0.4, 0, 1, 1);
}

.mdl-layout__tab .mdl-layout__tab-ripple-container {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    overflow: hidden;
}

.mdl-layout__tab .mdl-layout__tab-ripple-container .mdl-ripple {
    background-color: rgb(255, 255, 255);
}

.mdl-layout__tab-panel {
    display: block;
}

.mdl-layout.is-upgraded .mdl-layout__tab-panel {
    display: none;
}

.mdl-layout.is-upgraded .mdl-layout__tab-panel.is-active {
    display: block;
}

.mdl-radio {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    height: 24px;
    margin: 0;
    padding-left: 0;
}

.mdl-radio.is-upgraded {
    padding-left: 24px;
}

.mdl-radio__button {
    line-height: 24px;
}

.mdl-radio.is-upgraded .mdl-radio__button {
    position: absolute;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    -ms-appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
}

.mdl-radio__outer-circle {
    position: absolute;
    top: 4px;
    left: 0;
    display: inline-block;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    margin: 0;
    cursor: pointer;
    border: 2px solid rgba(0, 0, 0, 0.54);
    border-radius: 50%;
    z-index: 2;
}

.mdl-radio.is-checked .mdl-radio__outer-circle {
    border: 2px solid rgb(63, 81, 181);
}

.mdl-radio__outer-circle fieldset[disabled] .mdl-radio,
.mdl-radio.is-disabled .mdl-radio__outer-circle {
    border: 2px solid rgba(0, 0, 0, 0.26);
    cursor: auto;
}

.mdl-radio__inner-circle {
    position: absolute;
    z-index: 1;
    margin: 0;
    top: 8px;
    left: 4px;
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    cursor: pointer;
    transition-duration: 0.28s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
    border-radius: 50%;
    background: rgb(63, 81, 181);
}

.mdl-radio.is-checked .mdl-radio__inner-circle {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

fieldset[disabled] .mdl-radio .mdl-radio__inner-circle,
.mdl-radio.is-disabled .mdl-radio__inner-circle {
    background: rgba(0, 0, 0, 0.26);
    cursor: auto;
}

.mdl-radio.is-focused .mdl-radio__inner-circle {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
}

.mdl-radio__label {
    cursor: pointer;
}

fieldset[disabled] .mdl-radio .mdl-radio__label,
.mdl-radio.is-disabled .mdl-radio__label {
    color: rgba(0, 0, 0, 0.26);
    cursor: auto;
}

.mdl-radio__ripple-container {
    position: absolute;
    z-index: 2;
    top: -9px;
    left: -13px;
    box-sizing: border-box;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(circle, #fff, #000);
}

.mdl-radio__ripple-container .mdl-ripple {
    background: rgb(63, 81, 181);
}

fieldset[disabled] .mdl-radio .mdl-radio__ripple-container,
.mdl-radio.is-disabled .mdl-radio__ripple-container {
    cursor: auto;
}

fieldset[disabled] .mdl-radio .mdl-radio__ripple-container .mdl-ripple,
.mdl-radio.is-disabled .mdl-radio__ripple-container .mdl-ripple {
    background: 0 0;
}

_:-ms-input-placeholder,
:root .mdl-slider.mdl-slider.is-upgraded {
    -ms-appearance: none;
    height: 32px;
    margin: 0;
}

.mdl-slider {
    width: calc(100% - 40px);
    margin: 0 20px;
}

.mdl-slider.is-upgraded {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 2px;
    background: 0 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: 0;
    padding: 0;
    color: rgb(63, 81, 181);
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    z-index: 1;
    cursor: pointer;
}

.mdl-slider.is-upgraded::-moz-focus-outer {
    border: 0;
}

.mdl-slider.is-upgraded::-ms-tooltip {
    display: none;
}

.mdl-slider.is-upgraded::-webkit-slider-runnable-track {
    background: 0 0;
}

.mdl-slider.is-upgraded::-moz-range-track {
    background: 0 0;
    border: none;
}

.mdl-slider.is-upgraded::-ms-track {
    background: 0 0;
    color: transparent;
    height: 2px;
    width: 100%;
    border: none;
}

.mdl-slider.is-upgraded::-ms-fill-lower {
    padding: 0;
    background: linear-gradient(to right, transparent, transparent 16px, rgb(63, 81, 181) 16px, rgb(63, 81, 181) 0);
}

.mdl-slider.is-upgraded::-ms-fill-upper {
    padding: 0;
    background: linear-gradient(to left, transparent, transparent 16px, rgba(0, 0, 0, 0.26) 16px, rgba(0, 0, 0, 0.26) 0);
}

.mdl-slider.is-upgraded::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 12px;
    height: 12px;
    box-sizing: border-box;
    border-radius: 50%;
    background: rgb(63, 81, 181);
    border: none;
    transition: transform 0.18s cubic-bezier(0.4, 0, 0.2, 1), border 0.18s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.18s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.18s cubic-bezier(0.4, 0, 0.2, 1), border 0.18s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.18s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.18s cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-slider.is-upgraded::-moz-range-thumb {
    -moz-appearance: none;
    width: 12px;
    height: 12px;
    box-sizing: border-box;
    border-radius: 50%;
    background-image: none;
    background: rgb(63, 81, 181);
    border: none;
}

.mdl-slider.is-upgraded:focus:not(:active)::-webkit-slider-thumb {
    box-shadow: 0 0 0 10px rgba(63, 81, 181, 0.26);
}

.mdl-slider.is-upgraded:focus:not(:active)::-moz-range-thumb {
    box-shadow: 0 0 0 10px rgba(63, 81, 181, 0.26);
}

.mdl-slider.is-upgraded:active::-webkit-slider-thumb {
    background-image: none;
    background: rgb(63, 81, 181);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
}

.mdl-slider.is-upgraded:active::-moz-range-thumb {
    background-image: none;
    background: rgb(63, 81, 181);
    transform: scale(1.5);
}

.mdl-slider.is-upgraded::-ms-thumb {
    width: 32px;
    height: 32px;
    border: none;
    border-radius: 50%;
    background: rgb(63, 81, 181);
    transform: scale(0.375);
    transition: transform 0.18s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.18s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.18s cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-slider.is-upgraded:focus:not(:active)::-ms-thumb {
    background: radial-gradient(circle closest-side, rgb(63, 81, 181) 0%, rgb(63, 81, 181) 37.5%, rgba(63, 81, 181, 0.26) 37.5%, rgba(63, 81, 181, 0.26) 100%);
    transform: scale(1);
}

.mdl-slider.is-upgraded:active::-ms-thumb {
    background: rgb(63, 81, 181);
    transform: scale(0.5625);
}

.mdl-slider.is-upgraded.is-lowest-value::-webkit-slider-thumb {
    border: 2px solid rgba(0, 0, 0, 0.26);
    background: 0 0;
}

.mdl-slider.is-upgraded.is-lowest-value::-moz-range-thumb {
    border: 2px solid rgba(0, 0, 0, 0.26);
    background: 0 0;
}

.mdl-slider.is-upgraded.is-lowest-value+.mdl-slider__background-flex>.mdl-slider__background-upper {
    left: 6px;
}

.mdl-slider.is-upgraded.is-lowest-value:focus:not(:active)::-webkit-slider-thumb {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.12);
    background: rgba(0, 0, 0, 0.12);
}

.mdl-slider.is-upgraded.is-lowest-value:focus:not(:active)::-moz-range-thumb {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.12);
    background: rgba(0, 0, 0, 0.12);
}

.mdl-slider.is-upgraded.is-lowest-value:active::-webkit-slider-thumb {
    border: 1.6px solid rgba(0, 0, 0, 0.26);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
}

.mdl-slider.is-upgraded.is-lowest-value:active+.mdl-slider__background-flex>.mdl-slider__background-upper {
    left: 9px;
}

.mdl-slider.is-upgraded.is-lowest-value:active::-moz-range-thumb {
    border: 1.5px solid rgba(0, 0, 0, 0.26);
    transform: scale(1.5);
}

.mdl-slider.is-upgraded.is-lowest-value::-ms-thumb {
    background: radial-gradient(circle closest-side, transparent 0%, transparent 66.67%, rgba(0, 0, 0, 0.26) 66.67%, rgba(0, 0, 0, 0.26) 100%);
}

.mdl-slider.is-upgraded.is-lowest-value:focus:not(:active)::-ms-thumb {
    background: radial-gradient(circle closest-side, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 25%, rgba(0, 0, 0, 0.26) 25%, rgba(0, 0, 0, 0.26) 37.5%, rgba(0, 0, 0, 0.12) 37.5%, rgba(0, 0, 0, 0.12) 100%);
    transform: scale(1);
}

.mdl-slider.is-upgraded.is-lowest-value:active::-ms-thumb {
    transform: scale(0.5625);
    background: radial-gradient(circle closest-side, transparent 0%, transparent 77.78%, rgba(0, 0, 0, 0.26) 77.78%, rgba(0, 0, 0, 0.26) 100%);
}

.mdl-slider.is-upgraded.is-lowest-value::-ms-fill-lower {
    background: 0 0;
}

.mdl-slider.is-upgraded.is-lowest-value::-ms-fill-upper {
    margin-left: 6px;
}

.mdl-slider.is-upgraded.is-lowest-value:active::-ms-fill-upper {
    margin-left: 9px;
}

.mdl-slider.is-upgraded:disabled:focus::-webkit-slider-thumb,
.mdl-slider.is-upgraded:disabled:active::-webkit-slider-thumb,
.mdl-slider.is-upgraded:disabled::-webkit-slider-thumb {
    -webkit-transform: scale(0.667);
    transform: scale(0.667);
    background: rgba(0, 0, 0, 0.26);
}

.mdl-slider.is-upgraded:disabled:focus::-moz-range-thumb,
.mdl-slider.is-upgraded:disabled:active::-moz-range-thumb,
.mdl-slider.is-upgraded:disabled::-moz-range-thumb {
    transform: scale(0.667);
    background: rgba(0, 0, 0, 0.26);
}

.mdl-slider.is-upgraded:disabled+.mdl-slider__background-flex>.mdl-slider__background-lower {
    background-color: rgba(0, 0, 0, 0.26);
    left: -6px;
}

.mdl-slider.is-upgraded:disabled+.mdl-slider__background-flex>.mdl-slider__background-upper {
    left: 6px;
}

.mdl-slider.is-upgraded.is-lowest-value:disabled:focus::-webkit-slider-thumb,
.mdl-slider.is-upgraded.is-lowest-value:disabled:active::-webkit-slider-thumb,
.mdl-slider.is-upgraded.is-lowest-value:disabled::-webkit-slider-thumb {
    border: 3px solid rgba(0, 0, 0, 0.26);
    background: 0 0;
    -webkit-transform: scale(0.667);
    transform: scale(0.667);
}

.mdl-slider.is-upgraded.is-lowest-value:disabled:focus::-moz-range-thumb,
.mdl-slider.is-upgraded.is-lowest-value:disabled:active::-moz-range-thumb,
.mdl-slider.is-upgraded.is-lowest-value:disabled::-moz-range-thumb {
    border: 3px solid rgba(0, 0, 0, 0.26);
    background: 0 0;
    transform: scale(0.667);
}

.mdl-slider.is-upgraded.is-lowest-value:disabled:active+.mdl-slider__background-flex>.mdl-slider__background-upper {
    left: 6px;
}

.mdl-slider.is-upgraded:disabled:focus::-ms-thumb,
.mdl-slider.is-upgraded:disabled:active::-ms-thumb,
.mdl-slider.is-upgraded:disabled::-ms-thumb {
    transform: scale(0.25);
    background: rgba(0, 0, 0, 0.26);
}

.mdl-slider.is-upgraded.is-lowest-value:disabled:focus::-ms-thumb,
.mdl-slider.is-upgraded.is-lowest-value:disabled:active::-ms-thumb,
.mdl-slider.is-upgraded.is-lowest-value:disabled::-ms-thumb {
    transform: scale(0.25);
    background: radial-gradient(circle closest-side, transparent 0%, transparent 50%, rgba(0, 0, 0, 0.26) 50%, rgba(0, 0, 0, 0.26) 100%);
}

.mdl-slider.is-upgraded:disabled::-ms-fill-lower {
    margin-right: 6px;
    background: linear-gradient(to right, transparent, transparent 25px, rgba(0, 0, 0, 0.26) 25px, rgba(0, 0, 0, 0.26) 0);
}

.mdl-slider.is-upgraded:disabled::-ms-fill-upper {
    margin-left: 6px;
}

.mdl-slider.is-upgraded.is-lowest-value:disabled:active::-ms-fill-upper {
    margin-left: 6px;
}

.mdl-slider__ie-container {
    height: 18px;
    overflow: visible;
    border: none;
    margin: none;
    padding: none;
}

.mdl-slider__container {
    height: 18px;
    position: relative;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}

.mdl-slider__container,
.mdl-slider__background-flex {
    background: 0 0;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.mdl-slider__background-flex {
    position: absolute;
    height: 2px;
    width: calc(100% - 52px);
    top: 50%;
    left: 0;
    margin: 0 26px;
    overflow: hidden;
    border: 0;
    padding: 0;
    -webkit-transform: translate(0, -1px);
    transform: translate(0, -1px);
}

.mdl-slider__background-lower {
    background: rgb(63, 81, 181);
}

.mdl-slider__background-lower,
.mdl-slider__background-upper {
    -webkit-flex: 0;
    -ms-flex: 0;
    flex: 0;
    position: relative;
    border: 0;
    padding: 0;
}

.mdl-slider__background-upper {
    background: rgba(0, 0, 0, 0.26);
    transition: left 0.18s cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-snackbar {
    position: fixed;
    bottom: 0;
    left: 50%;
    cursor: default;
    background-color: #323232;
    z-index: 3;
    display: block;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    will-change: transform;
    -webkit-transform: translate(0, 80px);
    transform: translate(0, 80px);
    transition: transform 0.25s cubic-bezier(0.4, 0, 1, 1);
    transition: transform 0.25s cubic-bezier(0.4, 0, 1, 1), -webkit-transform 0.25s cubic-bezier(0.4, 0, 1, 1);
    pointer-events: none;
}

@media (max-width: 479px) {
    .mdl-snackbar {
        width: 100%;
        left: 0;
        min-height: 48px;
        max-height: 80px;
    }
}

@media (min-width: 480px) {
    .mdl-snackbar {
        min-width: 288px;
        max-width: 568px;
        border-radius: 2px;
        -webkit-transform: translate(-50%, 80px);
        transform: translate(-50%, 80px);
    }
}

.mdl-snackbar--active {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    pointer-events: auto;
    transition: transform 0.25s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.25s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.25s cubic-bezier(0, 0, 0.2, 1);
}

@media (min-width: 480px) {
    .mdl-snackbar--active {
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    }
}

.mdl-snackbar__text {
    padding: 14px 12px 14px 24px;
    vertical-align: middle;
    color: #fff;
    float: left;
}

.mdl-snackbar__action {
    background: 0 0;
    border: none;
    color: rgb(255, 64, 129);
    float: right;
    padding: 14px 24px 14px 12px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 0;
    overflow: hidden;
    outline: none;
    opacity: 0;
    pointer-events: none;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

.mdl-snackbar__action::-moz-focus-inner {
    border: 0;
}

.mdl-snackbar__action:not([aria-hidden]) {
    opacity: 1;
    pointer-events: auto;
}

.mdl-spinner {
    display: inline-block;
    position: relative;
    width: 28px;
    height: 28px;
}

.mdl-spinner:not(.is-upgraded).is-active:after {
    content: 'Loading...';
}

.mdl-spinner.is-upgraded.is-active {
    -webkit-animation: mdl-spinner__container-rotate 1568.23529412ms linear infinite;
    animation: mdl-spinner__container-rotate 1568.23529412ms linear infinite;
}

@-webkit-keyframes mdl-spinner__container-rotate {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes mdl-spinner__container-rotate {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.mdl-spinner__layer {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.mdl-spinner__layer-1 {
    border-color: #42a5f5;
}

.mdl-spinner--single-color .mdl-spinner__layer-1 {
    border-color: rgb(63, 81, 181);
}

.mdl-spinner.is-active .mdl-spinner__layer-1 {
    -webkit-animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-1-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-1-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdl-spinner__layer-2 {
    border-color: #f44336;
}

.mdl-spinner--single-color .mdl-spinner__layer-2 {
    border-color: rgb(63, 81, 181);
}

.mdl-spinner.is-active .mdl-spinner__layer-2 {
    -webkit-animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-2-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-2-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdl-spinner__layer-3 {
    border-color: #fdd835;
}

.mdl-spinner--single-color .mdl-spinner__layer-3 {
    border-color: rgb(63, 81, 181);
}

.mdl-spinner.is-active .mdl-spinner__layer-3 {
    -webkit-animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-3-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-3-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdl-spinner__layer-4 {
    border-color: #4caf50;
}

.mdl-spinner--single-color .mdl-spinner__layer-4 {
    border-color: rgb(63, 81, 181);
}

.mdl-spinner.is-active .mdl-spinner__layer-4 {
    -webkit-animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-4-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-4-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

@-webkit-keyframes mdl-spinner__fill-unfill-rotate {
    12.5% {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }
    25% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }
    37.5% {
        -webkit-transform: rotate(405deg);
        transform: rotate(405deg);
    }
    50% {
        -webkit-transform: rotate(540deg);
        transform: rotate(540deg);
    }
    62.5% {
        -webkit-transform: rotate(675deg);
        transform: rotate(675deg);
    }
    75% {
        -webkit-transform: rotate(810deg);
        transform: rotate(810deg);
    }
    87.5% {
        -webkit-transform: rotate(945deg);
        transform: rotate(945deg);
    }
    to {
        -webkit-transform: rotate(1080deg);
        transform: rotate(1080deg);
    }
}

@keyframes mdl-spinner__fill-unfill-rotate {
    12.5% {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }
    25% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }
    37.5% {
        -webkit-transform: rotate(405deg);
        transform: rotate(405deg);
    }
    50% {
        -webkit-transform: rotate(540deg);
        transform: rotate(540deg);
    }
    62.5% {
        -webkit-transform: rotate(675deg);
        transform: rotate(675deg);
    }
    75% {
        -webkit-transform: rotate(810deg);
        transform: rotate(810deg);
    }
    87.5% {
        -webkit-transform: rotate(945deg);
        transform: rotate(945deg);
    }
    to {
        -webkit-transform: rotate(1080deg);
        transform: rotate(1080deg);
    }
}

@-webkit-keyframes mdl-spinner__layer-1-fade-in-out {
    from,
    25% {
        opacity: 0.99;
    }
    26%,
    89% {
        opacity: 0;
    }
    90%,
    100% {
        opacity: 0.99;
    }
}

@keyframes mdl-spinner__layer-1-fade-in-out {
    from,
    25% {
        opacity: 0.99;
    }
    26%,
    89% {
        opacity: 0;
    }
    90%,
    100% {
        opacity: 0.99;
    }
}

@-webkit-keyframes mdl-spinner__layer-2-fade-in-out {
    from,
    15% {
        opacity: 0;
    }
    25%,
    50% {
        opacity: 0.99;
    }
    51% {
        opacity: 0;
    }
}

@keyframes mdl-spinner__layer-2-fade-in-out {
    from,
    15% {
        opacity: 0;
    }
    25%,
    50% {
        opacity: 0.99;
    }
    51% {
        opacity: 0;
    }
}

@-webkit-keyframes mdl-spinner__layer-3-fade-in-out {
    from,
    40% {
        opacity: 0;
    }
    50%,
    75% {
        opacity: 0.99;
    }
    76% {
        opacity: 0;
    }
}

@keyframes mdl-spinner__layer-3-fade-in-out {
    from,
    40% {
        opacity: 0;
    }
    50%,
    75% {
        opacity: 0.99;
    }
    76% {
        opacity: 0;
    }
}

@-webkit-keyframes mdl-spinner__layer-4-fade-in-out {
    from,
    65% {
        opacity: 0;
    }
    75%,
    90% {
        opacity: 0.99;
    }
    100% {
        opacity: 0;
    }
}

@keyframes mdl-spinner__layer-4-fade-in-out {
    from,
    65% {
        opacity: 0;
    }
    75%,
    90% {
        opacity: 0.99;
    }
    100% {
        opacity: 0;
    }
}

.mdl-spinner__gap-patch {
    position: absolute;
    box-sizing: border-box;
    top: 0;
    left: 45%;
    width: 10%;
    height: 100%;
    overflow: hidden;
    border-color: inherit;
}

.mdl-spinner__gap-patch .mdl-spinner__circle {
    width: 1000%;
    left: -450%;
}

.mdl-spinner__circle-clipper {
    display: inline-block;
    position: relative;
    width: 50%;
    height: 100%;
    overflow: hidden;
    border-color: inherit;
}

.mdl-spinner__circle-clipper.mdl-spinner__left {
    float: left;
}

.mdl-spinner__circle-clipper.mdl-spinner__right {
    float: right;
}

.mdl-spinner__circle-clipper .mdl-spinner__circle {
    width: 200%;
}

.mdl-spinner__circle {
    box-sizing: border-box;
    height: 100%;
    border-width: 3px;
    border-style: solid;
    border-color: inherit;
    border-bottom-color: transparent !important;
    border-radius: 50%;
    -webkit-animation: none;
    animation: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.mdl-spinner__left .mdl-spinner__circle {
    border-right-color: transparent !important;
    -webkit-transform: rotate(129deg);
    transform: rotate(129deg);
}

.mdl-spinner.is-active .mdl-spinner__left .mdl-spinner__circle {
    -webkit-animation: mdl-spinner__left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: mdl-spinner__left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdl-spinner__right .mdl-spinner__circle {
    left: -100%;
    border-left-color: transparent !important;
    -webkit-transform: rotate(-129deg);
    transform: rotate(-129deg);
}

.mdl-spinner.is-active .mdl-spinner__right .mdl-spinner__circle {
    -webkit-animation: mdl-spinner__right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: mdl-spinner__right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

@-webkit-keyframes mdl-spinner__left-spin {
    from {
        -webkit-transform: rotate(130deg);
        transform: rotate(130deg);
    }
    50% {
        -webkit-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }
    to {
        -webkit-transform: rotate(130deg);
        transform: rotate(130deg);
    }
}

@keyframes mdl-spinner__left-spin {
    from {
        -webkit-transform: rotate(130deg);
        transform: rotate(130deg);
    }
    50% {
        -webkit-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }
    to {
        -webkit-transform: rotate(130deg);
        transform: rotate(130deg);
    }
}

@-webkit-keyframes mdl-spinner__right-spin {
    from {
        -webkit-transform: rotate(-130deg);
        transform: rotate(-130deg);
    }
    50% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg);
    }
    to {
        -webkit-transform: rotate(-130deg);
        transform: rotate(-130deg);
    }
}

@keyframes mdl-spinner__right-spin {
    from {
        -webkit-transform: rotate(-130deg);
        transform: rotate(-130deg);
    }
    50% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg);
    }
    to {
        -webkit-transform: rotate(-130deg);
        transform: rotate(-130deg);
    }
}

.mdl-switch {
    position: relative;
    z-index: 1;
    vertical-align: middle;
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    height: 24px;
    margin: 0;
    padding: 0;
    overflow: visible;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.mdl-switch.is-upgraded {
    padding-left: 28px;
}

.mdl-switch__input {
    line-height: 24px;
}

.mdl-switch.is-upgraded .mdl-switch__input {
    position: absolute;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    -ms-appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
}

.mdl-switch__track {
    background: rgba(0, 0, 0, 0.26);
    position: absolute;
    left: 0;
    top: 5px;
    height: 14px;
    width: 36px;
    border-radius: 14px;
    cursor: pointer;
}

.mdl-switch.is-checked .mdl-switch__track {
    background: rgba(63, 81, 181, 0.5);
}

.mdl-switch__track fieldset[disabled] .mdl-switch,
.mdl-switch.is-disabled .mdl-switch__track {
    background: rgba(0, 0, 0, 0.12);
    cursor: auto;
}

.mdl-switch__thumb {
    background: #fafafa;
    position: absolute;
    left: 0;
    top: 2px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    transition-duration: 0.28s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: left;
}

.mdl-switch.is-checked .mdl-switch__thumb {
    background: rgb(63, 81, 181);
    left: 16px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

.mdl-switch__thumb fieldset[disabled] .mdl-switch,
.mdl-switch.is-disabled .mdl-switch__thumb {
    background: #bdbdbd;
    cursor: auto;
}

.mdl-switch__focus-helper {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-4px, -4px);
    transform: translate(-4px, -4px);
    display: inline-block;
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: transparent;
}

.mdl-switch.is-focused .mdl-switch__focus-helper {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.1);
}

.mdl-switch.is-focused.is-checked .mdl-switch__focus-helper {
    box-shadow: 0 0 0 20px rgba(63, 81, 181, 0.26);
    background-color: rgba(63, 81, 181, 0.26);
}

.mdl-switch__label {
    position: relative;
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    left: 24px;
}

.mdl-switch__label fieldset[disabled] .mdl-switch,
.mdl-switch.is-disabled .mdl-switch__label {
    color: #bdbdbd;
    cursor: auto;
}

.mdl-switch__ripple-container {
    position: absolute;
    z-index: 2;
    top: -12px;
    left: -14px;
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(circle, #fff, #000);
    transition-duration: 0.4s;
    transition-timing-function: step-end;
    transition-property: left;
}

.mdl-switch__ripple-container .mdl-ripple {
    background: rgb(63, 81, 181);
}

.mdl-switch__ripple-container fieldset[disabled] .mdl-switch,
.mdl-switch.is-disabled .mdl-switch__ripple-container {
    cursor: auto;
}

fieldset[disabled] .mdl-switch .mdl-switch__ripple-container .mdl-ripple,
.mdl-switch.is-disabled .mdl-switch__ripple-container .mdl-ripple {
    background: 0 0;
}

.mdl-switch.is-checked .mdl-switch__ripple-container {
    left: 2px;
}

.mdl-tabs {
    display: block;
    width: 100%;
}

.mdl-tabs__tab-bar {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: space-between;
    -ms-flex-line-pack: justify;
    align-content: space-between;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    height: 48px;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid #e0e0e0;
}

.mdl-tabs__tab {
    margin: 0;
    border: none;
    padding: 0 24px;
    float: left;
    position: relative;
    display: block;
    text-decoration: none;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.54);
    overflow: hidden;
}

.mdl-tabs.is-upgraded .mdl-tabs__tab.is-active {
    color: rgba(0, 0, 0, 0.87);
}

.mdl-tabs.is-upgraded .mdl-tabs__tab.is-active:after {
    height: 2px;
    width: 100%;
    display: block;
    content: ' ';
    bottom: 0;
    left: 0;
    position: absolute;
    background: rgb(63, 81, 181);
    -webkit-animation: border-expand 0.2s cubic-bezier(0.4, 0, 0.4, 1) 0.01s alternate forwards;
    animation: border-expand 0.2s cubic-bezier(0.4, 0, 0.4, 1) 0.01s alternate forwards;
    transition: all 1s cubic-bezier(0.4, 0, 1, 1);
}

.mdl-tabs__tab .mdl-tabs__ripple-container {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    overflow: hidden;
}

.mdl-tabs__tab .mdl-tabs__ripple-container .mdl-ripple {
    background: rgb(63, 81, 181);
}

.mdl-tabs__panel {
    display: block;
}

.mdl-tabs.is-upgraded .mdl-tabs__panel {
    display: none;
}

.mdl-tabs.is-upgraded .mdl-tabs__panel.is-active {
    display: block;
}

@-webkit-keyframes border-expand {
    0% {
        opacity: 0;
        width: 0;
    }
    100% {
        opacity: 1;
        width: 100%;
    }
}

@keyframes border-expand {
    0% {
        opacity: 0;
        width: 0;
    }
    100% {
        opacity: 1;
        width: 100%;
    }
}

.mdl-textfield {
    position: relative;
    font-size: 16px;
    display: inline-block;
    box-sizing: border-box;
    width: 300px;
    max-width: 100%;
    margin: 0;
    padding: 20px 0;
}

.mdl-textfield .mdl-button {
    position: absolute;
    bottom: 20px;
}

.mdl-textfield--align-right {
    text-align: right;
}

.mdl-textfield--full-width {
    width: 100%;
}

.mdl-textfield--expandable {
    min-width: 32px;
    width: auto;
    min-height: 32px;
}

.mdl-textfield--expandable .mdl-button--icon {
    top: 16px;
}

.mdl-textfield__input {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    display: block;
    font-size: 16px;
    font-family: 'Helvetica', 'Arial', sans-serif;
    margin: 0;
    padding: 4px 0;
    width: 100%;
    background: 0 0;
    text-align: left;
    color: inherit;
}

.mdl-textfield__input[type='number'] {
    -moz-appearance: textfield;
}

.mdl-textfield__input[type='number']::-webkit-inner-spin-button,
.mdl-textfield__input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.mdl-textfield.is-focused .mdl-textfield__input {
    outline: none;
}

.mdl-textfield.is-invalid .mdl-textfield__input {
    border-color: #d50000;
    box-shadow: none;
}

fieldset[disabled] .mdl-textfield .mdl-textfield__input,
.mdl-textfield.is-disabled .mdl-textfield__input {
    background-color: transparent;
    border-bottom: 1px dotted rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.26);
}

.mdl-textfield textarea.mdl-textfield__input {
    display: block;
}

.mdl-textfield__label {
    bottom: 0;
    color: rgba(0, 0, 0, 0.26);
    font-size: 16px;
    left: 0;
    right: 0;
    pointer-events: none;
    position: absolute;
    display: block;
    top: 24px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
}

.mdl-textfield.is-dirty .mdl-textfield__label,
.mdl-textfield.has-placeholder .mdl-textfield__label {
    visibility: hidden;
}

.mdl-textfield--floating-label .mdl-textfield__label {
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
    transition: none;
}

fieldset[disabled] .mdl-textfield .mdl-textfield__label,
.mdl-textfield.is-disabled.is-disabled .mdl-textfield__label {
    color: rgba(0, 0, 0, 0.26);
}

.mdl-textfield--floating-label.is-focused .mdl-textfield__label,
.mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
.mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
    color: rgb(63, 81, 181);
    font-size: 12px;
    top: 4px;
    visibility: visible;
}

.mdl-textfield--floating-label.is-focused .mdl-textfield__expandable-holder .mdl-textfield__label,
.mdl-textfield--floating-label.is-dirty .mdl-textfield__expandable-holder .mdl-textfield__label,
.mdl-textfield--floating-label.has-placeholder .mdl-textfield__expandable-holder .mdl-textfield__label {
    top: -16px;
}

.mdl-textfield--floating-label.is-invalid .mdl-textfield__label {
    color: #d50000;
    font-size: 12px;
}

.mdl-textfield__label:after {
    background-color: rgb(63, 81, 181);
    bottom: 20px;
    content: '';
    height: 2px;
    left: 45%;
    position: absolute;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    visibility: hidden;
    width: 10px;
}

.mdl-textfield.is-focused .mdl-textfield__label:after {
    left: 0;
    visibility: visible;
    width: 100%;
}

.mdl-textfield.is-invalid .mdl-textfield__label:after {
    background-color: #d50000;
}

.mdl-textfield__error {
    color: #d50000;
    position: absolute;
    font-size: 12px;
    margin-top: 3px;
    visibility: hidden;
    display: block;
}

.mdl-textfield.is-invalid .mdl-textfield__error {
    visibility: visible;
}

.mdl-textfield__expandable-holder {
    display: inline-block;
    position: relative;
    margin-left: 32px;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    display: inline-block;
    max-width: 0.1px;
}

.mdl-textfield.is-focused .mdl-textfield__expandable-holder,
.mdl-textfield.is-dirty .mdl-textfield__expandable-holder {
    max-width: 600px;
}

.mdl-textfield__expandable-holder .mdl-textfield__label:after {
    bottom: 0;
}

.mdl-tooltip {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: top center;
    transform-origin: top center;
    z-index: 999;
    background: rgba(97, 97, 97, 0.9);
    border-radius: 2px;
    color: #fff;
    display: inline-block;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    max-width: 170px;
    position: fixed;
    top: -500px;
    left: -500px;
    padding: 8px;
    text-align: center;
}

.mdl-tooltip.is-active {
    -webkit-animation: pulse 200ms cubic-bezier(0, 0, 0.2, 1) forwards;
    animation: pulse 200ms cubic-bezier(0, 0, 0.2, 1) forwards;
}

.mdl-tooltip--large {
    line-height: 14px;
    font-size: 14px;
    padding: 16px;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
    50% {
        -webkit-transform: scale(0.99);
        transform: scale(0.99);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
        visibility: visible;
    }
}

@keyframes pulse {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
    50% {
        -webkit-transform: scale(0.99);
        transform: scale(0.99);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
        visibility: visible;
    }
}

.mdl-shadow--2dp {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mdl-shadow--3dp {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

.mdl-shadow--4dp {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.mdl-shadow--6dp {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}

.mdl-shadow--8dp {
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.mdl-shadow--16dp {
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.mdl-shadow--24dp {
    box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.14), 0 11px 15px -7px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.2);
}

.mdl-grid {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin: 0 auto;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.mdl-grid.mdl-grid--no-spacing {
    padding: 0;
}

.mdl-cell {
    box-sizing: border-box;
}

.mdl-cell--top {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
}

.mdl-cell--middle {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

.mdl-cell--bottom {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
}

.mdl-cell--stretch {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
}

.mdl-grid.mdl-grid--no-spacing>.mdl-cell {
    margin: 0;
}

.mdl-cell--order-1 {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
}

.mdl-cell--order-2 {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
}

.mdl-cell--order-3 {
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
}

.mdl-cell--order-4 {
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
}

.mdl-cell--order-5 {
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
}

.mdl-cell--order-6 {
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
}

.mdl-cell--order-7 {
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
}

.mdl-cell--order-8 {
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
}

.mdl-cell--order-9 {
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
}

.mdl-cell--order-10 {
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
}

.mdl-cell--order-11 {
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
}

.mdl-cell--order-12 {
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
}

@media (max-width: 479px) {
    .mdl-grid {
        padding: 8px;
    }
    .mdl-cell {
        margin: 8px;
        width: calc(100% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell {
        width: 100%;
    }
    .mdl-cell--hide-phone {
        display: none !important;
    }
    .mdl-cell--order-1-phone.mdl-cell--order-1-phone {
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
    }
    .mdl-cell--order-2-phone.mdl-cell--order-2-phone {
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
    }
    .mdl-cell--order-3-phone.mdl-cell--order-3-phone {
        -webkit-order: 3;
        -ms-flex-order: 3;
        order: 3;
    }
    .mdl-cell--order-4-phone.mdl-cell--order-4-phone {
        -webkit-order: 4;
        -ms-flex-order: 4;
        order: 4;
    }
    .mdl-cell--order-5-phone.mdl-cell--order-5-phone {
        -webkit-order: 5;
        -ms-flex-order: 5;
        order: 5;
    }
    .mdl-cell--order-6-phone.mdl-cell--order-6-phone {
        -webkit-order: 6;
        -ms-flex-order: 6;
        order: 6;
    }
    .mdl-cell--order-7-phone.mdl-cell--order-7-phone {
        -webkit-order: 7;
        -ms-flex-order: 7;
        order: 7;
    }
    .mdl-cell--order-8-phone.mdl-cell--order-8-phone {
        -webkit-order: 8;
        -ms-flex-order: 8;
        order: 8;
    }
    .mdl-cell--order-9-phone.mdl-cell--order-9-phone {
        -webkit-order: 9;
        -ms-flex-order: 9;
        order: 9;
    }
    .mdl-cell--order-10-phone.mdl-cell--order-10-phone {
        -webkit-order: 10;
        -ms-flex-order: 10;
        order: 10;
    }
    .mdl-cell--order-11-phone.mdl-cell--order-11-phone {
        -webkit-order: 11;
        -ms-flex-order: 11;
        order: 11;
    }
    .mdl-cell--order-12-phone.mdl-cell--order-12-phone {
        -webkit-order: 12;
        -ms-flex-order: 12;
        order: 12;
    }
    .mdl-cell--1-col,
    .mdl-cell--1-col-phone.mdl-cell--1-col-phone {
        width: calc(25% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--1-col,
    .mdl-grid--no-spacing>.mdl-cell--1-col-phone.mdl-cell--1-col-phone {
        width: 25%;
    }
    .mdl-cell--2-col,
    .mdl-cell--2-col-phone.mdl-cell--2-col-phone {
        width: calc(50% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--2-col,
    .mdl-grid--no-spacing>.mdl-cell--2-col-phone.mdl-cell--2-col-phone {
        width: 50%;
    }
    .mdl-cell--3-col,
    .mdl-cell--3-col-phone.mdl-cell--3-col-phone {
        width: calc(75% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--3-col,
    .mdl-grid--no-spacing>.mdl-cell--3-col-phone.mdl-cell--3-col-phone {
        width: 75%;
    }
    .mdl-cell--4-col,
    .mdl-cell--4-col-phone.mdl-cell--4-col-phone {
        width: calc(100% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--4-col,
    .mdl-grid--no-spacing>.mdl-cell--4-col-phone.mdl-cell--4-col-phone {
        width: 100%;
    }
    .mdl-cell--5-col,
    .mdl-cell--5-col-phone.mdl-cell--5-col-phone {
        width: calc(100% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--5-col,
    .mdl-grid--no-spacing>.mdl-cell--5-col-phone.mdl-cell--5-col-phone {
        width: 100%;
    }
    .mdl-cell--6-col,
    .mdl-cell--6-col-phone.mdl-cell--6-col-phone {
        width: calc(100% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--6-col,
    .mdl-grid--no-spacing>.mdl-cell--6-col-phone.mdl-cell--6-col-phone {
        width: 100%;
    }
    .mdl-cell--7-col,
    .mdl-cell--7-col-phone.mdl-cell--7-col-phone {
        width: calc(100% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--7-col,
    .mdl-grid--no-spacing>.mdl-cell--7-col-phone.mdl-cell--7-col-phone {
        width: 100%;
    }
    .mdl-cell--8-col,
    .mdl-cell--8-col-phone.mdl-cell--8-col-phone {
        width: calc(100% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--8-col,
    .mdl-grid--no-spacing>.mdl-cell--8-col-phone.mdl-cell--8-col-phone {
        width: 100%;
    }
    .mdl-cell--9-col,
    .mdl-cell--9-col-phone.mdl-cell--9-col-phone {
        width: calc(100% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--9-col,
    .mdl-grid--no-spacing>.mdl-cell--9-col-phone.mdl-cell--9-col-phone {
        width: 100%;
    }
    .mdl-cell--10-col,
    .mdl-cell--10-col-phone.mdl-cell--10-col-phone {
        width: calc(100% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--10-col,
    .mdl-grid--no-spacing>.mdl-cell--10-col-phone.mdl-cell--10-col-phone {
        width: 100%;
    }
    .mdl-cell--11-col,
    .mdl-cell--11-col-phone.mdl-cell--11-col-phone {
        width: calc(100% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--11-col,
    .mdl-grid--no-spacing>.mdl-cell--11-col-phone.mdl-cell--11-col-phone {
        width: 100%;
    }
    .mdl-cell--12-col,
    .mdl-cell--12-col-phone.mdl-cell--12-col-phone {
        width: calc(100% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--12-col,
    .mdl-grid--no-spacing>.mdl-cell--12-col-phone.mdl-cell--12-col-phone {
        width: 100%;
    }
    .mdl-cell--1-offset,
    .mdl-cell--1-offset-phone.mdl-cell--1-offset-phone {
        margin-left: calc(25% + 8px);
    }
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--1-offset,
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--1-offset-phone.mdl-cell--1-offset-phone {
        margin-left: 25%;
    }
    .mdl-cell--2-offset,
    .mdl-cell--2-offset-phone.mdl-cell--2-offset-phone {
        margin-left: calc(50% + 8px);
    }
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--2-offset,
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--2-offset-phone.mdl-cell--2-offset-phone {
        margin-left: 50%;
    }
    .mdl-cell--3-offset,
    .mdl-cell--3-offset-phone.mdl-cell--3-offset-phone {
        margin-left: calc(75% + 8px);
    }
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--3-offset,
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--3-offset-phone.mdl-cell--3-offset-phone {
        margin-left: 75%;
    }
}

@media (min-width: 480px) and (max-width: 839px) {
    .mdl-grid {
        padding: 8px;
    }
    .mdl-cell {
        margin: 8px;
        width: calc(50% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell {
        width: 50%;
    }
    .mdl-cell--hide-tablet {
        display: none !important;
    }
    .mdl-cell--order-1-tablet.mdl-cell--order-1-tablet {
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
    }
    .mdl-cell--order-2-tablet.mdl-cell--order-2-tablet {
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
    }
    .mdl-cell--order-3-tablet.mdl-cell--order-3-tablet {
        -webkit-order: 3;
        -ms-flex-order: 3;
        order: 3;
    }
    .mdl-cell--order-4-tablet.mdl-cell--order-4-tablet {
        -webkit-order: 4;
        -ms-flex-order: 4;
        order: 4;
    }
    .mdl-cell--order-5-tablet.mdl-cell--order-5-tablet {
        -webkit-order: 5;
        -ms-flex-order: 5;
        order: 5;
    }
    .mdl-cell--order-6-tablet.mdl-cell--order-6-tablet {
        -webkit-order: 6;
        -ms-flex-order: 6;
        order: 6;
    }
    .mdl-cell--order-7-tablet.mdl-cell--order-7-tablet {
        -webkit-order: 7;
        -ms-flex-order: 7;
        order: 7;
    }
    .mdl-cell--order-8-tablet.mdl-cell--order-8-tablet {
        -webkit-order: 8;
        -ms-flex-order: 8;
        order: 8;
    }
    .mdl-cell--order-9-tablet.mdl-cell--order-9-tablet {
        -webkit-order: 9;
        -ms-flex-order: 9;
        order: 9;
    }
    .mdl-cell--order-10-tablet.mdl-cell--order-10-tablet {
        -webkit-order: 10;
        -ms-flex-order: 10;
        order: 10;
    }
    .mdl-cell--order-11-tablet.mdl-cell--order-11-tablet {
        -webkit-order: 11;
        -ms-flex-order: 11;
        order: 11;
    }
    .mdl-cell--order-12-tablet.mdl-cell--order-12-tablet {
        -webkit-order: 12;
        -ms-flex-order: 12;
        order: 12;
    }
    .mdl-cell--1-col,
    .mdl-cell--1-col-tablet.mdl-cell--1-col-tablet {
        width: calc(12.5% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--1-col,
    .mdl-grid--no-spacing>.mdl-cell--1-col-tablet.mdl-cell--1-col-tablet {
        width: 12.5%;
    }
    .mdl-cell--2-col,
    .mdl-cell--2-col-tablet.mdl-cell--2-col-tablet {
        width: calc(25% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--2-col,
    .mdl-grid--no-spacing>.mdl-cell--2-col-tablet.mdl-cell--2-col-tablet {
        width: 25%;
    }
    .mdl-cell--3-col,
    .mdl-cell--3-col-tablet.mdl-cell--3-col-tablet {
        width: calc(37.5% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--3-col,
    .mdl-grid--no-spacing>.mdl-cell--3-col-tablet.mdl-cell--3-col-tablet {
        width: 37.5%;
    }
    .mdl-cell--4-col,
    .mdl-cell--4-col-tablet.mdl-cell--4-col-tablet {
        width: calc(50% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--4-col,
    .mdl-grid--no-spacing>.mdl-cell--4-col-tablet.mdl-cell--4-col-tablet {
        width: 50%;
    }
    .mdl-cell--5-col,
    .mdl-cell--5-col-tablet.mdl-cell--5-col-tablet {
        width: calc(62.5% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--5-col,
    .mdl-grid--no-spacing>.mdl-cell--5-col-tablet.mdl-cell--5-col-tablet {
        width: 62.5%;
    }
    .mdl-cell--6-col,
    .mdl-cell--6-col-tablet.mdl-cell--6-col-tablet {
        width: calc(75% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--6-col,
    .mdl-grid--no-spacing>.mdl-cell--6-col-tablet.mdl-cell--6-col-tablet {
        width: 75%;
    }
    .mdl-cell--7-col,
    .mdl-cell--7-col-tablet.mdl-cell--7-col-tablet {
        width: calc(87.5% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--7-col,
    .mdl-grid--no-spacing>.mdl-cell--7-col-tablet.mdl-cell--7-col-tablet {
        width: 87.5%;
    }
    .mdl-cell--8-col,
    .mdl-cell--8-col-tablet.mdl-cell--8-col-tablet {
        width: calc(100% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--8-col,
    .mdl-grid--no-spacing>.mdl-cell--8-col-tablet.mdl-cell--8-col-tablet {
        width: 100%;
    }
    .mdl-cell--9-col,
    .mdl-cell--9-col-tablet.mdl-cell--9-col-tablet {
        width: calc(100% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--9-col,
    .mdl-grid--no-spacing>.mdl-cell--9-col-tablet.mdl-cell--9-col-tablet {
        width: 100%;
    }
    .mdl-cell--10-col,
    .mdl-cell--10-col-tablet.mdl-cell--10-col-tablet {
        width: calc(100% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--10-col,
    .mdl-grid--no-spacing>.mdl-cell--10-col-tablet.mdl-cell--10-col-tablet {
        width: 100%;
    }
    .mdl-cell--11-col,
    .mdl-cell--11-col-tablet.mdl-cell--11-col-tablet {
        width: calc(100% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--11-col,
    .mdl-grid--no-spacing>.mdl-cell--11-col-tablet.mdl-cell--11-col-tablet {
        width: 100%;
    }
    .mdl-cell--12-col,
    .mdl-cell--12-col-tablet.mdl-cell--12-col-tablet {
        width: calc(100% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--12-col,
    .mdl-grid--no-spacing>.mdl-cell--12-col-tablet.mdl-cell--12-col-tablet {
        width: 100%;
    }
    .mdl-cell--1-offset,
    .mdl-cell--1-offset-tablet.mdl-cell--1-offset-tablet {
        margin-left: calc(12.5% + 8px);
    }
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--1-offset,
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--1-offset-tablet.mdl-cell--1-offset-tablet {
        margin-left: 12.5%;
    }
    .mdl-cell--2-offset,
    .mdl-cell--2-offset-tablet.mdl-cell--2-offset-tablet {
        margin-left: calc(25% + 8px);
    }
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--2-offset,
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--2-offset-tablet.mdl-cell--2-offset-tablet {
        margin-left: 25%;
    }
    .mdl-cell--3-offset,
    .mdl-cell--3-offset-tablet.mdl-cell--3-offset-tablet {
        margin-left: calc(37.5% + 8px);
    }
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--3-offset,
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--3-offset-tablet.mdl-cell--3-offset-tablet {
        margin-left: 37.5%;
    }
    .mdl-cell--4-offset,
    .mdl-cell--4-offset-tablet.mdl-cell--4-offset-tablet {
        margin-left: calc(50% + 8px);
    }
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--4-offset,
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--4-offset-tablet.mdl-cell--4-offset-tablet {
        margin-left: 50%;
    }
    .mdl-cell--5-offset,
    .mdl-cell--5-offset-tablet.mdl-cell--5-offset-tablet {
        margin-left: calc(62.5% + 8px);
    }
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--5-offset,
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--5-offset-tablet.mdl-cell--5-offset-tablet {
        margin-left: 62.5%;
    }
    .mdl-cell--6-offset,
    .mdl-cell--6-offset-tablet.mdl-cell--6-offset-tablet {
        margin-left: calc(75% + 8px);
    }
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--6-offset,
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--6-offset-tablet.mdl-cell--6-offset-tablet {
        margin-left: 75%;
    }
    .mdl-cell--7-offset,
    .mdl-cell--7-offset-tablet.mdl-cell--7-offset-tablet {
        margin-left: calc(87.5% + 8px);
    }
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--7-offset,
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--7-offset-tablet.mdl-cell--7-offset-tablet {
        margin-left: 87.5%;
    }
}

@media (min-width: 840px) {
    .mdl-grid {
        padding: 8px;
    }
    .mdl-cell {
        margin: 8px;
        width: calc(33.3333333333% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell {
        width: 33.3333333333%;
    }
    .mdl-cell--hide-desktop {
        display: none !important;
    }
    .mdl-cell--order-1-desktop.mdl-cell--order-1-desktop {
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
    }
    .mdl-cell--order-2-desktop.mdl-cell--order-2-desktop {
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
    }
    .mdl-cell--order-3-desktop.mdl-cell--order-3-desktop {
        -webkit-order: 3;
        -ms-flex-order: 3;
        order: 3;
    }
    .mdl-cell--order-4-desktop.mdl-cell--order-4-desktop {
        -webkit-order: 4;
        -ms-flex-order: 4;
        order: 4;
    }
    .mdl-cell--order-5-desktop.mdl-cell--order-5-desktop {
        -webkit-order: 5;
        -ms-flex-order: 5;
        order: 5;
    }
    .mdl-cell--order-6-desktop.mdl-cell--order-6-desktop {
        -webkit-order: 6;
        -ms-flex-order: 6;
        order: 6;
    }
    .mdl-cell--order-7-desktop.mdl-cell--order-7-desktop {
        -webkit-order: 7;
        -ms-flex-order: 7;
        order: 7;
    }
    .mdl-cell--order-8-desktop.mdl-cell--order-8-desktop {
        -webkit-order: 8;
        -ms-flex-order: 8;
        order: 8;
    }
    .mdl-cell--order-9-desktop.mdl-cell--order-9-desktop {
        -webkit-order: 9;
        -ms-flex-order: 9;
        order: 9;
    }
    .mdl-cell--order-10-desktop.mdl-cell--order-10-desktop {
        -webkit-order: 10;
        -ms-flex-order: 10;
        order: 10;
    }
    .mdl-cell--order-11-desktop.mdl-cell--order-11-desktop {
        -webkit-order: 11;
        -ms-flex-order: 11;
        order: 11;
    }
    .mdl-cell--order-12-desktop.mdl-cell--order-12-desktop {
        -webkit-order: 12;
        -ms-flex-order: 12;
        order: 12;
    }
    .mdl-cell--1-col,
    .mdl-cell--1-col-desktop.mdl-cell--1-col-desktop {
        width: calc(8.3333333333% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--1-col,
    .mdl-grid--no-spacing>.mdl-cell--1-col-desktop.mdl-cell--1-col-desktop {
        width: 8.3333333333%;
    }
    .mdl-cell--2-col,
    .mdl-cell--2-col-desktop.mdl-cell--2-col-desktop {
        width: calc(16.6666666667% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--2-col,
    .mdl-grid--no-spacing>.mdl-cell--2-col-desktop.mdl-cell--2-col-desktop {
        width: 16.6666666667%;
    }
    .mdl-cell--3-col,
    .mdl-cell--3-col-desktop.mdl-cell--3-col-desktop {
        width: calc(25% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--3-col,
    .mdl-grid--no-spacing>.mdl-cell--3-col-desktop.mdl-cell--3-col-desktop {
        width: 25%;
    }
    .mdl-cell--4-col,
    .mdl-cell--4-col-desktop.mdl-cell--4-col-desktop {
        width: calc(33.3333333333% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--4-col,
    .mdl-grid--no-spacing>.mdl-cell--4-col-desktop.mdl-cell--4-col-desktop {
        width: 33.3333333333%;
    }
    .mdl-cell--5-col,
    .mdl-cell--5-col-desktop.mdl-cell--5-col-desktop {
        width: calc(41.6666666667% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--5-col,
    .mdl-grid--no-spacing>.mdl-cell--5-col-desktop.mdl-cell--5-col-desktop {
        width: 41.6666666667%;
    }
    .mdl-cell--6-col,
    .mdl-cell--6-col-desktop.mdl-cell--6-col-desktop {
        width: calc(50% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--6-col,
    .mdl-grid--no-spacing>.mdl-cell--6-col-desktop.mdl-cell--6-col-desktop {
        width: 50%;
    }
    .mdl-cell--7-col,
    .mdl-cell--7-col-desktop.mdl-cell--7-col-desktop {
        width: calc(58.3333333333% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--7-col,
    .mdl-grid--no-spacing>.mdl-cell--7-col-desktop.mdl-cell--7-col-desktop {
        width: 58.3333333333%;
    }
    .mdl-cell--8-col,
    .mdl-cell--8-col-desktop.mdl-cell--8-col-desktop {
        width: calc(66.6666666667% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--8-col,
    .mdl-grid--no-spacing>.mdl-cell--8-col-desktop.mdl-cell--8-col-desktop {
        width: 66.6666666667%;
    }
    .mdl-cell--9-col,
    .mdl-cell--9-col-desktop.mdl-cell--9-col-desktop {
        width: calc(75% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--9-col,
    .mdl-grid--no-spacing>.mdl-cell--9-col-desktop.mdl-cell--9-col-desktop {
        width: 75%;
    }
    .mdl-cell--10-col,
    .mdl-cell--10-col-desktop.mdl-cell--10-col-desktop {
        width: calc(83.3333333333% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--10-col,
    .mdl-grid--no-spacing>.mdl-cell--10-col-desktop.mdl-cell--10-col-desktop {
        width: 83.3333333333%;
    }
    .mdl-cell--11-col,
    .mdl-cell--11-col-desktop.mdl-cell--11-col-desktop {
        width: calc(91.6666666667% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--11-col,
    .mdl-grid--no-spacing>.mdl-cell--11-col-desktop.mdl-cell--11-col-desktop {
        width: 91.6666666667%;
    }
    .mdl-cell--12-col,
    .mdl-cell--12-col-desktop.mdl-cell--12-col-desktop {
        width: calc(100% - 16px);
    }
    .mdl-grid--no-spacing>.mdl-cell--12-col,
    .mdl-grid--no-spacing>.mdl-cell--12-col-desktop.mdl-cell--12-col-desktop {
        width: 100%;
    }
    .mdl-cell--1-offset,
    .mdl-cell--1-offset-desktop.mdl-cell--1-offset-desktop {
        margin-left: calc(8.3333333333% + 8px);
    }
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--1-offset,
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--1-offset-desktop.mdl-cell--1-offset-desktop {
        margin-left: 8.3333333333%;
    }
    .mdl-cell--2-offset,
    .mdl-cell--2-offset-desktop.mdl-cell--2-offset-desktop {
        margin-left: calc(16.6666666667% + 8px);
    }
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--2-offset,
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--2-offset-desktop.mdl-cell--2-offset-desktop {
        margin-left: 16.6666666667%;
    }
    .mdl-cell--3-offset,
    .mdl-cell--3-offset-desktop.mdl-cell--3-offset-desktop {
        margin-left: calc(25% + 8px);
    }
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--3-offset,
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--3-offset-desktop.mdl-cell--3-offset-desktop {
        margin-left: 25%;
    }
    .mdl-cell--4-offset,
    .mdl-cell--4-offset-desktop.mdl-cell--4-offset-desktop {
        margin-left: calc(33.3333333333% + 8px);
    }
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--4-offset,
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--4-offset-desktop.mdl-cell--4-offset-desktop {
        margin-left: 33.3333333333%;
    }
    .mdl-cell--5-offset,
    .mdl-cell--5-offset-desktop.mdl-cell--5-offset-desktop {
        margin-left: calc(41.6666666667% + 8px);
    }
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--5-offset,
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--5-offset-desktop.mdl-cell--5-offset-desktop {
        margin-left: 41.6666666667%;
    }
    .mdl-cell--6-offset,
    .mdl-cell--6-offset-desktop.mdl-cell--6-offset-desktop {
        margin-left: calc(50% + 8px);
    }
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--6-offset,
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--6-offset-desktop.mdl-cell--6-offset-desktop {
        margin-left: 50%;
    }
    .mdl-cell--7-offset,
    .mdl-cell--7-offset-desktop.mdl-cell--7-offset-desktop {
        margin-left: calc(58.3333333333% + 8px);
    }
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--7-offset,
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--7-offset-desktop.mdl-cell--7-offset-desktop {
        margin-left: 58.3333333333%;
    }
    .mdl-cell--8-offset,
    .mdl-cell--8-offset-desktop.mdl-cell--8-offset-desktop {
        margin-left: calc(66.6666666667% + 8px);
    }
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--8-offset,
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--8-offset-desktop.mdl-cell--8-offset-desktop {
        margin-left: 66.6666666667%;
    }
    .mdl-cell--9-offset,
    .mdl-cell--9-offset-desktop.mdl-cell--9-offset-desktop {
        margin-left: calc(75% + 8px);
    }
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--9-offset,
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--9-offset-desktop.mdl-cell--9-offset-desktop {
        margin-left: 75%;
    }
    .mdl-cell--10-offset,
    .mdl-cell--10-offset-desktop.mdl-cell--10-offset-desktop {
        margin-left: calc(83.3333333333% + 8px);
    }
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--10-offset,
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--10-offset-desktop.mdl-cell--10-offset-desktop {
        margin-left: 83.3333333333%;
    }
    .mdl-cell--11-offset,
    .mdl-cell--11-offset-desktop.mdl-cell--11-offset-desktop {
        margin-left: calc(91.6666666667% + 8px);
    }
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--11-offset,
    .mdl-grid.mdl-grid--no-spacing>.mdl-cell--11-offset-desktop.mdl-cell--11-offset-desktop {
        margin-left: 91.6666666667%;
    }
}

body {
    margin: 0;
}

.styleguide-demo h1 {
    margin: 48px 24px 0;
}

.styleguide-demo h1:after {
    content: '';
    display: block;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    margin-top: 24px;
}

.styleguide-demo {
    opacity: 0;
    transition: opacity 0.6s ease;
}

.styleguide-masthead {
    height: 256px;
    background: #212121;
    padding: 115px 16px 0;
}

.styleguide-container {
    position: relative;
    max-width: 960px;
    width: 100%;
}

.styleguide-title {
    color: #fff;
    bottom: auto;
    position: relative;
    font-size: 56px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: -0.02em;
}

.styleguide-title:after {
    border-bottom: 0;
}

.styleguide-title span {
    font-weight: 300;
}

.mdl-styleguide .mdl-layout__drawer .mdl-navigation__link {
    padding: 10px 24px;
}

.demosLoaded .styleguide-demo {
    opacity: 1;
}

iframe {
    display: block;
    width: 100%;
    border: none;
}

iframe.heightSet {
    overflow: hidden;
}

.demo-wrapper {
    margin: 24px;
}

.demo-wrapper iframe {
    border: 1px solid rgba(0, 0, 0, 0.5);
}


/*
 *  Document   : responsive.css
 *  Author     : RedStar Theme
 *  Description: A common stylesheet for responsive layout
 */


/* MEDIA QUERIES */

@media (max-width: 768px) {
    .dropzone-file-area {
        width: auto;
    }
    .hidden-phone {
        display: none;
    }
}

@media (max-width: 480px) {
    .inbox-compose .inbox-form-group>label {
        margin-top: 7px;
    }
    .dataTables_extended_wrapper div.dataTables_info,
    .dataTables_extended_wrapper div.dataTables_length,
    .dataTables_extended_wrapper div.dataTables_paginate {
        display: block;
        margin-bottom: 10px !important;
    }
    .dataTables_extended_wrapper .seperator {
        display: none !important;
    }
}

@media (max-width: 991px) {
    .profile-sidebar {
        float: none;
        width: 100% !important;
        margin: 0;
    }
    .profile-content {
        overflow: visible;
    }
    .form .form-bordered .form-group>div {
        border-left: 0;
    }
    .form .form-bordered .form-group .control-label {
        padding-top: 10px;
    }
    .form .form-bordered .form-actions {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .page-header.navbar .menu-toggler.responsive-toggler {
        display: block;
    }
    .navbar-custom {
        display: none;
    }
}

@media (max-width: 1024px) {
    .hidden-1024 {
        display: none;
    }
}

@media (max-width: 480px) {
    .hidden-480 {
        display: none;
    }
}

@media (max-width: 320px) {
    .hidden-320 {
        display: none;
    }
}

@media (max-width: 767px) {
    .list-separated>div {
        margin-bottom: 20px;
    }
    .navbar-nav .open .dropdown-menu {
        position: absolute;
        float: left;
        width: auto;
        margin-top: 0;
        background-color: #fff;
        border: 1px solid #efefef;
        box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
    }
    .navbar-nav .open .dropdown-menu>li>a {
        padding: 6px 0 6px 13px;
        color: #333;
    }
    .navbar-nav .open .dropdown-menu>li>a:active,
    .navbar-nav .open .dropdown-menu>li>a:hover {
        background-color: #eee;
    }
    .form .form-actions .btn-set,
    .form-actions .btn-set {
        margin-bottom: 3px;
        margin-top: 3px;
        float: left !important;
    }
    .text-stat {
        margin-top: 20px;
    }
    .table-advance tr>td.highlight:first-child a {
        margin-left: 8px;
    }
}

@media only screen and (min-width: 768px) {
    .sl-iconsdemo .item-box {
        width: 33.333%;
    }
    .cd-timeline-content h2 {
        font-size: 18px;
    }
    .cd-timeline-content p {
        font-size: 13px;
    }
    .cd-timeline-content .cd-read-more,
    .cd-timeline-content .cd-date {
        font-size: 14px;
    }
    .form-horizontal .control-label {
        padding-top: 7px;
        margin-bottom: 0;
        text-align: right;
    }
    .navbar-left {
        float: left !important;
    }
}

@media (min-width: 992px) {
    .sidemenu.sidemenu-hover-submenu>li:hover>.sub-menu {
        box-shadow: 5px 5px rgba(44, 53, 66, 0.2);
    }
    .sidemenu.sidemenu-hover-submenu>li:hover>.sub-menu.sidebar-search-wrapper,
    .sidemenu.sidemenu-hover-submenu>li:hover>.sub-menu.sidebar-toggler-wrapper {
        box-shadow: none;
    }
    .sidemenu.sidemenu-closed>li:hover {
        box-shadow: 5px 5px rgba(44, 53, 66, 0.2);
        background-color: #1d262b;
        color: white;
    }
    .sidemenu.sidemenu-closed>li:hover.sidebar-search-wrapper,
    .sidemenu.sidemenu-closed>li:hover.sidebar-toggler-wrapper {
        box-shadow: none;
    }
    .sidemenu.sidemenu-closed>li:hover>.sub-menu {
        box-shadow: 5px 5px rgba(44, 53, 66, 0.2);
    }
    .sidemenu.sidemenu-closed>li:hover>.sub-menu.sidebar-search-wrapper,
    .sidemenu.sidemenu-closed>li:hover>.sub-menu.sidebar-toggler-wrapper {
        box-shadow: none;
    }
    .sidemenu-container-fixed:not(.page-footer-fixed) .page-content {
        border-bottom: 0;
    }
    .sidemenu-container-fixed:not(.page-footer-fixed) .page-footer {
        background-color: #fff;
    }
    .sidemenu-container-fixed:not(.page-footer-fixed) .page-footer .page-footer-inner {
        color: #333;
    }
    .page-boxed {
        background-color: #303a47 !important;
    }
    .page-boxed .page-container {
        background-color: #1c262f;
        border-left: 1px solid #3d4957;
        border-bottom: 1px solid #3d4957;
    }
    .page-boxed.sidemenu-container-reversed .page-container {
        border-left: 0;
        border-right: 1px solid #3d4957;
    }
    .page-boxed.sidemenu-container-fixed .page-container {
        border-left: 0;
        border-bottom: 0;
    }
    .page-boxed.sidemenu-container-reversed.sidemenu-container-fixed .page-container {
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
    }
    .page-boxed.sidemenu-container-fixed .sidemenu-container {
        border-left: 1px solid #3d4957;
    }
    .page-boxed.sidemenu-container-reversed.sidemenu-container-fixed .sidemenu-container {
        border-right: 1px solid #3d4957;
        border-left: 0;
    }
    .page-boxed.sidemenu-container-fixed.page-footer-fixed .page-footer {
        background-color: #303a47 !important;
    }
    .page-boxed.sidemenu-container-fixed.page-footer-fixed .page-footer .page-footer-inner {
        color: #98a6ba;
    }
    .sidemenu-hover-submenu li:hover a>.arrow {
        border-right: 8px solid #ffffff;
    }
    .sidemenu-container-reversed .sidemenu-hover-submenu li:hover a>.arrow {
        border-left: 8px solid #323c4b;
    }
    .sidemenu-hover-submenu li:hover>.sub-menu {
        background: #323c4b;
    }
}

@media (max-width: 991px) {
    .sidemenu-container {
        background-color: #28303b;
    }
    .sidemenu-container .sidemenu>li.open>a,
    .sidemenu-container .sidemenu>li:hover>a {
        background: #2e3744;
    }
    .sidemenu-container .sidemenu>li:last-child>a {
        border-bottom: 0 !important;
    }
    .quick-setting-main {
        display: none;
    }
    .white-sidebar-color .sidemenu-hover-submenu li:hover a>.arrow,
    .dark-sidebar-color .sidemenu-hover-submenu li:hover a>.arrow,
    .cyan-sidebar-color .sidemenu-hover-submenu li:hover a>.arrow,
    .indigo-sidebar-color .sidemenu-hover-submenu li:hover a>.arrow,
    .blue-sidebar-color .sidemenu-hover-submenu li:hover a>.arrow,
    .green-sidebar-color .sidemenu-hover-submenu li:hover a>.arrow,
    .red-sidebar-color .sidemenu-hover-submenu li:hover a>.arrow {
        border-right: none !important;
    }
}

@media (max-width: 480px) {
    .page-header.navbar .top-menu {
        background-color: #1c262f;
    }
    .page-header-fixed-mobile .page-header.navbar .top-menu {
        background-color: #2b3643;
    }
    .page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-toggle {}
    .page-header-fixed-mobile .page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-toggle {
        background: 0 0;
    }
}

@media (max-width: 768px) {
    .input-large {
        width: 250px !important;
    }
    .input-xlarge {
        width: 300px !important;
    }
    .input-lg {
        width: 250px !important;
    }
    .input-xlg {
        width: 300px !important;
    }
    .modal-full.modal-dialog {
        width: auto;
    }
}

@media (min-width: 768px) {
    .page-header.navbar .search-form.search-form-expanded {
        width: 200px;
    }
    .page-header.navbar .search-form.search-form-expanded .input-group .form-control {
        text-indent: 0;
    }
    .page-header.navbar .search-form.search-form-expanded .input-group .form-control:hover {
        cursor: text;
    }
    .page-header.navbar .search-form.search-form-expanded .input-group .input-group-btn .btn.submit {
        margin-left: 0;
    }
    .navbar-nav>li {
        float: left;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .page-boxed .page-header.navbar .top-menu .navbar-nav>li.dropdown-language .dropdown-toggle .langname,
    .page-boxed .page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-toggle .username.username-hide-on-mobile {
        display: none;
    }
}

@media (min-width: 992px) {
    .sidemenu-closed.sidemenu-closed-hidelogo .page-header.navbar .page-logo {
        padding: 10px !important;
        width: 45px;
    }
    .sidemenu-closed.sidemenu-closed-hidelogo .page-header.navbar .page-logo .logo-default {
        display: none;
    }
    .page-boxed .page-header.navbar .page-logo {
        width: 236px;
    }
    .page-boxed .page-header.navbar .top-menu .navbar-nav {
        margin-right: 0;
    }
    .sidemenu-closed.sidemenu-closed-hidelogo.page-boxed .page-header.navbar .page-logo {
        width: 46px;
    }
    .page-boxed.sidemenu-container-fixed .page-header.navbar .page-logo {
        width: 235px;
    }
    .sidemenu-container {
        width: 235px;
        float: left;
        position: relative;
        margin-right: -100%;
    }
    .page-full-width .sidemenu-container {
        display: none !important;
    }
    .sidemenu-container.collapse {
        display: block;
        max-height: none !important;
    }
    .sidemenu-container-reversed .sidemenu-container {
        float: right;
        margin-right: 0;
        margin-left: -100%;
    }
    .sidemenu-container-reversed.sidemenu-container-fixed .sidemenu-container {
        margin-left: -235px;
    }
    .sidemenu-container-reversed.sidemenu-container-fixed .sidebar-container {
        position: relative;
        float: right;
    }
    .sidemenu-container-fixed .sidemenu-container {
        position: fixed !important;
        margin-left: 0;
        top: 50px;
    }
    .sidemenu-container-fixed .sidemenu>li.last {
        margin-bottom: 15px !important;
    }
    .sidemenu-container-fixed .sidemenu .sub-menu {
        height: auto !important;
    }
    .sidemenu-closed .sidemenu-container,
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed {
        width: 45px !important;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed>li.open>.sub-menu,
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed>li>.sub-menu {
        display: none !important;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed>li:hover {
        width: 256px !important;
        position: relative !important;
        z-index: 10000;
        display: block !important;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed>li:hover>a {
        -webkit-border-radius: 0 4px 0 0;
        -moz-border-radius: 0 4px 0 0;
        -ms-border-radius: 0 4px 0 0;
        -o-border-radius: 0 4px 0 0;
        border-radius: 0 4px 0 0;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed>li:hover>a>i {
        margin-right: 10px;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed>li:hover>a>.title {
        display: inline !important;
        padding-left: 15px;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed>li:hover>a>.badge {
        display: block !important;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed>li:hover>a>.selected {
        display: none;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed>li:hover.heading {
        width: 45px !important;
        box-shadow: none;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed>li:hover>.sub-menu {
        width: 210px;
        position: absolute;
        z-index: 2000;
        left: 46px;
        margin-top: 0;
        top: 100%;
        display: block !important;
        -webkit-border-radius: 0 0 4px 4px;
        -moz-border-radius: 0 0 4px 4px;
        -ms-border-radius: 0 0 4px 4px;
        -o-border-radius: 0 0 4px 4px;
        border-radius: 0 0 4px 4px;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed>li:hover>.sub-menu>li>a {
        padding-left: 15px !important;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed>li:hover>.sub-menu>li>.sub-menu>li>a {
        padding-left: 30px !important;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed>li:hover>.sub-menu>li>.sub-menu>li>.sub-menu>li>a {
        padding-left: 45px !important;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed>li.heading>h3 {
        display: none;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed>li.sidebar-toggler-wrapper .sidebar-toggler {
        margin-right: 8px;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed>li.sidebar-search-wrapper:hover,
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed>li.sidebar-toggler-wrapper:hover {
        width: 45px !important;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed>li>a {
        padding-left: 11px;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed>li>a .selected {
        right: -3px !important;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed>li>a>.arrow,
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed>li>a>.badge,
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed>li>a>.title {
        display: none !important;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed .sidebar-toggler {
        margin-left: 3px;
        margin-right: 3px;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed .sidebar-search .input-group {
        border-color: transparent;
        margin-left: -4px;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed .sidebar-search .input-group .form-control {
        display: none;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed .sidebar-search .input-group .input-group-btn .btn {
        display: block;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed .sidebar-search.sidebar-search-bordered .input-group {
        padding: 5px 0 3px;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed .sidebar-search.open {
        height: 40px;
        margin-top: 15px;
        margin-bottom: 14px;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed .sidebar-search.open .input-group {
        width: 210px;
        position: relative;
        z-index: 1;
        margin-left: 24px;
        padding: 0;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed .sidebar-search.open .input-group .form-control {
        background: 0 0;
        border: 0;
        display: block;
        padding: 8px;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed .sidebar-search.open .input-group .input-group-btn .btn {
        display: block;
        margin-right: 8px;
        margin-top: 1px;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed .sidebar-search.open .remove {
        background-repeat: no-repeat;
        width: 11px;
        height: 11px;
        margin: 10px -5px 8px -7px;
        display: block;
        float: left;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed .sidebar-search.open.sidebar-search-bordered {
        height: 38px;
        margin-top: 23px;
        margin-bottom: 23px;
    }
    .sidemenu-closed .sidemenu-container .sidemenu.sidemenu-closed .sidebar-search.open.sidebar-search-bordered .input-group {
        padding: 0;
    }
    .sidemenu-closed.sidemenu-container-reversed .sidemenu-container {
        margin-left: -45px;
        width: 45px;
    }
    .sidemenu-closed.sidemenu-container-reversed .sidemenu-container .sidemenu.sidemenu-closed>li>.sub-menu {
        left: auto;
        right: 46px;
    }
    .sidemenu-closed.sidemenu-container-reversed .sidemenu-container .sidemenu.sidemenu-closed>li:hover {
        margin-left: -211px;
    }
    .sidemenu-closed.sidemenu-container-reversed .sidemenu-container .sidemenu.sidemenu-closed>li:hover>a {
        -webkit-border-radius: 4px 0 0;
        -moz-border-radius: 4px 0 0;
        -ms-border-radius: 4px 0 0 0;
        -o-border-radius: 4px 0 0;
        border-radius: 4px 0 0;
    }
    .sidemenu-closed.sidemenu-container-reversed .sidemenu-container .sidemenu.sidemenu-closed>li:hover>a>.title {
        padding-left: 0;
        padding-right: 15px;
    }
    .sidemenu-closed.sidemenu-container-reversed .sidemenu-container .sidemenu.sidemenu-closed>li:hover>a>i {
        margin-right: 0;
        margin-left: 2px;
    }
    .sidemenu-closed.sidemenu-container-reversed .sidemenu-container .sidemenu.sidemenu-closed>li.sidebar-search-wrapper:hover,
    .sidemenu-closed.sidemenu-container-reversed .sidemenu-container .sidemenu.sidemenu-closed>li.sidebar-toggler-wrapper:hover {
        margin-left: 0;
    }
    .sidemenu-closed.sidemenu-container-reversed .sidemenu-container .sidemenu.sidemenu-closed .sidebar-search.open .input-group {
        margin-left: -227px;
    }
    .sidemenu-closed.sidemenu-container-reversed .sidemenu-container .sidemenu.sidemenu-closed .sidebar-search.open .input-group .input-group-btn .btn {
        margin-right: 10px !important;
    }
    .sidemenu-closed.sidemenu-container-reversed .sidemenu-container .sidemenu.sidemenu-closed .sidebar-search.open .remove {
        margin: 9px 4px 12px -16px !important;
        float: right !important;
    }
    .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover {
        width: 235px !important;
        display: block;
        z-index: 10000;
    }
    .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .selected,
    .sidemenu-closed.sidemenu-container-hide .sidemenu-container {
        display: none !important;
    }
    .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu {
        width: 235px !important;
    }
    .sidemenu-closed.sidemenu-container-fixed.sidemenu-container-reversed .sidemenu-container:hover {
        width: 235px !important;
        z-index: 10000;
        margin-left: -235px !important;
    }
    .sidemenu-closed.sidemenu-container-fixed.sidemenu-container-reversed .sidemenu-container:hover .sidemenu {
        width: 235px !important;
    }
    .sidemenu.sidemenu-hover-submenu li .sub-menu {
        display: none;
        width: 210px;
        z-index: 2000;
        position: absolute;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        border-radius: 4px;
    }
    .sidemenu.sidemenu-hover-submenu li .sub-menu>li>a {
        margin: 3px;
    }
    .sidemenu.sidemenu-hover-submenu li.active .sub-menu,
    .sidemenu.sidemenu-hover-submenu li.open .sub-menu {
        display: none !important;
    }
    .sidemenu.sidemenu-hover-submenu li a>.arrow {
        display: none;
    }
    .sidemenu.sidemenu-hover-submenu li:hover>a>.arrow {
        display: block;
        float: right;
        position: absolute;
        right: 0;
        margin-top: -20px;
        background: 0 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-top: 12px double transparent;
        border-bottom: 12px double transparent;
        border-left: 0;
    }
    .sidemenu.sidemenu-hover-submenu li:hover>a>.arrow:after,
    .sidemenu.sidemenu-hover-submenu li:hover>a>.arrow:before {
        display: none;
    }
    .sidemenu-container-reversed .sidemenu.sidemenu-hover-submenu li:hover>a>.arrow {
        right: auto;
        left: 0;
        border-right: 0;
    }
    .sidemenu.sidemenu-hover-submenu li:hover>.sub-menu {
        display: inline-block !important;
    }
    .sidemenu.sidemenu-hover-submenu>li:hover>a>.arrow {
        z-index: 1;
        right: 0;
        margin-top: -23px;
    }
    .sidemenu.sidemenu-hover-submenu>li:hover>a>.selected {
        display: none;
    }
    .sidemenu.sidemenu-hover-submenu>li:hover>.sub-menu {
        margin-left: 235px;
        margin-top: -40px;
    }
    .sidemenu-container-reversed .sidemenu.sidemenu-hover-submenu>li:hover>.sub-menu {
        margin-left: -210px !important;
    }
    .sidemenu-closed .sidemenu.sidemenu-hover-submenu>li:hover>.sub-menu {
        margin-left: 0;
    }
    .sidemenu.sidemenu-hover-submenu>li:hover>.sub-menu>li>a {
        padding-left: 15px;
    }
    .sidemenu.sidemenu-hover-submenu>li:hover>.sub-menu>li .sub-menu {
        margin-left: 210px;
        margin-top: -38px !important;
    }
    .sidemenu-container-reversed .sidemenu.sidemenu-hover-submenu>li:hover>.sub-menu>li .sub-menu {
        margin-left: -210px !important;
    }
    .sidemenu.sidemenu-hover-submenu>li:hover>.sub-menu>li .sub-menu>li>a {
        padding-left: 10px;
        padding-right: 10px;
    }
    .page-content-wrapper {
        float: left;
        width: 100%;
    }
    .page-content-wrapper .page-content {
        margin-left: 235px;
        margin-top: 0;
        min-height: 759px;
        /*791px*/
        padding: 25px 20px 10px;
    }
    .page-content-wrapper .page-content.no-min-height {
        min-height: auto;
    }
    .sidemenu-container-fixed.sidemenu-container-hover-on .page-content-wrapper .page-content {
        margin-left: 45px;
    }
    .sidemenu-container-reversed .page-content-wrapper .page-content {
        margin-left: 0 !important;
        margin-right: 235px !important;
    }
    .sidemenu-container-reversed.sidemenu-container-fixed.sidemenu-container-hover-on .page-content-wrapper .page-content {
        margin-left: 0;
        margin-right: 45px;
    }
    .sidemenu-container-reversed.sidemenu-closed .page-content-wrapper .page-content {
        margin-left: 0 !important;
        margin-right: 45px !important;
    }
    .sidemenu-closed .page-content-wrapper .page-content {
        margin-left: 45px !important;
    }
    .page-full-width .page-content-wrapper .page-content,
    .sidemenu-closed.sidemenu-container-hide .page-content-wrapper .page-content {
        margin-left: 0 !important;
    }
    .sidemenu-closed.sidemenu-container-reversed.sidemenu-container-hide .page-content-wrapper .page-content {
        margin-right: 0 !important;
    }
    .page-footer {
        clear: left;
    }
    .page-footer-fixed .page-footer {
        position: fixed;
        left: 0;
        right: 0;
        z-index: 10000;
        bottom: 0;
    }
    .sidemenu-container-fixed.sidemenu-closed .page-footer {
        margin-left: 45px;
    }
    .sidemenu-container-fixed.page-footer-fixed .page-footer {
        margin-left: 0 !important;
    }
    .sidemenu-container-fixed .page-footer {
        margin-left: 235px;
        padding: 8px 20px 5px;
    }
    .page-boxed .page-footer {
        padding: 8px 0 5px;
    }
    .page-boxed.sidemenu-container-fixed .page-footer {
        padding-right: 20px;
        padding-left: 20px;
    }
    .sidemenu-container-reversed.sidemenu-container-fixed .page-footer {
        margin-left: 0;
        margin-right: 235px;
        padding: 8px 20px 5px;
    }
    .sidemenu-container-reversed.sidemenu-container-fixed.page-footer-fixed .page-footer {
        margin-left: 0;
        margin-right: 0;
    }
    .sidemenu-container-reversed.sidemenu-container-fixed.sidemenu-closed .page-footer {
        margin-right: 45px;
    }
    .scroll-to-top {
        right: 20px;
    }
    .page-footer-fixed .page-container {
        margin-bottom: 20px !important;
    }
}

@media (max-width: 991px) {
    .page-header.navbar {
        /*padding: 0 20px; */
        position: relative;
        clear: both;
    }
    .page-header.navbar .page-logo {
        width: auto;
        padding: 10px 20px 0px 20px;
        margin-right: 0px;
        margin-left: 0 !important;
        /*padding-left: 0 !important;*/
        background: transparent;
    }
    .header-white .page-logo a {
        /*color: #000;*/
    }
    .page-header.navbar .page-logo img {
        margin-left: 4px !important;
    }
    .page-header.navbar .menu-toggler.sidebar-toggler {
        display: none !important;
    }
    .page-header.navbar .top-menu .navbar-nav {
        display: inline-block;
        margin: 0;
    }
    .page-header.navbar .top-menu .navbar-nav>li {
        float: left;
    }
    .page-header.navbar .top-menu .navbar-nav .nav li.dropdown i {
        display: inline-block;
        position: relative;
        top: 1px;
        right: 0;
    }
    .page-header.navbar .top-menu .navbar-nav .open .dropdown-menu {
        position: absolute;
    }
    .page-header-fixed.page-header-fixed-mobile .navbar-fixed-top {
        position: fixed;
    }
    .page-boxed .page-header.navbar>.container {
        max-width: none !important;
        margin: 0 !important;
        padding: 0 !important;
    }
    .sidemenu-container,
    .sidemenu-container.navbar-collapse.in {
        border-top: 0 !important;
        margin: 20px;
    }
    .sidemenu-container .sidebar-toggler {
        display: none;
    }
    .sidemenu-container.navbar-collapse {
        max-height: none;
    }
    .sidemenu-container.navbar-collapse.in {
        position: relative;
        overflow: hidden !important;
        overflow-y: auto !important;
        display: block !important;
    }
    .sidemenu-container.navbar-collapse.navbar-no-scroll {
        max-height: none !important;
    }
    .sidemenu-container .mega-menu-responsive-content {
        padding: 10px 18px 10px 45px;
    }
    .page-full-width .sidemenu {
        display: block;
    }
    .sidemenu-container-mobile-offcanvas .sidebar-container {
        z-index: 10000;
        position: fixed;
        top: 0;
        bottom: 0;
        overflow-y: auto;
        width: 235px;
        left: -235px;
        transition: all 0.3s;
    }
    .sidemenu-container-mobile-offcanvas .sidebar-container .sidemenu-container {
        margin: 0 !important;
    }
    .sidemenu-container-mobile-offcanvas .sidebar-container .sidemenu-container .sidemenu {
        width: 100%;
        margin: 0 !important;
        padding: 0 0 20px;
    }
    .sidemenu-container-mobile-offcanvas .sidebar-container .sidemenu-container .sidemenu>li.sidebar-mobile-offcanvas-toggler {
        display: block;
        border: 0;
        text-align: right;
    }
    .sidemenu-container-mobile-offcanvas .sidebar-container .sidemenu-container .sidemenu>li.sidebar-mobile-offcanvas-toggler>a {
        border: 0;
    }
    .sidemenu-container-mobile-offcanvas .sidebar-container .sidemenu-container .sidemenu>li.sidebar-mobile-offcanvas-toggler>a:hover {
        background: 0 0;
    }
    .sidemenu-container-mobile-offcanvas.sidemenu-container-mobile-offcanvas-open .sidebar-container {
        left: 0;
        transition: all 0.3s;
    }
    .sidemenu-container.fixed-menu {
        position: relative;
    }
    body,
    html {
        overflow-x: hidden;
    }
    .page-container-bg-solid .page-bar,
    .page-content-white .page-bar {
        margin-top: -20px;
    }
    .page-boxed>.container {
        max-width: none !important;
        margin: 0 !important;
        padding: 0 !important;
    }
    .page-content-wrapper .page-content {
        margin: 0 !important;
        padding: 20px !important;
        min-height: 280px;
    }
    .sidemenu-container-mobile-offcanvas .page-wrapper {
        left: 0;
        transition: all 0.3s;
    }
    .sidemenu-container-mobile-offcanvas .page-wrapper .page-header {
        transition: all 0.3s;
    }
    .sidemenu-container-mobile-offcanvas.sidemenu-container-mobile-offcanvas-open {
        overflow-x: hidden;
        transition: all 0.3s;
    }
    .sidemenu-container-mobile-offcanvas.sidemenu-container-mobile-offcanvas-open .page-wrapper {
        position: relative;
        left: 235px;
        transition: all 0.3s;
    }
    .sidemenu-container-mobile-offcanvas.sidemenu-container-mobile-offcanvas-open .page-wrapper .page-header {
        transition: all 0.3s;
    }
    .page-boxed .page-footer {
        padding-left: 0;
        padding-right: 0;
    }
    .scroll-to-top {
        bottom: 10px;
        right: 10px;
    }
    .scroll-to-top>i {
        font-size: 28px;
    }
    .page-container {
        margin: 0 !important;
        padding: 0 !important;
    }
    .page-header-fixed.page-header-fixed-mobile .page-container {
        margin-top: 50px !important;
    }
}

@media (max-width: 815px) {
    .chat-sidebar-container {
        top: 106px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .page-boxed .page-header.navbar {
        margin: auto !important;
        padding: 0;
    }
    .page-boxed .page-header.navbar>.container {
        margin: auto !important;
    }
    .sidemenu-container .btn-navbar.collapsed .arrow {
        display: none;
    }
    .sidemenu-container .btn-navbar .arrow {
        position: absolute;
        right: 25px;
        width: 0;
        height: 0;
        top: 50px;
        border-bottom: 15px solid #5f646b;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
    }
    .page-boxed>.container {
        margin: auto !important;
    }
}

@media (max-width: 767px) {
    .page-header.navbar {
        /*padding: 0 10px*/
    }
    .page-header.navbar .page-logo {
        width: auto;
    }
    .page-header.navbar .search-form.open {
        z-index: 3;
        left: 10px;
        right: 10px;
        position: absolute;
        width: auto !important;
    }
    .page-header.navbar .top-menu .navbar-nav>li.dropdown-extended>.dropdown-menu {
        max-width: 255px;
        width: 255px;
    }
    .page-header.navbar .top-menu .navbar-nav>li.dropdown-notification .dropdown-menu {
        margin-right: -110px;
    }
    .page-header.navbar .top-menu .navbar-nav>li.dropdown-notification .dropdown-menu:after,
    .page-header.navbar .top-menu .navbar-nav>li.dropdown-notification .dropdown-menu:before {
        margin-right: 105px;
    }
    .page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox .dropdown-menu {
        margin-right: -90px;
    }
    .page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox .dropdown-menu:after,
    .page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox .dropdown-menu:before {
        margin-right: 85px;
    }
    .page-header.navbar .top-menu .navbar-nav>li.dropdown-tasks .dropdown-menu {
        margin-right: -110px;
    }
    .page-header.navbar .top-menu .navbar-nav>li.dropdown-tasks .dropdown-menu:after,
    .page-header.navbar .top-menu .navbar-nav>li.dropdown-tasks .dropdown-menu:before {
        margin-right: 110px;
    }
    .page-content-wrapper .page-content {
        padding: 20px 10px 10px !important;
        overflow: hidden;
    }
    .page-content-wrapper .page-content .page-title {
        margin-bottom: 20px;
        font-size: 18px;
    }
    .page-content-wrapper .page-content .page-title small {
        font-size: 13px;
        padding-top: 3px;
    }
    .page-boxed .page-footer,
    .page-footer {
        padding-left: 10px;
        padding-right: 10px;
    }
    .page-footer-fixed .page-footer .container {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 580px) {
    .page-header.navbar .top-menu .navbar-nav>li.dropdown-language .dropdown-toggle .langname,
    .page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-toggle .username.username-hide-on-mobile {
        display: none;
    }
}

@media (max-width: 591px) {
    .page-header.navbar .top-menu .navbar-nav>li.dropdown.language-switch>.dropdown-toggle {
        padding: 16px 5px 14px;
    }
}

@media (max-width: 547px) {
    .chat-sidebar-container {
        top: 100px;
    }
}

@media (max-width: 516px) {
    .chat-sidebar-container {
        top: 120px;
    }
}

@media (max-width: 480px) {
    .page-header-fixed.page-header-fixed-mobile .page-header.navbar {
        height: 100px;
    }
    .page-header.navbar .top-menu {
        display: block;
        clear: both;
        float: none;
    }
    .page-header.navbar .top-menu .navbar-nav {
        margin-right: 0;
    }
    .page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-toggle {
        padding: 19px 5px 10px;
    }
    .page-header.navbar .top-menu .navbar-nav>li.dropdown-language .dropdown-toggle {
        padding: 16px 4px 13px 2px;
    }
    .page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-toggle {
        padding: 16px 0 13px 2px;
    }
    form.search-form-opened {
        margin-left: 0;
        max-width: 170px;
    }
    .sidemenu-container,
    .sidemenu-container.in {
        margin: 0 10px 10px !important;
    }
    .page-header-fixed.page-header-fixed-mobile .sidemenu-container,
    .page-header-fixed.page-header-fixed-mobile .sidemenu-container.in {
        margin-top: 10px !important;
    }
    .page-content-wrapper .page-content .page-title small {
        display: block;
        clear: both;
    }
    .page-content-wrapper .page-content .page-title small {
        display: block;
        clear: both;
    }
    .sidemenu-container,
    .sidemenu-container.in {
        margin: 0 10px 10px !important;
    }
    .page-header-fixed.page-header-fixed-mobile .sidemenu-container,
    .page-header-fixed.page-header-fixed-mobile .sidemenu-container.in {
        margin-top: 10px !important;
    }
    .page-header-fixed.page-header-fixed-mobile .page-container {
        margin-top: 100px !important;
    }
    .page-header-fixed.page-header-fixed-mobile .page-container {
        margin-top: 100px !important;
    }
    .page-header.navbar .top-menu .navbar-nav>li.dropdown {
        padding: 5px 6px;
    }
}

@media (max-width: 420px) {
    .page-header.navbar .page-logo {
        width: 100%;
    }
    .chat-sidebar-container {
        top: 168px;
    }
}

@media only screen and (min-width: 991px) {
    #cd-timeline {
        margin-top: 3em;
        margin-bottom: 3em;
    }
    #cd-timeline::before {
        left: 50%;
        margin-left: -2px;
    }
    .cd-timeline-block {
        margin: 4em 0;
    }
    .cd-timeline-block:first-child {
        margin-top: 0;
    }
    .cd-timeline-block:last-child {
        margin-bottom: 0;
    }
    .cd-timeline-img {
        width: 60px;
        height: 60px;
        left: 50%;
        margin-left: -30px;
        -webkit-transform: translateZ(0);
        -webkit-backface-visibility: hidden;
    }
    .cssanimations .cd-timeline-img.is-hidden {
        visibility: hidden;
    }
    .cssanimations .cd-timeline-img.bounce-in {
        visibility: visible;
        -webkit-animation: cd-bounce-1 0.6s;
        -moz-animation: cd-bounce-1 0.6s;
        animation: cd-bounce-1 0.6s;
    }
    .cd-timeline-content {
        margin-left: 0;
        padding: 1.6em;
        width: 45%;
    }
    .cd-timeline-content::before {
        top: 24px;
        left: 100%;
        border-color: transparent;
        border-left-color: white;
    }
    .cd-timeline-content.cd-picture::before {
        border-left-color: #75ce66;
    }
    .cd-timeline-content.cd-location::before {
        border-left-color: #f0ca45;
    }
    .cd-timeline-content.cd-movie::before {
        border-left-color: #c03b44;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content.cd-movie::before {
        border-right-color: #c03b44;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content.cd-location::before {
        border-right-color: #f0ca45;
    }
    .cd-timeline-content .cd-read-more {
        float: left;
    }
    .cd-timeline-content .cd-date {
        position: absolute;
        width: 100%;
        left: 122%;
        top: 6px;
        font-size: 16px;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content {
        float: right;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
        top: 24px;
        left: auto;
        right: 100%;
        border-color: transparent;
        border-right-color: white;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
        float: right;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
        left: auto;
        right: 122%;
        text-align: right;
    }
    .cssanimations .cd-timeline-content.is-hidden {
        visibility: hidden;
    }
    .cssanimations .cd-timeline-content.bounce-in {
        visibility: visible;
        -webkit-animation: cd-bounce-2 0.6s;
        -moz-animation: cd-bounce-2 0.6s;
        animation: cd-bounce-2 0.6s;
    }
    .cssanimations .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
        -webkit-animation: cd-bounce-2-inverse 0.6s;
        -moz-animation: cd-bounce-2-inverse 0.6s;
        animation: cd-bounce-2-inverse 0.6s;
    }
}

@media (min-width: 1400px) {
    .page-boxed .container {
        width: 1370px;
        max-width: 100%;
    }
    .page-boxed .page-header-inner.container {
        display: block;
    }
}

@media print {
    body {
        background-color: #fff !important;
    }
    .hidden-print,
    .page-bar,
    .page-footer,
    .chat-sidebar-container,
    .sidebar-container,
    .chatpane {
        display: none;
    }
    .page-container {
        margin: 0 !important;
        padding: 0 !important;
    }
    .page-content {
        min-height: 300px !important;
        padding: 0 20px 20px !important;
        margin: 0 !important;
    }
}


/*
 *  Document   : style.css
 *  Author     : RedStar Theme
 *  Description: The common theme stylesheet
 */


/* Doctor list style*/

ul.docListWindow {
    list-style-type: none;
    padding: 0 10px;
}

ul.docListWindow li {
    display: inline-block;
    width: 100%;
    border-bottom: 1px dashed #dcdcdc;
    margin-bottom: 11px;
    padding-bottom: 11px;
}

ul.docListWindow li .prog-avatar {
    width: 40px;
    height: 40px;
    float: left;
    margin-right: 25px;
}

ul.docListWindow li .prog-avatar img {
    width: 100%;
    border-radius: 50%;
    -webkit-border-radius: 50%;
}

ul.docListWindow .progress {
    height: 10px;
    text-indent: 0;
    margin: 0;
}

ul.docListWindow .progress-bar {
    text-align: right;
    padding-right: 15px;
    line-height: 10px;
    font-size: 10px;
}


/* Notifications */

.noti-information .notification-list {
    padding: 0;
}

.notification-list a:first-child {
    border-top: none;
}

.noti-information .notification-list a {
    padding: 10px 18px 10px 18px;
}

.notification-list a {
    display: inline-block;
    width: 100%;
    padding: 20px 0;
    border-top: 1px solid #f3f3f3;
    color: #2b2b2c;
    text-decoration: none;
    font-size: 14px;
}

.notification-list.not-list a span.icon {
    margin-top: 5px;
}

.notification-list.mail-list a span.icon {
    padding: 3px 10px;
    margin-top: 0px;
    color: white;
}

.notification-list a span.icon {
    margin-right: 15px;
    font-size: 16px;
    float: left;
}

.notification-list.mail-list.not-list {
    background: #e7ecf1;
}

.notification-list p,
.notification-list p small {
    margin: 0;
    padding: 0;
}

.noti-information .mail-list .un-read,
.noti-information .mail-list .read {
    right: 20px;
}

.mail-list .single-mail {
    position: relative;
}

a.single-mail.text-center.view-all {
    background: white;
}

.not-list span.icon {
    width: 40px;
    height: 40px;
    line-height: 33px;
    font-size: 12px;
    text-align: center;
    border-radius: 6px;
}

.bg-primary {
    color: #fff;
    background-color: #9c78cd;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.mail-list .un-read,
.mail-list .read {
    position: absolute;
    right: 0px;
    top: 32px;
    font-size: 12px;
    color: #dfdfe2;
}

.vew-mail-header {
    color: #666f7b;
    font-weight: 700;
}

h4.vew-mail-header b {
    font-weight: 700;
}

.fa-comments-o:before {
    content: '\f0e6';
}

.bg-warning {
    color: #fff;
    background-color: #ffd200;
}

.bg-success {
    background: #5fc29d;
}

.bg-orange {
    background: #e67d21;
}

.bg-blue {
    background: #3598dc;
}

.bg-purple {
    background: #8e44ad;
}

.bg-danger {
    color: #fff;
    background-color: #e55957;
}

.bg-danger {
    background: #e55957;
}

.panel-body {
    padding: 15px;
}

.form-group {
    margin-bottom: 15px;
}

.work-monitor .states {
    width: 90%;
}

.compose-editor {
    padding-left: 15px;
}

.work-monitor .states .info {
    display: inline-block;
    width: 100%;
    font-size: 15px;
    margin: 10px 0 0 0;
    font-weight: 500;
}

.progress.progress-sm {
    height: 8px;
}

.work-monitor .title {
    margin: 0 0 40px 0;
}

.text-danger {
    color: #f44336;
}

.md {
    line-height: inherit;
    vertical-align: bottom;
}

.md-refresh:before {
    content: '\f2a4';
}

.card .btn-collapse {
    -webkit-transition: all 0.1s ease-out;
    -o-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.fa-angle-down:before {
    content: '\f107';
}

section.panel.tab-border {
    border: 1px solid #e5e5e5;
}

.select.control {
    background: #262936;
    border-radius: 40px;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    padding-right: 30px;
    position: relative;
}

.select.control select {
    padding: 10px 30px;
    width: 110%;
    border: none;
    background: none;
    outline: none;
    -webkit-appearance: none;
    color: white;
}


/* widget */

.sidebar .widget {
    background: #262936;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;
}

.sidebar .widget .widget-title {
    font-size: 24px;
    font-size: 1.7142857143em;
    margin-bottom: 20px;
    font-weight: 300;
}

.sidebar .widget .arrow-list li {
    border: none;
}

.sidebar .widget.top-rated {
    padding: 0;
}

.sidebar .widget.top-rated .widget-title {
    padding: 20px 20px 0;
}

.sidebar .widget.top-rated ul {
    list-style: none;
}

.sidebar .widget.top-rated ul li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 10px 20px;
}

.sidebar .widget.top-rated ul li:last-child {
    border-bottom: none;
}

.sidebar .widget.top-rated .entry-title {
    font-size: 14px;
    font-size: 1em;
    margin-bottom: 5px;
    font-weight: 300;
}

.sidebar .widget.top-rated .entry-title a {
    color: #bfc1c8;
}

.sidebar .widget.top-rated .rating strong {
    color: #009ad8;
}

.stat-item {
    display: inline-block;
    padding-right: 15px;
}

.stats-row {
    margin-bottom: 20px;
}

.widget-gradient,
.widget-wrap-img {
    min-height: 350px;
    border-radius: 2px;
}

.analysis-box {
    padding: 2px 25px 10px 20px;
    margin-bottom: 20px;
}

.megamenu-header {
    display: block;
    padding: 0px 20px 5px 0px;
    font-size: 22px;
    line-height: 1.428571429;
    color: #fafafa;
}

.mega-menu-item-name {
    color: #fafafa;
    padding: 10px 0px 10px 0px;
}

.demo-container {
    box-sizing: border-box;
    width: 100%;
    height: 450px;
    padding: 20px 15px 15px 15px;
    margin: 15px auto 30px auto;
    border: 1px solid #ddd;
    background: #fff;
    background: linear-gradient(#f6f6f6 0, #fff 50px);
    background: -o-linear-gradient(#f6f6f6 0, #fff 50px);
    background: -ms-linear-gradient(#f6f6f6 0, #fff 50px);
    background: -moz-linear-gradient(#f6f6f6 0, #fff 50px);
    background: -webkit-linear-gradient(#f6f6f6 0, #fff 50px);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
    -o-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.demo-placeholder {
    width: 100%;
    height: 100%;
    font-size: 14px;
    line-height: 1.2em;
}

.legend table {
    border-spacing: 5px;
}

.clsAvailable {
    color: #0cc745;
    font-size: 13px;
    font-weight: 600;
}

.clsNotAvailable {
    color: #ff0000;
    font-size: 13px;
    font-weight: 600;
}

.clsOnLeave {
    color: #32c5d2;
    font-size: 13px;
    font-weight: 600;
}

.table-padding {
    padding-bottom: 20px;
    padding-top: 20px;
}

.doctitle {
    padding-bottom: 5px;
}

.user-bg {
    margin: -25px;
    overflow: hidden;
    position: relative;
}

.user-bg .overlay-box {
    background: #9675ce;
    opacity: 0.9;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    text-align: center;
}

.user-bg .overlay-box .user-content {
    padding: 15px;
}

.user-btm-box {
    padding: 40px 0 10px;
    clear: both;
    overflow: hidden;
}

.input-group-btn .btn {
    box-shadow: none;
}

.addr-font-h1 span {
    font-size: 14px;
    vertical-align: text-top;
}

.text-stat h3 {
    margin-top: 5px;
    margin-bottom: 0;
    font-size: 18px;
}

.text-stat span {
    font-size: 13px !important;
}

.overflow-h {
    overflow: hidden;
}

.rt-code {
    padding: 3px;
    color: #e43a45;
    border-radius: 4px !important;
    display: inline;
    word-wrap: normal;
}

.caption-desc {
    font-size: 13px;
    margin-top: 0.5em;
    line-height: 2.3em;
}

.mail-label {
    padding: 0.2em 0.6em 0.3em !important;
}

.mail-counter-style {
    padding: 2px 6px 4px !important;
}

h4.media-heading {
    color: #3d3d3d;
    font-weight: 500;
    font-family: Roboto, sans-serif;
}

.language-switch a {
    color: #1e2629;
}

.notification-label {
    float: right;
    border-radius: 10px;
    padding: 0px 10px;
    color: #fff;
}

.task-body .list-group-item {
    border-width: 0 0 1px 0;
    margin: 0;
}

.progress-line {
    background: #e7ecf1;
}

span.notificationtime {
    display: block;
}

span.online-status {
    display: block;
    margin-bottom: 0;
    padding: 0 22px;
    color: #9d9f9e;
    font-size: 11px;
    margin-top: 0px;
}

.addr-font-h3 {
    font-size: 24px;
}

.addr-font-h4 {
    font-size: 18px;
}

.help-block {
    margin-top: 5px;
    margin-bottom: 5px;
}

.help-inline {
    font-size: 13px;
    color: #737373;
    display: inline-block;
    padding: 5px;
}

.input-mini,
.input-xxs {
    width: 45px !important;
}

.progress {
    border: 0;
    background-image: none;
    filter: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    height: 8px;
    border-radius: 0 !important;
    margin: 0;
}

.progress-bar-success {
    background-color: #36c6d3;
}

.progress-bar-warning {
    background-color: #f0ad4e;
}

.progress-bar-danger {
    background-color: #d9534f;
}

.state-overview .addr-font-h1 {
    font-size: 36px;
    float: left;
    width: auto;
}

.no-pad-left {
    padding-left: 0 !important;
}

.text-purple .fa-facebook {
    font-size: 20px;
}

.text-success .fa-twitter {
    font-size: 20px;
}

.text-danger .fa-instagram {
    font-size: 20px;
}

.refresh-block {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-transition: all 0.05s ease;
    transition: all 0.05s ease;
    top: 0px;
    left: 0px;
    position: absolute;
    z-index: 1000;
    border-radius: 2px;
}

.refresh-block .refresh-loader {
    display: inline-block;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    margin-left: -16px;
    margin-top: -16px;
}

.refresh-block .refresh-loader i {
    display: inline-block;
    line-height: 32px;
    color: #000;
    font-size: 16px;
}


/* Social Buttons */

.btn-facebook {
    color: #ffffff !important;
    background-color: #3b5998 !important;
}

.btn-twitter {
    color: #ffffff !important;
    background-color: #00aced !important;
}

.btn-linkedin {
    color: #ffffff !important;
    background-color: #007bb6 !important;
}

.btn-dribbble {
    color: #ffffff !important;
    background-color: #ea4c89 !important;
}

.btn-googleplus {
    color: #ffffff !important;
    background-color: #dd4b39 !important;
}

.btn-instagram {
    color: #ffffff !important;
    background-color: #517fa4 !important;
}

.btn-pinterest {
    color: #ffffff !important;
    background-color: #cb2027 !important;
}

.btn-dropbox {
    color: #ffffff !important;
    background-color: #007ee5 !important;
}

.btn-flickr {
    color: #ffffff !important;
    background-color: #ff0084 !important;
}

.btn-tumblr {
    color: #ffffff !important;
    background-color: #32506d !important;
}

.btn-skype {
    color: #ffffff !important;
    background-color: #00aff0 !important;
}

.btn-youtube {
    color: #ffffff !important;
    background-color: #bb0000 !important;
}

.btn-github {
    color: #ffffff !important;
    background-color: #171515 !important;
}

.app-search {
    position: relative;
}

.contact-detail {
    float: left;
    width: 100%;
}

.contact-detail .fa {
    float: left;
    width: 30px;
    font-size: 20px;
    margin-top: 5px;
}

.contact-detail span {
    float: left;
    width: calc(100% - 30px);
    margin-bottom: 20px;
}

.contact-detail .fa-envelope {
    font-size: 15px;
}

.contact-detail .fa-mobile {
    font-size: 25px;
}

.item img {
    max-width: 100%;
}

.dataTables_wrapper {
    margin-top: 15px;
}

tr.group,
tr.group:hover {
    background-color: #ddd !important;
}

.profile-userpic {
    float: left;
    width: 100%;
    text-align: center;
}


/*-----------------Theme color style------------------------------*/

.settingSidebar {
    background: #fff;
    position: fixed;
    height: 100%;
    width: 250px;
    top: 120px;
    right: -250px;
    z-index: 999;
    transition: 0.3s ease-in;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
}

.settingSidebar .settingPanelToggle {
    background: #6777ef;
    padding: 10px 15px;
    color: #fff;
    position: absolute;
    top: 30%;
    left: -40px;
    width: 40px;
    border-radius: 10px 0 0 10px;
}

.settingSidebar.showSettingPanel {
    right: 0;
}

.settingSidebar .settingSidebar-body {
    position: relative;
    height: 100% !important;
}

.settingSidebar .settingSidebar-tab {
    display: flex;
}

.settingSidebar .settingSidebar-tab .nav-item {
    width: 33.33%;
    text-align: center;
}

.settingSidebar .settingSidebar-tab .nav-item .nav-link {
    padding: 15px 12px;
    color: #6a7a8c;
    border-bottom: 3px solid transparent;
}

.settingSidebar .settingSidebar-tab .nav-item .nav-link.active {
    border-bottom: 3px solid #2962ff;
    color: #2962ff;
}

.settingSidebar .settingSidebar-tab .nav-item .nav-link:hover {
    border-bottom: 3px solid #2962ff;
    color: #2962ff;
}

.settingSidebar ul.choose-theme li {
    display: inline-block;
}

.settingSidebar ul.choose-theme li:hover {
    cursor: pointer;
}

.settingSidebar ul.choose-theme li div {
    border-radius: 15px;
    display: inline-block;
    vertical-align: middle;
    height: 25px;
    width: 25px;
    overflow: hidden;
    position: relative;
    margin: 1px;
}

.settingSidebar ul.choose-theme li div.purple {
    background: #6777ef;
    -webkit-box-shadow: 4px 3px 6px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 4px 3px 6px 0 rgba(0, 0, 0, 0.2);
}

.settingSidebar ul.choose-theme li div.orange {
    background: #ffa117;
    -webkit-box-shadow: 4px 3px 6px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 4px 3px 6px 0 rgba(0, 0, 0, 0.2);
}

.settingSidebar ul.choose-theme li div.cyan {
    background: #3dc7be;
    -webkit-box-shadow: 4px 3px 6px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 4px 3px 6px 0 rgba(0, 0, 0, 0.2);
}

.settingSidebar ul.choose-theme li div.green {
    background: #4caf4f;
    -webkit-box-shadow: 4px 3px 6px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 4px 3px 6px 0 rgba(0, 0, 0, 0.2);
}

.settingSidebar ul.choose-theme li div.red {
    background: #ea5455;
    -webkit-box-shadow: 4px 3px 6px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 4px 3px 6px 0 rgba(0, 0, 0, 0.2);
}

.settingSidebar ul.choose-theme li div.white {
    background: #ece8e8;
    -webkit-box-shadow: 4px 3px 6px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 4px 3px 6px 0 rgba(0, 0, 0, 0.2);
}

.settingSidebar ul.choose-theme li div.black {
    background: #343a40;
    -webkit-box-shadow: 4px 3px 6px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 4px 3px 6px 0 rgba(0, 0, 0, 0.2);
}

.settingSidebar ul.choose-theme li.active div::after {
    content: '\f00c';
    color: #fff;
    top: 4px;
    left: 7px;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 12px;
    position: absolute;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.settingSidebar .setting-panel-header {
    display: block;
    padding: 15px 20px;
    color: #212529;
    font-size: 15px;
    border: 1px solid #eae9e9;
    background: #e9ecef;
}

.settingSidebar .disk-server-setting .progress {
    height: 8px;
}

.settingSidebar .disk-server-setting p {
    font-weight: bold;
    margin: 0;
    border-bottom: 1px solid #eee;
    font-size: 14px;
    text-align: left;
    padding-bottom: 5px;
}

.settingSidebar .rt-sidebar-last-ele {
    margin-bottom: 70px !important;
}

.settingList {
    list-style-type: none;
    padding-left: 15px;
}

.sidebarSettingTitle {
    padding-top: 10px;
    margin-bottom: 9px;
    font-size: 15px;
    font-weight: 600;
}

@media only screen and (max-width: 1024px) {
    .settingSidebar {
        display: none;
    }
}

.control-sidebar-btn.btn {
    position: absolute;
    left: -53px;
    padding: 25px;
    z-index: 999;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    font-size: 18px;
    color: #fff;
    border-color: #2f323e;
    background-color: #2f323e;
    margin: 0;
}

.control-sidebar-btn i {
    position: absolute;
    top: 18px;
    left: 15px;
}

.display-none {
    display: none;
}


/* Theme color CSS */

.control-sidebar-btn.btn {
    position: absolute;
    left: -53px;
    padding: 25px;
    z-index: 999;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    font-size: 18px;
    color: #fff;
    border-color: #2f323e;
    background-color: #2f323e;
    margin: 0;
}

.control-sidebar-btn i {
    position: absolute;
    top: 18px;
    left: 15px;
}

.quick-setting-main {
    float: left;
    position: fixed;
    top: 25%;
    z-index: 99999;
    right: 0;
}

.quick-setting {
    float: left;
    margin-top: -15px;
    max-width: 380px;
    padding: 20px;
}

.quick-setting ul#themecolors li {
    list-style: none;
}

.quick-setting ul#themecolors {
    padding: 0;
    margin: 0;
    float: left;
    width: 100%;
    max-width: 330px;
}

.quick-setting ul#themecolors li a {
    width: 32px;
    height: 32px;
    display: inline-block;
    margin: 5px;
    color: transparent;
    position: relative;
    background-color: #fff;
}

.quick-setting ul#themecolors li a.green-theme {
    background-color: #0f0;
}

.quick-setting ul#themecolors li a.turquoise {
    background-color: #00c5cd;
}

.theme-color a {
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    margin: 5px 8px 5px 0;
    -webkit-box-shadow: 0.4px 0.9px 3px 0 rgba(0, 0, 0, 0.33);
    box-shadow: 0.4px 0.9px 3px 0 rgba(0, 0, 0, 0.33);
    overflow: hidden;
    width: 35px;
    height: 30px;
}

.theme-color a[data-theme='white'] .head:before,
.theme-color a[data-theme='white'] .head:after,
.theme-color a[data-theme='dark'] .head:before,
.theme-color a[data-theme='blue'] .head:before,
.theme-color a[data-theme='indigo'] .head:before,
.theme-color a[data-theme='red'] .head:before,
.theme-color a[data-theme='cyan'] .head:before,
.theme-color a[data-theme='logo-white'] .head:before,
.theme-color a[data-theme='logo-white'] .head:after,
.theme-color a[data-theme='logo-dark'] .head:before,
.theme-color a[data-theme='logo-blue'] .head:before,
.theme-color a[data-theme='logo-indigo'] .head:before,
.theme-color a[data-theme='logo-cyan'] .head:before,
.theme-color a[data-theme='logo-green'] .head:before,
.theme-color a[data-theme='logo-red'] .head:before,
.theme-color a[data-theme='header-white'] .head:before,
.theme-color a[data-theme='header-white'] .head:after {
    background: #fff;
}

.theme-color a[data-theme='white'] .cont:after {
    background: #fff;
}

.theme-color a[data-theme='dark'] .cont:after,
.theme-color a[data-theme='header-dark'] .head:before,
.theme-color a[data-theme='header-dark'] .head:after {
    background: #303548;
}

.theme-color a[data-theme='blue'] .cont:after,
.theme-color a[data-theme='blue'] .head:after,
.theme-color a[data-theme='logo-blue'] .head:after,
.theme-color a[data-theme='header-blue'] .head:before,
.theme-color a[data-theme='header-blue'] .head:after {
    background: #5093eb;
}

.theme-color a[data-theme='indigo'] .cont:after,
.theme-color a[data-theme='indigo'] .head:after,
.theme-color a[data-theme='logo-indigo'] .head:after,
.theme-color a[data-theme='header-indigo'] .head:before,
.theme-color a[data-theme='header-indigo'] .head:after {
    background: #6673fc;
}

.theme-color a[data-theme='cyan'] .cont:after,
.theme-color a[data-theme='cyan'] .head:after,
.theme-color a[data-theme='logo-cyan'] .head:after,
.theme-color a[data-theme='header-cyan'] .head:before,
.theme-color a[data-theme='header-cyan'] .head:after {
    background: #4abad2;
}

.theme-color a[data-theme='green'] .cont:after,
.theme-color a[data-theme='green'] .head:after,
.theme-color a[data-theme='logo-green'] .head:after,
.theme-color a[data-theme='header-green'] .head:before,
.theme-color a[data-theme='header-green'] .head:after {
    background: #49d075;
}

.theme-color a[data-theme='red'] .cont:after,
.theme-color a[data-theme='red'] .head:after,
.theme-color a[data-theme='logo-red'] .head:after,
.theme-color a[data-theme='header-red'] .head:before,
.theme-color a[data-theme='header-red'] .head:after {
    background: #e44f56;
}

.theme-color a[data-theme='dark'] .head:after,
.theme-color a[data-theme='logo-dark'] .head:after {
    background: #303548;
}

.layout-theme a[data-theme='dark'] .cont {
    background: #464545;
}

.layout-theme a[data-theme='dark'] .head:before {
    background: #242b3a;
}

.layout-theme a[data-theme='light'] .cont {
    background: #ffffff;
}

.layout-theme a[data-theme='light'] .head:before {
    background: #ffffff;
}

.layout-theme a[data-theme='light'] .head:after {
    background: #6673fc;
}

.theme-color a .head {
    height: 10px;
}

.theme-color a .cont,
.theme-color a .head {
    display: block;
    position: relative;
}

.theme-color a .cont:before,
.theme-color a .head:before {
    width: 60%;
    right: 0;
}

.theme-color a .cont:after,
.theme-color a .cont:before,
.theme-color a .head:after,
.theme-color a .head:before {
    content: '';
    height: 100%;
    display: inline-block;
    position: absolute;
}

.theme-color a .cont:after,
.theme-color a .head:after {
    width: 40%;
    left: 0;
}

.theme-color a .cont {
    height: 40px;
}

.theme-color a .cont:after,
.theme-color a .head:after {
    width: 40%;
    left: 0;
}

.selector-title {
    margin-top: 0px !important;
    color: #000000;
}

.mega-menu-dropdown .material-icons {
    float: left;
    line-height: 24px;
}

.mega-menu-dropdown .dropdown-toggle {
    line-height: 24px;
}


/**************************************************************
 3RD PARTY PLUGIN CUSTOMIZATION 
**********************************************************************************************/


/**********************************
		Bootstrap DatePicker
***********************************/

.datepicker .active,
.datepicker .active:hover,
.datepicker .selected,
.datepicker .today {
    background-image: none !important;
    filter: none !important;
}

.datepaginator a {
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: 300;
}

.datepicker table td,
.datepicker table th,
.datetimepicker table td,
.datetimepicker table th {
    font-family: Roboto, sans-serif;
}

#dp-calendar {
    right: 4px !important;
}

.datepaginator .fa-angle-right:before {
    content: '';
}

.datepaginator .fa-angle-left:before,
.datepicker .fa-angle-left:before {
    content: '';
}

.datepicker.dropdown-menu {
    padding: 5px;
    border: 1px solid #efefef !important;
    border-radius: 5px !important;
    right: auto;
}

.datepicker th.switch,
.datetimepicker th.switch,
.datetimepicker th.today {
    width: 145px;
    background: #007bff;
    color: #fff;
}

.datepicker thead tr:first-child th:hover {
    background: #4b8df8;
}

.datetimepicker thead tr:first-child th:hover,
.datetimepicker tfoot th:hover {
    background: #4b8df8;
}

.datepicker .selected {
    background-color: #909090 !important;
}

.datepicker .active {
    background-color: #4b8df8 !important;
}

.datepicker .active:hover {
    background-color: #2678fc !important;
}

.datepicker .input-daterange input {
    text-align: left;
}

.datepicker table td {
    color: #000;
    font-weight: 300 !important;
}

.datepicker table th {
    color: #333;
    font-weight: 400 !important;
}

.datepicker .fa-angle-right:before,
.daterangepicker .fa-angle-right:before {
    content: '';
}


/***********************************
Bootstrap Daterangepicker
************************************/

.daterangepicker {
    margin-top: 4px;
}

.daterangepicker .input-mini {
    width: 100% !important;
    outline: 0 !important;
}

.daterangepicker td {
    text-shadow: none;
}

.daterangepicker td.active {
    background-color: #4b8df8;
    background-image: none;
    filter: none;
}

.datetimepicker .active,
.datetimepicker .active:hover {
    filter: none !important;
    background-image: none !important;
}

.daterangepicker th {
    font-weight: 400;
    font-size: 14px;
}

.daterangepicker .ranges input[type='text'] {
    width: 70px !important;
    font-size: 11px;
    vertical-align: middle;
}

.daterangepicker .ranges label {
    font-weight: 300;
    display: block;
}

.daterangepicker .ranges {
    width: 170px;
}

.daterangepicker .ranges ul>li.active {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
}

.daterangepicker .ranges .btn {
    margin-top: 10px;
}

.daterangepicker .ranges li {
    color: #333;
}

.daterangepicker .ranges li.active,
.daterangepicker .ranges li:hover {
    background: #4b8df8 !important;
    border: 1px solid #4b8df7 !important;
    color: #fff;
}

.daterangepicker .range_inputs input {
    margin-bottom: 0 !important;
}

.daterangepicker .fa-angle-left:before,
.datetimepicker .fa-angle-left:before {
    content: '';
}


/***********************************
Bootstrap  Datetimepicker
************************************/

.datetimepicker table td {
    color: #000;
    font-weight: 300 !important;
    padding: 7px;
    text-align: center;
}

.datetimepicker table th {
    font-weight: 400 !important;
}

.datetimepicker .active {
    background-color: #4b8df8 !important;
}

.datetimepicker table td:hover {
    cursor: pointer;
}

.input-append.date .add-on i,
.input-prepend.date .add-on i {
    display: inline-block;
}

.datetimepicker .active:hover {
    background-color: #2678fc !important;
}

.datetimepicker .fa-angle-right:before {
    content: '';
}


/* change z-index when opened in modal */

.modal-open .colorpicker,
.modal-open .datepicker,
.modal-open .daterangepicker {
    z-index: 10055 !important;
}


/***********************************
Bootstrap Colorpicker
************************************/

.colorpicker.dropdown-menu,
.daterangepicker.dropdown-menu,
.datetimepicker.dropdown-menu {
    padding: 5px;
}

.input-group.color .input-group-btn i {
    position: absolute;
    display: block;
    cursor: pointer;
    width: 20px;
    height: 20px;
    right: 6px;
}

.modal-open .datetimepicker {
    z-index: 10055;
}

.editable-input table,
.editable-input table td,
.editable-input table th,
.editable-input table tr {
    border: 0 !important;
}

.editable-input .combodate select {
    margin-bottom: 5px;
}

.fileinput {
    margin-bottom: 0;
}

.fileinput .close {
    float: none;
}

.fileinput .input-group {
    white-space: nowrap;
    overflow: hidden;
}


/***********************************
Bootstrap Modal
***********************************/

.modal {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}

.modal-open.page-overflow .modal-scrollable,
.modal-open.page-overflow .page-container,
.modal-open.page-overflow .page-container .navbar-fixed-bottom,
.modal-open.page-overflow .page-container .navbar-fixed-top {
    overflow-y: auto !important;
}

.modal-scrollable {
    overflow: hidden !important;
    z-index: 10051;
    overflow-y: scroll !important;
}

.modal-backdrop {
    position: fixed;
    bottom: 0;
}

.modal {
    border-radius: 2px;
    border: 0 !important;
}

.modal .modal-body {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    border: 0;
}

.modal .modal-title {
    font-weight: 400;
}

.modal .modal-footer {
    border: 0 !important;
}

.modal-backdrop,
.modal-backdrop.fade.in {
    opacity: 0.2;
    filter: alpha(opacity=20);
}


/***********************************
Bootstrap Select
***********************************/

.bootstrap-select .btn {
    border-color: #c2cad8;
}

.has-error .bootstrap-select .btn {
    border-color: #fbe1e3;
}

.has-success .bootstrap-select .btn {
    border-color: #abe7ed;
}

.has-warning .bootstrap-select .btn {
    border-color: #f9e491;
}

.bootstrap-select.open .btn,
.bootstrap-select.open.dropup .btn {
    border-color: #93a1bb;
}

.bootstrap-select .btn:focus {
    outline: 0 !important;
    outline-offset: 0;
}

.bootstrap-select.btn-group .dropdown-menu {
    margin-top: 1px;
}

.bootstrap-select.btn-group .dropdown-menu>li>dt>.text {
    font-weight: 600;
    font-family: Roboto, sans-serif;
    font-size: 14px;
}

.bootstrap-select.btn-group .dropdown-menu .text-muted {
    color: #999 !important;
}

.bootstrap-select .caret {
    border: 0;
    width: auto;
    height: auto;
    margin-top: -10px !important;
}

.bootstrap-select .caret:before {
    content: '\f107';
    display: inline-block;
    border: 0;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
}

.bootstrap-select .selected i {
    color: #aaa;
}

.bootstrap-select .dropdown-menu {
    z-index: 9999 !important;
}


/********************************************
Bootstrap Switch 
********************************************/

.bootstrap-switch {
    border-color: #c2cad8;
}

.bootstrap-switch.bootstrap-switch-focused {
    box-shadow: none;
    border-color: #c2cad8;
}


/********************************************
Color variants
*********************************************/

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary {
    color: #fff;
    background: #337ab7;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-info,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-info {
    color: #fff;
    background: #659be0;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-success,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success {
    color: #fff;
    background: #36c6d3;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-warning,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning {
    color: #fff;
    background: #f1c40f;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-danger,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger {
    color: #fff;
    background: #ed6b75;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default {
    color: #444;
    background: #e8eaef;
}

.bootstrap-timepicker-widget table td a {
    padding: 4px 0;
}

.bootstrap-timepicker-widget input,
.bootstrap-timepicker-widget input:focus {
    outline: 0 !important;
    border: 0;
}

.modal-open .bootstrap-timepicker-widget {
    z-index: 10055 !important;
}

.bootstrap-timepicker-widget.timepicker-orient-bottom:after,
.bootstrap-timepicker-widget.timepicker-orient-bottom:before {
    top: auto;
}

.toast {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background-color: #030303;
}

.tabbable-tabdrop .nav-pills,
.tabbable-tabdrop .nav-tabs {
    position: relative;
}

.tabbable-tabdrop .nav-pills .caret,
.tabbable-tabdrop .nav-tabs .caret {
    display: none;
}

.tabbable-tabdrop .nav-pills .tabdrop .dropdown-toggle i,
.tabbable-tabdrop .nav-tabs .tabdrop .dropdown-toggle i {
    font-size: 14px;
}

.CSSAnimationChart a,
.mapChart a {
    color: #bbb !important;
    font-size: 12px !important;
}

.tabbable-tabdrop .dropdown-menu:after {
    right: 10px;
    left: auto;
}

.tabbable-tabdrop .dropdown-menu:before {
    right: 9px;
    left: auto;
}

.wysihtml5-sandbox {
    width: 100% !important;
}

.wysihtml5-toolbar li {
    margin: 0;
    height: 29px;
}

.wysihtml5-toolbar li .dropdown-menu {
    margin-top: 5px;
}

.has-error .wysihtml5-sandbox {
    border: 1px solid #fbe1e3 !important;
}

.has-success .wysihtml5-sandbox {
    border: 1px solid #abe7ed !important;
}

.has-warning .wysihtml5-sandbox {
    border: 1px solid #f9e491 !important;
}

.note-editor {
    border: 1px solid #c2cad8;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
}

.note-editor .note-toolbar {
    border-bottom: 1px solid #c2cad8;
    -webkit-border-radius: 2px 2px 0 0;
    -moz-border-radius: 2px 2px 0 0;
    -ms-border-radius: 2px 2px 0 0;
    -o-border-radius: 2px 2px 0 0;
    border-radius: 2px 2px 0 0;
}

.note-editor .note-statusbar {
    -webkit-border-radius: 0 0 2px 2px;
    -moz-border-radius: 0 0 2px 2px;
    -ms-border-radius: 0 0 2px 2px;
    -o-border-radius: 0 0 2px 2px;
    border-radius: 0 0 2px 2px;
}

.note-editor .note-statusbar .note-resizebar {
    border-top: 1px solid #c2cad8;
}

.note-editor.note-frame.fullscreen {
    z-index: 10050;
    width: 100% !important;
}

.note-editor .dropdown-menu:before {
    left: 9px;
    right: auto;
}

.note-editor .dropdown-menu:after {
    left: 10px;
    right: auto;
}

.note-link-dialog .checkbox {
    margin-left: 20px;
}

.has-error .note-editor {
    border: 1px solid #fbe1e3 !important;
}

.has-success .note-editor {
    border: 1px solid #abe7ed !important;
}

.has-warning .note-editor {
    border: 1px solid #f9e491 !important;
}

.gtreetable .dropdown-menu {
    margin-top: 0;
}

.gtreetable .dropdown-menu:after,
.gtreetable .dropdown-menu:before {
    display: none !important;
}

.gtreetable .node-action .form-control {
    position: relative;
    top: 2px;
    display: inline-block;
}

.gtreetable .node-icon-ce,
.gtreetable .node-icon-handle,
.gtreetable .node-icon-selected,
.gtreetable .node-icon-type {
    opacity: 0.6;
    filter: alpha(opacity=60);
}

.bootstrap-tagsinput .tag [data-role='remove'] {
    box-shadow: none;
    font-family: Roboto, sans-serif;
}

.bootstrap-tagsinput .tag [data-role='remove']:after {
    font-family: Roboto, sans-serif;
}

.bootstrap-tagsinput .tag [data-role='remove']:hover {
    text-decoration: none;
    box-shadow: none;
}

.rt-bootstrap-tables .rt-btm-transform .bars {
    height: auto;
}

.CSSAnimationChart,
.mapChart {
    width: 100%;
    height: 500px;
}

.fixed-table-toolbar .columns label {
    margin: 10px;
    padding: 0 0 0 30px;
}

.fixed-table-header th.bs-checkbox {
    outline: 0;
}

.fixed-table-header th.bs-checkbox .th-inner {
    overflow: visible;
}

.fixed-table-header th.bs-checkbox .th-inner .rt-chkbox {
    right: -5px;
}

.bootbox .bootbox-close-button {
    margin-top: 0 !important;
}

.amcharts-graph-g2 .amcharts-graph-stroke {
    -webkit-animation: am-moving-dashes 1s linear infinite;
    animation: am-moving-dashes 1s linear infinite;
}

.amcharts-graph-column-front {
    -webkit-transition: all 0.3s 0.3s ease-out;
    transition: all 0.3s 0.3s ease-out;
}

.amcharts-graph-column-front:hover {
    fill: #496375;
    stroke: #496375;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.amcharts-graph-g3 {
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-dasharray: 500%;
    stroke-dasharray: 0\9;
    stroke-dashoffset: 0\9;
    -webkit-animation: am-draw 40s;
    animation: am-draw 40s;
}


/*--------------------------------------------------
    [Animated Pie Chart]
----------------------------------------------------*/

.animated-pie-chart,
.columnLine {
    width: 100%;
    height: 350px;
    font-size: 11px;
}

.amcharts-graph-graph2 .amcharts-graph-stroke {
    stroke-dasharray: 4px 5px;
    stroke-linejoin: round;
    stroke-linecap: round;
    -webkit-animation: am-moving-dashes 1s linear infinite;
    animation: am-moving-dashes 1s linear infinite;
}

.cke {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
}

.cke .cke-top {
    -webkit-border-radius: 2px 2px 0 0;
    -moz-border-radius: 2px 2px 0 0;
    -ms-border-radius: 2px 2px 0 0;
    -o-border-radius: 2px 2px 0 0;
    border-radius: 2px 2px 0 0;
}

.cke .cke-bottom {
    -webkit-border-radius: 0 0 2px 2px;
    -moz-border-radius: 0 0 2px 2px;
    -ms-border-radius: 0 0 2px 2px;
    -o-border-radius: 0 0 2px 2px;
    border-radius: 0 0 2px 2px;
}

.cke_bottom,
.cke_dialog,
.cke_dialog_footer,
.cke_dialog_title,
.cke_inner,
.cke_reset,
.cke_top {
    background-image: none !important;
    filter: none;
    border-top: 0;
    border-bottom: 0;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    text-shadow: none;
}

.cke_dialog_tab,
.cke_dialog_ui_button,
.cke_dialog_ui_input_text {
    filter: none;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    background-image: none !important;
}

.cke_dialog_tab,
.cke_dialog_ui_button {
    box-shadow: none !important;
    text-shadow: none !important;
}

.cke_dialog_tab:hover,
.cke_dialog_ui_button:hover {
    text-decoration: none;
    text-shadow: none;
}

.cke_dialog_ui_input_text {
    box-shadow: none !important;
}

.cke_button,
.cke_combo_button,
.cke_toolbar,
.cke_toolgroup {
    background-image: none !important;
    filter: none !important;
    border: 0;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.cke_button,
.cke_combo_button,
.cke_hc.cke_panel_listItem a,
.cke_panel_grouptitle {
    background-image: none !important;
    filter: none;
    text-shadow: none;
}

.cke_button:hover,
.cke_combo_button:hover {
    background-color: #ddd;
}

.cke_toolbar_break {
    background-image: none !important;
    filter: none !important;
    border: 0;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -ms-box-shadow: none !important;
    -o-box-shadow: none !important;
}

.has-error .cke {
    border: 1px solid #fbe1e3 !important;
}

.has-success .cke {
    border: 1px solid #abe7ed !important;
}

.has-warning .cke {
    border: 1px solid #f9e491 !important;
}

.modal-open .clockface {
    z-index: 10055 !important;
}

.clockface .cell .inner.active,
.clockface .cell .outer.active {
    background-color: #4b8df8 !important;
    background-image: none;
    filter: none;
}

.table-checkable tr>td:first-child,
.table-checkable tr>th:first-child {
    text-align: center;
    max-width: 50px;
    min-width: 40px;
    padding-left: 0;
    padding-right: 0;
}

table.dataTable thead td,
table.dataTable thead th {
    border-bottom: 2px solid #e7ecf1;
    outline: 0 !important;
}

table.dataTable.no-footer {
    border-bottom-color: #e7ecf1;
}

.dataTables_extended_wrapper.DTS .dataTables_scrollBody,
.dataTables_extended_wrapper.DTS .dataTables_scrollHead {
    border-bottom: 0 !important;
}

table.dataTable tr.heading>th {
    background-color: #fbfcfd;
}

table.dataTable td.sorting_1,
table.dataTable td.sorting_2,
table.dataTable td.sorting_3,
table.dataTable th.sorting_1,
table.dataTable th.sorting_2,
table.dataTable th.sorting_3 {
    background: #fbfcfd !important;
}

.paging_bootstrap_extended {
    margin: 5px 0 0 !important;
    padding: 0 !important;
    float: none !important;
    font-size: 13px;
}

.dataTables_extended_wrapper {
    margin-top: 10px;
}

.dataTables_extended_wrapper .seperator {
    padding: 0 2px;
}

.dataTables_extended_wrapper .table.dataTable {
    margin: 20px 0 !important;
}

.dataTables_extended_wrapper div.dataTables_info,
.dataTables_extended_wrapper div.dataTables_length,
.dataTables_extended_wrapper div.dataTables_paginate {
    display: inline-block;
    float: none !important;
    padding: 0 !important;
    margin: 0 !important;
    position: static !important;
}

.dataTables_extended_wrapper .table-group-actions>span {
    font-size: 13px;
}

.dataTables_extended_wrapper.DTS .paging_bootstrap_extended {
    display: inline-block;
}

.dataTables_extended_wrapper.DTS .dataTables_info {
    display: inline-block;
    padding-top: 8px !important;
}

.dataTables_extended_wrapper.DTS .dataTables_info .seperator {
    display: none;
}

.dataTables_extended_wrapper.DTS .dataTables_scrollHead .dataTables_scrollHeadInner .table.dataTable {
    margin-bottom: 0 !important;
}

.dataTables_extended_wrapper.DTS .dataTables_scrollBody .table.dataTable {
    margin: 0 !important;
}

.dataTables_extended_wrapper div.dataTables_length label {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 13px;
    float: none !important;
    display: inline-block !important;
}

.table-container .table-actions-wrapper {
    display: none;
}

.dataTables_scroll {
    margin-bottom: 10px;
}

.dataTables_scrollHead {
    border-bottom: 2px solid #e7ecf1 !important;
}

.dataTables_scrollHead thead th {
    border-bottom: 0 !important;
}

.dataTables_scrollBody {
    border-bottom: 1px solid #e7ecf1 !important;
}

.table-both-scroll .dataTables_scrollBody {
    border-bottom: 0 !important;
}

.dataTables_wrapper .dataTables_processing {
    width: 200px;
    display: inline-block;
    padding: 7px;
    left: 50%;
    margin-left: -100px;
    margin-top: 10px;
    text-align: center;
    color: #3f444a;
    border: 1px solid #e7ecf1;
    background: #eef1f5;
    vertical-align: middle;
    -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}

.dt-button-background {
    display: none !important;
}

div.dt-button-collection {
    border: 1px solid #eee;
    background: #fff;
    box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
    padding: 0;
}

div.dt-button-collection>a.dt-button {
    background: 0 0;
    padding: 8px 16px;
    font-weight: 300;
    margin: 1px;
    box-shadow: none !important;
    border: 0 !important;
}

div.dt-button-collection>a.dt-button>span {
    font-size: 14px;
    color: #333;
}

div.dt-button-collection>a.dt-button.active {
    background: #f6f6f6 !important;
    box-shadow: none !important;
    border: 0 !important;
}

div.dt-button-collection>a.dt-button.active>span {
    color: #333;
}

a.dt-button,
button.dt-button,
div.dt-button {
    background-image: none !important;
    padding: 6px 12px !important;
}

a.dt-button:last-child,
button.dt-button:last-child,
div.dt-button:last-child {
    margin-right: 0;
}

.dataTables_wrapper .dt-buttons {
    float: left;
}

.buttons-copy {
    background-color: #666 !important;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px #666;
    color: #fff !important;
    border-radius: 30px !important;
    border: 0px !important;
    height: 30px;
    width: 60px;
}

.buttons-excel {
    background-color: #59bf70 !important;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px #59bf70;
    color: #fff !important;
    border-radius: 30px !important;
    border: 0px !important;
    height: 30px;
    width: 60px;
}

.buttons-csv {
    background-color: #2ab9d0 !important;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px #2ab9d0;
    color: #fff !important;
    border-radius: 30px !important;
    border: 0px !important;
    height: 30px;
    width: 60px;
}

.buttons-pdf {
    background-color: #e91e63 !important;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px #e91e63;
    color: #fff !important;
    border-radius: 30px !important;
    border: 0px !important;
    height: 30px;
    width: 60px;
}

.buttons-print {
    background-color: #6563ef !important;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px #6563ef;
    color: #fff !important;
    border-radius: 30px !important;
    border: 0px !important;
    height: 30px;
    width: 60px;
}

.dataTable td .popover-content .btn-group {
    position: static !important;
}

.table .btn-group .btn {
    margin-left: 0;
    margin-right: -1px;
}

.dropzone {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.dropzone-file-area {
    border: 2px dashed #028af4;
    background: #fff;
    padding: 20px;
    margin: 0 auto;
    text-align: center;
}

.dz-hidden-input {
    left: 0;
}

.fancybox-overlay {
    z-index: 100000;
}

.fancybox-opened {
    z-index: 100001;
}

.spinner-buttons.btn-group-vertical .btn {
    text-align: center;
    margin: 0;
    height: 17px;
    width: 22px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 0;
}

.page-style-rounded .spinner-buttons.btn-group-vertical .btn.spinner-up {
    border-radius: 0;
    border-top-right-radius: 2px !important;
}

.page-style-rounded .spinner-buttons.btn-group-vertical .btn.spinner-down {
    border-radius: 0 0 2px;
}


/***********************************************
Fullcalendar
***********************************************/

.external-event {
    display: inline-block;
    cursor: move;
    margin-bottom: 5px;
    margin-left: 5px;
}

.fc-scroller {
    overflow-y: auto;
    overflow-x: hidden;
}

.fc-month-view .fc-scroller {
    height: auto !important;
}


/**********************************************
Google Maps
**********************************************/

.gmaps {
    height: 300px;
    width: 100%;
}

.gmaps img {
    max-width: none;
}

#gmap_static div {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: block;
    height: 300px;
}

#gmap_routes_instructions {
    margin-top: 10px;
    margin-bottom: 0;
}


/***
Dashboard Charts(new in v1.2.1)
***/

.blueimp-gallery .next,
.blueimp-gallery .prev {
    border-radius: 23px !important;
}

.jquery-notific8-message {
    font-size: 13px;
}

[class*='jquery-notific8'],
[class*='jquery-notific8']:after,
[class*='jquery-notific8']:before {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.left .jquery-notific8-close-sticky span,
.right .jquery-notific8-close-sticky span {
    font-size: 10px;
}

.jquery-notific8-heading {
    font-weight: 300;
    font-size: 16px;
}

.scroller {
    padding: 0 12px 0 0;
    margin: 0;
    overflow: hidden;
}


/***
jQuery Sparkline
***/

.jqstooltip {
    width: auto !important;
    height: auto !important;
}

.slider {
    border: 0;
    padding: 0;
    display: block;
    margin: 12px 5px;
    min-height: 11px;
}

.ui-slider-vertical {
    width: 11px;
}

.ui-slider-horizontal .ui-slider-handle {
    top: -3px;
}

.ui-slider-vertical .ui-slider-handle {
    left: -3px;
}

.ui-slider-handle,
.ui-slider-vertical {
    filter: none !important;
    background-image: none !important;
}


/*************************************
				MAPS
*****************************************/

.jqvmap-zoomin,
.jqvmap-zoomout {
    height: 16px;
    width: 16px;
    background-color: #666;
}

.vmaps {
    position: relative;
    overflow: hidden;
    height: 300px;
}


/*************************************
				MORRIS CHART
*****************************************/

.morris-hover.morris-default-style .morris-hover-row-label {
    text-align: left;
    font-weight: 400;
    font-size: 15px;
    color: #7d8c9d;
    font-family: Roboto, sans-serif;
}

.morris-hover.morris-default-style .morris-hover-point {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
}

.morris-hover.morris-default-style .morris-hover-point,
.select2-container--bootstrap .select2-results__group,
.select2-container--bootstrap .select2-selection {
    font-family: Roboto, sans-serif;
}

.form-recaptcha-img {
    margin-bottom: 10px;
    clear: both;
    border: 1px solid #e5e5e5;
    padding: 5px;
}

iframe[src='about:blank'] {
    display: none;
}


/***********************************
			Select2
***********************************/

.select2-container--bootstrap .select2-search--dropdown .select2-search__field,
.select2-container--bootstrap .select2-selection,
.select2-container--bootstrap.select2-container--focus .select2-selection,
.select2-container--bootstrap.select2-container--open .select2-selection {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice__remove {
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    margin-right: 3px;
}

.select2-container--bootstrap .select2-results__group {
    display: block;
    font-size: 12px;
    white-space: nowrap;
    font-weight: 600;
}

.modal-open .select2-dropdown {
    z-index: 10060;
}

.modal-open .select2-close-mask {
    z-index: 10055;
}

.modal-open .select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field {
    width: auto !important;
}

.select2-result-repository {
    padding-top: 4px;
    padding-bottom: 3px;
}

.select2-result-repository__avatar {
    float: left;
    width: 60px;
    margin-right: 10px;
}

.select2-result-repository__avatar img {
    width: 100%;
    height: auto;
    border-radius: 2px;
}

.select2-result-repository__meta {
    margin-left: 70px;
}

.select2-result-repository__title {
    color: #000;
    font-weight: 700;
    word-wrap: break-word;
    line-height: 1.1;
    margin-bottom: 4px;
}

.select2-result-repository__forks,
.select2-result-repository__stargazers {
    margin-right: 1em;
}

.select2-result-repository__forks,
.select2-result-repository__stargazers,
.select2-result-repository__watchers {
    display: inline-block;
    color: #aaa;
    font-size: 11px;
}

.select2-result-repository__description {
    font-size: 13px;
    color: #777;
    margin-top: 4px;
}

.select2-results__option--highlighted .select2-result-repository__title {
    color: #fff;
}

.select2-results__option--highlighted .select2-result-repository__description,
.select2-results__option--highlighted .select2-result-repository__forks,
.select2-results__option--highlighted .select2-result-repository__stargazers,
.select2-results__option--highlighted .select2-result-repository__watchers {
    color: #c1d7e9;
}

.select2-selection__choice {
    background-color: #eee !important;
    border: 1px solid #eee !important;
}

.select2-selection__choice .select2-selection__choice__remove {
    font-weight: 400 !important;
}


/***********************************
	iCheck
***********************************/

.icheck-list>label {
    display: block;
    margin-bottom: 8px;
}

.icheck-list>label:last-child {
    margin-bottom: 0;
}

.form-horizontal .icheck-inline {
    margin-top: 8px;
}

.icheck-inline>label {
    margin-left: 15px;
}

.icheck-inline>label:first-child {
    margin-left: 0;
}

div[class*='icheckbox_'],
div[class*='iradio_'] {
    margin-right: 5px;
    top: -1px !important;
}

.ui-select-match-close {
    margin-top: 5px !important;
    margin-left: 5px !important;
}

.minicolors-swatch {
    border: 0;
}

pre[class*='language-'] {
    padding: 10px;
    background: 0 0 !important;
    border: 1px solid #f1f4f7 !important;
    box-shadow: none !important;
    margin: 0;
}

pre[class*='language-'] code {
    background: 0 0 !important;
    box-shadow: none !important;
    border: 0 !important;
}


/***********************************
			Color Picker
***********************************/

.colorpicker.dropdown-menu,
.daterangepicker.dropdown-menu,
.datetimepicker.dropdown-menu {
    padding: 5px;
    right: auto !important;
}


/*
 *  Document   : theme_style.css
 *  Author     : RedStar Theme
 *  Description: The main theme stylesheet of the template
 *
 *  Structure (with shortcodes):
 
 		1. GLOBLE STYLE
		2. BODY
		3. HEADER	
		4. SIDE MENU
		5. SEARCH BAR 
		6. NAVIGATION 
		7. PANEL 
		8. PAGE CONTENT 
		9. FORM 
		10. USER PANEL STYLE 
		11. MODAL WINDOW 
		12. CHAT PANEL 
		13. CARD BOX STYLE 
		14. PROFILE 
		15. WIZARD 
		16. TABLE 
		17. BUTTON 
		18. LABEL 
		19. CALENDAR 
		20. DROP DOWN STYLE 
		21. CHECKBOX & RADIO 
		22. ICON BUTTON 
		23. VALIDATION ICONS 
		24. NOTE 
		25. PAGINATION 
		26. ACCORDION 
		27. TAB 
		28. STATE OVERVIEW 
		29. BORDER BOX 
		30. RATING BUTTON 
		31. TO DO LIST 
		32. BADGE 
		33. SCROLL TO TOP 
		34. FOOTER 	
		35. MODALS
 */


/***********************************************************************

						[1. GLOBLE STYLE ]
						
***********************************************************************/

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Roboto, sans-serif;
    font-weight: 300;
}

h1 {
    font-size: 36px !important;
}

h2 {
    font-size: 30px !important;
}

h3 {
    font-size: 23px !important;
}

h4 {
    font-size: 18px !important;
}

h5 {
    font-size: 14px !important;
}

h6 {
    font-size: 13px !important;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
    color: #444;
}

h1.block,
h2.block,
h3.block,
h4.block,
h5.block,
h6.block {
    padding-top: 10px;
    padding-bottom: 10px;
}

.h4,
.h5,
.h6,
h4,
h5,
h6 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.alert,
.badge,
.btn-group>.btn,
.btn.btn-link:hover,
.btn,
.icon-btn,
.label,
.note,
.panel,
.overview-panel {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.18);
}

.badge {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #777;
    border-radius: 10px;
}

.label {
    display: inline;
    padding: 0.2em 0.6em 0.3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
}

.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.badge,
.input-inline {
    vertical-align: middle;
}

a {
    text-shadow: none;
    color: #337ab7;
}

a:hover {
    cursor: pointer;
    color: #23527c;
}

a:active,
a:focus,
a:hover {
    outline: 0;
    text-decoration: none;
}

code {
    border: 1px solid #e1e1e1;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.bold {
    font-weight: 700 !important;
}

.sbold {
    font-weight: 600 !important;
}

.thin {
    font-weight: 500 !important;
}

.uppercase {
    text-transform: uppercase !important;
}

.border {
    border: 1px solid red;
}

.inline {
    display: inline;
}

.inline-block {
    display: inline-block;
}

.circle {
    border-radius: 25px !important;
}

.circle-right {
    border-radius: 0 25px 25px 0 !important;
}

.circle-left {
    border-radius: 25px 0 0 25px !important;
}

.circle-bottom {
    border-radius: 0 0 25px 25px !important;
}

.circle-top {
    border-radius: 25px 25px 0 0 !important;
}

.rounded {
    border-radius: 50% !important;
}

.display-hide,
.display-none {
    display: none;
}

.hidden {
    display: none !important;
}

.no-margin {
    margin: 0;
}

.mg-bottom-10 {
    margin-bottom: 10px !important;
}

.margin-top-20 {
    margin-top: 20px !important;
}

.margin-right-10 {
    margin-right: 10px !important;
}

.visible-ie8 {
    display: none;
}

.ie8 .visible-ie8 {
    display: inherit !important;
}

.visible-ie9 {
    display: none;
}

.ie9 .visible-ie9 {
    display: inherit !important;
}

.hidden-ie8 {
    display: inherit;
}

.ie8 .hidden-ie8 {
    display: none !important;
}

.hidden-ie9 {
    display: inherit;
}

.ie9 .hidden-ie9 {
    display: none !important;
}

.m-b-10 {
    margin-bottom: 10px !important;
}

.p-t-20 {
    padding-top: 20px !important;
}

.p-r-20 {
    padding-right: 20px !important;
}

.p-b-20 {
    padding-bottom: 20px !important;
}

.p-l-20 {
    padding-left: 20px !important;
}

.p-r-100 {
    padding-right: 100px !important;
}

.m-r-20 {
    margin-right: 20px !important;
}

.m-b-20 {
    margin-bottom: 20px !important;
}

.m-t-20 {
    margin-top: 20px !important;
}

.m-l-20 {
    margin-left: 20px !important;
}

.m-r-100 {
    margin-right: 100px !important;
}

.margin-0 {
    margin: 0 !important;
}

.input-small {
    width: 145px !important;
}

.input-inline {
    width: auto;
}

.font-bold {
    font-weight: bold;
}

.label-rouded {
    border-radius: 50px;
}

.text-center {
    text-align: center;
}


/******* Colors Codes********/

.text-purple {
    color: #9675ce;
}

.text-white {
    color: #ffffff;
}

.text-success {
    color: #36c6d3;
}

.text-info {
    color: #659be0;
}

.text-warning {
    color: #f1c40f;
}

.text-danger {
    color: #ed6b75;
}

.headerBadgeColor1 {
    background-color: #c46210;
}

.headerBadgeColor2 {
    background-color: #673ab7;
}

.orange-bgcolor {
    background-color: #c46210 !important;
}

.purple-bgcolor,
.label.purple-bgcolor {
    background-color: #673ab7;
}

.blue-bgcolor,
.btn.blue-bgcolor {
    background-color: #2196f3;
    color: #fff;
}

.cyan-bgcolor {
    background-color: #36c6d3;
    color: #fff;
}

.deepPink-bgcolor,
.label.deepPink-bgcolor {
    background-color: #e91e63 !important;
    color: #fff !important;
}

.bg-warning {
    color: #fff;
    background-color: #ffd200;
}

.bg-success {
    background: #5fc29d;
    color: #fff;
}

.bg-orange {
    background: #e67d21;
    color: #fff;
}

.bg-blue {
    background: #3598dc;
    color: #fff;
}

.bg-purple {
    background: #8e44ad;
    color: #fff;
}

.red {
    color: #fff;
    background-color: #f44336;
}

.pink {
    background-color: #ff1493;
    color: #fff;
}

.yellow {
    background-color: #f1c500;
    color: #fff;
}

.state-overview {
    color: #fff;
}

.overview-panel {
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.badge,
.btn {
    font-weight: 600;
    text-transform: uppercase;
}

.list-separated {
    margin-top: 10px;
    margin-bottom: 15px;
}

.list-separated>div:last-child {
    border-right: 0;
}


/****** Fontawasome style  *********/

[class*=' fa-']:not(.fa-stack),
[class*=' glyphicon-'],
[class*=' icon-'],
[class^='fa-']:not(.fa-stack),
[class^='glyphicon-'],
[class^='icon-'] {
    display: inline-block;
    line-height: 14px;
    -webkit-font-smoothing: antialiased;
    text-decoration: none;
}

li [class*=' fa-'],
li [class*=' glyphicon-'],
li [class*=' icon-'],
li [class^='fa-'],
li [class^='glyphicon-'],
li [class^='icon-'] {
    display: inline-block;
    width: 1.25em;
    text-align: center;
}

li [class*=' glyphicon-'],
li [class^='glyphicon-'] {
    top: 2px;
}

li [class*=' icon-'],
li [class^='icon-'] {
    top: 1px;
    position: relative;
}

li [class*=' fa-'].icon-large,
li [class*=' glyphicon-'].icon-large,
li [class*=' icon-'].icon-large,
li [class^='fa-'].icon-large,
li [class^='glyphicon-'].icon-large,
li [class^='icon-'].icon-large {
    width: 1.5625em;
}

.fa-item {
    font-size: 14px;
    padding: 15px 10px 15px 20px;
}

.fa-item i {
    font-size: 16px;
    display: inline-block;
    width: 20px;
    color: #333;
}

.fa-item:hover {
    cursor: pointer;
    background: #eee;
}


/********* Simple line icon  style ***************/

.sl-iconsdemo .item-box {
    display: inline-block;
    font-size: 16px;
    margin: 0 -0.22em 1em 0;
    padding-left: 1em;
    width: 100%;
}

.sl-iconsdemo .item-box .item {
    background-color: #fff;
    color: #33383e;
    border-radius: 8px;
    display: inline-block;
    padding: 10px;
    width: 100%;
}

.sl-iconsdemo .item-box .item span {
    font-size: 22px;
}

.badge-danger,
.badge-default,
.badge-info,
.badge-primary,
.badge-success,
.badge-warning,
.btn.white:not(.btn-outline).active,
.btn.white:not(.btn-outline):active,
.open>.btn.white:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.alert>p {
    margin: 0;
}

.alert.alert-borderless {
    border: 0;
}

.alert {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    border-width: 0;
}

.margin-top-10 {
    margin-top: 10px;
}

.full-width {
    float: left;
    width: 100%;
}

.hide {
    display: none !important;
}

.img-circle {
    border-radius: 50%;
}

.pull-right {
    float: right !important;
}


/******************************************************************************************

						 		[ 2. BODY ]
							
*******************************************************************************************/

body {
    background-color: #eef1f5;
    padding: 0 !important;
    margin: 0 !important;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.7;
    color: rgba(0, 0, 0, 0.87);
}


/* ***********************************************************************

							    [ 3. PAGE HEADER ]	 
								
**************************************************************************/

.navbar-fixed-top {
    position: fixed;
    right: 0px;
    left: 0px;
    top: 0px;
}

.page-header.navbar {
    background: #193650;
}

.page-header.navbar .page-logo {
    float: left;
    display: block;
    width: 235px;
    height: 60px;
    padding: 10px 20px 0px 30px;
    background: #222c3c;
}

.logo-icon {
    font-size: 35px !important;
    padding-right: 5px;
}

.fa-rotate-45 {
    -webkit-transform: rotate(330deg) !important;
    -moz-transform: rotate(330deg) !important;
    -ms-transform: rotate(330deg) !important;
    -o-transform: rotate(330eg) !important;
    transform: rotate(330deg) !important;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-toggle>i {
    color: #1e2629;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-toggle .badge.badge-default {
    background-color: #3881b3;
    color: #fff;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-toggle:hover,
.page-header.navbar .top-menu .navbar-nav>li.dropdown.open .dropdown-toggle {
    background-color: rgba(0, 0, 0, 0);
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-toggle:hover>i,
.page-header.navbar .top-menu .navbar-nav>li.dropdown.open .dropdown-toggle>i {
    color: #8c8c8c;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu {
    border-color: #e7eaf0;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu:after {
    border-bottom-color: #eaedf2;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu>li.external {
    background: #eff0f4;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu>li.external>h3 {
    color: #555;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu>li.external>a {
    color: #337ab7;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu>li.external>a:hover {
    color: #23527c;
    text-decoration: none;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu .dropdown-menu-list>li>a {
    border-bottom: 1px solid #eff2f6 !important;
    color: #888;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu .dropdown-menu-list>li>a:hover {
    background: #f8f9fa;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li>a .time {
    background: #f1f1f1;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li>a:hover .time {
    background: #e4e4e4;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox>.dropdown-toggle>.circle {
    background-color: #3881b3;
    color: #fff;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox>.dropdown-toggle>.corner {
    border-color: transparent transparent transparent #3881b3;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox .dropdown-menu .dropdown-menu-list .subject .from {
    color: #5b9bd1;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-language>.dropdown-toggle>.langname,
.page-header.navbar .top-menu .navbar-nav>li.dropdown-user>.dropdown-toggle>.username,
.page-header.navbar .top-menu .navbar-nav>li.dropdown-user>.dropdown-toggle>i {
    color: #1e2629;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-tasks .dropdown-menu .dropdown-menu-list .progress {
    background-color: #dfe2e9;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-user>.dropdown-menu {
    width: 195px;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-dark .dropdown-menu {
    background: #3f4f62;
    border: 0;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-dark .dropdown-menu:after {
    border-bottom-color: #3f4f62;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-dark .dropdown-menu>li.external {
    background: #2f3b49;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-dark .dropdown-menu>li.external>h3 {
    color: #adbaca;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-dark .dropdown-menu>li.external>a:hover {
    color: #5496cf;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-dark .dropdown-menu .dropdown-menu-list>li a,
.page-header.navbar .top-menu .navbar-nav>li.dropdown-dark .dropdown-menu.dropdown-menu-default>li a {
    color: #bcc7d4;
    border-bottom: 1px solid #4b5e75 !important;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-dark .dropdown-menu .dropdown-menu-list>li a>i,
.page-header.navbar .top-menu .navbar-nav>li.dropdown-dark .dropdown-menu.dropdown-menu-default>li a>i {
    color: #9dadc0;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-dark .dropdown-menu .dropdown-menu-list>li a:hover,
.page-header.navbar .top-menu .navbar-nav>li.dropdown-dark .dropdown-menu.dropdown-menu-default>li a:hover {
    background: #47596e;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-dark .dropdown-menu.dropdown-menu-default>li a {
    border-bottom: 0 !important;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-dark .dropdown-menu.dropdown-menu-default>li.divider {
    background: #4b5e75;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-notification.dropdown-dark .dropdown-menu .dropdown-menu-list>li>a .time {
    background: #354353;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-notification.dropdown-dark .dropdown-menu .dropdown-menu-list>li>a:hover .time {
    background: #2b3643;
}

.dropdown-quick-sidebar-toggler a {
    padding: 0px !important;
    top: 10px;
}

.page-header.navbar .search-form {
    background: transparent;
    border: 0;
    color: #b4bcc8;
}

.page-header.navbar input.search-form:focus {
    outline: 0;
}

.page-header.navbar .search-form.open,
.page-header.navbar .search-form:hover {
    background: transparent;
}

.page-header.navbar .search-form .input-group .form-control {
    color: #959fad;
}

.page-header.navbar .search-form .input-group .form-control::-moz-placeholder {
    color: #929cab;
    opacity: 1;
}

.page-header.navbar .search-form .input-group .form-control:-ms-input-placeholder {
    color: #929cab;
}

.page-header.navbar .search-form .input-group .form-control::-webkit-input-placeholder {
    color: #929cab;
}

.page-header.navbar .search-form .input-group .input-group-btn .btn.submit>i {
    color: #ffffff;
}

.page-header.navbar .menu-toggler>span,
.page-header.navbar .menu-toggler>span:after,
.page-header.navbar .menu-toggler>span:before,
.page-header.navbar .menu-toggler>span:hover,
.page-header.navbar .menu-toggler>span:hover:after,
.page-header.navbar .menu-toggler>span:hover:before {
    background: #a7b5c6;
}

.page-header.navbar .menu-toggler.th-toggle-exit>span {
    background-color: transparent !important;
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu {
    box-shadow: 5px 5px rgba(63, 79, 98, 0.2);
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>h3,
.page-header.navbar .hor-menu .navbar-nav>li>a {
    color: #c6cfda;
}

.page-header.navbar .hor-menu .navbar-nav>li>a>i {
    color: #c2cad8;
    padding-right: 3px;
}

.page-header.navbar .hor-menu .navbar-nav>li.open>a,
.page-header.navbar .hor-menu .navbar-nav>li:hover>a,
.page-header.navbar .hor-menu .navbar-nav>li>a:hover {
    color: #d5dce4;
    background: #3f4f62 !important;
}

.page-header.navbar .hor-menu .navbar-nav>li.open>a>i,
.page-header.navbar .hor-menu .navbar-nav>li:hover>a>i,
.page-header.navbar .hor-menu .navbar-nav>li>a:hover>i {
    color: #889bb2;
}

.page-header.navbar .hor-menu .navbar-nav>li.active>a,
.page-header.navbar .hor-menu .navbar-nav>li.current>a {
    color: #fff;
    background: #3881b3;
}

.page-header.navbar .hor-menu .navbar-nav>li.active>a>i,
.page-header.navbar .hor-menu .navbar-nav>li.current>a>i {
    color: #c2cad8;
}

.page-header.navbar .hor-menu .navbar-nav>li.active .selected,
.page-header.navbar .hor-menu .navbar-nav>li.current .selected {
    border-top: 6px solid #3881b3;
}

.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu {
    box-shadow: 5px 5px rgba(63, 79, 98, 0.2);
    background: #3f4f62;
}

.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li>a,
.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li>a>i {
    color: #c6cfda;
}

.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li:hover>a {
    color: #f1f3f6;
}

.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.active>a,
.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.active>a:hover,
.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.current>a,
.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.current>a:hover {
    color: #f1f3f6;
    background: #47596e;
}

.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.active>a:hover>i,
.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.active>a>i,
.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.current>a:hover>i,
.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.current>a>i {
    color: #f1f3f6;
}

.page-header-inner {
    float: left;
    width: 100%;
}

.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.divider {
    background-color: #495c72;
}

.page-header.navbar .hor-menu .navbar-nav>li .dropdown-submenu>a:after {
    color: #c6cfda;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li.mega-menu-dropdown>.dropdown-menu {
    box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>h3 {
    color: #666;
}

.mega-menu-dropdown .owl-controls {
    display: none !important;
}

.classic-menu-dropdown .dropdown-menu,
.mega-menu-dropdown .dropdown-menu {
    right: auto;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li>a {
    color: #c6cfda;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li>a>i {
    color: #788ea8;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li:hover>a,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li>a:hover {
    color: #d5dce4;
    background: #3f4f62;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li:hover>a>i,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li>a:hover>i {
    color: #889bb2;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li.open>a {
    color: #333 !important;
    background: #fff !important;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li.open>a>i {
    color: #333 !important;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li.active>a,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li.active>a:hover,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li.current>a,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li.current>a:hover {
    color: #fff;
    background: #3881b3;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li.active>a:hover>i,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li.active>a>i,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li.current>a:hover>i,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li.current>a>i {
    color: #788ea8;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li .dropdown-menu {
    box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
    background: #fff;
    border: 1px solid #f2f2f2;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li .dropdown-menu li>a {
    color: #000;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li .dropdown-menu li>a>i {
    color: #888;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li .dropdown-menu li:hover>a {
    color: #000;
    background: #f5f5f5;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li .dropdown-menu li:hover>a>i {
    color: #666;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li .dropdown-menu li.active>a,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li .dropdown-menu li.active>a:hover,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li .dropdown-menu li.current>a,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li .dropdown-menu li.current>a:hover {
    color: #000;
    background: #f5f5f5;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li .dropdown-menu li.active>a:hover>i,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li .dropdown-menu li.active>a>i,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li .dropdown-menu li.current>a:hover>i,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li .dropdown-menu li.current>a>i {
    color: #666;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li .dropdown-menu li.divider {
    background-color: #f5f5f5;
}

.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li>.dropdown-menu {
    border-top: 0;
}

.page-header.navbar .menu-toggler>span,
.sidemenu-container .sidebar-toggler>span,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span {
    outline: 0 !important;
}

.page-header.navbar,
.page-header.navbar .top-menu .navbar-nav>li.dropdown-tasks .dropdown-menu .dropdown-menu-list>li .progress .progress-bar {
    box-shadow: none;
}

.page-header.navbar {
    width: 100%;
    margin: 0;
    border: 0;
    padding: 0;
    min-height: 50px;
    filter: none;
    background-color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.navbar-custom {
    background: #f1efef;
    float: left;
    width: 100%;
    text-align: center;
    height: 60px;
}

.navbar-nav .dropdown-menu {
    position: absolute;
}

.page-header.navbar.navbar-fixed-top,
.page-header.navbar.navbar-static-top {
    z-index: 9995;
}

.sidemenu-closed.sidemenu-closed-hidelogo .page-header.navbar .page-logo {
    padding: 0;
}

.page-header.navbar .page-logo>.logo-image,
.page-header.navbar .page-logo>a {
    display: inline-block;
    float: left;
}

.page-header.navbar .page-logo .logo-default {
    margin: 7px 0 0;
    height: 34px;
}

.page-header.navbar .page-logo .logo-mini {
    display: none;
    margin-left: 5px;
}

.page-header.navbar .page-logo .text-logo {
    padding-left: 20px;
    padding-top: 12px;
}

.page-logo a {
    text-decoration: none;
    color: #fff;
    font-size: 25px;
}

.navbar-nav>li>a,
.navbar-nav>li>a.sidebar-toggler {
    padding: 20px 10px 20px 15px;
    line-height: 20px;
}

.page-header.navbar .search-form {
    display: inline-block;
    position: relative;
    width: 50px;
    float: left;
    transition: all 0.6s;
    margin-top: 10px;
}

.page-header.navbar .search-form .input-group .form-control {
    height: 50px;
    border: 0;
    background: 0 0 !important;
    font-size: 13px;
    padding-left: 0;
    margin-left: 12px;
    text-indent: -150000px;
}

.page-header.navbar .search-form .input-group .form-control:hover {
    cursor: pointer;
}

.page-header.navbar .search-form .input-group .input-group-btn {
    height: 50px;
}

.page-header.navbar .search-form .input-group .input-group-btn .btn.submit {
    margin-left: -24px;
    padding: 0;
    width: 50px;
    background: 0 0;
    margin-top: 4px;
    display: block;
}

.page-header.navbar .search-form .input-group .input-group-btn .btn.submit>i {
    font-size: 15px;
}

.page-header.navbar .search-form.open {
    transition: all 0.6s;
    width: 200px !important;
}

.page-header.navbar .search-form.open .input-group .form-control {
    text-indent: 0;
}

.page-header.navbar .search-form.open .input-group .form-control:hover {
    cursor: text;
}

.page-header.navbar .search-form.open .input-group .input-group-btn .btn.submit {
    margin-left: 0;
}

form.search-form-opened {
    float: left;
    width: 100%;
    max-width: 200px;
    background-color: transparent;
    color: #fff;
    border-radius: 30px;
    margin: 10px 0 0 10px;
    border: 1px solid #bdbdbd;
}

form.search-form-opened .btn.submit {
    margin: 0;
    color: #1e2629;
}

.search-form-opened .input-group-btn a {
    padding: 12px 12px 7px;
}

.search-form-opened input[type='text'] {
    background-color: transparent;
    border: 0;
    color: #1e2629;
    padding: 7px 12px;
}

.page-header.navbar .menu-toggler {
    cursor: pointer;
    opacity: 0.7;
    filter: alpha(opacity=70);
    display: block;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
    background: none;
}

.page-header.navbar .menu-toggler>span:hover,
.page-header.navbar .menu-toggler>span:hover:after,
.page-header.navbar .menu-toggler>span:hover:before {
    background: #a7b5c6;
}

.page-header.navbar .menu-toggler>span,
.page-header.navbar .menu-toggler>span:after,
.page-header.navbar .menu-toggler>span:before {
    display: inline-block;
    width: 19px;
    height: 1px;
    position: relative;
    top: -10px;
    transition: all ease 0.3s;
}

.menu-toggler i.icon-menu {
    color: #808040;
    font-size: 18px;
}

.page-header.navbar .menu-toggler>span:after,
.page-header.navbar .menu-toggler>span:before {
    position: absolute;
    left: 0;
    content: '';
}

.page-header.navbar .menu-toggler>span:before {
    top: 6px;
}

.page-header.navbar .menu-toggler>span:after {
    top: -6px;
}

.page-header.navbar .menu-toggler.th-toggle-exit>span {
    background-color: transparent !important;
}

.page-header.navbar .menu-toggler.th-toggle-exit>span:after {
    -webkit-transform: translateY(6px) rotateZ(45deg);
    -moz-transform: translateY(6px) rotateZ(45deg);
    -ms-transform: translateY(6px) rotateZ(45deg);
    -o-transform: translateY(6px) rotateZ(45deg);
    transform: translateY(6px) rotateZ(45deg);
}

.page-header.navbar .menu-toggler.th-toggle-exit>span:before {
    -webkit-transform: translateY(-6px) rotateZ(-45deg);
    -moz-transform: translateY(-6px) rotateZ(-45deg);
    -ms-transform: translateY(-6px) rotateZ(-45deg);
    -o-transform: translateY(-6px) rotateZ(-45deg);
    transform: translateY(-6px) rotateZ(-45deg);
}

.page-header.navbar .menu-toggler:hover {
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
    opacity: 1;
    filter: alpha(opacity=100);
}

.page-header.navbar .menu-toggler:focuse {
    background: none;
}


/* .page-header.navbar .menu-toggler.sidebar-toggler {
    float: right;
    margin: 15.5px 0 0
} */

.page-header.navbar .menu-toggler.responsive-toggler {
    display: none;
    float: right;
    margin: 23px 13px 0;
}

.page-header.navbar .top-menu {
    margin: 0;
    padding: 0;
    float: right;
}

.page-header.navbar .top-menu .navbar-nav {
    padding: 0;
    margin-right: 20px;
    display: block;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown {
    margin: 0;
    padding: 5px 8px;
    display: inline-block;
    float: left;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown:last-child {
    padding-right: 0;
    padding-left: 3px;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown>.dropdown-toggle {
    margin: 0;
    padding: 16px 10px 9px 0;
    display: block;
    position: relative;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown>.dropdown-toggle:last-child {
    padding-right: 0;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown>.dropdown-toggle>i {
    font-size: 17px;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown>.dropdown-toggle>i.glyphicon {
    font-size: 16px;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown>.dropdown-toggle>.badge {
    font-family: Roboto, sans-serif;
    position: absolute;
    top: 10px;
    right: 0px;
    font-weight: 300;
    padding: 3px 6px;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown>.dropdown-toggle:focus {
    background: 0 0;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-menu {
    margin-top: 3px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-menu:before {
    position: absolute;
    top: -7px;
    right: 9px;
    display: inline-block !important;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #eee;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-menu:after {
    position: absolute;
    top: -6px;
    right: 10px;
    display: inline-block !important;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
    content: '';
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-menu>li>a {
    color: #555;
    font-size: 13px;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu {
    min-width: 160px;
    max-width: 355px;
    width: 355px;
    z-index: 9995;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu>li.external {
    display: block;
    overflow: hidden;
    padding: 15px;
    letter-spacing: 0.5px;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    -ms-border-radius: 4px 4px 0 0;
    -o-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu>li.external>h3 {
    margin: 0;
    padding: 0;
    float: left;
    font-size: 13px;
    display: inline-block;
    font-size: 18px !important;
    line-height: 20px;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu>li.external>a {
    display: inline-block;
    padding: 0;
    background: 0 0;
    clear: inherit;
    font-size: 13px;
    font-weight: 300;
    position: absolute;
    right: 10px;
    border: 0;
    margin-top: -1px;
}

.page-bar:after,
.page-container:after,
.page-footer:after,
.sidemenu-container .sidemenu>li.sidebar-search-wrapper:after,
.sidemenu-container .sidemenu>li.sidebar-toggler-wrapper:after,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.sidebar-search-wrapper:after,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.sidebar-toggler-wrapper:after {
    clear: both;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu>li.external>a:hover {
    text-decoration: none;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu .dropdown-menu-list {
    padding-right: 0 !important;
    padding-left: 0;
    list-style: none;
    overflow-y: auto;
    height: 260px;
    width: auto;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu .dropdown-menu-list>li>a {
    display: block;
    clear: both;
    font-weight: 300;
    line-height: 20px;
    white-space: normal;
    font-size: 13px;
    padding: 8px 15px 8px;
    text-shadow: none;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu .dropdown-menu-list>li>a:hover {
    opacity: 1;
    filter: alpha(opacity=100);
    text-decoration: none;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu .dropdown-menu-list>li:first-child a {
    border-top: none;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li a .details {
    overflow: hidden;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li a .details .label-icon {
    margin-right: 10px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li a .details .label-icon i {
    margin-right: 2px;
    margin-left: 1px;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li a .details .label-icon .badge {
    right: 15px;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li a .time {
    float: right;
    max-width: 75px;
    font-size: 11px;
    font-weight: 400;
    opacity: 0.7;
    filter: alpha(opacity=70);
    text-align: right;
    padding: 1px 5px;
    margin: 7px 0px;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox>.dropdown-menu .dropdown-menu-list>li .photo {
    float: left;
    margin: 0 6px 6px 0;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox>.dropdown-menu .dropdown-menu-list>li .photo img {
    height: 40px;
    width: 40px;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    -ms-border-radius: 50% !important;
    -o-border-radius: 50% !important;
    border-radius: 50% !important;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox>.dropdown-menu .dropdown-menu-list>li .subject {
    display: block;
    margin-left: 46px;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox>.dropdown-menu .dropdown-menu-list>li .subject .from {
    font-size: 13px;
    font-weight: 600;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox>.dropdown-menu .dropdown-menu-list>li .subject .time {
    font-size: 12px;
    font-weight: 400;
    opacity: 0.5;
    filter: alpha(opacity=50);
    float: right;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox>.dropdown-menu .dropdown-menu-list>li .message {
    display: block !important;
    font-size: 12px;
    line-height: 1.3;
    margin-left: 46px;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-tasks .dropdown-menu .dropdown-menu-list>li .task {
    margin-bottom: 5px;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-tasks .dropdown-menu .dropdown-menu-list>li .task .desc {
    font-size: 13px;
    font-weight: 300;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-tasks .dropdown-menu .dropdown-menu-list>li .task .percent {
    float: right;
    font-weight: 600;
    display: inline-block;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-tasks .dropdown-menu .dropdown-menu-list>li .progress {
    display: block;
    height: 8px;
    margin: 8px 0 2px;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-toggle {
    padding: 13px 6px 12px 8px;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-toggle>.username {
    display: inline-block;
    font-size: 13px;
    font-weight: 300;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-toggle>img {
    float: left;
    margin-top: -2px;
    margin-right: 5px;
    height: 29px;
    display: inline-block;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-toggle>i {
    display: inline-block;
    margin: 0;
    font-size: 13px;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-menu {
    width: 175px;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-menu>li>a {
    font-size: 14px;
    font-weight: 300;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-menu>li>a i {
    width: 15px;
    display: inline-block;
    margin-right: 9px;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-menu>li>a .badge {
    margin-right: 10px;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-language {
    padding-left: 0;
    padding-right: 0;
    margin: 0;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-language>.dropdown-toggle {
    padding: 16px 3px 13px 7px;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-language>.dropdown-toggle>img {
    margin-bottom: 2px;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-language>.dropdown-toggle>i {
    font-size: 14px;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-language>.dropdown-menu>li>a {
    font-size: 13px;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-language>.dropdown-menu>li>a>img {
    margin-bottom: 2px;
    margin-right: 5px;
}

.page-header.navbar .top-menu .navbar-nav li.dropdown-dark .dropdown-menu:before {
    border-left: none;
    border-right: none;
}

.page-header.navbar .top-menu .navbar-nav li.dropdown-dark .dropdown-menu .dropdown-menu-list>li.external a {
    background: 0 0 !important;
    border: none !important;
}

.page-header.navbar .container {
    position: relative;
}

.page-header.navbar .hor-menu {
    margin: 0;
    display: inline-block;
}

.page-header.navbar .hor-menu .navbar-nav {
    min-height: 50px;
    position: static;
    display: block;
}

.page-header.navbar .hor-menu .navbar-nav.navbar-right .dropdown-menu {
    left: auto;
    right: 0;
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown {
    position: static;
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu {
    left: auto;
    width: auto;
    position: absolute;
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content {
    font-family: Roboto, sans-serif;
    padding: 15px;
    margin: 0;
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content.mega-menu-responsive-content {
    padding: 10px 18px 10px 45px;
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu {
    padding: 0;
    margin: 0;
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu:last-child {
    border-right: 0;
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li {
    margin: 0 !important;
    list-style: none;
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>h3 {
    margin-top: 5px;
    padding-left: 6px;
    font-size: 15px;
    font-weight: 400;
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>a {
    display: block;
    white-space: normal;
    font-family: Roboto, sans-serif;
    padding: 7px;
    margin: 0;
    font-size: 14px;
    font-weight: 300;
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>a:hover {
    text-decoration: none;
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>a.iconify {
    padding: 7px 7px 7px 30px;
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>a.iconify>i {
    position: absolute;
    top: auto !important;
    margin-left: -24px;
    font-size: 15px;
    margin-top: 3px !important;
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>a .badge,
.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>a .label {
    margin-left: 5px;
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown.mega-menu-full .dropdown-menu {
    left: 20px;
    right: 20px;
}

.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown:hover>.dropdown-menu {
    display: block;
    padding: 5px 0;
}

.page-header.navbar .hor-menu .navbar-nav>li.menu-dropdown .dropdown-menu:after,
.page-header.navbar .hor-menu .navbar-nav>li.menu-dropdown .dropdown-menu:before {
    display: none !important;
}

.page-header.navbar .hor-menu .navbar-nav>li>a {
    font-size: 14px;
    font-weight: 400;
    padding: 19px 13px 18px 13px;
    min-height: 60px;
    float: left;
}

.page-header.navbar .hor-menu .navbar-nav>li>a:focus {
    background: 0 0 !important;
}

.page-header.navbar .hor-menu .navbar-nav>li.active .selected,
.page-header.navbar .hor-menu .navbar-nav>li.current .selected {
    left: 50%;
    bottom: 0;
    position: absolute;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid transparent;
    display: inline-block;
    margin: 0 0 -6px -7px;
    width: 0;
    height: 0;
}

.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu {
    margin-top: 0;
    border: none;
}

.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li>a {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
    padding: 9px 10px;
    white-space: normal;
}

.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li>a .badge,
.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li>a .label {
    font-weight: 300;
}

.page-header.navbar .hor-menu .navbar-nav>li.classic-menu-dropdown .dropdown-menu {
    min-width: 195px;
    max-width: 235px;
}

.page-header.navbar .hor-menu .navbar-nav>li.classic-menu-dropdown:hover>.dropdown-menu {
    display: block;
}

.page-header.navbar .hor-menu .navbar-nav>li .dropdown-submenu>.dropdown-menu {
    top: 0;
}

.page-header.navbar .hor-menu .navbar-nav>li .dropdown-submenu .nav-item>a:after {
    top: 9px;
    right: 10px;
}

.page-container {
    margin: 0;
    padding: 0;
    position: relative;
    background-color: #222c3c;
}

.page-container:after,
.page-container:before {
    content: ' ';
    display: table;
}

.page-header-fixed .page-container {
    /* margin-top: 50px */
}

.page-full-width.page-header-fixed .page-container {
    margin-top: 110px;
}

.page-header .top-menu .dropdown-quick-sidebar-toggler>.dropdown-toggle {
    padding: 19px 10px 10px;
}

.page-header .top-menu .dropdown-quick-sidebar-toggler>.dropdown-toggle i {
    top: 0;
}

.page-header .top-menu .dropdown-quick-sidebar-toggler>.dropdown-toggle i:before {
    content: '';
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu>li.external h3 {
    text-transform: uppercase;
    padding: 2px 0;
    font-size: 14px;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown.language-switch>.dropdown-toggle {
    padding: 13px 0px 12px;
    display: block;
}


/******************************************************************************************

						 		[ 4. SIDE MENU ]
							
*******************************************************************************************/

.sidemenu-container {
    margin-top: 50px;
}

.sidemenu-container,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover {}

.sidemenu-container .sidemenu>li>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a {
    /* border-bottom: 1px solid #1c262f; */
    color: #b7c0cd;
    font-weight: 500 !important;
}

.sidemenu-container .sidemenu>li>a>i,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a>i {
    color: currentColor;
}

.sidemenu-container .sidemenu>li>a>i[class*='icon-'],
.sidemenu-container .sidemenu>li>a>i[class^='icon-'],
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a>i[class*='icon-'],
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a>i[class^='icon-'] {
    color: #6b788b;
}

.sidemenu-container .sidemenu>li>a>.arrow.open:before,
.sidemenu-container .sidemenu>li>a>.arrow:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a>.arrow.open:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a>.arrow:before {
    color: #606c7d;
}

.sidemenu-container .sidemenu>li.heading>h4,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.heading>h4 {
    color: #708096;
}

.sidemenu-container .sidemenu>li.open>a,
.sidemenu-container .sidemenu>li:hover>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a {
    background-color: #273244;
    color: #fff;
}

.sidemenu-container .sidemenu>li.open>a>.arrow.open:before,
.sidemenu-container .sidemenu>li.open>a>.arrow:before,
.sidemenu-container .sidemenu>li.open>a>i,
.sidemenu-container .sidemenu>li:hover>a>.arrow.open:before,
.sidemenu-container .sidemenu>li:hover>a>.arrow:before,
.sidemenu-container .sidemenu>li:hover>a>i,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>.arrow.open:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>.arrow:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>i,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>.arrow.open:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>.arrow:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>i {
    color: #fff;
}

.sidemenu-container .sidemenu>li.active.open>a,
.sidemenu-container .sidemenu>li.active>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a {
    background-color: #273244;
    border-top-color: transparent;
    color: #fff;
}

.sidemenu-container .sidemenu>li.active.open>a:hover,
.sidemenu-container .sidemenu>li.active>a:hover,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a:hover,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a:hover {
    background-color: #273244;
    color: white;
}

.sidemenu-container .sidemenu>li.active.open>a>.arrow.open:before,
.sidemenu-container .sidemenu>li.active.open>a>.arrow:before,
.sidemenu-container .sidemenu>li.active.open>a>i,
.sidemenu-container .sidemenu>li.active>a>.arrow.open:before,
.sidemenu-container .sidemenu>li.active>a>.arrow:before,
.sidemenu-container .sidemenu>li.active>a>i,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a>.arrow.open:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a>.arrow:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a>i,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>.arrow.open:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>.arrow:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>i {
    color: #fff;
}

.sidemenu-container .sidemenu>li.active+li>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active+li>a {
    border-top-color: transparent;
}

.sidemenu-container .sidemenu>li.active.open+li>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open+li>a {
    border-top-color: #3d4957;
}

.sidemenu-container .sidemenu>li:last-child>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:last-child>a {
    border-bottom: 1px solid transparent !important;
}

.sidemenu-container .sidemenu li:hover>a>.arrow.open:before,
.sidemenu-container .sidemenu li:hover>a>.arrow:before,
.sidemenu-container .sidemenu li>a>.arrow.open:before,
.sidemenu-container .sidemenu li>a>.arrow:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu li:hover>a>.arrow.open:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu li:hover>a>.arrow:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu li>a>.arrow.open:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu li>a>.arrow:before {
    color: currentColor;
}

.sidemenu-container .sidemenu li.active>a>.arrow.open:before,
.sidemenu-container .sidemenu li.active>a>.arrow:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu li.active>a>.arrow.open:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu li.active>a>.arrow:before {
    color: #fff;
}


/* .sidemenu-closed .sidemenu-container .sidemenu:hover .sub-menu,
.sidemenu-closed .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu:hover .sub-menu {
    background-color: #1C262F;
} */

.sidemenu-container .sidemenu .sub-menu>li>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li>a {
    color: #cccbcb;
    font-weight: 500 !important;
}

.sidemenu-container .sidemenu .sub-menu>li>a>i,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li>a>i {
    color: #cccbcb;
}

.sidemenu-container .sidemenu .sub-menu>li>a>i[class*='icon-'],
.sidemenu-container .sidemenu .sub-menu>li>a>i[class^='icon-'],
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li>a>i[class*='icon-'],
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li>a>i[class^='icon-'] {
    color: #ffffff;
}

.sidemenu-container .sidemenu .sub-menu>li>a>.arrow.open:before,
.sidemenu-container .sidemenu .sub-menu>li>a>.arrow:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li>a>.arrow.open:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li>a>.arrow:before {
    color: #ffffff;
}

.sidemenu-container .sidemenu .sub-menu>li.active>a,
.sidemenu-container .sidemenu .sub-menu>li.open>a,
.sidemenu-container .sidemenu .sub-menu>li:hover>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.active>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.open>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li:hover>a {
    color: #ffffff;
}

.sidemenu-container .sidemenu .sub-menu>li.active>a>i,
.sidemenu-container .sidemenu .sub-menu>li.open>a>i,
.sidemenu-container .sidemenu .sub-menu>li:hover>a>i,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.active>a>i,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.open>a>i,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li:hover>a>i {
    color: #ffffff;
}

.sidemenu-container .sidemenu .sub-menu>li.active>a>.arrow.open:before,
.sidemenu-container .sidemenu .sub-menu>li.active>a>.arrow:before,
.sidemenu-container .sidemenu .sub-menu>li.open>a>.arrow.open:before,
.sidemenu-container .sidemenu .sub-menu>li.open>a>.arrow:before,
.sidemenu-container .sidemenu .sub-menu>li:hover>a>.arrow.open:before,
.sidemenu-container .sidemenu .sub-menu>li:hover>a>.arrow:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.active>a>.arrow.open:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.active>a>.arrow:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.open>a>.arrow.open:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.open>a>.arrow:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li:hover>a>.arrow.open:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li:hover>a>.arrow:before {
    color: #ffffff;
}

.sidemenu-container .sidebar-search .input-group .input-group-btn .btn>i,
.sidemenu-closed .sidemenu-container .sidebar-search.open .remove>i,
.sidemenu-closed .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search.open .remove>i,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search .input-group .input-group-btn .btn>i {
    color: #b4bcc8;
}

.sidemenu-container .sidebar-toggler,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler {
    background: #2c3541;
}

.sidemenu-container .sidebar-toggler>span,
.sidemenu-container .sidebar-toggler>span:after,
.sidemenu-container .sidebar-toggler>span:before,
.sidemenu-container .sidebar-toggler>span:hover,
.sidemenu-container .sidebar-toggler>span:hover:after,
.sidemenu-container .sidebar-toggler>span:hover:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span:after,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span:hover,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span:hover:after,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span:hover:before {
    background: #a7b5c6;
}

.sidemenu-container .sidebar-toggler.th-toggle-exit>span,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler.th-toggle-exit>span {
    background-color: transparent !important;
}

.sidemenu-container .sidebar-toggler:hover,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler:hover {
    background: #212832;
}

.sidemenu-container .sidebar-search .input-group,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search .input-group {
    border-bottom: 1px solid #b4bcc8;
}

.sidemenu-container .sidebar-search .input-group .form-control,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search .input-group .form-control {
    background-color: rgba(255, 255, 255, 0);
    color: #ffffff;
}

.sidemenu-container .sidebar-search .input-group .form-control::-moz-placeholder,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search .input-group .form-control::-moz-placeholder {
    color: #4e5d6f;
    opacity: 1;
}

.sidemenu-container .sidebar-search .input-group .form-control:-ms-input-placeholder,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search .input-group .form-control:-ms-input-placeholder {
    color: #4e5d6f;
}

.sidemenu-container .sidebar-search .input-group .form-control::-webkit-input-placeholder,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search .input-group .form-control::-webkit-input-placeholder {
    color: #4e5d6f;
}

.page-header .search-form-opened .input-group .form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #c0c0c0;
    opacity: 1;
}

.page-header .search-form-opened .input-group .form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: #c0c0c0;
    opacity: 1;
}

.page-header .search-form-opened .input-group .form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: #c0c0c0;
    opacity: 1;
}

.page-header .search-form-opened .input-group .form-control:-moz-placeholder {
    /* Firefox 18- */
    color: #c0c0c0;
    opacity: 1;
}

.sidemenu-container .sidebar-search.sidebar-search-bordered .input-group,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search.sidebar-search-bordered .input-group {
    border: 1px solid #435060;
}

.sidemenu-closed .sidemenu-container .sidebar-search.open .input-group,
.sidemenu-closed .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search.open .input-group {
    background-color: #1c262f;
}

.sidemenu-closed .sidemenu-container .sidebar-search.sidebar-search-solid .input-group,
.sidemenu-closed .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search.sidebar-search-solid .input-group {
    background: 0 0;
}

.sidemenu-container .sidebar-search.sidebar-search-solid .input-group,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search.sidebar-search-solid .input-group {
    border: 1px solid #2c3541;
    background: #2c3542;
}

.sidemenu-container .sidebar-search.sidebar-search-solid .input-group .form-control,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search.sidebar-search-solid .input-group .form-control {
    background: #2c3541;
}

.sidemenu-container .sidebar-search.sidebar-search-solid.open .input-group,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search.sidebar-search-solid.open .input-group {
    border: 1px solid #1c262f;
    background: #1c262e;
}

.sidemenu-container .sidebar-search.sidebar-search-solid.open .input-group .form-control,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search.sidebar-search-solid.open .input-group .form-control {
    background: #1c262f;
}

.sidemenu-container .badge {
    font-size: 10px !important;
}

.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover.navbar-collapse,
.sidemenu-container.navbar-collapse {
    padding: 0;
    box-shadow: none;
}

.sidemenu-container .sidemenu,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu {
    list-style: none;
    margin: 0;
    padding: 0;
}

.sidemenu-container .sidemenu>li,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li {
    display: block;
    margin: 0;
    border: 0;
}

.sidemenu-container .sidemenu>li.sidebar-search-wrapper,
.sidemenu-container .sidemenu>li.sidebar-toggler-wrapper,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.sidebar-search-wrapper,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.sidebar-toggler-wrapper {
    border: 0 !important;
}

.sidemenu-container .sidemenu>li.sidebar-search-wrapper:after,
.sidemenu-container .sidemenu>li.sidebar-search-wrapper:before,
.sidemenu-container .sidemenu>li.sidebar-toggler-wrapper:after,
.sidemenu-container .sidemenu>li.sidebar-toggler-wrapper:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.sidebar-search-wrapper:after,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.sidebar-search-wrapper:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.sidebar-toggler-wrapper:after,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.sidebar-toggler-wrapper:before,
.sidebar-user-panel::before,
.sidebar-user-panel::after {
    content: ' ';
    display: table;
}

.sidebar-user-panel::after {
    clear: both;
}

.sidemenu-container .sidemenu>li.start>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.start>a {
    border-top-color: transparent !important;
}

.sidemenu-container .sidemenu>li.last>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.last>a {
    border-bottom-color: transparent !important;
}

.sidemenu-container .sidemenu>li>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a {
    display: block;
    position: relative;
    margin: 0;
    padding: 17px 15px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 300;
}

.sidemenu-container .sidemenu>li>a>i,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a>i {
    font-size: 18px;
    margin-right: 5px;
    text-shadow: none;
    float: left;
    line-height: 24px;
}

.sidemenu-container .sidemenu.sidemenu-closed>li>a>i {
    float: none;
}

.sidemenu-container .sidemenu>li>a>[class^='icon-'],
.sidemenu-container .sidemenu>li>a>i.glyphicon,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a>[class^='icon-'],
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a>i.glyphicon {
    margin-left: 1px;
    margin-right: 4px;
}

.sidemenu-container-fixed .sidemenu-container .sidemenu>li>a,
.sidemenu-container-fixed .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a {
    transition: all 0.2s ease;
}

.sidemenu-container-reversed.sidemenu-container-fixed .sidemenu-container .sidemenu>li>a,
.sidemenu-container-reversed.sidemenu-container-fixed .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a {
    transition: none;
}

.sidemenu-container .sidemenu>li.heading,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.heading {
    padding: 15px;
}

.sidemenu-container .sidemenu>li.heading>h3,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.heading>h3 {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 300;
}

.sidemenu-container .sidemenu>li.heading+li>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.heading+li>a {
    border-top: 0;
}

.sidemenu-container .sidemenu>li.open>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a {
    font-size: 14px;
}

.sidemenu-container .sidemenu>li.active>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a {
    border: none;
    text-shadow: none;
    font-size: 14px;
}

.sidemenu-container .sidemenu>li.active>a>.selected,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>.selected {
    display: block;
    float: right;
    position: absolute;
    right: 0;
    top: 8px;
    background: 0 0;
    width: 0;
    height: 0;
}

.sidemenu-container-reversed .sidemenu-container .sidemenu>li.active>a>.selected,
.sidemenu-container-reversed .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>.selected {
    right: auto;
    left: 0;
    border-right: 0;
    border-left: 8px solid #fff;
}

.page-container-bg-solid .sidemenu-container .sidemenu>li.active>a>.selected,
.page-container-bg-solid .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>.selected {
    border-color: transparent #eef1f5 transparent transparent;
}

.page-container-bg-solid.sidemenu-container-reversed .sidemenu-container .sidemenu>li.active>a>.selected,
.page-container-bg-solid.sidemenu-container-reversed .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>.selected {
    border-color: transparent transparent transparent #eef1f5;
}

.sidemenu-container .sidemenu li>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu li>a {
    position: relative;
}

.sidemenu-container .sidemenu li>a>.arrow:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu li>a>.arrow:before {
    float: right;
    width: 20px;
    text-align: center;
    display: inline;
    font-size: 16px;
    font-family: FontAwesome;
    height: auto;
    content: '\f105';
    font-weight: 300;
    text-shadow: none;
    position: absolute;
    top: 4px;
    right: 14px;
}

.sidemenu-container .sidemenu li>a>.arrow.open:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu li>a>.arrow.open:before {
    content: '\f107';
}

.sidemenu-container .sidemenu li>a>.badge,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu li>a>.badge {
    float: right;
    margin-top: 1px;
    margin-right: 0;
    position: absolute;
    right: 14px;
    top: 6px;
}

.sidemenu-container .sidemenu>li>a>.arrow:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a>.arrow:before {
    top: 14px;
}

.sidemenu-container .sidemenu .sub-menu,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu {
    list-style: none;
    display: none;
    padding: 0 0 15px 0;
    margin: 0;
    background-color: #1e293c;
}

.sidemenu-container .sidemenu .sub-menu li,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu li {
    background: 0 0;
    margin: 0;
    padding: 0;
}

.sidemenu-container .sidemenu .sub-menu li>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu li>a {
    display: block;
    margin: 0;
    padding: 8px 15px 8px 43px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 300;
    background: 0 0;
}

.sidemenu-container .sidemenu .sub-menu li>a>i,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu li>a>i {
    font-size: 14px;
}

.sidemenu-container .sidemenu .sub-menu li>.sub-menu,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu li>.sub-menu {
    margin: 0;
}

.sidemenu-container .sidemenu .sub-menu li>.sub-menu>li>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu li>.sub-menu>li>a {
    padding-left: 60px;
}

.sidemenu-container .sidemenu .sub-menu li>.sub-menu>li>.sub-menu,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu li>.sub-menu>li>.sub-menu {
    margin: 0;
}

.sidemenu-container .sidemenu .sub-menu li>.sub-menu>li>.sub-menu>li>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu li>.sub-menu>li>.sub-menu>li>a {
    padding-left: 80px;
}

.sidemenu-container .sidemenu .sub-menu.always-open,
.sidemenu-container .sidemenu li.active>.sub-menu,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu.always-open,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu li.active>.sub-menu {
    display: block;
}

.sidemenu-container .sidebar-toggler,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler {
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
    display: block;
    cursor: pointer;
    opacity: 0.7;
    filter: alpha(opacity=70);
    padding: 6px 8px;
    margin-top: 15px;
    margin-right: 16px;
    float: right;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
}

.sidemenu-container .sidebar-toggler>span:hover,
.sidemenu-container .sidebar-toggler>span:hover:after,
.sidemenu-container .sidebar-toggler>span:hover:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span:hover,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span:hover:after,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span:hover:before {
    background: #fff;
}

.sidemenu-container .sidebar-toggler>span,
.sidemenu-container .sidebar-toggler>span:after,
.sidemenu-container .sidebar-toggler>span:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span:after,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span:before {
    display: inline-block;
    width: 16px;
    height: 1px;
    background: #fff;
    position: relative;
    top: -5px;
    transition: all ease 0.3s;
}

.sidemenu-container .sidebar-toggler>span:after,
.sidemenu-container .sidebar-toggler>span:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span:after,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span:before {
    position: absolute;
    left: 0;
    content: '';
}

.sidemenu-container .sidebar-toggler>span:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span:before {
    top: 5px;
}

.sidemenu-container .sidebar-toggler>span:after,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span:after {
    top: -5px;
}

.sidemenu-container .sidebar-toggler.th-toggle-exit>span,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler.th-toggle-exit>span {
    background-color: transparent !important;
}

.sidemenu-container .sidebar-toggler.th-toggle-exit>span:after,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler.th-toggle-exit>span:after {
    -webkit-transform: translateY(5px) rotateZ(45deg);
    -moz-transform: translateY(5px) rotateZ(45deg);
    -ms-transform: translateY(5px) rotateZ(45deg);
    -o-transform: translateY(5px) rotateZ(45deg);
    transform: translateY(5px) rotateZ(45deg);
}

.sidemenu-container .sidebar-toggler.th-toggle-exit>span:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler.th-toggle-exit>span:before {
    -webkit-transform: translateY(-5px) rotateZ(-45deg);
    -moz-transform: translateY(-5px) rotateZ(-45deg);
    -ms-transform: translateY(-5px) rotateZ(-45deg);
    -o-transform: translateY(-5px) rotateZ(-45deg);
    transform: translateY(-5px) rotateZ(-45deg);
}

.sidemenu-container .sidebar-toggler:hover,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler:hover {
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
    opacity: 1;
    filter: alpha(opacity=100);
}

.sidemenu-container .sidebar-search,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search {
    padding: 0;
    margin: 12px 18px;
}

.sidemenu-container .sidebar-search .remove,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search .remove {
    display: none;
}

.sidemenu-container .sidebar-search .remove>i,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search .remove>i {
    font-size: 16px;
}

.sidemenu-container .sidebar-search .input-group,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search .input-group {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
}

.sidemenu-container .sidebar-search .input-group .form-control,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search .input-group .form-control {
    border: 0;
    font-size: 14px;
    padding: 0;
    height: auto;
    line-height: normal;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
}

.sidemenu-container .sidebar-search .input-group .input-group-btn .btn,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search .input-group .input-group-btn .btn {
    padding: 2px 0 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 100% 3px;
}

.sidemenu-container .sidebar-search .input-group .input-group-btn .btn>i,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search .input-group .input-group-btn .btn>i {
    font-size: 15px;
}

.sidemenu-container .sidebar-search.sidebar-search-bordered,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search.sidebar-search-bordered {
    margin: 25px 18px;
}

.sidemenu-container .sidebar-search.sidebar-search-bordered .input-group .form-control,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search.sidebar-search-bordered .input-group .form-control {
    font-size: 13px;
    padding: 6px 8px;
}

.sidemenu-container .sidebar-search.sidebar-search-bordered .input-group .input-group-btn .btn,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search.sidebar-search-bordered .input-group .input-group-btn .btn {
    margin-right: 6px;
}

.sidemenu-closed.sidemenu-closed-hidelogo .page-header.navbar .menu-toggler.sidebar-toggler {
    margin-right: 13px;
    background: none;
}

.sidebar-container .sidemenu>li.sidebar-mobile-offcanvas-toggler {
    display: none;
}

.sidemenu-closed .user-panel {
    display: none;
}

.sidemenu-container {
    background-color: #222c3c;
}

.sidemenu-closed .sidemenu-container .slimScrollDiv {
    overflow: visible !important;
    height: 100% !important;
}

.sidemenu-closed .sidemenu-container .slimscroll-style {
    overflow: visible !important;
    height: 100% !important;
}

.sidemenu-container.fixed-menu {
    position: fixed;
    z-index: 999;
}

.sidemenu-closed .sidemenu-container.fixed-menu {
    position: relative;
}

.sidemenu-container-reversed .sidemenu-container.fixed-menu {
    right: 0;
}


/*********************************************************************

					[5. SEARCH BAR ]

**********************************************************************/

.input-group-btn .search-btn {
    padding: 7px 12px 7px;
}

.search-bar-form {
    padding: 0px 0px;
}

.search-form::-webkit-input-placeholder {
    color: #b4bcc8 !important;
}

.search-form:-moz-placeholder {
    color: #b4bcc8 !important;
}

.search-form::-moz-placeholder {
    color: #b4bcc8 !important;
}

.search-form:-ms-input-placeholder {
    color: #b4bcc8 !important;
}


/**************************************************************

					[6. NAVIGATION ]
						
***************************************************************/

.nav-pills,
.nav-tabs {
    margin-bottom: 10px;
}

.nav-pills>li>a,
.nav-tabs>li>a {
    font-size: 14px;
    -webkit-border-radius: 2px 2px 0 0;
    -moz-border-radius: 2px 2px 0 0;
    -ms-border-radius: 2px 2px 0 0;
    -o-border-radius: 2px 2px 0 0;
    border-radius: 2px 2px 0 0;
}

.nav-pills>li>a>.badge,
.nav-tabs>li>a>.badge {
    margin-top: -6px;
}

.nav-pills>li .dropdown-menu:after,
.nav-pills>li .dropdown-menu:before,
.nav-tabs>li .dropdown-menu:after,
.nav-tabs>li .dropdown-menu:before {
    display: none;
}

.nav-pills.nav-pills-sm>li>a,
.nav-pills.nav-tabs-sm>li>a,
.nav-tabs.nav-pills-sm>li>a,
.nav-tabs.nav-tabs-sm>li>a {
    font-size: 13px;
}

.nav-pills .dropdown.open>.dropdown-toggle,
.nav-tabs .dropdown.open>.dropdown-toggle {
    background: #eee;
    color: #0d638f;
    border-color: transparent;
}

.tabs-left.nav-tabs,
.tabs-right.nav-tabs {
    border-bottom: 0;
}

.tabs-left.nav-tabs>li,
.tabs-right.nav-tabs>li {
    float: none;
}

.tabs-left.nav-tabs>li>a,
.tabs-right.nav-tabs>li>a {
    margin-right: 0;
    margin-bottom: 3px;
}

.tabs-left.nav-tabs {
    border-right: 1px solid #ddd;
    display: block;
}

.tabs-left.nav-tabs>li>a {
    display: block;
    margin-right: -1px;
}

.tabs-left.nav-tabs>li>a:focus,
.tabs-left.nav-tabs>li>a:hover {
    border-radius: 2px 0 0 2px;
    border-color: #eee #ddd #eee #eee;
}

.tabs-left.nav-tabs>li.active>a,
.tabs-left.nav-tabs>li.active>a:hover>li.active>a:focus,
.tabs-left.nav-tabs>li a.active,
.tabs-left.nav-tabs>li a.active:hover,
.tabs-left.nav-tabs>li a.active:focus {
    -webkit-border-radius: 2px 0 0 2px;
    -moz-border-radius: 2px 0 0 2px;
    -ms-border-radius: 2px 0 0 2px;
    -o-border-radius: 2px 0 0 2px;
    border-radius: 2px 0 0 2px;
    border-color: #ddd transparent #ddd #ddd;
}

.tabs-right.nav-tabs {
    border-left: 1px solid #ddd;
    display: block;
}

.tabs-right.nav-tabs>li>a {
    display: block;
    margin-left: -1px;
}

.tabbable:after,
.tabbable:before {
    display: table;
    content: ' ';
}

.tabs-right.nav-tabs>li>a:focus,
.tabs-right.nav-tabs>li>a:hover {
    border-radius: 0 2px 2px 0;
    border-color: #eee #eee #eee #ddd;
}

.tabs-right.nav-tabs>li.active>a,
.tabs-right.nav-tabs>li.active>a:hover>li.active>a:focus,
.tabs-right.nav-tabs>li a.active,
.tabs-right.nav-tabs>li a.active:hover,
.tabs-right.nav-tabs>li a.active:focus {
    -webkit-border-radius: 0 2px 2px 0;
    -moz-border-radius: 0 2px 2px 0;
    -ms-border-radius: 0 2px 2px 0;
    -o-border-radius: 0 2px 2px 0;
    border-radius: 0 2px 2px 0;
    border-color: #ddd #ddd #ddd transparent;
}

.tabs-below>.nav-pills,
.tabs-below>.nav-tabs {
    border-bottom: 0;
    margin-bottom: 0;
    margin-top: 10px;
}

.tabs-below>.nav-tabs {
    border-top: 1px solid #ddd;
    margin-bottom: 0;
    margin-top: 10px;
}

.tabs-below>.nav-tabs>li>a {
    margin-top: -1px;
    margin-bottom: 0;
}

.tabs-below>.nav-tabs>li>a:focus,
.tabs-below>.nav-tabs>li>a:hover {
    border-top-color: #ddd;
    border-bottom-color: transparent;
}

.tabs-below>.nav-tabs>li>a .dropdown-menu {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
}

.tabs-below>.nav-tabs .active a,
.tabs-below>.nav-tabs .active a:hover .active a:focus {
    -webkit-border-radius: 0 0 2px 2px;
    -moz-border-radius: 0 0 2px 2px;
    -ms-border-radius: 0 0 2px 2px;
    -o-border-radius: 0 0 2px 2px;
    border-radius: 0 0 2px 2px;
    border-color: transparent #ddd #ddd !important;
}

.tabbable:after {
    clear: both;
}

.tabbable-line>.nav-tabs {
    border: none;
    margin: 0;
}

.tabbable-line>.nav-tabs>li {
    margin: 0;
    border-bottom: 4px solid transparent;
}

.tabbable-line>.nav-tabs>li>a {
    background: 0 0 !important;
    border: 0;
    margin: 0;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 18px;
    color: #737373;
}

.tabbable-line>.nav-tabs>li>a>i {
    color: #a6a6a6;
}

.tabbable-line>.nav-tabs>li.active,
.tabbable-line>.nav-tabs>li a.active,
.borderBox .tabbable-line>.nav-tabs>li a.active {
    background: 0 0;
    border-bottom: 4px solid #36c6d3;
    position: relative;
}

.tabbable-line>.nav-tabs>li.active>a {
    border: 0;
    font-size: 18px;
    color: #333;
}

.tabbable-line>.nav-tabs>li.active>a>i {
    color: #404040;
}

.tabbable-line>.nav-tabs>li.open,
.tabbable-line>.nav-tabs>li:hover {
    background: 0 0;
}

.tabbable-line>.nav-tabs>li.open>a,
.tabbable-line>.nav-tabs>li:hover>a {
    border: 0;
    background: 0 0 !important;
    color: #333;
}

.tabbable-line>.nav-tabs>li.open>a>i,
.tabbable-line>.nav-tabs>li:hover>a>i {
    color: #a6a6a6;
}

.tabbable-line>.tab-content {
    margin-top: 0;
    border: 0;
    border-top: 1px solid #eef1f5;
    /* padding: 30px 0 */
}

.page-container-bg-solid .tabbable-line>.tab-content {
    border-top: 1px solid #dae2ea;
}

.tabbable-line>.tab-content {
    padding-bottom: 0;
}

.tabbable-line.tabs-below>.nav-tabs>li {
    border-top: 4px solid transparent;
}

.tabbable-line.tabs-below>.nav-tabs>li:hover {
    border-bottom: 0;
    border-top: 4px solid #fbdcde;
}

.tabbable-line.tabs-below>.nav-tabs>li.active {
    margin-bottom: -2px;
    border-bottom: 0;
    border-top: 4px solid #ed6b75;
}

.tabbable-line.tabs-below>.tab-content {
    margin-top: -10px;
    border-top: 0;
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
}

.tabbable-bordered {
    margin-top: 20px;
}

.tabbable-bordered .nav-tabs {
    margin-bottom: 0;
    border-bottom: 0;
}

.tabbable-bordered .tab-content {
    padding: 30px 20px 20px;
    border: 1px solid #ddd;
    background: #fff;
}


/****************************************************************

					[7. PANEL ]
						
*****************************************************************/

.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
}

.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.panel-heading-gray {
    background: #eee;
}

.panel-heading-purple {
    background: #a860c5;
    color: white;
}

.panel-heading-yellow {
    background: #ecb301;
    color: white;
}

.panel-heading-blue {
    background: #428bca;
    color: white;
}

.panel-heading-red {
    background: #d9534f;
    color: white;
}

.panel-heading-green {
    background: #8bdb00;
    color: white;
}

.panel-heading a,
.panel-heading a:active,
.panel-heading a:focus,
.panel-heading a:hover {
    text-decoration: none;
}

.panel-group .panel {
    overflow: visible;
}

.panel .panel-title>a:hover {
    text-decoration: none;
}


/********************************************************************

						[8. PAGE CONTENT ]
						
*********************************************************************/

.page-title {
    padding: 0;
    font-size: 28px;
    letter-spacing: -1px;
    display: block;
    color: #666;
    margin: 0 0 15px;
    font-weight: 300;
}

.page-title small {
    font-size: 14px;
    letter-spacing: 0;
    font-weight: 300;
    color: #888;
}

.page-container-bg-solid .page-title,
.page-content-white .page-title {
    color: #666;
    margin-bottom: 15px;
    margin-top: 15px;
}

.page-container-bg-solid .page-title small,
.page-content-white .page-title small {
    color: #666;
}

.page-content-white .page-title {
    font-size: 24px;
}

.page-bar {
    padding: 0;
    background-color: #f1f4f7;
    margin-bottom: 25px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
}

.page-bar:after,
.page-bar:before {
    content: ' ';
    display: table;
}

.page-bar .page-breadcrumb {
    display: inline-block;
    float: left;
    padding: 8px;
    margin: 0;
    list-style: none;
    background-color: transparent;
    border-radius: 50px;
    background: rgba(220, 208, 208, 0.3);
    padding-left: 20px !important;
}

.page-bar .page-breadcrumb>li {
    display: inline-block;
}

.ie8 .page-bar .page-breadcrumb>li {
    margin-right: 1px;
}

.page-bar .page-breadcrumb>li>a,
.page-bar .page-breadcrumb>li>span {
    color: #888;
    font-size: 14px;
    text-shadow: none;
}

.page-bar .page-breadcrumb>li>i {
    color: #aaa;
    font-size: 14px;
    text-shadow: none;
}

.page-bar .page-breadcrumb>li>i[class*='icon-'],
.page-bar .page-breadcrumb>li>i[class^='icon-'] {
    color: gray;
}

.page-bar .page-toolbar {
    display: inline-block;
    float: right;
    padding: 0;
}

.page-title-breadcrumb .breadcrumb li+li:before {
    content: '';
    padding: 0;
}

.page-bar .page-toolbar .btn-fit-height {
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    -ms-border-radius: 0 4px 4px 0;
    -o-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
    padding-top: 8px;
    padding-bottom: 8px;
}

.page-md .page-bar .page-toolbar .btn-fit-height {
    padding-top: 9px;
    padding-bottom: 9px;
    box-shadow: none !important;
}

.page-container-bg-solid .page-bar,
.page-content-white .page-bar {
    background-color: #fff;
    position: relative;
    /* padding: 0 20px;
    margin: 50px -20px 15px; */
}

.page-container-bg-solid .page-bar .page-breadcrumb,
.page-content-white .page-bar .page-breadcrumb {
    padding: 15px 15px;
    margin-top: 10px;
}

.page-container-bg-solid .page-bar .page-breadcrumb>li>a,
.page-container-bg-solid .page-bar .page-breadcrumb>li>span,
.page-content-white .page-bar .page-breadcrumb>li>a,
.page-content-white .page-bar .page-breadcrumb>li>span {
    color: #888;
    text-decoration: none;
}

.parent-item:hover {
    color: #1ebdcc !important;
}

.page-container-bg-solid .page-bar .page-breadcrumb>li>i,
.page-content-white .page-bar .page-breadcrumb>li>i {
    color: #aaa;
}

.page-container-bg-solid .page-bar .page-breadcrumb>li>i.fa-circle,
.page-content-white .page-bar .page-breadcrumb>li>i.fa-circle {
    font-size: 5px;
    margin: 0 5px;
    position: relative;
    top: -3px;
    opacity: 0.4;
    filter: alpha(opacity=40);
}

.page-container-bg-solid .page-bar .page-breadcrumb>li>i[class*='icon-'],
.page-container-bg-solid .page-bar .page-breadcrumb>li>i[class^='icon-'],
.page-content-white .page-bar .page-breadcrumb>li>i[class*='icon-'],
.page-content-white .page-bar .page-breadcrumb>li>i[class^='icon-'] {
    color: #8c8c8c;
}

.page-container-bg-solid .page-bar .page-toolbar,
.page-content-white .page-bar .page-toolbar {
    padding: 6px 0;
}

.page-container-bg-solid .page-bar .page-toolbar .btn,
.page-content-white .page-bar .page-toolbar .btn {
    margin-top: -2px;
}

.page-container-bg-solid .page-bar .page-toolbar .btn.btn-sm,
.page-content-white .page-bar .page-toolbar .btn.btn-sm {
    margin-top: 0;
}

.page-content {
    margin-top: 0;
    padding: 0;
    background-color: #fff;
}

.page-full-width .page-content {
    margin-left: 0 !important;
}


/**************************************************************

					[9. FORM ]
					
***************************************************************/

.form {
    padding: 0 !important;
}

.form .form-body,
.form-body {
    padding: 20px;
}

.form .form-body,
.form-body {
    padding-left: 0;
    padding-right: 0;
}

.form .form-actions,
.form-actions {
    padding: 20px;
    margin: 0;
    background-color: #f5f5f5;
}

.form .form-actions,
.form-actions {
    background: 0 0;
    padding-left: 0;
    padding-right: 0;
}

.form .form-actions.nobg,
.form-actions.nobg,
.tile .tile-object {
    background-color: transparent;
}

.form .form-actions.noborder,
.form-actions.noborder {
    border-top: 0;
}

.form .form-actions,
.form-actions {
    -webkit-border-radius: 0 0 2px 2px;
    -moz-border-radius: 0 0 2px 2px;
    -ms-border-radius: 0 0 2px 2px;
    -o-border-radius: 0 0 2px 2px;
    border-radius: 0 0 2px 2px;
}

.form .form-actions:after,
.form .form-actions:before,
.form-actions:after,
.form-actions:before {
    content: ' ';
    display: table;
}

.form .form-actions:after,
.form-actions:after {
    clear: both;
}

.form .form-actions.right,
.form-actions.right {
    padding-left: 0;
    padding-right: 20px;
    text-align: right;
}

.form .form-actions.right,
.form-actions.right {
    padding-right: 0;
}

.form .form-actions.left,
.form-actions.left {
    padding-left: 20px;
    padding-right: 0;
    text-align: left;
}

.control-label {
    margin-top: 1px;
    font-weight: 400;
}

.control-label .required,
.form-group .required {
    color: #e02222;
    font-size: 12px;
    padding-left: 2px;
}

.form-inline input {
    margin-bottom: 0 !important;
}

.checkbox-list>label.checkbox-inline:first-child,
.checkbox>label,
.form-horizontal .checkbox>label,
.form .form-actions.left,
.form-actions.left,
.radio-list>label.radio-inline:first-child {
    padding-left: 0;
}

.form .form-actions.top,
.form-actions.top {
    margin-top: 0;
    margin-bottom: 20px;
    border-top: 0;
}

.form .form-actions.top,
.form-actions.top {
    background: 0 0;
}

.form .form-actions .btn-set,
.form-actions .btn-set {
    display: inline-block;
}

.form .form-section,
.form-section {
    margin: 30px 0;
    padding-bottom: 5px;
}

.form .form-bordered .form-group .help-block,
.form .form-row-seperated .form-group .help-block,
.form-horizontal .radio-list>label {
    margin-bottom: 0;
}

.form-fit .form .form-section,
.form-fit .form-section {
    margin-left: 20px;
    margin-right: 20px;
}

.checkbox,
.form-horizontal .checkbox {
    padding: 0;
}

.radio-list>label {
    display: block;
}

.form-control-static,
.input-inline,
.radio-list>label.radio-inline {
    display: inline-block;
}

.form-horizontal .radio-list .radio {
    padding-top: 1px;
}

.form-horizontal .radio>span {
    margin-top: 2px;
}

.form .form-row-seperated {
    padding: 0;
}

.form .form-row-seperated .form-group {
    margin: 0;
    border-bottom: 1px solid #efefef;
    padding: 15px 0;
}

.form .form-row-seperated .form-group.last {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 13px;
}

.form .form-row-seperated .form-body {
    padding: 0;
}

.form .form-row-seperated .form-actions {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.form .form-bordered .form-group {
    margin: 0;
    border-bottom: 1px solid #efefef;
}

.form .form-bordered .form-group>div {
    padding: 15px;
    border-left: 1px solid #efefef;
}

.form .form-bordered .form-group.last {
    border-bottom: 0;
}

.form .form-bordered .form-group .control-label {
    padding-top: 20px;
}

.form .form-bordered .form-group .form-control {
    margin: 0;
}

.form .form-bordered .form-body {
    margin: 0;
    padding: 0;
}

.form .form-bordered .form-actions {
    margin-top: 0;
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.form .form-horizontal.form-bordered.form-row-stripped .form-group:nth-child(even) {
    background-color: #fcfcfc;
}

.form .form-horizontal.form-bordered.form-row-stripped .form-control {
    background: #fff !important;
}

.form .form-horizontal.form-bordered.form-label-stripped .form-group:nth-child(even)>div {
    background-color: #fff;
}

.form .form-horizontal.form-bordered.form-label-stripped .form-group:nth-child(even) {
    background-color: #fcfcfc;
}

.form-horizontal .form-group .input-sm {
    margin-top: 3px;
}

.form-horizontal .form-group .form-control-static {
    margin-top: 1px;
}

.form-control {
    display: block;
    width: 100%;
    height: 39px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    outline: 0 !important;
    box-shadow: none !important;
}

select[multiple],
select[size],
textarea.form-control {
    height: auto;
}

select.input-sm {
    height: 30px !important;
    line-height: 30px;
}

select.form-control.form-control-sm {
    height: 30px !important;
}

.input-sm {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.input-height {
    height: 45px !important;
}

.form-control.height-auto {
    height: auto;
}

.form-horizontal .control-label.small-label {
    float: left;
    text-align: center;
}

textarea.form-control-textarea {
    float: left;
    width: 100%;
}

.input-group-addon>i {
    color: #c5cdda;
}

.form-control.white {
    border-color: #fff;
}

.form-control.white:focus {
    border-color: #e6e6e6;
}

.form-control.focus-white:focus {
    border-color: #fff;
}

.form-control.default {
    border-color: #e1e5ec;
}

.form-control.default:focus {
    border-color: #c2cad8;
}

.form-control.focus-default:focus {
    border-color: #e1e5ec;
}

.form-control.dark {
    border-color: #2f353b;
}

.form-control.dark:focus {
    border-color: #181c1f;
}

.form-control.focus-dark:focus {
    border-color: #2f353b;
}

.form-control.blue {
    border-color: #3598dc;
}

.form-control.blue:focus {
    border-color: #217ebd;
}

.form-control.focus-blue:focus {
    border-color: #3598dc;
}

.form-control.green {
    border-color: #32c5d2;
}

.form-control.green:focus {
    border-color: #26a1ab;
}

.form-control.focus-green:focus {
    border-color: #32c5d2;
}

.form-control.grey {
    border-color: #e5e5e5;
}

.form-control.grey:focus {
    border-color: #ccc;
}

.form-control.focus-grey:focus {
    border-color: #e5e5e5;
}

.form-control.red {
    border-color: #e7505a;
}

.form-control.red:focus {
    border-color: #e12330;
}

.form-control.focus-red:focus {
    border-color: #e7505a;
}

.form-control.yellow {
    border-color: #c49f47;
}

.form-control.yellow:focus {
    border-color: #a48334;
}

.form-control.focus-yellow:focus {
    border-color: #c49f47;
}

.form-control.purple {
    border-color: #8e44ad;
}

.form-control.purple:focus {
    border-color: #703688;
}

.form-control.focus-purple:focus {
    border-color: #8e44ad;
}

.form-inline .rt-chkbox {
    margin-left: 15px;
    margin-right: 15px;
}

.form-control-static {
    margin: 0;
}

.form-group .input-inline {
    margin-right: 5px;
}

.patient-img img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
    border: 1px solid #fff;
}

.form-material .form-control,
.form-material .form-control.focus,
.form-material .form-control:focus {
    background-image: linear-gradient(#9675ce, #9675ce), linear-gradient(rgba(120, 130, 140, 0.13), rgba(120, 130, 140, 0.13));
    border: 0 none;
    border-radius: 0;
    box-shadow: none;
    float: none;
}

.form-material .form-control {
    background-color: rgba(0, 0, 0, 0);
    background-position: center bottom, center calc(99%);
    background-repeat: no-repeat;
    background-size: 0 2px, 100% 1px;
    padding: 0;
    transition: background 0s ease-out 0s;
}

.form-material .form-control.focus,
.form-material .form-control:focus {
    background-size: 100% 2px, 100% 1px;
    outline: 0 none;
    transition-duration: 0.3s;
}

.fileinput .form-control {
    width: 100%;
    position: relative;
}

.fileinput .form-control {
    padding-top: 7px;
    padding-bottom: 5px;
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
    cursor: text;
}

.fileinput-new .input-group-addon {
    position: absolute;
    right: 3px;
    top: 3px;
    z-index: 10 !important;
}


/*****************************************************************

					[10. USER PANEL STYLE ]
						
******************************************************************/

.user-panel {
    float: left;
    width: 100%;
    color: #ccc;
    padding: 10px 10px 20px 10px;
}

.user-online {
    color: #26c281;
}

.user-img-circle {
    background: #fff;
    z-index: 1000;
    position: inherit;
    border: 1px solid rgba(52, 73, 94, 0.44);
    padding: 2px;
}

.txtOnline {
    color: #ccc;
    font-size: 11px;
}

.user-panel .image img {
    max-width: 100%;
}

.user-panel .image {
    width: 35%;
    max-width: 75px;
}

.user-panel .info {
    width: 50%;
    margin-left: 11px;
    margin-top: 6px;
}

.user-panel .info p {
    margin: 5px 0;
}


/*************************************************************

				[11. MODAL WINDOW ]
							
*************************************************************/

.modal {
    z-index: 10050;
    outline: 0;
    overflow-y: auto !important;
}

.modal .modal-header {
    border-bottom: 1px solid #efefef;
}

.modal .modal-header h3 {
    font-weight: 300;
}

.modal .modal-header .close {
    margin-top: 0 !important;
}

.modal .modal-dialog {
    z-index: 10051;
}

.modal>.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -22px;
    margin-left: -22px;
}

.modal.in .page-loading {
    display: none;
}

.modal-open {
    overflow-y: auto !important;
}

.modal-open-noscroll {
    overflow-y: hidden !important;
}

.modal-backdrop {
    border: 0;
    outline: 0;
}

.modal-backdrop,
.modal-backdrop.fade.in {
    background-color: #333 !important;
}

.modal.draggable-modal .modal-header {
    cursor: move;
}

.modal .modal-content {
    box-shadow: 0 8px 18px rgba(0, 0, 0, 0.18), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.note .highlight,
.note code,
.note.note-default {
    background-color: #fff;
}

body[ng-controller] .modal-backdrop {
    z-index: 10049;
}

body[ng-controller] .modal {
    z-index: 10050;
}

.modal-full.modal-dialog {
    width: 99%;
}

.modal .modal-content {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    border: 0;
}

.modal .modal-content .modal-title {
    font-weight: 400;
}

.modal .modal-content .modal-footer {
    border: 0;
}

.modal-backdrop.in {
    opacity: 0.2;
    filter: alpha(opacity=20);
}


/****************************************************************************

						[12. CHAT PANEL ]
								
*****************************************************************************/

.chat {
    margin: 0;
    padding: 0;
    margin-top: -15px;
    margin-right: 10px;
}

.chat li {
    list-style: none;
    padding: 7px 0 5px;
    margin: 7px auto;
    font-size: 14px;
    float: left;
    width: 100%;
}

.chat li img.avatar {
    height: 45px;
    width: 45px;
    float: left;
    margin-right: 10px;
    margin-top: 0px;
    border-radius: 50% !important;
}

.nice-chat li.in .message {
    background: #817cce;
    color: #fff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}

.chat li.in .message {
    text-align: left;
    margin-left: 65px;
}

.chat li .message {
    display: block;
    padding: 5px;
    position: relative;
}

.nice-chat li.in .message .arrow {
    border-right: 8px solid #817cce;
}

.chat li.in .message .arrow {
    display: block;
    position: absolute;
    top: 15px;
    left: -8px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
}

.nice-chat li.in .message a.name,
.nice-chat li.in .message .datetime {
    color: #fff;
}

.nice-chat li.in .message a.name {
    font-weight: bold;
}

.chat li .name {
    font-size: 15px;
    font-weight: 400;
}

.nice-chat li.in .message a.name,
.nice-chat li.in .message .datetime {
    color: #fff;
}

.nice-chat li.in .message .datetime {
    opacity: 0.7;
}

.chat li .datetime {
    color: #adadad;
    font-size: 13px;
    font-weight: 400;
}

.chat li .message .body {
    display: block;
}

.chat li.out img.avatar {
    float: right;
    margin-left: 10px;
    margin-top: 0px;
}

.nice-chat li.out .message {
    background: #eff0f4;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}

.nice li.out .message {
    border-right: 2px solid #eff0f4;
    margin-right: 65px;
    text-align: right;
}

.nice li .message {
    display: block;
    padding: 5px;
    position: relative;
}

.nice-chat li.out .message .arrow {
    border-left: 8px solid #eff0f4;
}

.chat li.out .message .arrow {
    display: block;
    position: absolute;
    top: 15px;
    right: -8px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #eff0f4;
}

.chat li.out img.avatar {
    float: right;
    margin-left: 10px;
    margin-top: 0px;
}

.chat li.out .message {
    border-right: 2px solid #eff0f4;
    margin-right: 65px;
    text-align: right;
}

.chat li .message {
    display: block;
    padding: 5px;
    position: relative;
}

.chat-txt-form {
    margin-top: 15px;
    padding: 10px;
    background-color: #eff0f4;
    clear: both;
}

.chat-form .form-group {
    width: 83%;
    margin-right: 2%;
    float: left;
}

.chatpane>.toggler,
.chatpane>.toggler-close {
    padding: 20px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    top: 4px;
    cursor: pointer;
}

.chatpane {
    width: 100%;
    margin-top: -13px;
    margin-right: 0;
    z-index: 100;
    float: right;
    position: relative;
}

.chatpane>.toggler {
    right: 0;
    position: absolute;
    border-radius: 4px;
}

.chatpane>.toggler:hover {
    background-color: #3f4f62 !important;
}

.chatpane>.toggler-close {
    display: block !important;
    right: 0;
    z-index: 101;
    position: absolute;
    border-radius: 4px;
}

.chatpane>.theme-options {
    top: 4px;
    right: 0;
    display: none;
    display: block !important;
    z-index: 100;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
}

.chatpane>.theme-options>.theme-option {
    color: #c6cfda;
    padding: 15px;
    margin-top: 0;
    margin-bottom: 0;
}

.chatpane>.theme-options>.theme-option>span {
    text-transform: uppercase;
    display: inline-block;
    width: 145px;
    font-size: 12px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
}

.chatpane>.theme-options>.theme-option>select.form-control {
    display: inline;
    width: 135px;
    padding: 2px;
    text-transform: lowercase;
}

.chatpane>.theme-options>.theme-option.theme-colors {
    border-top: 0;
}

.chatpane>.theme-options>.theme-option.theme-colors>span {
    display: block;
    width: auto;
}

.chatpane>.theme-options>.theme-option.theme-colors>ul {
    list-style: none;
    padding: 0;
    display: block;
    margin-bottom: 10px !important;
    margin-top: 15px;
}

.chatpane>.theme-options>.theme-option.theme-colors>ul>li {
    width: 40px;
    height: 40px;
    margin: 0 4px;
    cursor: pointer;
    list-style: none;
    float: left;
    border: 1px solid #707070;
}

.chatpane>.theme-options>.theme-option.theme-colors>ul>li:first-child {
    margin-left: 0;
}

.chatpane>.theme-options>.theme-option.theme-colors>ul>li.current,
.chatpane>.theme-options>.theme-option.theme-colors>ul>li:hover {
    border: 2px solid #d64635;
}

.chatpane>.theme-options>.theme-option.theme-colors>ul>li.color-default {
    background: #333438;
}

.page-container-bg-solid .chatpane,
.page-content-white .chatpane {
    position: absolute;
    margin-top: 30px;
    margin-right: 20px;
    right: 0;
}

.page-container-bg-solid.sidemenu-container-reversed.sidemenu-closed .chatpane,
.page-content-white.sidemenu-container-reversed.sidemenu-closed .chatpane {
    margin-right: 65px;
}

.chat-sidebar-open .page-header .top-menu .dropdown-quick-sidebar-toggler>.dropdown-toggle i:before,
.chat-sidebar-open .chat-sidebar-toggler>i:before {
    content: '';
}

.chat-sidebar-toggler {
    overflow: hidden;
    z-index: 99999;
    display: none;
    width: 28px;
    height: 27px;
    position: fixed;
    top: 10px;
    right: 15px;
    text-align: center;
    padding-top: 6px;
}

.chat-sidebar-toggler:hover {
    background: #303a43;
}

.chat-sidebar-open .chat-sidebar-toggler {
    display: inline-block;
}

.chat-sidebar-open .chat-sidebar-toggler:hover {
    background: 0 0;
}

.chat-sidebar-toggler>i {
    color: #99a8b5;
    font-size: 17px;
}

.chat-sidebar-toggler>i:hover {
    color: #fff !important;
}

.chat-sidebar-container {
    transition: right 0.3s;
    z-index: 99999;
    position: fixed;
    top: 60px;
    bottom: 0;
    width: 320px;
    right: -320px;
    overflow: hidden;
    color: #7d7b7b;
    background: #ffffff;
}

.chat-sidebar-open .chat-sidebar-container {
    transition: right 0.3s;
    right: 0;
}

.chat-sidebar-container .chat-sidebar {
    background: #ffffff;
}

.chat-sidebar-container .chat-sidebar .nav-tabs {
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.chat-sidebar-container .chat-sidebar .nav-tabs>li {
    display: table-cell !important;
    width: 1% !important;
    padding: 0;
    margin: 0;
    float: none;
}

.chat-sidebar-container .chat-sidebar .nav-tabs>li>a {
    position: relative;
    display: block;
    text-align: center;
    height: auto;
    font-size: 14px;
    padding: 10px 15px 8px;
    text-transform: uppercase;
    background: 0 0;
    margin-right: 0;
    color: #90a1af;
    border: 0;
    border-bottom: 3px solid rgba(21, 90, 214, 0.3);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    outline: 0 !important;
}

.chat-sidebar-container .chat-sidebar .nav-tabs>li>a .material-icons {
    vertical-align: middle;
}

.chat-sidebar-container .chat-sidebar .nav-tabs>li>a>.badge {
    position: absolute;
    top: 10px;
    right: 22px;
}

.chat-sidebar-container .chat-sidebar .nav-tabs>li.active>a,
.chat-sidebar-container .chat-sidebar .nav-tabs>li>a.active,
.chat-sidebar-container .chat-sidebar .nav-tabs>li:hover>a {
    border: 0;
    border-bottom: 3px solid #060bbb;
    background: 0 0;
    color: #7d7b7b;
    text-decoration: none;
}

.chat-sidebar-container .chat-sidebar .nav-tabs>li .dropdown-menu {
    border: 0;
    background: #36424c;
    box-shadow: 5px 5px rgba(97, 117, 135, 0.1);
    margin-top: 8px;
    margin-right: 20px;
}

.chat-sidebar-container .chat-sidebar .nav-tabs>li .dropdown-menu:before {
    position: absolute;
    top: -7px;
    right: 19px;
    display: inline-block !important;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #36424c;
    content: '';
}

.chat-sidebar-container .chat-sidebar .nav-tabs>li .dropdown-menu:after {
    position: absolute;
    top: -6px;
    right: 20px;
    display: inline-block !important;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    border-bottom: 7px solid #36424c;
    content: '';
}

.chat-sidebar-container .chat-sidebar .nav-tabs>li .dropdown-menu>li>a {
    padding: 10px 15px;
    color: #99a8b5;
}

.chat-sidebar-container .chat-sidebar .nav-tabs>li .dropdown-menu>li>a>i {
    color: #93a3b1;
}

.chat-sidebar-container .chat-sidebar .nav-tabs>li .dropdown-menu>li:hover>a {
    background: #3d4a55;
    color: #99a8b5;
}

.chat-sidebar-container .chat-sidebar .nav-tabs>li .dropdown-menu>li:hover>a>i {
    color: #9babb8;
}

.chat-sidebar-container .chat-sidebar .nav-tabs>li .dropdown-menu>li.active>a {
    background: #38444f;
    color: #99a8b5;
}

.chat-sidebar-container .chat-sidebar .nav-tabs>li .dropdown-menu>li.divider {
    background-color: #3d4a55;
}

.chat-sidebar-container .chat-sidebar .nav-tabs>li.open>a.dropdown-toggle {
    border-bottom: 3px solid #f3565d;
    background: 0 0;
    text-decoration: none;
    color: #90a1af;
}

.chat-sidebar-container .chat-sidebar .tab-content {
    margin: 0;
    padding: 0;
}

.chat-header {
    background: #ddd;
    padding-top: 10px;
}

.chat-sidebar-container .chat-sidebar .list-heading {
    font-size: 14px;
    margin: 0px 10px 10px 10px;
    font-family: Roboto, sans-serif;
    font-weight: 600;
    color: #00000099;
    background: transparent;
    text-align: center;
    line-height: 15px;
    padding-bottom: 14px;
    text-transform: uppercase;
    border-bottom: 1px solid #80808040;
}

.chat-sidebar-container .chat-sidebar .list-items {
    margin: 0;
    padding: 0;
    list-style: none;
}

.chat-sidebar-container .chat-sidebar .list-items>li {
    margin: 0;
    padding: 15px;
    background: #fff;
    color: #585859;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #ddd;
}

.chat-sidebar-container .chat-sidebar .list-items>li:hover {
    background: #ddd;
}

.chat-sidebar-container .chat-sidebar .list-items>li:last-child {
    border-bottom: 0;
}

.chat-sidebar-container .chat-sidebar .list-items.borderless li {
    border: 0;
    text-transform: uppercase;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 500;
}

.chat-sidebar-container .chat-sidebar .inner-content {
    margin: 0px;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-list {
    position: absolute !important;
    width: 320px !important;
    transition: margin 0.3s;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-item {
    position: absolute !important;
    width: 320px !important;
    transition: margin 0.3s;
    margin-left: 320px;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-item .page-quick-sidemenu {
    padding: 15px 10px 0;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-item .page-quick-sidemenu .chat-sidebar-back-to-list {
    vertical-align: middle;
    display: inline-block;
    font-size: 14px;
    color: #90a1af;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-item .page-quick-sidemenu .chat-sidebar-back-to-list:hover {
    text-decoration: none;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-item .page-quick-sidemenu .chat-sidebar-back-to-list>i {
    font-size: 17px;
    line-height: 17px;
    vertical-align: top;
    margin-right: 3px;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-content-item-shown .chat-sidebar-list {
    transition: margin 0.3s;
    margin-left: -320px;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-content-item-shown .chat-sidebar-item {
    transition: margin 0.3s;
    margin-left: 0;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users {
    padding: 0;
    position: relative;
    border-left: 1px solid #e9e9e9;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media {
    padding: 5px 15px 5px 15px;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media .media-object {
    border-radius: 50% !important;
    width: 35px;
    height: 35px;
    filter: alpha(opacity=80);
    float: left;
    margin-right: 10px;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media .media-object:after,
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media .media-object:before {
    content: ' ';
    display: table;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media .media-object:after {
    clear: both;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media:hover {
    cursor: pointer;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media:hover .media-object {
    opacity: 1;
    filter: alpha(opacity=100);
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media .media-body .media-heading {
    margin: 5px 0px 3px 0px;
    font-size: 14px;
    line-height: 10px;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media .media-body .media-heading-sub {
    font-size: 10px;
    text-transform: uppercase;
    color: #777;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media .media-body .media-heading-small {
    font-size: 10px;
    color: #5d7081;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media .media-status {
    margin-top: 10px;
    right: 10px;
    position: absolute;
    display: inline-block;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages {
    padding: 0 10px;
    border-left: 1px solid #e9e9e9;
    position: relative;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post {
    transition: display 0.3s;
    padding: 5px 0;
    margin: 10px auto;
    font-size: 13px;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post .body {
    color: #333;
    display: block;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post .body-out {
    color: #fff;
    display: block;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post .avatar {
    width: 45.71px;
    border-radius: 50% !important;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post.in .avatar {
    float: left;
    margin-right: 10px;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post.out .avatar {
    float: right;
    margin-left: 10px;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post .datetime,
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post .name {
    font-size: 12px;
    font-weight: 300;
    color: #3c8dbc;
    font-family: Roboto, sans-serif;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post .message {
    display: block;
    padding: 5px;
    position: relative;
    color: #90a1af;
    background: #36424c;
    border-radius: 5px;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post.in .message {
    text-align: left;
    margin-left: 55px;
    background: #eff0f4;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post.in .message .arrow {
    display: block;
    position: absolute;
    top: 9px;
    left: -6px;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right-width: 6px;
    border-right-style: solid;
    border-right-color: #eff0f4;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post.in .msg-in {
    text-align: left;
    margin-left: 55px;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post.in .msg-in .arrow {
    display: block;
    position: absolute;
    top: 9px;
    left: -6px;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right-width: 6px;
    border-right-style: solid;
    border-right-color: #36424c;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post.out .message {
    margin-right: 55px;
    text-align: right;
    background: #817cce;
}

.chat-sidebar-chat-user {
    background: #fff;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post.out .message .arrow {
    display: block;
    position: absolute;
    top: 9px;
    right: -6px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left-width: 6px;
    border-left-style: solid;
    border-left-color: #817cce;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post.out .msg-out {
    margin-right: 55px;
    text-align: right;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post.out .msg-out .arrow {
    display: block;
    position: absolute;
    top: 9px;
    right: -6px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left-width: 6px;
    border-left-style: solid;
    border-left-color: #36424c;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post.out .datetime,
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post.out .name {
    text-align: right;
    color: white;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-form {
    padding: 20px 10px 15px;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-alerts .chat-sidebar-alerts-list {
    padding: 10px 0;
    position: relative;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-settings .chat-sidebar-settings-list {
    padding: 0;
    position: relative;
    background: #ffffff;
    border-left: 1px solid #e9e9e9;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-settings .chat-sidebar-settings-list .list-items li .bootstrap-switch {
    margin-top: -3px;
    float: right;
    border: 0;
    min-width: 59px;
    border: 1px solid #ddd;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-settings .chat-sidebar-settings-list .list-items li .form-control {
    width: 75px !important;
    padding: 4px !important;
    float: right;
    border: 0;
    margin-top: -4px;
    border: 1px solid #ddd;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-settings .chat-sidebar-settings-list .list-items li select.form-control {
    padding: 4px 0 !important;
    border: 1px solid #ddd;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media .dot {
    width: 12px;
    height: 12px;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    left: 37px;
    border: 2px solid #fff;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media .online {
    background: #5cc691;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media .offline {
    background: #bfbfbf;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media .away {
    background: #ffd200;
}

.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media .busy {
    background: #ff6a6a;
}

.chat-txt-form .form-group {
    float: left;
    width: 83%;
}

.chat-sidebar-container .chat-sidebar {
    border: 1px solid #80808036;
}

.chat-box-submit {
    width: 100%;
}

.post {
    padding: 50px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.post.single {
    border-bottom: none;
}

.post:first-child {
    padding-top: 0;
}

.post .entry-title {
    font-size: 30px;
    font-size: 2.1428571429em;
    font-weight: 300;
    color: white;
}

.post .featured-image {
    margin-bottom: 20px;
}

.post .featured-image img {
    width: 100%;
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    display: block;
}

.post p {
    margin-bottom: 30px;
}

.notification-icon {
    width: 36px;
    height: 36px;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    font-size: 15px;
    color: #fff;
    line-height: 1.2;
    padding: 9px 0;
    margin-right: 5px;
}

.settings-list .setting-item {
    border-bottom: 1px solid #eee;
    padding: 15px;
    overflow: hidden;
}

.settings-list .setting-item .setting-text {
    float: left;
    font-size: 13px;
}

.settings-list .setting-item .setting-set {
    float: right;
}


/******************************************************************

					[13. CARD BOX STYLE ]
							
*********************************************************************/

.card {
    position: relative;
    margin-bottom: 24px;
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
}

.card-topline-yellow {
    border-top: 3px solid #f39c12;
}

.card-topline-aqua {
    border-top: 3px solid #00c0ef;
}

.card-topline-lightblue {
    border-top: 3px solid #3c8dbc;
}

.card-topline-green {
    border-top: 3px solid #00a65a;
}

.card-topline-red {
    border-top: 3px solid #dd4b39;
}

.card-topline-purple {
    border-top: 3px solid #ad5bff;
}

.card-topline-gray {
    border-top: 3px solid #ddd;
}

.card:before,
.card:after {
    content: ' ';
    display: table;
}

.card:after {
    clear: both;
}

.card>.nano:last-child {
    border-radius: 0 0 2px 2px;
}

.card.no-shadow {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.card-head {
    border-radius: 2px 2px 0 0;
    border-bottom: 1px dotted rgba(0, 0, 0, 0.2);
    padding: 2px;
    text-transform: uppercase;
    color: #3a405b;
    font-size: 14px;
    font-weight: bold;
    line-height: 40px;
    min-height: 40px;
}

.card-head:before,
.card-head:after {
    content: ' ';
    display: table;
}

.card-head:after {
    clear: both;
}

.card-head header {
    display: inline-block;
    padding: 11px 24px;
    vertical-align: middle;
    line-height: 17px;
    font-size: 16px;
}

.card-head header>h1,
.card-head header>h2,
.card-head header>h3,
.card-head header>h4,
.card-head header>h5,
.card-head header>h6 {
    vertical-align: middle;
    margin: 0;
    line-height: 1;
}

.card-head header small {
    color: inherit;
    opacity: 0.6;
    font-size: 75%;
}

.card-head>div {
    display: inline-block;
}

.card-head .tools {
    padding-right: 16px;
    float: right;
    margin-top: 7px;
    margin-bottom: 7px;
    margin-left: 24px;
    line-height: normal;
    vertical-align: middle;
}

.card-head .tools .btn {
    border-radius: 2px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid transparent;
    padding: 0px 2px;
    font-size: 12px;
    background: transparent;
}

.card-head .tools .btn-color {
    color: #97a0b3;
    margin-right: 3px;
    font-size: 12px;
}

.card-head .tools .btn-color:hover {
    color: black;
}

.card-head.card-head-xs header {
    font-size: 15px;
}

.card-head.card-head-sm header {
    font-size: 15px;
}

.card-head.card-head-lg header {
    font-size: 24px;
}

.card-body {
    padding: 10px 24px 24px 24px;
    position: relative;
}

.card-body:before,
.card-body:after {
    content: ' ';
    display: table;
}

.card-body:after {
    clear: both;
}

.card-body:last-child {
    border-radius: 0 0 2px 2px;
}

.card-body.table-responsive {
    margin: 0;
}

.card-actionbar {
    padding-bottom: 8px;
    position: relative;
}

.card-actionbar:before,
.card-actionbar:after {
    content: ' ';
    display: table;
}

.card-actionbar:after {
    clear: both;
}

.card-actionbar:last-child {
    border-radius: 0 0 2px 2px;
}

.card-actionbar-row {
    padding: 6px 16px;
    text-align: right;
}

.card-tiles>.row,
.card-type-blog-masonry>.row {
    margin: 0;
}

.card-tiles>.row>[class^='col-'],
.card-type-blog-masonry>.row>[class^='col-'] {
    padding: 0;
}

.card-underline .card-head {
    border-bottom: 1px solid rgba(150, 156, 156, 0.4);
}

.card-underline .card-foot {
    border-top: 1px solid rgba(150, 156, 156, 0.4);
}

.card-bordered,
.card-outlined {
    border: 2px solid #969c9c;
}

.cardbox:hover {
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
    transition: all 150ms linear;
}

.cardbox {
    background: #fff;
    min-height: 50px;
    position: relative;
    margin-bottom: 20px;
    transition: 0.5s;
    border: 1px solid #f2f2f2;
    display: inline-block;
    position: relative;
    width: 100%;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.cardbox .header {
    color: #555;
    padding: 0 0 10px 0;
    position: relative;
    border-bottom: 1px solid rgba(204, 204, 204, 0.8);
    box-shadow: none;
    margin-bottom: 0;
    margin: 15px;
    border-radius: 4px;
    padding: 15px 0;
    background-color: #fff;
}

.cardbox .header h2 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    color: #3a405b;
    text-transform: uppercase;
}

.cardbox .body {
    font-size: 15px;
    padding: 15px;
    font-weight: 400;
}

.card-box {
    background: #fff;
    min-height: 50px;
    box-shadow: none;
    position: relative;
    margin-bottom: 20px;
    transition: 0.5s;
    border: 1px solid #f2f2f2;
    border-radius: 0;
}

.card-box:hover {
    /*    box-shadow: 0 1px 5px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
    transition: all 150ms linear; */
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 20px 20px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 20px 20px rgba(0, 0, 0, 0.1);
}


/*************************************************************************

						[14. PROFILE ]
							
**************************************************************************/

.doctor-pic {
    padding: 3px;
    border: 3px solid rgb(210, 214, 222);
    border-radius: 50% 50% 50% 50%;
    max-width: 112px;
}

.doctor-profile {
    float: left;
    width: 100%;
    text-align: center;
}

.doctor-name {
    padding: 3px;
    font-weight: bold;
    font-size: 22px;
    text-align: center;
}

#biography {
    padding: 0 20px;
}

.center {
    text-align: center;
}

.white-box {
    background: #ffffff;
    padding: 25px;
    margin-top: 10px;
    margin-bottom: 15px;
}

.patient-profile {
    height: 100%;
    width: 100%;
}

.profile-sidebar {
    float: left;
    width: 350px;
    margin-right: 20px;
}

.profile-content {
    overflow: hidden;
}

.profile-userpic img {
    margin: 0px auto;
    width: 130px;
    padding: 3px;
    border: 3px solid rgb(210, 214, 222);
    border-radius: 50% 50% 50% 50%;
}

.profile-usertitle {
    text-align: center;
    margin-top: 5px;
}

.profile-usertitle-name {
    font-size: 20px;
    margin-bottom: 2px;
    font-weight: bold;
    color: #3a405b;
}

.profile-usertitle-job {
    color: #777777;
    font-size: 12px;
    margin-bottom: 5px;
}

.profile-userbuttons {
    text-align: center;
    margin-top: 10px;
}

.profile-userbuttons .btn {
    margin-right: 5px;
}

.profile-userbuttons .btn:last-child {
    margin-right: 0;
}

.profile-userbuttons button {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    padding: 6px 15px;
}

.profile-usermenu {
    margin-top: 30px;
    padding-bottom: 20px;
}

.profile-usermenu ul li {
    border-bottom: 1px solid #f0f4f7;
}

.profile-usermenu ul li:last-child {
    border-bottom: none;
}

.profile-usermenu ul li a {
    color: #93a3b5;
    font-size: 16px;
    font-weight: 400;
}

.profile-usermenu ul li a i {
    margin-right: 8px;
    font-size: 16px;
}

.profile-usermenu ul li a:hover {
    background-color: #fafcfd;
    color: #5b9bd1;
}

.profile-usermenu ul li.active a {
    color: #5b9bd1;
    background-color: #f6f9fb;
    border-left: 2px solid #5b9bd1;
    margin-left: -2px;
}

.profile-stat {
    padding-bottom: 20px;
    border-bottom: 1px solid #f0f4f7;
}

.profile-stat-title {
    color: #7f90a4;
    font-size: 25px;
    text-align: center;
}

.profile-stat-text {
    color: #5b9bd1;
    font-size: 11px;
    font-weight: 800;
    text-align: center;
}

.profile-desc-title {
    color: #7f90a4;
    font-size: 17px;
    font-weight: 600;
}

.profile-desc-text {
    color: #7e8c9e;
    font-size: 14px;
}

.profile-desc-link i {
    width: 22px;
    font-size: 19px;
    color: #abb6c4;
    margin-right: 5px;
}

.profile-desc-link a {
    font-size: 14px;
    font-weight: 600;
    color: #5b9bd1;
}

.profile-tabname {
    font-size: 12px;
    text-transform: capitalize;
    font-weight: normal;
}

.thumb-lg {
    width: 33%;
}

.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}

.blogThumb {
    padding: 4px;
    margin-bottom: 20px;
    line-height: 1.428571429;
    background-color: #fff;
    border: 1px solid #ddd;
}

.thumb-center {
    text-align: center;
}

.thumb-center img {
    display: inline-block;
}

.border-gray {
    border: 1px solid #ddd;
}

.list-group-unbordered>.list-group-item {
    border-left: 0px none;
    border-right: 0px none;
    border-radius: 0px 0px 0px 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.list-group .list-group-item {
    display: block;
}

.profile-desc {
    text-align: left;
    margin-top: 10px;
    margin-bottom: 25px;
}

.profile-desc-item {
    text-align: left;
    font-size: 14px;
    color: #6f6d6d;
    width: 100px;
}

.performance-list {
    list-style: none outside none;
    padding: 0px;
}

.performance-list li a {
    color: rgb(158, 158, 158);
    margin-bottom: 10px;
    display: block;
    text-decoration: none;
}

.performance-list li a:hover {
    color: #00c0ef;
}

.work-monitor .title {
    margin: 0 0 40px 0;
}

.work-monitor .title h3 {
    font-size: 14px;
    text-transform: uppercase;
    color: #555;
    margin: 20px 0 10px 0;
}

.work-monitor .title p {
    font-size: 14px;
    color: #b1b5b7;
}

.work-monitor .states {
    width: 90%;
}

.work-monitor .states .info {
    display: inline-block;
    width: 100%;
    font-size: 13px;
}

.work-progress .states {
    width: 100%;
}

.p-text-area,
.p-text-area:focus {
    box-shadow: none;
    color: #c3c3c3;
    font-size: 16px;
    font-weight: 300;
    border: 2px solid #f5f5f5;
}

ul.activity-list {
    list-style-type: none;
    padding: 0;
}

ul.activity-list li {
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eff0f4;
}

ul.activity-list .avatar img {
    float: left;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
}

ul.activity-list .activity-desk {
    margin-left: 70px;
}

ul.activity-list .activity-desk h5 {
    color: #2a323f;
}

ul.activity-list .activity-desk h5 a {
    font-weight: bold;
}

.activity-desk .album a {
    float: left;
    margin-right: 10px;
    width: 170px;
    height: 110px;
    overflow: hidden;
    margin-bottom: 10px;
}

.activity-desk .album a img {
    width: 100%;
}

#map-canvas {
    height: 200px;
    width: 100%;
}

.revenue-graph {
    height: 220px;
}


/**********************************************************

						[15. WIZARD ]
						
***********************************************************/

.sw-toolbar-top,
.sw-toolbar-bottom {
    flex-direction: row-reverse;
    padding-top: 15px;
}

.sw-toolbar-top .navbar-btn .btn {
    margin-left: 10px;
}

.sw-toolbar-bottom .navbar-btn .btn {
    margin-left: 10px;
}

.wizard-form .navbar-btn .btn {
    margin-left: 10px;
}

.wizard-form .form-group {
    padding-right: 20px;
}

.checkbox-list>label {
    display: block;
}

.checkbox-list>label.checkbox-inline {
    display: inline-block;
}

.sw-main .nav-tabs>li {
    width: 25%;
}

.sw-main.sw-theme-circles .nav-tabs>li {
    width: calc(25% - 40px);
}


/****************************************************************

					[16. TABLE ]
						
*****************************************************************/

.table-scrollable {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    border: 1px solid #e7ecf1;
    margin: 10px 0 !important;
}

.DTS .table-scrollable,
.table-scrollable.table-scrollable-borderless,
.table-scrollable>.table-bordered {
    border: 0;
}

.table-scrollable>.table-bordered>tbody>tr:last-child>td,
.table-scrollable>.table-bordered>tbody>tr:last-child>th,
.table-scrollable>.table-bordered>tfoot>tr:last-child>td,
.table-scrollable>.table-bordered>tfoot>tr:last-child>th,
.table-scrollable>.table-bordered>thead>tr:last-child>td,
.table-scrollable>.table-bordered>thead>tr:last-child>th,
.table.table-bordered thead>tr>th {
    border-bottom: 0;
}

.table-scrollable>.table {
    width: 100% !important;
    margin: 0 !important;
    background-color: #fff;
}

.table-scrollable>.table>tbody>tr>th,
.table-scrollable>.table>tfoot>tr>td,
.table-scrollable>.table>tfoot>tr>th,
.table-scrollable>.table>thead>tr>th {
    white-space: nowrap;
}

.table-scrollable>.table-bordered>tbody>tr>td:first-child,
.table-scrollable>.table-bordered>tbody>tr>th:first-child,
.table-scrollable>.table-bordered>tfoot>tr>td:first-child,
.table-scrollable>.table-bordered>tfoot>tr>th:first-child,
.table-scrollable>.table-bordered>thead>tr>td:first-child,
.table-scrollable>.table-bordered>thead>tr>th:first-child {
    border-left: 0;
}

.table-scrollable>.table-bordered>tbody>tr>td:last-child,
.table-scrollable>.table-bordered>tbody>tr>th:last-child,
.table-scrollable>.table-bordered>tfoot>tr>td:last-child,
.table-scrollable>.table-bordered>tfoot>tr>th:last-child,
.table-scrollable>.table-bordered>thead>tr>td:last-child,
.table-scrollable>.table-bordered>thead>tr>th:last-child {
    border-right: 0;
}

.table td,
.table th,
.card .table td,
.card .table th,
.card .dataTable td,
.card .dataTable th {
    padding: 15px 8px;
    vertical-align: middle;
}

.table td.valigntop {
    vertical-align: top;
}

.table-toolbar {
    margin-bottom: 20px;
}

.table-toolbar:after,
.table-toolbar:before {
    content: ' ';
    display: table;
}

.table-toolbar:after {
    clear: both;
}

.table.table-full-width {
    width: 100% !important;
}

.table .btn {
    margin-left: 0;
    margin-right: 5px;
}

.table thead tr th {
    font-size: 14px;
    font-weight: 600;
}

.table-advance {
    margin-bottom: 10px !important;
}

.table-advance thead {
    color: #3f444a;
}

.table-advance thead tr th {
    background-color: #f1f4f7;
    font-size: 14px;
    font-weight: 400;
    color: #3f444a;
}

.table-advance div.danger,
.table-advance div.important,
.table-advance div.info,
.table-advance div.success,
.table-advance div.warning {
    position: absolute;
    margin-top: -5px;
    float: left;
    width: 2px;
    height: 30px;
    margin-right: 20px !important;
}

.table-advance tr td {
    border-left-width: 0;
}

.table-advance tr td:first-child {
    border-left-width: 2px !important;
}

.table-advance tr td.highlight:first-child a {
    margin-left: 15px;
}

.table-advance td.highlight div.primary {
    border-left: 2px solid #337ab7;
}

.table-advance td.highlight div.success {
    border-left: 2px solid #36c6d3;
}

.table-advance td.highlight div.info {
    border-left: 2px solid #659be0;
}

.table-advance td.highlight div.warning {
    border-left: 2px solid #f1c40f;
}

.table-advance td.highlight div.danger {
    border-left: 2px solid #ed6b75;
}

.table.table-light {
    border: 0 !important;
}

.table.table-light>thead>tr:hover>th {
    background: 0 0;
}

.table.table-light>thead>tr.uppercase {
    text-transform: uppercase;
}

.table.table-light>thead>tr>th {
    font-weight: 600;
    font-size: 13px;
    color: #93a2a9;
    font-family: Roboto, sans-serif;
    border: 0;
    border-bottom: 1px solid #f2f5f8;
}

.table.table-light>tbody>tr:last-child>td {
    border: 0;
}

.table.table-light>tbody>tr>td {
    border: 0;
    border-bottom: 1px solid #f2f5f8;
    color: #8896a0;
    vertical-align: middle;
}

.table.table-light>tbody>tr>td.fit {
    width: 1px;
    padding-right: 3px;
}

.table.table-light>tbody>tr>td .user-pic {
    display: inline-block;
    vertical-align: middle;
    height: 30px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    border-radius: 100%;
}

.table.table-light.table-hover>tbody>tr:hover>td,
.table.table-light.table-hover>tbody>tr>td:hover,
.table.table-light.table-hover>tbody>tr>th:hover {
    background: #f9fafb !important;
}

.table-hover>tbody>tr:hover,
.table-hover>tbody>tr:hover>td {
    background: #f3f4f6 !important;
}

table.dataTable tbody td.patient-img {
    padding: 2px;
}

table.valign-middle tbody tr td {
    vertical-align: middle;
}


/*********************************************************************

					[17. BUTTON ]

**********************************************************************/

.btn {
    outline: 0 !important;
}

.btn:hover {
    transition: all 0.3s;
}

.btn-default,
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.btn-default.focus,
.btn-default:active,
.btn-default:focus,
.btn-default:hover,
.open>.dropdown-toggle.btn-default,
.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open>.dropdown-toggle.btn-default.focus,
.open>.dropdown-toggle.btn-default:focus,
.open>.dropdown-toggle.btn-default:hover {
    background-color: #e1e5ec !important;
    border-color: #e1e5ec !important;
}

.btn-primary {
    background-color: #188ae2 !important;
    border: 1px solid #188ae2 !important;
    color: #fff !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.focus,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.open>.dropdown-toggle.btn-primary {
    background-color: #167ccb !important;
    border: 1px solid #167ccb !important;
    color: #fff !important;
}

.btn-success {
    background-color: #4bd396 !important;
    border: 1px solid #4bd396 !important;
    color: #fff !important;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success.focus,
.btn-success:active,
.btn-success:focus,
.btn-success:hover,
.open>.dropdown-toggle.btn-success {
    background-color: #37ce8a !important;
    border: 1px solid #37ce8a !important;
    color: #fff !important;
}

.btn-info {
    background-color: #3ac9d6 !important;
    border: 1px solid #3ac9d6 !important;
    color: #fff !important;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info.focus,
.btn-info:active,
.btn-info:focus,
.btn-info:hover,
.open>.dropdown-toggle.btn-info {
    background-color: #2abfcc !important;
    border: 1px solid #2abfcc !important;
    color: #fff !important;
}

.btn-warning {
    background-color: #f9c851 !important;
    border: 1px solid #f9c851 !important;
    color: #fff !important;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning.focus,
.btn-warning:active,
.btn-warning:focus,
.btn-warning:hover,
.open>.dropdown-toggle.btn-warning {
    background-color: #f8c038 !important;
    border: 1px solid #f8c038 !important;
    color: #fff !important;
}

.btn-danger {
    background-color: #f5707a !important;
    border: 1px solid #f5707a !important;
    color: #fff !important;
}

.btn-danger:active,
.btn-danger:focus,
.btn-danger:hover,
.btn-danger.active,
.btn-danger.focus,
.btn-danger:active,
.btn-danger:focus,
.btn-danger:hover,
.open>.dropdown-toggle.btn-danger {
    background-color: #f35864 !important;
    border: 1px solid #f35864 !important;
    color: #fff !important;
}

.btn-pink {
    background-color: #ff4081 !important;
    border: 1px solid #ff4081 !important;
    color: #fff !important;
}

.btn-pink:active,
.btn-pink:focus,
.btn-pink:hover,
.btn-pink.active,
.btn-pink.focus,
.btn-pink:active,
.btn-pink:focus,
.btn-pink:hover,
.open>.dropdown-toggle.btn-pink {
    background-color: #ff4081 !important;
    border: 1px solid #ff4081 !important;
    color: #fff !important;
}


/* .btn.btn-default {
    color: #666;
    background-color: #e1e5ec;
    border-color: #e1e5ed;
}
.btn.btn-default:hover {
    color: #666;
    background-color: #c2cad8;
    border-color: #bcc5d4;
}
.btn-primary {
    background-color: #4489e4;
    border-color: #4489e4;
    color: #FFFFFF;
}
.btn-info {
    background-color: #34d3eb !important;
    border: 1px solid #34d3eb !important;
}
.btn-warning {
  background-color: #ffa91c !important;
  border: 1px solid #ffa91c !important;
}
.btn-success {
  background-color: #32c861 !important;
  border: 1px solid #32c861 !important;
}
.btn-danger {
  background-color: #f96a74 !important;
  border: 1px solid #f96a74 !important;
} */

.btn:hover,
.icon-btn:hover,
.btn-group>.btn:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.26);
}

.btn:active {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.26), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.btn:not(.btn-sm):not(.btn-lg) {}

.btn.white:not(.btn-outline) {
    color: #666;
    background-color: #fff;
    border-color: #fbfbfb;
}

.btn.white:not(.btn-outline).focus,
.btn.white:not(.btn-outline):focus {
    color: #666;
    background-color: #e6e6e6;
    border-color: #bfbfbf;
}

.btn.white:not(.btn-outline).active,
.btn.white:not(.btn-outline):active,
.btn.white:not(.btn-outline):hover,
.open>.btn.white:not(.btn-outline).dropdown-toggle {
    color: #666;
    background-color: #e6e6e6;
    border-color: #e0e0e0;
}

.btn.white:not(.btn-outline).active.focus,
.btn.white:not(.btn-outline).active:focus,
.btn.white:not(.btn-outline).active:hover,
.btn.white:not(.btn-outline):active.focus,
.btn.white:not(.btn-outline):active:focus,
.btn.white:not(.btn-outline):active:hover,
.open>.btn.white:not(.btn-outline).dropdown-toggle.focus,
.open>.btn.white:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.white:not(.btn-outline).dropdown-toggle:hover {
    color: #666;
    background-color: #d4d4d4;
    border-color: #bfbfbf;
}

.btn.white:not(.btn-outline).disabled.focus,
.btn.white:not(.btn-outline).disabled:focus,
.btn.white:not(.btn-outline).disabled:hover,
.btn.white:not(.btn-outline)[disabled].focus,
.btn.white:not(.btn-outline)[disabled]:focus,
.btn.white:not(.btn-outline)[disabled]:hover,
fieldset[disabled] .btn.white:not(.btn-outline).focus,
fieldset[disabled] .btn.white:not(.btn-outline):focus,
fieldset[disabled] .btn.white:not(.btn-outline):hover {
    background-color: #fff;
    border-color: #fbfbfb;
}

.btn.white:not(.btn-outline) .badge {
    color: #fff;
    background-color: #666;
}

.btn.btn-outline.white {
    border-color: #fff;
    color: #fff;
    background: 0 0;
}

.btn.btn-outline.white.active,
.btn.btn-outline.white:active,
.btn.btn-outline.white:active:focus,
.btn.btn-outline.white:active:hover,
.btn.btn-outline.white:focus,
.btn.btn-outline.white:hover {
    border-color: #fff;
    color: #666;
    background-color: #fbfbfb;
}

.btn.white-stripe {
    border-left: 4px solid #fff !important;
}

.btn.white.btn-no-border:not(.active) {
    border-color: transparent;
}

.btn.default:not(.btn-outline) {
    color: #666;
    background-color: #e1e5ec;
    border-color: #e1e5ed;
}

.btn.default:not(.btn-outline).focus,
.btn.default:not(.btn-outline):focus {
    color: #666;
    background-color: #c2cad8;
    border-color: #93a1bb;
}

.btn.default:not(.btn-outline).active,
.btn.default:not(.btn-outline):active,
.btn.default:not(.btn-outline):hover,
.open>.btn.default:not(.btn-outline).dropdown-toggle {
    color: #666;
    background-color: #c2cad8;
    border-color: #bcc5d4;
}

.btn.default:not(.btn-outline).active.focus,
.btn.default:not(.btn-outline).active:focus,
.btn.default:not(.btn-outline).active:hover,
.btn.default:not(.btn-outline):active.focus,
.btn.default:not(.btn-outline):active:focus,
.btn.default:not(.btn-outline):active:hover,
.open>.btn.default:not(.btn-outline).dropdown-toggle.focus,
.open>.btn.default:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.default:not(.btn-outline).dropdown-toggle:hover {
    color: #666;
    background-color: #acb7ca;
    border-color: #93a1bb;
}

.btn.default:not(.btn-outline).active,
.btn.default:not(.btn-outline):active,
.open>.btn.default:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.default:not(.btn-outline).disabled.focus,
.btn.default:not(.btn-outline).disabled:focus,
.btn.default:not(.btn-outline).disabled:hover,
.btn.default:not(.btn-outline)[disabled].focus,
.btn.default:not(.btn-outline)[disabled]:focus,
.btn.default:not(.btn-outline)[disabled]:hover,
fieldset[disabled] .btn.default:not(.btn-outline).focus,
fieldset[disabled] .btn.default:not(.btn-outline):focus,
fieldset[disabled] .btn.default:not(.btn-outline):hover {
    background-color: #e1e5ec;
    border-color: #e1e5ed;
}

.btn.default:not(.btn-outline) .badge {
    color: #e1e5ec;
    background-color: #666;
}

.btn.btn-outline.default {
    border-color: #e1e5ec;
    color: #e1e5ec;
    background: 0 0;
}

.btn.btn-outline.default.active,
.btn.btn-outline.default:active,
.btn.btn-outline.default:active:focus,
.btn.btn-outline.default:active:hover,
.btn.btn-outline.default:focus,
.btn.btn-outline.default:hover {
    border-color: #e1e5ec;
    color: #666;
    background-color: #e1e5ed;
}

.btn.default-stripe {
    border-left: 4px solid #e1e5ec !important;
}

.btn.default.btn-no-border:not(.active) {
    border-color: transparent;
}

.btn.dark:not(.btn-outline) {
    color: #fff;
    background-color: #2f353b;
    border-color: #2f353c;
}

.btn.dark:not(.btn-outline).focus,
.btn.dark:not(.btn-outline):focus {
    color: #fff;
    background-color: #181c1f;
    border-color: #000;
}

.btn.dark:not(.btn-outline).active,
.btn.dark:not(.btn-outline):active,
.btn.dark:not(.btn-outline):hover,
.open>.btn.dark:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #181c1f;
    border-color: #141619;
}

.btn.dark:not(.btn-outline).active.focus,
.btn.dark:not(.btn-outline).active:focus,
.btn.dark:not(.btn-outline).active:hover,
.btn.dark:not(.btn-outline):active.focus,
.btn.dark:not(.btn-outline):active:focus,
.btn.dark:not(.btn-outline):active:hover,
.open>.btn.dark:not(.btn-outline).dropdown-toggle.focus,
.open>.btn.dark:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.dark:not(.btn-outline).dropdown-toggle:hover {
    color: #fff;
    background-color: #090a0b;
    border-color: #000;
}

.btn.dark:not(.btn-outline).active,
.btn.dark:not(.btn-outline):active,
.open>.btn.dark:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.dark:not(.btn-outline).disabled.focus,
.btn.dark:not(.btn-outline).disabled:focus,
.btn.dark:not(.btn-outline).disabled:hover,
.btn.dark:not(.btn-outline)[disabled].focus,
.btn.dark:not(.btn-outline)[disabled]:focus,
.btn.dark:not(.btn-outline)[disabled]:hover,
fieldset[disabled] .btn.dark:not(.btn-outline).focus,
fieldset[disabled] .btn.dark:not(.btn-outline):focus,
fieldset[disabled] .btn.dark:not(.btn-outline):hover {
    background-color: #2f353b;
    border-color: #2f353c;
}

.btn.dark:not(.btn-outline) .badge {
    color: #2f353b;
    background-color: #fff;
}

.btn.btn-outline.dark {
    border-color: #2f353b;
    color: #2f353b;
    background: 0 0;
}

.btn.btn-outline.dark.active,
.btn.btn-outline.dark:active,
.btn.btn-outline.dark:active:focus,
.btn.btn-outline.dark:active:hover,
.btn.btn-outline.dark:focus,
.btn.btn-outline.dark:hover {
    border-color: #2f353b;
    color: #fff;
    background-color: #2f353c;
}

.btn.dark-stripe {
    border-left: 4px solid #2f353b !important;
}

.btn.dark.btn-no-border:not(.active) {
    border-color: transparent;
}

.btn.blue:not(.btn-outline) {
    color: #fff;
    background-color: #3598dc;
    border-color: #3598dd;
}

.btn.blue:not(.btn-outline).focus,
.btn.blue:not(.btn-outline):focus {
    color: #fff;
    background-color: #217ebd;
    border-color: #15527c;
}

.btn.blue:not(.btn-outline).active,
.btn.blue:not(.btn-outline):active,
.btn.blue:not(.btn-outline):hover,
.open>.btn.blue:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #217ebd;
    border-color: #1f78b5;
}

.btn.blue:not(.btn-outline).active.focus,
.btn.blue:not(.btn-outline).active:focus,
.btn.blue:not(.btn-outline).active:hover,
.btn.blue:not(.btn-outline):active.focus,
.btn.blue:not(.btn-outline):active:focus,
.btn.blue:not(.btn-outline):active:hover,
.open>.btn.blue:not(.btn-outline).dropdown-toggle.focus,
.open>.btn.blue:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.blue:not(.btn-outline).dropdown-toggle:hover {
    color: #fff;
    background-color: #1c699f;
    border-color: #15527c;
}

.btn.blue:not(.btn-outline).active,
.btn.blue:not(.btn-outline):active,
.open>.btn.blue:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.blue:not(.btn-outline).disabled.focus,
.btn.blue:not(.btn-outline).disabled:focus,
.btn.blue:not(.btn-outline).disabled:hover,
.btn.blue:not(.btn-outline)[disabled].focus,
.btn.blue:not(.btn-outline)[disabled]:focus,
.btn.blue:not(.btn-outline)[disabled]:hover,
fieldset[disabled] .btn.blue:not(.btn-outline).focus,
fieldset[disabled] .btn.blue:not(.btn-outline):focus,
fieldset[disabled] .btn.blue:not(.btn-outline):hover {
    background-color: #3598dc;
    border-color: #3598dd;
}

.btn.blue:not(.btn-outline) .badge {
    color: #3598dc;
    background-color: #fff;
}

.btn.btn-outline.blue {
    border-color: #3598dc;
    color: #3598dc;
    background: 0 0;
}

.btn.btn-outline.blue.active,
.btn.btn-outline.blue:active,
.btn.btn-outline.blue:active:focus,
.btn.btn-outline.blue:active:hover,
.btn.btn-outline.blue:focus,
.btn.btn-outline.blue:hover {
    border-color: #3598dc;
    color: #fff;
    background-color: #3598dd;
}

.btn.green-bgcolor:not(.btn-outline) {
    color: #fff;
    background-color: #69c2fe;
    border-color: #69c2ff;
}

.btn.green-bgcolor:not(.btn-outline).focus,
.btn.green-bgcolor:not(.btn-outline):focus {
    color: #fff;
    background-color: #26a1ab;
    border-color: #18666d;
}

.btn.green-bgcolor:not(.btn-outline).active,
.btn.green-bgcolor:not(.btn-outline):active,
.btn.green-bgcolor:not(.btn-outline):hover,
.open>.btn.green-bgcolor:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #69c2fe;
    border-color: #69c2ff;
}

.btn.green-bgcolor:not(.btn-outline).active.focus,
.btn.green-bgcolor:not(.btn-outline).active:focus,
.btn.green-bgcolor:not(.btn-outline).active:hover,
.btn.green-bgcolor:not(.btn-outline):active.focus,
.btn.green-bgcolor:not(.btn-outline):active:focus,
.btn.green-bgcolor:not(.btn-outline):active:hover,
.open>.btn.green-bgcolor:not(.btn-outline).dropdown-toggle.focus,
.open>.btn.green-bgcolor:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.green-bgcolor:not(.btn-outline).dropdown-toggle:hover {
    color: #fff;
    background-color: #1f858e;
    border-color: #18666d;
}

.btn.green-bgcolor:not(.btn-outline).active,
.btn.green-bgcolor:not(.btn-outline):active,
.open>.btn.green-bgcolor:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.green-bgcolor:not(.btn-outline).disabled.focus,
.btn.green-bgcolor:not(.btn-outline).disabled:focus,
.btn.green-bgcolor:not(.btn-outline).disabled:hover,
.btn.green-bgcolor:not(.btn-outline)[disabled].focus,
.btn.green-bgcolor:not(.btn-outline)[disabled]:focus,
.btn.green-bgcolor:not(.btn-outline)[disabled]:hover,
fieldset[disabled] .btn.green-bgcolor:not(.btn-outline).focus,
fieldset[disabled] .btn.green-bgcolor:not(.btn-outline):focus,
fieldset[disabled] .btn.green-bgcolor:not(.btn-outline):hover {
    background-color: #32c5d2;
    border-color: #32c5d3;
}

.btn.green-bgcolor:not(.btn-outline) .badge {
    color: #32c5d2;
    background-color: #fff;
}

.btn.grey:not(.btn-outline) {
    color: #333;
    background-color: #e5e5e5;
    border-color: #e5e5e6;
}

.btn.grey:not(.btn-outline).focus,
.btn.grey:not(.btn-outline):focus {
    color: #333;
    background-color: #ccc;
    border-color: #a5a5a5;
}

.btn.grey:not(.btn-outline).active,
.btn.grey:not(.btn-outline):active,
.btn.grey:not(.btn-outline):hover,
.open>.btn.grey:not(.btn-outline).dropdown-toggle {
    color: #333;
    background-color: #ccc;
    border-color: #c6c6c6;
}

.btn.grey:not(.btn-outline).active.focus,
.btn.grey:not(.btn-outline).active:focus,
.btn.grey:not(.btn-outline).active:hover,
.btn.grey:not(.btn-outline):active.focus,
.btn.grey:not(.btn-outline):active:focus,
.btn.grey:not(.btn-outline):active:hover,
.open>.btn.grey:not(.btn-outline).dropdown-toggle.focus,
.open>.btn.grey:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.grey:not(.btn-outline).dropdown-toggle:hover {
    color: #333;
    background-color: #bababa;
    border-color: #a5a5a5;
}

.btn.grey:not(.btn-outline).active,
.btn.grey:not(.btn-outline):active,
.open>.btn.grey:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.grey:not(.btn-outline).disabled.focus,
.btn.grey:not(.btn-outline).disabled:focus,
.btn.grey:not(.btn-outline).disabled:hover,
.btn.grey:not(.btn-outline)[disabled].focus,
.btn.grey:not(.btn-outline)[disabled]:focus,
.btn.grey:not(.btn-outline)[disabled]:hover,
fieldset[disabled] .btn.grey:not(.btn-outline).focus,
fieldset[disabled] .btn.grey:not(.btn-outline):focus,
fieldset[disabled] .btn.grey:not(.btn-outline):hover {
    background-color: #e5e5e5;
    border-color: #e5e5e6;
}

.btn.grey:not(.btn-outline) .badge {
    color: #e5e5e5;
    background-color: #333;
}

.btn.btn-outline.grey {
    border-color: #e5e5e5;
    color: #e5e5e5;
    background: 0 0;
}

.btn.btn-outline.grey.active,
.btn.btn-outline.grey:active,
.btn.btn-outline.grey:active:focus,
.btn.btn-outline.grey:active:hover,
.btn.btn-outline.grey:focus,
.btn.btn-outline.grey:hover {
    border-color: #e5e5e5;
    color: #333;
    background-color: #e5e5e6;
}

.btn.red:not(.btn-outline) {
    color: #fff;
    background-color: #e7505a;
    border-color: #e7505b;
}

.btn.red:not(.btn-outline).focus,
.btn.red:not(.btn-outline):focus {
    color: #fff;
    background-color: #e12330;
    border-color: #a1161f;
}

.btn.red:not(.btn-outline).active,
.btn.red:not(.btn-outline):active,
.btn.red:not(.btn-outline):hover,
.open>.btn.red:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #c71c27;
    border-color: #dc1e2b;
}

.btn.red:not(.btn-outline).active.focus,
.btn.red:not(.btn-outline).active:focus,
.btn.red:not(.btn-outline).active:hover,
.btn.red:not(.btn-outline):active.focus,
.btn.red:not(.btn-outline):active:focus,
.btn.red:not(.btn-outline):active:hover,
.open>.btn.red:not(.btn-outline).dropdown-toggle.focus,
.open>.btn.red:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.red:not(.btn-outline).dropdown-toggle:hover {
    color: #fff;
    background-color: #c51b26;
    border-color: #d82431;
}

a.btn.blue-bgcolor:hover {
    color: #fff;
}

.btn.red:not(.btn-outline).active,
.btn.red:not(.btn-outline):active,
.open>.btn.red:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.red:not(.btn-outline).disabled.focus,
.btn.red:not(.btn-outline).disabled:focus,
.btn.red:not(.btn-outline).disabled:hover,
.btn.red:not(.btn-outline)[disabled].focus,
.btn.red:not(.btn-outline)[disabled]:focus,
.btn.red:not(.btn-outline)[disabled]:hover,
fieldset[disabled] .btn.red:not(.btn-outline).focus,
fieldset[disabled] .btn.red:not(.btn-outline):focus,
fieldset[disabled] .btn.red:not(.btn-outline):hover {
    background-color: #e7505a;
    border-color: #e7505b;
}

.btn.red:not(.btn-outline) .badge {
    color: #e7505a;
    background-color: #fff;
}

.btn.btn-outline.red {
    border-color: #e7505a;
    color: #e7505a;
    background: 0 0;
}

.btn.btn-outline.red.active,
.btn.btn-outline.red:active,
.btn.btn-outline.red:active:focus,
.btn.btn-outline.red:active:hover,
.btn.btn-outline.red:focus,
.btn.btn-outline.red:hover {
    border-color: #e7505a;
    color: #fff;
    background-color: #e7505b;
}

.btn.yellow:not(.btn-outline) {
    color: #fff;
    background-color: #c49f47;
    border-color: #c49f48;
}

.btn.yellow:not(.btn-outline).focus,
.btn.yellow:not(.btn-outline):focus {
    color: #fff;
    background-color: #a48334;
    border-color: #6a5422;
}

.btn.yellow:not(.btn-outline).active,
.btn.yellow:not(.btn-outline):active,
.btn.yellow:not(.btn-outline):hover,
.open>.btn.yellow:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #a48334;
    border-color: #9c7c32;
}

.btn.yellow:not(.btn-outline).active.focus,
.btn.yellow:not(.btn-outline).active:focus,
.btn.yellow:not(.btn-outline).active:hover,
.btn.yellow:not(.btn-outline):active.focus,
.btn.yellow:not(.btn-outline):active:focus,
.btn.yellow:not(.btn-outline):active:hover,
.open>.btn.yellow:not(.btn-outline).dropdown-toggle.focus,
.open>.btn.yellow:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.yellow:not(.btn-outline).dropdown-toggle:hover {
    color: #fff;
    background-color: #896d2c;
    border-color: #6a5422;
}

.btn.yellow:not(.btn-outline).active,
.btn.yellow:not(.btn-outline):active,
.open>.btn.yellow:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.yellow:not(.btn-outline).disabled.focus,
.btn.yellow:not(.btn-outline).disabled:focus,
.btn.yellow:not(.btn-outline).disabled:hover,
.btn.yellow:not(.btn-outline)[disabled].focus,
.btn.yellow:not(.btn-outline)[disabled]:focus,
.btn.yellow:not(.btn-outline)[disabled]:hover,
fieldset[disabled] .btn.yellow:not(.btn-outline).focus,
fieldset[disabled] .btn.yellow:not(.btn-outline):focus,
fieldset[disabled] .btn.yellow:not(.btn-outline):hover {
    background-color: #c49f47;
    border-color: #c49f48;
}

.btn.yellow:not(.btn-outline) .badge {
    color: #c49f47;
    background-color: #fff;
}

.btn.btn-outline.yellow {
    border-color: #c49f47;
    color: #c49f47;
    background: 0 0;
}

.btn.btn-outline.yellow.active,
.btn.btn-outline.yellow:active,
.btn.btn-outline.yellow:active:focus,
.btn.btn-outline.yellow:active:hover,
.btn.btn-outline.yellow:focus,
.btn.btn-outline.yellow:hover {
    border-color: #c49f47;
    color: #fff;
    background-color: #c49f48;
}

.btn.purple:not(.btn-outline) {
    color: #fff;
    background-color: #8e44ad;
    border-color: #8e44ae;
}

.btn.purple:not(.btn-outline).focus,
.btn.purple:not(.btn-outline):focus {
    color: #fff;
    background-color: #703688;
    border-color: #432051;
}

.btn.purple:not(.btn-outline).active,
.btn.purple:not(.btn-outline):active,
.btn.purple:not(.btn-outline):hover,
.open>.btn.purple:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #703688;
    border-color: #6a3381;
}

.btn.purple:not(.btn-outline).active.focus,
.btn.purple:not(.btn-outline).active:focus,
.btn.purple:not(.btn-outline).active:hover,
.btn.purple:not(.btn-outline):active.focus,
.btn.purple:not(.btn-outline):active:focus,
.btn.purple:not(.btn-outline):active:hover,
.open>.btn.purple:not(.btn-outline).dropdown-toggle.focus,
.open>.btn.purple:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.purple:not(.btn-outline).dropdown-toggle:hover {
    color: #fff;
    background-color: #5b2c6f;
    border-color: #432051;
}

.btn.purple:not(.btn-outline).active,
.btn.purple:not(.btn-outline):active,
.open>.btn.purple:not(.btn-outline).dropdown-toggle {
    background-image: none;
}

.btn.purple:not(.btn-outline).disabled.focus,
.btn.purple:not(.btn-outline).disabled:focus,
.btn.purple:not(.btn-outline).disabled:hover,
.btn.purple:not(.btn-outline)[disabled].focus,
.btn.purple:not(.btn-outline)[disabled]:focus,
.btn.purple:not(.btn-outline)[disabled]:hover,
fieldset[disabled] .btn.purple:not(.btn-outline).focus,
fieldset[disabled] .btn.purple:not(.btn-outline):focus,
fieldset[disabled] .btn.purple:not(.btn-outline):hover {
    background-color: #8e44ad;
    border-color: #8e44ae;
}

.btn.purple:not(.btn-outline) .badge {
    color: #8e44ad;
    background-color: #fff;
}

.btn.btn-outline.purple {
    border-color: #8e44ad;
    color: #8e44ad;
    background: 0 0;
}

.btn.btn-outline.purple.active,
.btn.btn-outline.purple:active,
.btn.btn-outline.purple:active:focus,
.btn.btn-outline.purple:active:hover,
.btn.btn-outline.purple:focus,
.btn.btn-outline.purple:hover {
    border-color: #8e44ad;
    color: #fff;
    background-color: #8e44ae;
}

.btn.btn-outline.blue-bgcolor.active,
.btn.btn-outline.blue-bgcolor:active,
.btn.btn-outline.blue-bgcolor:active:focus,
.btn.btn-outline.blue-bgcolor:active:hover,
.btn.btn-outline.blue-bgcolor:focus,
.btn.btn-outline.blue-bgcolor:hover {
    border-color: #2196f2;
    color: #fff;
    background-color: #2196f3;
}

.btn.btn-outline.deepPink.active,
.btn.btn-outline.deepPink:active,
.btn.btn-outline.deepPink:active:focus,
.btn.btn-outline.deepPink:active:hover,
.btn.btn-outline.deepPink:focus,
.btn.btn-outline.deepPink:hover {
    border-color: #e91e63;
    color: #fff;
    background-color: #e91e63;
}

.btn-circle {
    border-radius: 25px !important;
    overflow: hidden;
    outline: 0px !important;
}

.btn-circle-right {
    border-radius: 0 25px 25px 0 !important;
}

.btn-circle-left {
    border-radius: 25px 0 0 25px !important;
}

.btn-circle-bottom {
    border-radius: 0 0 25px 25px !important;
}

.btn-circle-top {
    border-radius: 25px 25px 0 0 !important;
}

.btn-icon-only {
    height: 34px;
    width: 34px;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
}

.btn-icon-only>[class^='icon-'],
.btn-icon-only>i {
    text-align: center;
    margin-top: 2px;
}

.btn-group {
    display: inline-block;
}

.btn-group label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
}

.btn-group.btn-group-circle>.btn:first-child {
    border-radius: 25px 0 0 25px !important;
}

.btn-group.btn-group-circle>.btn:last-child {
    border-radius: 0 25px 25px 0 !important;
}

.btn-group.btn-group-devided>.btn {
    margin-right: 5px;
}

.btn-group.btn-group-devided>.btn:last-child {
    margin-right: 0;
}

.btn-group-vertical.btn-group-vertical-circle>.btn:first-child {
    border-radius: 25px 25px 0 0 !important;
}

.btn-group-vertical.btn-group-vertical-circle>.btn:last-child {
    border-radius: 0 0 25px 25px !important;
}

.btn-group .btn {
    margin-right: 0;
}

.btn-group-vertical .btn {
    margin-bottom: 0;
}

.btn-group>.dropdown-menu,
.dropdown-menu {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.22) !important;
}

.btn-group>.dropdown-menu {
    filter: alpha(opacity=0);
    margin-top: 0;
}

.btn-group.dropup>.dropdown-menu {
    margin-top: 0;
    margin-bottom: 0;
}

.btn-group.open>.dropdown-menu {
    transition: opacity 1.5s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    opacity: 1;
    filter: alpha(opacity=100);
}

.btn {
    font-size: 12px;
    transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    margin: 0 0px 10px 0;
}

.btn-chatpane .btn,
.search-form .btn {
    box-shadow: none !important;
    overflow: visible;
}

.btn.btn-link {
    box-shadow: none;
    color: #337ab7;
}

.btn.btn-link:hover {
    text-decoration: none;
}

.btn>i {
    margin-top: 0;
    margin-left: 3px;
    margin-right: 3px;
}

.btn.btn-sm {
    font-size: 11px;
    padding: 6px 18px;
}

.btn.btn-xs {
    font-size: 11px;
    padding: 3px 8px;
}

.btn.btn-lg {
    font-size: 16px;
    padding: 12px 26px 10px;
}

.btn.btn-icon-only {
    padding-left: 0;
    padding-right: 0;
}

.btn.btn-icon-only>i {
    margin-left: 0;
    margin-right: 0;
}

.btn.m-icon-big {
    padding: 9px 16px 8px;
}

.btn.m-icon-big.m-icon-only {
    padding: 9px 8px 8px 0;
}

.btn.m-icon-big [class^='m-icon-big-'] {
    margin: 0 0 0 10px;
}

.btn.m-icon-ony>i {
    margin-left: 0;
}

.btn.m-icon {
    padding: 7px 14px;
}

.btn.m-icon [class^='m-icon-'] {
    margin: 4px 0 0 5px;
}

.btn.m-icon.m-icon-only {
    padding: 7px 10px 7px 6px;
}

.btn.btn-outline.green-bgcolor {
    border-color: #32c5d2;
    color: #32c5d2;
    background: 0 0;
}

.btn.btn-outline.blue-bgcolor {
    border-color: #32c5d2;
    color: #32c5d2;
    background: 0 0;
}

.btn.btn-outline.deepPink {
    border-color: #e91e63;
    color: #e91e63;
    background: 0 0;
}

.btn.btn-outline.green-bgcolor.active,
.btn.btn-outline.green-bgcolor:active,
.btn.btn-outline.green-bgcolor:active:focus,
.btn.btn-outline.green-bgcolor:active:hover,
.btn.btn-outline.green-bgcolor:focus,
.btn.btn-outline.green-bgcolor:hover {
    border-color: #32c5d2;
    color: #fff;
    background-color: #32c5d3;
}

.btn.no-margin {
    margin: 0;
}


/************************************************************

					[18. LABEL ]
						
*************************************************************/

label {
    font-weight: 400;
}

.label {
    text-shadow: none !important;
    color: #fff;
    font-family: Roboto, sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    padding: 8px 15px;
    background: #1ebdcc;
    line-height: 1;
    text-align: center;
}

.label.label-sm {
    font-size: 13px;
    padding: 2px 5px;
}

h1 .label,
h2 .label,
h3 .label,
h4 .label,
h5 .label,
h6 .label {
    font-size: 75%;
}

.label-event {
    background-color: #dc35a9 !important;
}

.label-event[href]:focus,
.label-event[href]:hover {
    background-color: #9ca8bb !important;
}

.label-primary {
    background-color: #337ab7 !important;
}

.label-primary[href]:focus,
.label-primary[href]:hover {
    background-color: #286090 !important;
}

.label-success {
    background-color: #36c6d3 !important;
}

.label-success[href]:focus,
.label-success[href]:hover {
    background-color: #27a4b0 !important;
}

.label-info {
    background-color: #659be0 !important;
}

.label-info[href]:focus,
.label-info[href]:hover {
    background-color: #3a80d7 !important;
}

.label-warning {
    background-color: #f1c40f !important;
}

.label-warning[href]:focus,
.label-warning[href]:hover {
    background-color: #c29d0b !important;
}

.label-danger {
    background-color: #ed6b75 !important;
}

.label-danger[href]:focus,
.label-danger[href]:hover {
    background-color: #e73d4a !important;
}

.label.label-icon {
    padding: 4px 0 4px 4px;
    margin-right: 2px;
    text-align: center !important;
}

.label.label-icon>i {
    font-size: 12px;
    text-align: center !important;
}

.ie8 .label.label-icon,
.ie9 .label.label-icon {
    padding: 3px 0 3px 3px;
}

.label-menu {
    margin-right: 30px;
    font-size: 10px;
    border-radius: 60px;
    padding: 6px 8px;
    min-width: 30px;
    height: 20px;
    float: right;
}

.sidemenu-closed .label-menu {
    margin-right: -5px;
    margin-top: -5px;
}

.label.label-sm {
    font-size: 10px;
    font-weight: 600;
    padding: 6px 6px;
}

.label.label-icon {
    padding: 4px 0 4px 4px !important;
    font-size: 12px !important;
}

.note p,
.page-loading,
.panel .panel-body {
    font-size: 14px;
}

.ie8 .label.label-icon,
.ie9 .label.label-icon {
    padding: 3px 0 3px 3px !important;
}

.sub-title {
    border-bottom: 1px solid rgba(204, 204, 204, 0.35);
    padding-bottom: 10px;
    margin-bottom: 20px;
    text-transform: uppercase;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 600;
    color: #2c3e50;
}


/***************************************************************

					[19. CALENDAR ] 

****************************************************************/

.calendar-block .cal1 .clndr .clndr-controls .month {
    color: #fff;
}

.calendar-block .cal1 .clndr .clndr-controls .month {
    display: inline-block;
    width: 190px;
    text-align: center;
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    padding: 5px 5px;
    position: relative;
    top: 2px;
}

.calendar-block .cal1 .clndr .clndr-controls {
    -webkit-border-radius: 5px 5px 0px 0px;
    -moz-border-radius: 5px 5px 0px 0px;
    border-radius: 5px 5px 0px 0px;
    display: block !important;
    position: relative;
    margin-bottom: 10px;
    text-align: center;
    background: #32c5d2;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: -15px;
    padding: 45px 20px;
    width: auto !important;
    color: #fff;
}

.calendar-block .cal1 .clndr .clndr-controls .clndr-control-button .clndr-previous-button,
.calendar-block .cal1 .clndr .clndr-controls .clndr-control-button .clndr-next-button {
    color: #fff;
}

.calendar-block .cal1 .clndr .clndr-controls .month span {
    display: block;
    font-size: 30px;
    padding: 0px 10px;
    margin-bottom: 0;
}

.clndr-next-button,
.clndr-previous-button {
    position: relative;
    top: -30px;
}

.calendar-block .day-contents:hover {
    width: 30px;
    background: #32c5d2;
    cursor: pointer;
    color: #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    margin: 0 auto;
}

.today .day-contents {
    width: 30px;
    background: #32c5d2;
    cursor: pointer;
    color: #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    margin: 0 auto;
}


/***************************************************************

					[20. DROP DOWN STYLE ]

****************************************************************/

.dropdown.open>.dropdown-toggle,
.dropup.open>.dropdown-toggle {
    border-color: #ddd;
}

.dropdown-menu {
    left: auto;
    right: 0;
    min-width: 175px;
    position: absolute;
    z-index: 1000;
    display: none;
    float: left;
    list-style: none;
    text-shadow: none;
    padding: 0;
    background-color: #fff;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}

.dropdown-menu li.divider {
    background: #f1f3f6;
}

.dropdown-menu li.header {
    padding: 8px 14px 2px;
}

.dropdown-menu>li>a {
    padding: 8px 16px;
    color: #6f6f6f;
    text-decoration: none;
    display: block;
    clear: both;
    font-weight: 300;
    line-height: 18px;
    white-space: nowrap;
}

.dropdown-menu>li>a>[class*=' fa-'],
.dropdown-menu>li>a>[class^='fa-'] {
    color: #888;
}

.dropdown-menu>li>a>[class*=' icon-'],
.dropdown-menu>li>a>[class^='icon-'] {
    color: #666;
}

.dropdown-menu>li>a>[class*=' glyphicon-'],
.dropdown-menu>li>a>[class^='glyphicon-'] {
    color: #888;
}

.dropdown-menu>li.active:hover>a,
.dropdown-menu>li.active>a,
.dropdown-menu>li:hover>a {
    text-decoration: none;
    background-image: none;
    background-color: #f6f6f6;
    color: #555;
    filter: none;
}

.dropdown-menu.bottom-up {
    top: auto;
    bottom: 100%;
    margin-bottom: 2px;
}

.dropdown-menu.bottom-up:after,
.dropdown-menu.bottom-up:before {
    display: none;
}

.dropdown-menu>li:first-child:hover>a {
    border-radius: 2px 2px 0 0;
}

.dropdown-menu>li:last-child:hover>a {
    border-radius: 0 0 2px 2px;
}

.dropdown-hover:hover>.dropdown-menu {
    display: block;
}

.btn-group>.dropdown-menu.pull-left:before,
.dropdown-toggle>.dropdown-menu.pull-left:before,
.dropdown>.dropdown-menu.pull-left:before {
    left: auto;
    right: 9px;
}

.btn-group>.dropdown-menu.pull-left:after,
.dropdown-toggle>.dropdown-menu.pull-left:after,
.dropdown>.dropdown-menu.pull-left:after {
    left: auto;
    right: 10px;
}

.btn-group>.dropdown-menu.pull-right:before,
.dropdown-toggle>.dropdown-menu.pull-right:before,
.dropdown>.dropdown-menu.pull-right:before {
    left: auto;
    right: 9px;
}

.btn-group>.dropdown-menu.pull-right:after,
.dropdown-toggle>.dropdown-menu.pull-right:after,
.dropdown>.dropdown-menu.pull-right:after {
    left: auto;
    right: 10px;
}

.btn-group.dropup>.dropdown-menu,
.dropdown-toggle.dropup>.dropdown-menu,
.dropdown.dropup>.dropdown-menu {
    margin-top: 0;
    margin-bottom: 10px;
}

.btn-group.dropup>.dropdown-menu:after,
.btn-group.dropup>.dropdown-menu:before,
.dropdown-toggle.dropup>.dropdown-menu:after,
.dropdown-toggle.dropup>.dropdown-menu:before,
.dropdown.dropup>.dropdown-menu:after,
.dropdown.dropup>.dropdown-menu:before {
    display: none !important;
}

.dropdown-toggle:after {
    display: none;
}

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu>.dropdown-menu {
    top: 5px;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
}

.dropdown-submenu>a:after {
    position: absolute;
    display: inline-block;
    font-size: 14px;
    right: 7px;
    top: 7px;
    font-family: FontAwesome;
    height: auto;
    content: '\f105';
    font-weight: 300;
}

.dropdown-submenu:hover>.dropdown-menu {
    display: block;
}

.dropdown-submenu:hover>a:after {
    border-left-color: #fff;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
    left: -100%;
    margin-left: 10px;
}

.dropup .dropdown-submenu>.dropdown-menu {
    top: auto;
    bottom: 0;
    margin-top: 0;
    margin-bottom: -2px;
}

.nav.pull-right>li>.dropdown-menu,
.nav>li>.dropdown-menu.pull-right {
    right: 0;
    left: auto;
}

.nav.pull-right>li>.dropdown-menu:before,
.nav>li>.dropdown-menu.pull-right:before {
    right: 12px;
    left: auto;
}

.nav.pull-right>li>.dropdown-menu:after,
.nav>li>.dropdown-menu.pull-right:after {
    right: 13px;
    left: auto;
}

.nav.pull-right>li>.dropdown-menu .dropdown-menu,
.nav>li>.dropdown-menu.pull-right .dropdown-menu {
    right: 100%;
    left: auto;
    margin-right: -1px;
    margin-left: 0;
}

.dropdown-content {
    padding: 10px;
}

.dropdown-content form {
    margin: 0;
}

.dropdown.inline .dropdown-menu {
    display: inline-block;
    position: relative;
}

.dropdown-menu {
    border: 0 !important;
    border-radius: 2px !important;
    margin: 0;
}

.dropdown-menu:after,
.dropdown-menu:before {
    display: none !important;
}

.dropdown-menu-footer {
    padding: 10px;
    border-top: 1px solid #eff2f6;
    text-align: center;
    float: left;
    width: 100%;
}

.dropdown-menu-footer {
    padding: 10px;
    border-top: 1px solid #eff2f6;
    text-align: center;
    float: left;
    width: 100%;
    background-color: #f3f7f9;
}

.dropdown-menu-footer a {
    color: #a3afb7;
    transition: 0.5s;
}

.dropdown-menu-footer a:hover {
    color: #18b2bf;
    text-decoration: none;
}


/***************************************************************

					[21. CHECKBOX & RADIO ]
						
****************************************************************/

.rt-chkbox,
.rt-radio {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 14px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.rt-chkbox.rt-chkbox-disabled,
.rt-chkbox.rt-radio-disabled,
.rt-radio.rt-chkbox-disabled,
.rt-radio.rt-radio-disabled {
    opacity: 0.6;
    filter: alpha(opacity=60);
}

.rt-chkbox>input,
.rt-radio>input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    filter: alpha(opacity=0);
}

.rt-chkbox>span,
.rt-radio>span {
    border: 1px solid transparent;
    position: absolute;
    top: 0;
    left: 0;
    height: 19px;
    width: 19px;
    background: #e6e6e6;
}

.rt-chkbox>span:after,
.rt-radio>span:after {
    content: '';
    position: absolute;
    display: none;
}

.rt-chkbox-list .rt-chkbox,
.rt-chkbox-list .rt-radio,
.rt-chkbox>input:checked~span:after,
.rt-radio-list .rt-chkbox,
.rt-radio-list .rt-radio,
.rt-radio>input:checked~span:after {
    display: block;
}

.rt-chkbox:hover>input:not([disabled])~span,
.rt-chkbox>input:focus~span,
.rt-radio:hover>input:not([disabled])~span,
.rt-radio>input:focus~span {
    background: #d9d9d9;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.rt-chkbox:hover>input:not([disabled]):checked~span,
.rt-chkbox>input:checked~span,
.rt-radio:hover>input:not([disabled]):checked~span,
.rt-radio>input:checked~span {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    background: #d9d9d9;
}

.rt-chkbox.rt-chkbox-outline:hover>input:not([disabled]):checked~span,
.rt-chkbox.rt-chkbox-outline:hover>input:not([disabled])~span,
.rt-chkbox.rt-chkbox-outline>input:checked~span,
.rt-chkbox.rt-chkbox-outline>input:focus~span,
.rt-chkbox.rt-chkbox-outline>span,
.rt-chkbox.rt-radio-outline:hover>input:not([disabled]):checked~span,
.rt-chkbox.rt-radio-outline:hover>input:not([disabled])~span,
.rt-chkbox.rt-radio-outline>input:checked~span,
.rt-chkbox.rt-radio-outline>input:focus~span,
.rt-chkbox.rt-radio-outline>span,
.rt-radio.rt-chkbox-outline:hover>input:not([disabled]):checked~span,
.rt-radio.rt-chkbox-outline:hover>input:not([disabled])~span,
.rt-radio.rt-chkbox-outline>input:checked~span,
.rt-radio.rt-chkbox-outline>input:focus~span,
.rt-radio.rt-chkbox-outline>span,
.rt-radio.rt-radio-outline:hover>input:not([disabled]):checked~span,
.rt-radio.rt-radio-outline:hover>input:not([disabled])~span,
.rt-radio.rt-radio-outline>input:checked~span,
.rt-radio.rt-radio-outline>input:focus~span,
.rt-radio.rt-radio-outline>span {
    border: 1px solid #d9d9d9;
    background: 0 0;
}

.rt-chkbox>span:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #666;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.rt-chkbox>input:disabled~span:after {
    border-color: #666;
}

.rt-chkbox-list,
.rt-radio-list {
    padding: 10px 0;
}

.form-horizontal .form-group .rt-chkbox-list,
.form-horizontal .form-group .rt-radio-list {
    padding-top: 0;
}

.rt-chkbox-inline,
.rt-radio-inline {
    padding: 10px 0;
}

.form-horizontal .form-group .rt-chkbox-inline,
.form-horizontal .form-group .rt-radio-inline {
    padding-top: 8px;
}

.rt-chkbox-inline .rt-chkbox,
.rt-chkbox-inline .rt-radio,
.rt-radio-inline .rt-chkbox,
.rt-radio-inline .rt-radio {
    display: inline-block;
    margin-right: 15px;
}

.rt-chkbox-inline .rt-chkbox:last-child,
.rt-chkbox-inline .rt-radio:last-child,
.rt-radio-inline .rt-chkbox:last-child,
.rt-radio-inline .rt-radio:last-child {
    margin-right: 0;
}

td>.rt-chkbox.rt-chkbox-single,
td>.rt-radio.rt-radio-single,
th>.rt-chkbox.rt-chkbox-single,
th>.rt-radio.rt-radio-single {
    right: -5px;
}

.rt-radio>span,
.timeline .timeline-badge-userpic img {
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
}

.rt-radio>span {
    -ms-border-radius: 50% !important;
    -o-border-radius: 50% !important;
    border-radius: 50% !important;
}

.rt-radio>span:after {
    left: 6px;
    top: 6px;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: #666;
}

.rt-radio>input:disabled~span:after {
    background: #666;
}


/**********************************************************

					[22. ICON BUTTON ]
					
***********************************************************/

.icon-btn {
    height: 60px;
    min-width: 80px;
    margin: 5px 5px 0 0;
    padding: 12px 0 0;
    background-color: #fafafa;
    background-image: none;
    display: inline-block;
    color: #646464;
    text-shadow: none;
    text-align: center;
    cursor: pointer;
    position: relative;
}

.icon-btn,
.icon-btn:hover {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.icon-btn,
.ie8 .icon-btn:hover {
    filter: none;
}

.loading-message,
.page-loading {
    min-width: 125px;
    vertical-align: middle;
}

.icon-btn:hover {
    border-color: #999;
    color: #444;
    text-shadow: 0 1px 0 #fff;
    transition: all 0.3s ease;
    text-decoration: none;
}

.icon-btn:hover>.badge {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.icon-btn>.badge {
    position: absolute;
    font-size: 11px;
    font-weight: 300;
    top: -5px;
    right: -5px;
    padding: 3px 6px;
    color: #fff;
    text-shadow: none;
    border-width: 0;
    border-style: solid;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
}

.icon-btn>i {
    font-size: 18px;
}

.icon-btn {
    border: 0;
    transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

.icon-btn>div {
    margin-top: 5px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 11px;
    color: #565d66;
}


/************************************************************

			[23. VALIDATION ICONS ]
						
*************************************************************/

.input-icon {
    position: relative;
    left: 0;
}

.input-icon>.form-control {
    padding-left: 33px;
}

.input-group .input-icon>.form-control {
    border-radius: 2px 0 0 2px;
}

.input-group .input-icon.right>.form-control,
.tabs-right.nav-tabs>li>a:focus,
.tabs-right.nav-tabs>li>a:hover {
    -webkit-border-radius: 0 2px 2px 0;
    -moz-border-radius: 0 2px 2px 0;
    -ms-border-radius: 0 2px 2px 0;
    -o-border-radius: 0 2px 2px 0;
}

.input-icon>i {
    color: #ccc;
    display: block;
    position: absolute;
    margin: 11px 2px 4px 10px;
    z-index: 3;
    width: 16px;
    font-size: 16px;
    text-align: center;
    left: 0;
}

.modal .input-icon>i {
    z-index: 10055;
}

.has-success .input-icon>i {
    color: #36c6d3;
}

.has-warning .input-icon>i {
    color: #f1c40f;
}

.has-error .input-icon>i {
    color: #ed6b75;
}

.input-icon.right {
    left: auto;
    right: 0;
}

.input-icon.right>.form-control {
    padding-right: 33px;
    padding-left: 12px;
}

.input-group .input-icon.right>.form-control {
    border-radius: 0 2px 2px 0;
}

.input-icon.right>i {
    left: auto;
    right: 8px;
    float: right;
}

.input-icon.input-icon-lg>i {
    margin-top: 16px;
}

.input-icon.input-icon-sm>i {
    margin-top: 8px;
    font-size: 13px;
}


/****************************************************************

				[24. NOTE ]
						
*****************************************************************/

.note {
    margin: 0 0 20px;
    padding: 15px 30px 15px 15px;
}

.note h1,
.note h2,
.note h3,
.note h4,
.note h5,
.note h6 {
    margin-top: 0;
}

.note h1 .close,
.note h2 .close,
.note h3 .close,
.note h4 .close,
.note h5 .close,
.note h6 .close {
    margin-right: -10px;
}

.note p {
    margin: 0;
}

.note p:last-child {
    margin-bottom: 0;
}

.note.note-default {
    border-color: #b0c1d2;
    color: #000;
}

.note.note-default.note-bordered {
    background-color: #eef1f5;
    border-color: #c0cedb;
}

.note.note-default.note-shadow {
    background-color: #f1f4f7;
    border-color: #d1dbe4;
    box-shadow: 5px 5px rgba(212, 221, 230, 0.2);
}

.note.note-success {
    background-color: #c0edf1;
    border-color: #58d0da;
    color: #000;
}

.note.note-success.note-bordered {
    background-color: #a7e6ec;
    border-color: #6dd6df;
}

.note.note-success.note-shadow {
    background-color: #abe7ed;
    border-color: #81dbe3;
    box-shadow: 5px 5px rgba(134, 221, 228, 0.2);
}

.note.note-info {
    background-color: #f5f8fd;
    border-color: #8bb4e7;
    color: #010407;
}

.note.note-info.note-bordered {
    background-color: #dbe8f8;
    border-color: #a0c2ec;
}

.note.note-info.note-shadow {
    background-color: #e0ebf9;
    border-color: #b5cff0;
    box-shadow: 5px 5px rgba(185, 210, 241, 0.2);
}

.note.note-warning {
    background-color: #faeaa9;
    border-color: #f3cc31;
    color: #000;
}

.note.note-warning.note-bordered {
    background-color: #f8e38c;
    border-color: #f4d249;
}

.note.note-warning.note-shadow {
    background-color: #f9e491;
    border-color: #f6d861;
    box-shadow: 5px 5px rgba(246, 217, 102, 0.2);
}

.note.note-danger {
    background-color: #fef7f8;
    border-color: #f0868e;
    color: #210406;
}

.note.note-danger.note-bordered {
    background-color: #fbdcde;
    border-color: #f39da3;
}

.note.note-danger.note-shadow {
    background-color: #fbe1e3;
    border-color: #f6b3b8;
    box-shadow: 5px 5px rgba(246, 184, 189, 0.2);
}

.note {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    border: 0;
}


/****************************************************************

				[25. PAGINATION ]
						
*****************************************************************/

.pagination {
    margin: 10px 0;
}

.pagination.pagination-circle>li:first-child>a {
    border-radius: 25px 0 0 25px !important;
}

.pagination.pagination-circle>li:last-child>a {
    border-radius: 0 25px 25px 0 !important;
}


/****************************************************************

				[26. ACCORDION ]
						
*****************************************************************/

.accordion .card-header:after {
    font-family: 'FontAwesome';
    content: '\f068';
    float: right;
}

.accordion .card-header.collapsed:after {
    /* symbol for "collapsed" panels */
    content: '\f067';
}


/****************************************************************

					[27. TAB ]	
						
*****************************************************************/

.tab-pane>p:last-child {
    margin-bottom: 0;
}

.tabs-reversed>li {
    float: right;
    margin-right: 0;
}

.tabs-reversed>li>a {
    margin-right: 0;
}

.customtab.nav-tabs .nav-link.active,
.customtab.nav-tabs .nav-link:hover,
.customtab.nav-tabs .nav-link.active:focus {
    border-bottom: 0px solid #36c6d3;
    background-color: rgb(102, 115, 252) !important;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgb(102, 115, 252);
    color: #fff;
    border-radius: 30px;
}

.customtab.nav-tabs .nav-link {
    border-left: 0;
    border-top: 0;
    border-right: 0;
}

.btmBorder {
    border-bottom: 0px !important;
}

.customtab {
    border-bottom: 2px solid #f7fafc;
}

nav-tabs>li {
    float: left;
    margin-bottom: -1px;
}

.nav-tabs>li {
    float: left;
    margin-bottom: -1px;
}

.nav-pills>li>a,
.nav-tabs>li>a {
    font-size: 14px;
    -webkit-border-radius: 2px 2px 0 0;
    -moz-border-radius: 2px 2px 0 0;
    -ms-border-radius: 2px 2px 0 0;
    -o-border-radius: 2px 2px 0 0;
    position: relative;
    display: block;
    padding: 10px 15px;
    border-radius: 2px 2px 0 0;
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
}

.nav-tabs>li a.active,
.nav-tabs>li a.active:focus,
.nav-tabs>li a.active:hover {
    color: #555;
    cursor: default;
    background-color: #fff;
    border-bottom-color: transparent;
}


/****************************************************************

					[28. STATE OVERVIEW ]
						
*****************************************************************/

.state-overview h1.sbold,
.state-overview p.sbold {
    float: left;
}

.state-overview p {
    float: left;
    width: 100%;
}

.state-overview .purple {
    background: #a979d1;
    color: #fff;
}

.state-overview .orange {
    background: #e67d21;
    color: #fff;
}

.state-overview .blue {
    background: #55acef;
    color: #fff;
}

.state-overview .blue {
    background: #55acef;
    color: #fff;
}

.state-overview .green {
    background: #15a087;
    color: #fff;
}

.state-overview .symbol {
    width: 30%;
    padding: 50px 15px;
}

.state-overview .symbol,
.state-overview .value {
    display: inline-block;
    text-align: center;
}

.state-overview .symbol i {
    font-size: 25px;
}

.fa-send:before,
.fa-paper-plane:before {
    content: '\f1d8';
}

.state-overview .value.white {
    border-color: rgba(255, 255, 255, 0.2);
    text-align: left;
}

.state-overview .value {
    width: 68%;
    margin-top: 30px;
    border-left: 1px solid;
    padding-left: 10%;
}

.state-overview .value {
    float: right;
    text-align: left;
}

.state-overview .symbol,
.state-overview .value {
    display: inline-block;
    text-align: center;
}

.state-overview .value h1 {
    font-weight: 300;
}

.state-overview .value h1,
.state-overview .value p {
    margin: 0;
    padding: 0;
}

.state-overview .value h1,
.state-overview .value p {
    margin: 0;
    padding-bottom: 15px;
}

.bg-blue {
    background-color: #389af0;
}

.info-box {
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    min-height: 100px;
    background: #fff;
    width: 100%;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 15px;
}

.info-box small {
    font-size: 14px;
}

.info-box .progress {
    background: rgba(0, 0, 0, 0.2);
    margin: 5px -10px 5px 0;
    height: 2px;
}

.info-box .progress,
.info-box .progress .progress-bar {
    border-radius: 0;
}

.info-box .progress .progress-bar {
    background: #fff;
}

.info-box-icon {
    float: left;
    height: 70px;
    width: 70px;
    text-align: center;
    font-size: 30px;
    line-height: 74px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 100%;
}

.info-box-icon.push-bottom {
    margin-top: 20px;
}

.info-box-icon>img {
    max-width: 100%;
}

.info-box-content {
    padding: 10px 10px 10px 0;
    margin-left: 90px;
}

.info-box-number {
    font-weight: 300;
    font-size: 21px;
}

.info-box-text,
.progress-description {
    display: block;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
}

.progress-description {
    margin: 0;
}


/********************************************************************

					[29. BORDER BOX ]
							
*********************************************************************/

.borderBox.light.bordered {
    border: 1px solid #e7ecf1 !important;
}

.borderBox.light {
    padding: 12px 20px 15px;
    background-color: #fff;
}

.borderBox {
    margin-top: 0;
    margin-bottom: 25px;
    padding: 0;
    border-radius: 2px;
    box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.03);
}

.borderBox.light.bordered>.borderBox-title {
    border-bottom: 1px solid #eef1f5;
}

.borderBox.light>.borderBox-title {
    padding: 0;
    min-height: 48px;
}

.borderBox.bordered>.borderBox-title {
    border-bottom: 0;
}

.borderBox>.borderBox-title {
    border-bottom: 1px solid #eee;
    padding: 0;
    margin-bottom: 10px;
    min-height: 41px;
    -webkit-border-radius: 2px 2px 0 0;
    -moz-border-radius: 2px 2px 0 0;
    -ms-border-radius: 2px 2px 0 0;
    -o-border-radius: 2px 2px 0 0;
    border-radius: 2px 2px 0 0;
}

.borderBox>.borderBox-title:after,
.borderBox>.borderBox-title:before {
    content: ' ';
    display: table;
}

.borderBox.light>.borderBox-title>.caption {
    color: #666;
    padding: 10px 0;
}

.borderBox>.borderBox-title>.caption {
    float: left;
    display: inline-block;
    font-size: 18px;
    line-height: 18px;
    padding: 10px 0;
}

.borderBox.light>.borderBox-title>.caption>.caption-subject {
    font-size: 16px;
}

.borderBox>.borderBox-title>.nav-tabs {
    background: 0 0;
    margin: 1px 0 0;
    float: right;
    display: inline-block;
    border: 0;
}

.borderBox.light>.borderBox-title>.nav-tabs>li {
    margin: 0;
    padding: 0;
}

.borderBox>.borderBox-title>.nav-tabs>li {
    background: 0 0;
    margin: 0;
}

.borderBox.light>.borderBox-title>.nav-tabs>li>a {
    margin: 0;
    padding: 12px 13px 13px;
    font-size: 13px;
    color: #666;
}

.borderBox>.borderBox-title>.nav-tabs>li>a {
    background: 0 0;
    margin: 5px 0 0 1px;
    border: 0;
    padding: 8px 10px;
    color: #fff;
}

.borderBox.light>.borderBox-title>.nav-tabs>li.active>a,
.borderBox.light>.borderBox-title>.nav-tabs>li:hover>a {
    margin: 0;
    background: 0 0;
    color: #333;
}

.borderBox.light>.borderBox-title>.nav-tabs>li>a {
    margin: 0;
    padding: 12px 13px 13px;
    font-size: 13px;
    color: #666;
}


/***************************************************************

				[30. RATING BUTTON ]

****************************************************************/

.rating {
    unicode-bidi: bidi-override;
    direction: rtl;
}

.rating span.star,
.rating span.star {
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    font-size: 22px;
}

.rating span.star:before,
.rating span.star:before {
    content: '\f006';
    padding-right: 5px;
    color: #bec3c7;
}

.rating span.star:hover,
.rating span.star:hover {
    cursor: pointer;
}

.rating span.star,
.rating span.star {
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    font-size: 22px;
}

.rating span.star:hover:before,
.rating span.star:hover:before,
.rating span.star:hover~span.star:before,
.rating span.star:hover~span.star:before {
    content: '\f005';
    color: #986cbc;
}

.rating span.star:before,
.rating span.star:before {
    content: '\f006';
    padding-right: 5px;
    color: #bec3c7;
}


/*********************************************************************

					[31. TO DO LIST ]
							
**********************************************************************/

.todo-title {
    margin-right: 25px;
    padding-top: 5px;
}

.to-do-list {
    padding-left: 0;
    margin-top: -10px;
    font-size: 12px;
    min-height: 311px;
    float: left;
    width: 100%;
}

.to-do-list li {
    padding: 17px 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    position: relative;
    cursor: move;
    list-style: none;
    font-size: 14px;
    background: #fff;
    border-bottom: 1px dotted rgba(0, 0, 0, 0.2);
}

.to-do-list li p {
    margin: 0px;
}

.todo-actionlist {
    position: absolute;
    right: -5px;
    top: 22px;
}

.todo-actionlist a {
    height: 24px;
    width: 24px;
    display: inline-block;
    float: left;
}

.todo-actionlist a i {
    height: 24px;
    width: 24px;
    display: inline-block;
    text-align: center;
    line-height: 24px;
    color: #ccc;
}

.todo-actionlist a:hover i {
    color: #666;
}

.todo-done i {
    font-size: 14px;
}

.todo-remove i {
    font-size: 10px;
}

.line-through {
    text-decoration: line-through;
}

.todo-action-bar {
    margin-top: 20px;
}

.drag-marker {
    height: 17px;
    display: block;
    float: left;
    width: 7px;
    position: relative;
    top: 6px;
}

.drag-marker i {
    height: 2px;
    width: 2px;
    display: block;
    background: #ccc;
    box-shadow: 5px 0 0 0px #ccc, 0px 5px 0 0px #ccc, 5px 5px 0 0px #ccc, 0px 10px 0 0px #ccc, 5px 10px 0 0px #ccc, 0px 15px 0 0px #ccc, 5px 15px 0 0px #ccc;
    -webkit-box-shadow: 5px 0 0 0px #ccc, 0px 5px 0 0px #ccc, 5px 5px 0 0px #ccc, 0px 10px 0 0px #ccc, 5px 10px 0 0px #ccc, 0px 15px 0 0px #ccc, 5px 15px 0 0px #ccc;
    -moz-box-shadow: 5px 0 0 0px #ccc, 0px 5px 0 0px #ccc, 5px 5px 0 0px #ccc, 0px 10px 0 0px #ccc, 5px 10px 0 0px #ccc, 0px 15px 0 0px #ccc, 5px 15px 0 0px #ccc;
}

.to-do-list li .todo-check input[type='checkbox'] {
    visibility: hidden;
}

.inbox-small-cells .todo-check input[type='checkbox'] {
    visibility: hidden;
}

.todo-check input[type='checkbox'] {
    visibility: hidden;
}

.todo-check {
    width: 20px;
    position: relative;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 5px;
}

.todo-check label {
    cursor: pointer;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    border: #ccc 1px solid;
}

.todo-check label:after {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    filter: alpha(opacity=0);
    opacity: 0;
    content: '';
    position: absolute;
    width: 13px;
    height: 8px;
    background: transparent;
    top: 3px;
    left: 3px;
    border: 3px solid #cfcfcf;
    border-top: none;
    border-right: none;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.todo-checklabel:hover::after {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=30)';
    filter: alpha(opacity=30);
    opacity: 0.3;
}

.todo-check input[type='checkbox']:checked+label:after {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
    filter: alpha(opacity=100);
    opacity: 1;
}

.todo-entry {
    float: left;
    width: 88%;
}

.StrikeThrough {
    text-decoration: line-through;
}


/***********************************************************************

						[32. BADGE ]
						
***********************************************************************/

.badge {
    font-size: 11px !important;
    height: 18px;
    color: #fff;
    -webkit-border-radius: 12px !important;
    -moz-border-radius: 12px !important;
    border-radius: 12px !important;
    text-shadow: none !important;
    text-align: center;
}

.list-group-item>.badge {
    float: right;
    margin-left: 5px;
}

.badge.badge-roundless {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}

.badge.badge-empty {
    display: inline-block;
    padding: 0;
    min-width: 8px;
    height: 8px;
    width: 8px;
}

.badge-default {
    background-color: #bac3d0;
}

.badge-primary {
    background-color: #337ab7;
}

.badge-info {
    background-color: #659be0;
}

.badge-success {
    background-color: #36c6d3;
}

.badge-danger {
    background-color: #ed6b75;
}

.badge-warning {
    background-color: #f1c40f;
}

.nav.nav-pills>li>a>.badge,
.nav.nav-stacked>li>a>.badge {
    margin-top: -2px;
}

.dropdown-menu>li>a>.badge {
    position: absolute;
    margin-top: 1px;
    right: 3px;
    display: inline;
    padding: 4px 6px 2px;
}

.dropdown-menu.badge-roundless {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}

.badge {
    padding: 4px 6px 2px;
}


/****************************************************************************

						[33. SCROLL TO TOP ]
								
*****************************************************************************/

.scroll-to-top {
    padding: 11px;
    border-radius: 4px;
    background: #1c262f;
    text-align: center;
    position: fixed;
    bottom: 10px;
    z-index: 10001;
    display: none;
    right: 10px;
}

.scroll-to-top>i {
    display: inline-block;
    color: #ffffff;
    font-size: 20px;
    opacity: 0.6;
    filter: alpha(opacity=60);
}

.scroll-to-top:hover {
    cursor: pointer;
}

.scroll-to-top:hover>i {
    opacity: 1;
    filter: alpha(opacity=100);
}


/*********************************************************************

					[34. FOOTER ]

**********************************************************************/

.page-footer .page-footer-inner {
    color: #98a6ba;
}

.page-footer-fixed .page-footer {
    background-color: #28303b;
}

.page-footer-fixed.page-footer-fixed-mobile .page-container {
    margin-bottom: 20px !important;
}

.page-footer {
    position: relative;
    bottom: -5px;
    width: 100%;
    padding: 20px 5px;
    font-size: 13px;
    background-color: #222c3c;
}

.page-footer:after,
.page-footer:before {
    content: ' ';
    display: table;
}

.page-footer .page-footer-inner {
    float: left;
    width: 100%;
    text-align: center;
}

.page-footer-fixed.page-footer-fixed-mobile .page-footer {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 10000;
    bottom: 0;
}

.page-footer-fixed.page-footer-fixed-mobile.sidemenu-container-fixed .page-footer {
    margin-left: 0 !important;
}

.mat-card:not([class*='mat-elevation-z']) {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}


/*********************************************************************

					[35. MODALS ]

**********************************************************************/

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    font-size: 1.5rem !important;
    font-weight: 300;
}

.modal-sm {
    max-width: 300px;
}

.modal-lg {
    max-width: 800px;
}

.modal-xl {
    max-width: 1250px;
}

.modal .modal-fluid {
    width: 100%;
    max-width: 100%;
}

.modal.show .modal-dialog {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}

.modal .modal-dialog.modal-top-right {
    top: 10px;
    right: 10px;
}

.modal .modal-side {
    position: absolute;
    bottom: 10px;
    right: 10px;
    margin: 0;
    width: 400px;
}

.modal .modal-dialog.modal-top-left {
    top: 10px;
    left: 10px;
}

.modal .modal-dialog.modal-bottom-right {
    bottom: 10px;
    right: 10px;
}

.modal .modal-dialog.modal-bottom-left {
    bottom: 10px;
    left: 10px;
}

#import-dept-service-label::after {
    content: 'File';
}


/*
 *  Document   : extra_pages.css
 *  Author     : RedStar Theme
 *  Description: this style sheet for extra page like login, logout, page_404, page_500 etc.. 
 
 			[Table of contents]
 			
 			1. BODY 
 			2. FORM TITLE
 			3. LOGIN FORM
 			4. CUSTOM CHECKBOX
 			5. PAGE 404
 			6. LOCK SCREEN
 */

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body,
html {
    height: auto;
}


/*---------------------------------------------*/

a {
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
}

a:focus {
    outline: none !important;
}

a:hover {
    text-decoration: none;
    color: #fff;
}


/*---------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
}

p {
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
}

ul,
li {
    margin: 0px;
    list-style-type: none;
}


/*---------------------------------------------*/

input {
    outline: none;
    border: none;
}

textarea {
    outline: none;
    border: none;
}


/* textarea:focus, input:focus {
    border-color: transparent !important;
  }
  
  input:focus::-webkit-input-placeholder { color:transparent; }
  input:focus:-moz-placeholder { color:transparent; }
  input:focus::-moz-placeholder { color:transparent; }
  input:focus:-ms-input-placeholder { color:transparent; }
  
  textarea:focus::-webkit-input-placeholder { color:transparent; }
  textarea:focus:-moz-placeholder { color:transparent; }
  textarea:focus::-moz-placeholder { color:transparent; }
  textarea:focus:-ms-input-placeholder { color:transparent; }
  
  input::-webkit-input-placeholder { color: #fff;}
  input:-moz-placeholder { color: #fff;}
  input::-moz-placeholder { color: #fff;}
  input:-ms-input-placeholder { color: #fff;}
  
  textarea::-webkit-input-placeholder { color: #fff;}
  textarea:-moz-placeholder { color: #fff;}
  textarea::-moz-placeholder { color: #fff;}
  textarea:-ms-input-placeholder { color: #fff;} */

label {
    margin: 0;
    display: block;
}


/*---------------------------------------------*/

button {
    outline: none !important;
    border: none;
    background: transparent;
}

button:hover {
    cursor: pointer;
}

iframe {
    border: none !important;
}


/*//////////////////////////////////////////////////////////////////
  [ Utility ]*/

.txt1 {
    font-size: 13px;
    color: #e5e5e5;
    line-height: 1.5;
}


/*//////////////////////////////////////////////////////////////////
  [ login ]*/

.limiter {
    width: 100%;
    margin: 0 auto;
}

.container-login100 {
    width: 100%;
    min-height: 93vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 1;
}

.page-background {
    background-image: url('../../img/bg-01.jpg');
}

.container-login100::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
}

.wrap-login101 {
    width: 500px;
    border-radius: 10px;
    overflow: hidden;
    padding: 37px 55px 37px 55px;
    background: -webkit-linear-gradient(top, red, #5992d0);
}


/*------------------------------------------------------------------
  [ Form ]*/

.login100-form {
    width: 100%;
}

.login100-form-logo {
    font-size: 60px;
    color: #333333;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #2f94a5;
    margin: 0 auto;
}

.login100-form-title {
    font-size: 30px;
    color: #fff;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
    display: block;
}

.login100-form-logo img {
    width: 125px;
    /*border-radius: 50%;
      box-shadow: 0px 5px 25px 0px rgba(0,0,0,0.2);*/
}

.login-logo img {
    width: 80px;
    border-radius: 50%;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
}


/*------------------------------------------------------------------
  [ Input ]*/

.wrap-input100 {
    width: 100%;
    position: relative;
    border-bottom: 2px solid rgba(255, 255, 255, 0.24);
    margin-bottom: 30px;
}

.input101 {
    font-size: 16px;
    color: #fff;
    line-height: 1.2;
    display: block;
    width: 100%;
    height: 45px;
    background: transparent;
    padding: 0 5px 0 38px;
}


/*---------------------------------------------*/

.focus-input101 {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
}

.focus-input101::before {
    content: '';
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
    background: #fff;
}

.focus-input101::after {
    font-family: Material-Design-Iconic-Font;
    font-size: 22px;
    color: #fff;
    content: attr(data-placeholder);
    display: block;
    width: 100%;
    position: absolute;
    top: 6px;
    left: 0px;
    padding-left: 5px;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.input101:focus {
    padding-left: 5px;
}

.input101:focus+.focus-input101::after {
    top: -22px;
    font-size: 18px;
}

.input101:focus+.focus-input101::before {
    width: 100%;
}

.has-val.input101+.focus-input101::after {
    top: -22px;
    font-size: 18px;
}

.has-val.input101+.focus-input101::before {
    width: 100%;
}

.has-val.input101 {
    padding-left: 5px;
}


/*==================================================================
  [ Restyle Checkbox ]*/

.contact100-form-checkbox {
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 35px;
}

.input-checkbox100 {
    display: none;
}

.label-checkbox100 {
    font-size: 13px;
    color: #fff;
    line-height: 1.2;
    display: block;
    position: relative;
    padding-left: 26px;
    cursor: pointer;
}

.input-checkbox100:checked+.label-checkbox100::before {
    color: #007bff;
}

.label-checkbox100::before {
    content: '\f26b';
    font-family: Material-Design-Iconic-Font;
    font-size: 13px;
    color: transparent;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    background: #fff;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.input-checkbox100:checked+.label-checkbox100::before {
    color: #007bff;
    font-size: 20px;
}


/*------------------------------------------------------------------
  [ Button ]*/

.container-login100-form-btn {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.login100-form-btn {
    font-size: 16px;
    color: #555555;
    line-height: 1.2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    min-width: 120px;
    height: 50px;
    border-radius: 25px;
    background: #9152f8;
    background: -webkit-linear-gradient(to top, #ecedf1, #e6e1e8);
    background: -o-linear-gradient(to top, #ecedf1, #e6e1e8);
    background: -moz-linear-gradient(to top, #ecedf1, #e6e1e8);
    background: linear-gradient(to top, #ecedf1, #e6e1e8);
    position: relative;
    z-index: 1;
    box-shadow: 0 3px 10px 0px rgba(213, 208, 214, 0.5);
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login100-form-btn::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    background-color: #fff;
    top: 0;
    left: 0;
    opacity: 1;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login100-form-btn:hover {
    color: #443f3f;
}

.login100-form-btn:hover:before {
    opacity: 0;
}


/*------------------------------------------------------------------
  [ Responsive ]*/

@media (max-width: 576px) {
    .wrap-login101 {
        padding: 55px 15px 37px 15px;
    }
}


/*------------------------------------------------------------------
  [ Alert validate ]*/

.validate-input {
    position: relative;
}

.alert-validate::before {
    content: attr(data-validate);
    position: absolute;
    max-width: 70%;
    background-color: #fff;
    border: 1px solid #c80000;
    border-radius: 2px;
    padding: 4px 25px 4px 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0px;
    pointer-events: none;
    color: #c80000;
    font-size: 13px;
    line-height: 1.4;
    text-align: left;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 0.4s;
    -o-transition: opacity 0.4s;
    -moz-transition: opacity 0.4s;
    transition: opacity 0.4s;
}

.alert-validate::after {
    content: '\f12a';
    font-family: FontAwesome;
    font-size: 16px;
    color: #c80000;
    display: block;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 5px;
}

.alert-validate:hover:before {
    visibility: visible;
    opacity: 1;
}

.p-b-34 {
    padding-bottom: 34px;
}

.p-t-27 {
    padding-top: 27px;
}

.p-b-20 {
    padding-bottom: 20px;
}

.p-t-90 {
    padding-top: 90px;
}

.p-t-50 {
    padding-top: 50px;
}

.p-t-30 {
    padding-top: 30px;
}

.form-404 {
    text-align: center;
}

.content-404 {
    color: #fff;
    font-size: 14px;
    padding: 30px;
}

.form404-title {
    font-size: 50px;
    color: #fff;
}

.txt-locked {
    color: #fff;
    font-size: 18px;
    padding: 10px 0px;
}

.txt-small-heading {
    color: #fff;
    font-size: 18px;
    padding: 15px 0px;
}

@media (max-width: 992px) {
    .alert-validate::before {
        visibility: visible;
        opacity: 1;
    }
}


/*.select3-container {
    float: right !important;
    margin-left: 10px;
    max-width: 200px;
    min-width:120px;
}*/

.select3-container--default .select3-selection--single .select3-selection__rendered {
    height: 31.59px;
}

.custom-checkbox {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
}

.btnAddPLAN,
.btnREMOVEPLAN {
    margin-top: 5px;
}

.hidden {
    display: none;
}

li {
    list-style-type: none;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.table {
    width: 100% !important;
}

.modal-editor .select3-container {
    width: 100% !important;
    margin: 0;
}

.runApp header {
    display: none;
}

@media (min-width: 576px) {
    .runApp .content--full {
        padding: 30px 30px 0;
    }
}

@media (max-width: 767px) {
    .runApp .content--full {
        padding: 15px 30px 0;
    }
}

.custom-control-description {
    color: #fff;
}

.table-responsive {
    min-height: 0.01%;
    overflow-x: auto;
}

.dataTables_wrapper_basic {
    display: block !important;
}

.relative {
    position: relative !important;
}

.colorpicker {
    z-index: 9999 !important;
}

#ulMenu .header-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 0.9rem;
    padding: 0.45rem 1rem;
    background-color: rgba(255, 255, 255, 0.06);
    transition: background-color 0.3s;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
}

.select3-container {
    float: right !important;
    margin-right: 10px;
    max-width: 200px;
}

.select3-container .select3-selection--single {
    height: 34px;
}

.select3-container--default .select3-selection--single .select3-selection__rendered {
    line-height: 34px;
}

.select3-container--default .select3-selection--single {
    border-radius: 0;
    border: 0;
    background-color: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    height: auto;
}

.select3-container .select3-selection--single .select3-selection__rendered {
    display: block;
    padding-left: 8px;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.select3-container--default .select3-selection--single .select3-selection__arrow {
    height: 32px;
}

.select3-container--default .select3-selection--single .select3-selection__rendered {
    color: #444;
    line-height: 28px;
}

.select3-container--default .select3-selection--single .select3-selection__rendered {
    line-height: 1.25;
    padding: 0.6rem 1px;
    color: rgba(255, 255, 255, 0.85);
}

.select3-container--default .select3-selection--single .select3-selection__rendered {
    height: 31.59px;
}

.select3-container--default .select3-selection--single {
    border-radius: 0;
    border: 0;
    background-color: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    height: auto;
}

.select3-container--default .select3-selection--single:before {
    content: '';
    position: absolute;
    pointer-events: none;
    z-index: 1;
    right: 1px;
    bottom: 5px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 8px 8px;
    border-color: transparent transparent #d1d1d1;
}

.select3-container--default .select3-selection--single .select3-selection__placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.select3-container--default .select3-selection--single .select3-selection__rendered {
    line-height: 1.25;
    padding: 0.6rem 1px;
    color: rgba(255, 255, 255, 0.85);
}

.select3-container--default.select3-container--focus .select3-selection--multiple {
    border: 0;
}

.select3-container--default .select3-selection--multiple {
    background-color: transparent;
    border: 0;
    box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.2);
    border-radius: 0;
    padding-bottom: 1px;
}

.select3-container--default .select3-selection--multiple .select3-selection__choice {
    border-radius: 2px;
    border: 0;
    background-color: rgba(255, 255, 255, 0.08);
    padding: 0.4rem 0.8rem;
    color: rgba(255, 255, 255, 0.85);
}

.select3-container--default .select3-selection--multiple .select3-selection__choice__remove {
    margin-right: 0.5rem;
    color: rgba(255, 255, 255, 0.85);
}

.select3-container--default .select3-selection--multiple .select3-selection__choice__remove:hover {
    color: rgba(255, 255, 255, 0.85);
    opacity: 0.75;
}

.select3-container--default .select3-selection--multiple .select3-selection__rendered {
    padding: 0 1px;
}

.select3-container--default.select3-container--disabled .select3-selection--single {
    background-color: transparent;
    opacity: 0.5;
}

.select3-dropdown {
    background-color: rgba(0, 0, 0, 0.96);
    border: 0;
    border-radius: 2px;
    padding: 0.8rem 0;
    box-shadow: 0 4px 18px rgba(0, 0, 0, 0.5);
    z-index: 99;
    margin-top: -1px;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    animation-duration: 0.3s;
    animation-fill-mode: both;
}

.select3-dropdown .select3-results__option {
    padding: 0.65rem 1.5rem;
}

.select3-dropdown .select3-results__option--highlighted[aria-selected] {
    background-color: rgba(255, 255, 255, 0.06);
    color: #fff;
}

.select3-dropdown .select3-results__option[aria-disabled='true'] {
    color: rgba(255, 255, 255, 0.85);
    opacity: 0.25;
}

.select3-dropdown .select3-results__option[aria-selected='true'] {
    position: relative;
    padding-right: 1.5rem;
    background-color: rgba(255, 255, 255, 0.06);
    color: #fff;
}

.select3-dropdown .select3-results__option[aria-selected='true']:before {
    font-family: Material-Design-Iconic-Font;
    content: '';
    position: absolute;
    top: 0.45rem;
    right: 1.5rem;
    font-size: 1.3rem;
    color: #fff;
}

.select3-dropdown .select3-search--dropdown {
    margin-top: -0.5rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    position: relative;
    margin-bottom: 1rem;
}

.select3-dropdown .select3-search--dropdown:before {
    font-family: Material-Design-Iconic-Font;
    content: '';
    font-size: 1.5rem;
    color: rgba(255, 255, 255, 0.85);
    position: absolute;
    left: 1.4rem;
    top: 0.5rem;
}

.select3-dropdown .select3-search--dropdown .select3-search__field {
    border: 0;
    background-color: transparent;
    height: 2.8rem;
    padding-left: 3.5rem;
}

.select3-search__field {
    color: rgba(255, 255, 255, 0.85);
}

.select3-search__field::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.select3-search__field:-moz-placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.select3-search__field::-moz-placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.select3-search__field:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
}

span.select3-container.select3-container--open {
    z-index: 10050;
}

div.dataTables_wrapper div.dataTables_processing {
    margin-top: 20px;
}

.listview__attrs>span {
    position: relative;
}

.btnRemoveDoctor {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    line-height: 21px;
}

@media (min-width: 576px) {
    .modal-xl {
        max-width: 70%;
    }
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/iconfont/MaterialIcons-Regular.eot');
    /* For IE6-8 */
    src: local('Material Icons'), local('MaterialIcons-Regular'), url('../fonts/iconfont/MaterialIcons-Regular.woff2') format('woff2'), url('../fonts/iconfont/MaterialIcons-Regular.woff') format('woff'), url('../fonts/iconfont/MaterialIcons-Regular.ttf') format('truetype');
}


/*
   *  Document   : theme-color.css
   *  Author     : RedStar Theme
   *  Description: stylesheet for change theme color, user can set any custom color for template, User have to just replace three classes
       (header-white white-sidebar-color logo-indigo) with (custom-sidebar-color logo-custom header-custom) classes in template <body> tag 
       and set bellow variable color as per desire. Thats It !!!!!!
   */

:root {
    --sidebar-color: #ffffff;
    --sidebar-font-color: #4680ff;
    --logo-color: #222c3c;
    --header-color: #6673fc;
}


/*
    --white-color: #ffffff;
    --dark-color: #222c3c;
    --blue-color: #1880c9;
    --indigo-color: #6673FC;
    --cyan-color: #4ABAD2;
    --green-color: #3FCC7E;
    --red-color: #E44F56;
  */


/* Custom Theme Color */

.custom-sidebar-color .sidemenu-container {
    background-color: var(--sidebar-color);
}

.custom-sidebar-color .sidemenu-container .sidemenu>li.active.open>a,
.custom-sidebar-color .sidemenu-container .sidemenu>li.active>a,
.custom-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a,
.custom-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a {
    background-color: #ffffff;
    border-top-color: transparent;
    color: var(--sidebar-font-color);
}

.custom-sidebar-color .sidemenu-container .sidemenu>li>a,
.custom-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a {
    color: #555;
    border-bottom: none;
    background-color: var(--sidebar-color);
}

.custom-sidebar-color .user-panel {
    color: #444;
}

.custom-sidebar-color .txtOnline {
    color: #444;
}

.custom-sidebar-color .sidemenu-container .sidemenu>li.open>a>.arrow.open:before,
.custom-sidebar-color .sidemenu-container .sidemenu>li.open>a>.arrow:before,
.custom-sidebar-color .sidemenu-container .sidemenu>li.open>a>i,
.custom-sidebar-color .sidemenu-container .sidemenu>li:hover>a>.arrow.open:before,
.custom-sidebar-color .sidemenu-container .sidemenu>li:hover>a>.arrow:before,
.custom-sidebar-color .sidemenu-container .sidemenu>li:hover>a>i,
.custom-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>.arrow.open:before,
.custom-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>.arrow:before,
.custom-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>i,
.custom-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>.arrow.open:before,
.custom-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>.arrow:before,
.custom-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>i {
    color: var(--sidebar-font-color);
}

.custom-sidebar-color .sidemenu-container .sidemenu .sub-menu,
.custom-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu {
    background-color: #f5f5f5;
}

.custom-sidebar-color .sidemenu-container .sidemenu .sub-menu>li>a,
.custom-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li>a {
    color: #444;
}

.custom-sidebar-color .sidemenu-container .sidemenu .sub-menu>li.active>a,
.custom-sidebar-color .sidemenu-container .sidemenu .sub-menu>li.open>a,
.custom-sidebar-color .sidemenu-container .sidemenu .sub-menu>li:hover>a,
.custom-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.active>a,
.custom-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.open>a,
.custom-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li:hover>a {
    color: var(--sidebar-font-color);
}

.custom-sidebar-color .page-container {
    background-color: #ffffff;
}

.selector-title {
    margin-top: 0px !important;
}

.custom-sidebar-color .sidemenu-hover-submenu li:hover a>.arrow {
    border-right: 8px solid var(--sidebar-font-color);
}

.custom-sidebar-color .sidemenu-hover-submenu li:hover>.sub-menu {
    background-color: #f5f5f5;
}

.logo-custom .page-header.navbar .page-logo {
    background: var(--logo-color);
}

.header-custom .page-header.navbar {
    background-color: var(--header-color);
}

.header-custom form.search-form-opened .btn.submit {
    color: #fff;
}

.header-custom .search-form-opened input[type='text'] {
    color: #fff;
}

.header-custom form.search-form-opened {
    background-color: rgba(255, 255, 255, 0.3);
    border: 0;
}

.header-custom .page-header .search-form-opened .input-group .form-control::-moz-placeholder {
    color: #efefef;
    opacity: 1;
}

.header-custom .page-header .search-form-opened .input-group .form-control:-ms-input-placeholder {
    color: #efefef;
    opacity: 1;
}

.header-custom .page-header .search-form-opened .input-group .form-control::-webkit-input-placeholder {
    color: #efefef;
    opacity: 1;
}

.header-custom .page-header.navbar .top-menu .navbar-nav>li.dropdown-language>.dropdown-toggle>.langname,
.header-custom .page-header.navbar .top-menu .navbar-nav>li.dropdown-user>.dropdown-toggle>.username,
.header-custom .page-header.navbar .top-menu .navbar-nav>li.dropdown-user>.dropdown-toggle>i {
    color: #fff;
}

.header-custom .page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-toggle>i {
    color: #fff;
}

.header-custom .language-switch a {
    color: #fff;
}

.header-custom .menu-toggler i.icon-menu {
    color: #ffffff;
    font-size: 18px;
}

.header-custom .page-header.navbar .hor-menu .navbar-nav>li.active>a,
.header-custom .page-header.navbar .hor-menu .navbar-nav>li.current>a {
    background-color: transparent;
}

.header-custom .dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover {
    background-color: transparent;
}

.header-custom .dropdown-menu>li.active:hover>a,
.header-custom .dropdown-menu>li.active>a,
.header-custom .dropdown-menu>li:hover>a {
    background-color: transparent;
}

.header-custom .language-switch a.dropdown-toggle {
    color: #fff;
}


/* White sidebar color */

.white-sidebar-color .sidemenu-container {
    background-color: #ffffff;
}

.white-sidebar-color .sidemenu-container .sidemenu>li.active.open>a,
.white-sidebar-color .sidemenu-container .sidemenu>li.active>a,
.white-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a,
.white-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a {
    background-color: #ffffff;
    border-top-color: transparent;
    color: #4680ff;
}


/* .white-sidebar-color .sidemenu-container .sidemenu>li>a{
      color: #555;
      border-bottom:none;
      background-color: #ffffff;
  } */

.white-sidebar-color .sidemenu-container .sidemenu>li.open>a,
.white-sidebar-color .sidemenu-container .sidemenu>li:hover>a,
.white-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a,
.white-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a {
    background-color: #ffffff;
    border-top-color: transparent;
    color: #4680ff;
}

.white-sidebar-color .user-panel,
.white-sidebar-color .txtOnline,
.white-sidebar-color .sidemenu-container .sidemenu>li>a,
.white-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a {
    color: #444;
}

.white-sidebar-color .sidemenu-container .sidemenu>li.open>a>.arrow.open:before,
.white-sidebar-color .sidemenu-container .sidemenu>li.open>a>.arrow:before,
.white-sidebar-color .sidemenu-container .sidemenu>li.open>a>i,
.white-sidebar-color .sidemenu-container .sidemenu>li:hover>a>.arrow.open:before,
.white-sidebar-color .sidemenu-container .sidemenu>li:hover>a>.arrow:before,
.white-sidebar-color .sidemenu-container .sidemenu>li:hover>a>i,
.white-sidebar-color .sidemenu-container .sidemenu .sub-menu>li.open>a>i,
.white-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>.arrow.open:before,
.white-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>.arrow:before,
.white-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>i,
.white-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>.arrow.open:before,
.white-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>.arrow:before,
.white-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>i {
    color: #4680ff;
}

.white-sidebar-color .sidemenu-container .sidemenu .sub-menu>li>a>.arrow.open:before,
.white-sidebar-color .sidemenu-container .sidemenu .sub-menu>li>a>.arrow:before,
.white-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li>a>.arrow.open:before,
.white-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li>a>.arrow:before {
    color: #000000;
}

.white-sidebar-color .sidemenu-container .sidemenu .sub-menu>li>a>i {
    color: #000000;
}

.white-sidebar-color .sidemenu-container .sidemenu .sub-menu,
.white-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu {
    background-color: #f5f5f5;
}

.white-sidebar-color .sidemenu-container .sidemenu .sub-menu>li>a,
.white-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li>a {
    color: #444;
}

.white-sidebar-color .sidemenu-container .sidemenu .sub-menu>li.active>a,
.white-sidebar-color .sidemenu-container .sidemenu .sub-menu>li.open>a,
.white-sidebar-color .sidemenu-container .sidemenu .sub-menu>li:hover>a,
.white-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.active>a,
.white-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.open>a,
.white-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li:hover>a {
    color: #4680ff;
}

.white-sidebar-color .page-container {
    background-color: #ffffff;
}

.selector-title {
    margin-top: 0px !important;
}

.white-sidebar-color .sidemenu-hover-submenu li:hover a>.arrow {
    border-right: 8px solid #4680ff;
}

.white-sidebar-color .sidemenu-hover-submenu li:hover>.sub-menu {
    background-color: #f5f5f5;
}

.white-sidebar-color .sidemenu-container .sidemenu>li.active>a>i,
.white-sidebar-color .sidemenu-container .sidemenu li.active>a>.arrow.open:before,
.white-sidebar-color .sidemenu-container .sidemenu li.active>a>.arrow:before,
.white-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu li.active>a>.arrow.open:before,
.white-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu li.active>a>.arrow:before {
    color: #4680ff;
}

.white-sidebar-color .sidemenu-container .sidemenu .sub-menu>li:hover>a>i {
    color: #4680ff;
}


/* Dark sidebar color */

.dark-sidebar-color .sidemenu-container {
    background-color: #222c3c;
}

.dark-sidebar-color .sidemenu-container .sidemenu>li.active.open>a,
.dark-sidebar-color .sidemenu-container .sidemenu>li.active>a,
.dark-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a,
.dark-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a {
    background-color: #1e293c;
    border-top-color: transparent;
    color: #fff;
}

.dark-sidebar-color .sidemenu-container .sidemenu>li>a,
.dark-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a {
    color: #b7c0cd;
    border-bottom: none;
}

.dark-sidebar-color .user-panel {
    color: #ccc;
}

.dark-sidebar-color .txtOnline {
    color: #ccc;
}

.dark-sidebar-color .sidemenu-container .sidemenu>li.open>a,
.dark-sidebar-color .sidemenu-container .sidemenu>li:hover>a,
.dark-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a,
.dark-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a {
    background-color: #222c3c;
    color: #fff;
}

.dark-sidebar-color .sidemenu-container .sidemenu>li.open>a>.arrow.open:before,
.dark-sidebar-color .sidemenu-container .sidemenu>li.open>a>.arrow:before,
.dark-sidebar-color .sidemenu-container .sidemenu>li.open>a>i,
.dark-sidebar-color .sidemenu-container .sidemenu>li:hover>a>.arrow.open:before,
.dark-sidebar-color .sidemenu-container .sidemenu>li:hover>a>.arrow:before,
.dark-sidebar-color .sidemenu-container .sidemenu>li:hover>a>i,
.dark-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>.arrow.open:before,
.dark-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>.arrow:before,
.dark-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>i,
.dark-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>.arrow.open:before,
.dark-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>.arrow:before,
.dark-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>i {
    color: #fff;
}

.dark-sidebar-color .sidemenu-container .sidemenu .sub-menu,
.dark-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu {
    background-color: #1e293c;
}

.dark-sidebar-color .sidemenu-container .sidemenu .sub-menu>li>a,
.dark-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li>a {
    color: rgba(255, 255, 255, 0.5);
}

.dark-sidebar-color .sidemenu-container .sidemenu .sub-menu>li.active>a,
.dark-sidebar-color .sidemenu-container .sidemenu .sub-menu>li.open>a,
.dark-sidebar-color .sidemenu-container .sidemenu .sub-menu>li:hover>a,
.dark-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.active>a,
.dark-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.open>a,
.dark-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li:hover>a {
    color: #fff;
}

.dark-sidebar-color .sidemenu-container .sidemenu>li.active.open>a>.arrow.open:before,
.dark-sidebar-color .sidemenu-container .sidemenu>li.active.open>a>.arrow:before,
.dark-sidebar-color .sidemenu-container .sidemenu>li.active.open>a>i,
.dark-sidebar-color .sidemenu-container .sidemenu>li.active>a>.arrow.open:before,
.dark-sidebar-color .sidemenu-container .sidemenu>li.active>a>.arrow:before,
.dark-sidebar-color .sidemenu-container .sidemenu>li.active>a>i,
.dark-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a>.arrow.open:before,
.dark-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a>.arrow:before,
.dark-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a>i,
.dark-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>.arrow.open:before,
.dark-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>.arrow:before,
.dark-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>i {
    color: #fff;
}

.dark-sidebar-color .page-container {
    background-color: #222c3c;
}

.dark-sidebar-color .sidemenu-hover-submenu li:hover a>.arrow {
    border-right: 8px solid #fff;
}


/* Blue sidebar color */

.blue-sidebar-color .sidemenu-container {
    background-color: #1880c9;
}

.blue-sidebar-color .sidemenu-container .sidemenu>li.active.open>a,
.blue-sidebar-color .sidemenu-container .sidemenu>li.active>a,
.blue-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a,
.blue-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a {
    background-color: #186fad;
    border-top-color: transparent;
    color: #fff;
}

.blue-sidebar-color .sidemenu-container .sidemenu>li>a,
.blue-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a {
    color: rgba(255, 255, 255, 0.8);
    border-bottom: none;
}

.blue-sidebar-color .user-panel {
    color: #fff;
}

.blue-sidebar-color .txtOnline {
    color: #fff;
}

.blue-sidebar-color .sidemenu-container .sidemenu>li.open>a,
.blue-sidebar-color .sidemenu-container .sidemenu>li:hover>a,
.blue-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a,
.blue-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a {
    background-color: #1880c9;
    color: #fff;
}

.blue-sidebar-color .sidemenu-container .sidemenu>li.open>a>.arrow.open:before,
.blue-sidebar-color .sidemenu-container .sidemenu>li.open>a>.arrow:before,
.blue-sidebar-color .sidemenu-container .sidemenu>li.open>a>i,
.blue-sidebar-color .sidemenu-container .sidemenu>li:hover>a>.arrow.open:before,
.blue-sidebar-color .sidemenu-container .sidemenu>li:hover>a>.arrow:before,
.blue-sidebar-color .sidemenu-container .sidemenu>li:hover>a>i,
.blue-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>.arrow.open:before,
.blue-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>.arrow:before,
.blue-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>i,
.blue-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>.arrow.open:before,
.blue-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>.arrow:before,
.blue-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>i {
    color: #fff;
}

.blue-sidebar-color .sidemenu-container .sidemenu .sub-menu,
.blue-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu {
    background-color: #186fad;
}

.blue-sidebar-color .sidemenu-container .sidemenu .sub-menu>li>a,
.blue-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li>a {
    color: rgba(255, 255, 255, 0.8);
}

.blue-sidebar-color .sidemenu-container .sidemenu .sub-menu>li.active>a,
.blue-sidebar-color .sidemenu-container .sidemenu .sub-menu>li.open>a,
.blue-sidebar-color .sidemenu-container .sidemenu .sub-menu>li:hover>a,
.blue-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.active>a,
.blue-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.open>a,
.blue-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li:hover>a {
    color: #fff;
}

.blue-sidebar-color .sidemenu-container .sidemenu>li.active.open>a>.arrow.open:before,
.blue-sidebar-color .sidemenu-container .sidemenu>li.active.open>a>.arrow:before,
.blue-sidebar-color .sidemenu-container .sidemenu>li.active.open>a>i,
.blue-sidebar-color .sidemenu-container .sidemenu>li.active>a>.arrow.open:before,
.blue-sidebar-color .sidemenu-container .sidemenu>li.active>a>.arrow:before,
.blue-sidebar-color .sidemenu-container .sidemenu>li.active>a>i,
.blue-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a>.arrow.open:before,
.blue-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a>.arrow:before,
.blue-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a>i,
.blue-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>.arrow.open:before,
.blue-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>.arrow:before,
.blue-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>i {
    color: #fff;
}

.blue-sidebar-color .page-container {
    background-color: #1880c9;
}

.blue-sidebar-color .sidemenu-container .sidemenu>li.heading>h3,
.blue-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.heading>h3 {
    color: #fff;
}

.blue-sidebar-color .sidemenu-hover-submenu li:hover a>.arrow {
    border-right: 8px solid #fff;
}


/* Indigo sidebar color */

.indigo-sidebar-color .sidemenu-container {
    background-color: #6673fc;
}

.indigo-sidebar-color .sidemenu-container .sidemenu>li.active.open>a,
.indigo-sidebar-color .sidemenu-container .sidemenu>li.active>a,
.indigo-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a,
.indigo-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a {
    background-color: #545ed6;
    border-top-color: transparent;
    color: #fff;
}

.indigo-sidebar-color .sidemenu-container .sidemenu>li>a,
.indigo-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a {
    color: rgba(255, 255, 255, 0.8);
    border-bottom: none;
}

.indigo-sidebar-color .user-panel {
    color: #fff;
}

.indigo-sidebar-color .txtOnline {
    color: #fff;
}

.indigo-sidebar-color .sidemenu-container .sidemenu>li.open>a,
.indigo-sidebar-color .sidemenu-container .sidemenu>li:hover>a,
.indigo-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a,
.indigo-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a {
    background-color: #6673fc;
    color: #fff;
}

.indigo-sidebar-color .sidemenu-container .sidemenu>li.open>a>.arrow.open:before,
.indigo-sidebar-color .sidemenu-container .sidemenu>li.open>a>.arrow:before,
.indigo-sidebar-color .sidemenu-container .sidemenu>li.open>a>i,
.indigo-sidebar-color .sidemenu-container .sidemenu>li:hover>a>.arrow.open:before,
.indigo-sidebar-color .sidemenu-container .sidemenu>li:hover>a>.arrow:before,
.indigo-sidebar-color .sidemenu-container .sidemenu>li:hover>a>i,
.indigo-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>.arrow.open:before,
.indigo-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>.arrow:before,
.indigo-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>i,
.indigo-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>.arrow.open:before,
.indigo-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>.arrow:before,
.indigo-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>i {
    color: #fff;
}

.indigo-sidebar-color .sidemenu-container .sidemenu .sub-menu,
.indigo-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu {
    background-color: #545ed6;
}

.indigo-sidebar-color .sidemenu-container .sidemenu .sub-menu>li>a,
.indigo-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li>a {
    color: rgba(255, 255, 255, 0.8);
}

.indigo-sidebar-color .sidemenu-container .sidemenu .sub-menu>li.active>a,
.indigo-sidebar-color .sidemenu-container .sidemenu .sub-menu>li.open>a,
.indigo-sidebar-color .sidemenu-container .sidemenu .sub-menu>li:hover>a,
.indigo-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.active>a,
.indigo-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.open>a,
.indigo-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li:hover>a {
    color: #fff;
}

.indigo-sidebar-color .sidemenu-container .sidemenu>li.active.open>a>.arrow.open:before,
.indigo-sidebar-color .sidemenu-container .sidemenu>li.active.open>a>.arrow:before,
.indigo-sidebar-color .sidemenu-container .sidemenu>li.active.open>a>i,
.indigo-sidebar-color .sidemenu-container .sidemenu>li.active>a>.arrow.open:before,
.indigo-sidebar-color .sidemenu-container .sidemenu>li.active>a>.arrow:before,
.indigo-sidebar-color .sidemenu-container .sidemenu>li.active>a>i,
.indigo-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a>.arrow.open:before,
.indigo-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a>.arrow:before,
.indigo-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a>i,
.indigo-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>.arrow.open:before,
.indigo-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>.arrow:before,
.indigo-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>i {
    color: #fff;
}

.indigo-sidebar-color .page-container {
    background-color: #6673fc;
}

.indigo-sidebar-color .sidemenu-container .sidemenu>li.heading>h3,
.indigo-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.heading>h3 {
    color: #fff;
}

.indigo-sidebar-color .sidemenu-hover-submenu li:hover a>.arrow {
    border-right: 8px solid #fff;
}


/* Cyan sidebar color */

.cyan-sidebar-color .sidemenu-container {
    background-color: #4abad2;
}

.cyan-sidebar-color .sidemenu-container .sidemenu>li.active.open>a,
.cyan-sidebar-color .sidemenu-container .sidemenu>li.active>a,
.cyan-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a,
.cyan-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a {
    background-color: #34a3bc;
    border-top-color: transparent;
    color: #fff;
}

.cyan-sidebar-color .sidemenu-container .sidemenu>li>a,
.cyan-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a {
    color: rgba(255, 255, 255, 0.8);
    border-bottom: none;
}

.cyan-sidebar-color .user-panel {
    color: #fff;
}

.cyan-sidebar-color .txtOnline {
    color: #fff;
}

.cyan-sidebar-color .sidemenu-container .sidemenu>li.open>a,
.cyan-sidebar-color .sidemenu-container .sidemenu>li:hover>a,
.cyan-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a,
.cyan-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a {
    background-color: #4abad2;
    color: #fff;
}

.cyan-sidebar-color .sidemenu-container .sidemenu>li.open>a>.arrow.open:before,
.cyan-sidebar-color .sidemenu-container .sidemenu>li.open>a>.arrow:before,
.cyan-sidebar-color .sidemenu-container .sidemenu>li.open>a>i,
.cyan-sidebar-color .sidemenu-container .sidemenu>li:hover>a>.arrow.open:before,
.cyan-sidebar-color .sidemenu-container .sidemenu>li:hover>a>.arrow:before,
.cyan-sidebar-color .sidemenu-container .sidemenu>li:hover>a>i,
.cyan-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>.arrow.open:before,
.cyan-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>.arrow:before,
.cyan-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>i,
.cyan-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>.arrow.open:before,
.cyan-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>.arrow:before,
.cyan-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>i {
    color: #fff;
}

.cyan-sidebar-color .sidemenu-container .sidemenu .sub-menu,
.cyan-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu {
    background-color: #34a3bc;
}

.cyan-sidebar-color .sidemenu-container .sidemenu .sub-menu>li>a,
.cyan-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li>a {
    color: rgba(255, 255, 255, 0.8);
}

.cyan-sidebar-color .sidemenu-container .sidemenu .sub-menu>li.active>a,
.cyan-sidebar-color .sidemenu-container .sidemenu .sub-menu>li.open>a,
.cyan-sidebar-color .sidemenu-container .sidemenu .sub-menu>li:hover>a,
.cyan-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.active>a,
.cyan-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.open>a,
.cyan-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li:hover>a {
    color: #fff;
}

.cyan-sidebar-color .sidemenu-container .sidemenu>li.active.open>a>.arrow.open:before,
.cyan-sidebar-color .sidemenu-container .sidemenu>li.active.open>a>.arrow:before,
.cyan-sidebar-color .sidemenu-container .sidemenu>li.active.open>a>i,
.cyan-sidebar-color .sidemenu-container .sidemenu>li.active>a>.arrow.open:before,
.cyan-sidebar-color .sidemenu-container .sidemenu>li.active>a>.arrow:before,
.cyan-sidebar-color .sidemenu-container .sidemenu>li.active>a>i,
.cyan-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a>.arrow.open:before,
.cyan-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a>.arrow:before,
.cyan-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a>i,
.cyan-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>.arrow.open:before,
.cyan-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>.arrow:before,
.cyan-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>i {
    color: #fff;
}

.cyan-sidebar-color .page-container {
    background-color: #4abad2;
}

.cyan-sidebar-color .sidemenu-container .sidemenu>li.heading>h3,
.cyan-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.heading>h3 {
    color: #fff;
}

.cyan-sidebar-color .sidemenu-hover-submenu li:hover a>.arrow {
    border-right: 8px solid #fff;
}


/* Green sidebar color */

.green-sidebar-color .sidemenu-container {
    background-color: #3fcc7e;
}

.green-sidebar-color .sidemenu-container .sidemenu>li.active.open>a,
.green-sidebar-color .sidemenu-container .sidemenu>li.active>a,
.green-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a,
.green-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a {
    background-color: #3fa961;
    border-top-color: transparent;
    color: #fff;
}

.green-sidebar-color .sidemenu-container .sidemenu>li>a,
.green-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a {
    color: rgba(255, 255, 255, 0.8);
    border-bottom: none;
}

.green-sidebar-color .user-panel {
    color: #fff;
}

.green-sidebar-color .txtOnline {
    color: #fff;
}

.green-sidebar-color .sidemenu-container .sidemenu>li.open>a,
.green-sidebar-color .sidemenu-container .sidemenu>li:hover>a,
.green-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a,
.green-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a {
    background-color: #3fcc7e;
    color: #fff;
}

.green-sidebar-color .sidemenu-container .sidemenu>li.open>a>.arrow.open:before,
.green-sidebar-color .sidemenu-container .sidemenu>li.open>a>.arrow:before,
.green-sidebar-color .sidemenu-container .sidemenu>li.open>a>i,
.green-sidebar-color .sidemenu-container .sidemenu>li:hover>a>.arrow.open:before,
.green-sidebar-color .sidemenu-container .sidemenu>li:hover>a>.arrow:before,
.green-sidebar-color .sidemenu-container .sidemenu>li:hover>a>i,
.green-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>.arrow.open:before,
.green-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>.arrow:before,
.green-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>i,
.green-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>.arrow.open:before,
.green-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>.arrow:before,
.green-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>i {
    color: #fff;
}

.green-sidebar-color .sidemenu-container .sidemenu .sub-menu,
.green-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu {
    background-color: #3fa961;
}

.green-sidebar-color .sidemenu-container .sidemenu .sub-menu>li>a,
.green-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li>a {
    color: rgba(255, 255, 255, 0.8);
}

.green-sidebar-color .sidemenu-container .sidemenu .sub-menu>li.active>a,
.green-sidebar-color .sidemenu-container .sidemenu .sub-menu>li.open>a,
.green-sidebar-color .sidemenu-container .sidemenu .sub-menu>li:hover>a,
.green-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.active>a,
.green-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.open>a,
.green-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li:hover>a {
    color: #fff;
}

.green-sidebar-color .sidemenu-container .sidemenu>li.active.open>a>.arrow.open:before,
.green-sidebar-color .sidemenu-container .sidemenu>li.active.open>a>.arrow:before,
.green-sidebar-color .sidemenu-container .sidemenu>li.active.open>a>i,
.green-sidebar-color .sidemenu-container .sidemenu>li.active>a>.arrow.open:before,
.green-sidebar-color .sidemenu-container .sidemenu>li.active>a>.arrow:before,
.green-sidebar-color .sidemenu-container .sidemenu>li.active>a>i,
.green-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a>.arrow.open:before,
.green-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a>.arrow:before,
.green-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a>i,
.green-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>.arrow.open:before,
.green-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>.arrow:before,
.green-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>i {
    color: #fff;
}

.green-sidebar-color .page-container {
    background-color: #3fcc7e;
}

.green-sidebar-color .sidemenu-container .sidemenu>li.heading>h3,
.green-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.heading>h3 {
    color: #fff;
}

.green-sidebar-color .sidemenu-hover-submenu li:hover a>.arrow {
    border-right: 8px solid #fff;
}


/* Red sidebar color */

.red-sidebar-color .sidemenu-container {
    background-color: #e44f56;
}

.red-sidebar-color .sidemenu-container .sidemenu>li.active.open>a,
.red-sidebar-color .sidemenu-container .sidemenu>li.active>a,
.red-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a,
.red-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a {
    background-color: #b54b50;
    border-top-color: transparent;
    color: #fff;
}

.red-sidebar-color .sidemenu-container .sidemenu>li>a,
.red-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a {
    color: rgba(255, 255, 255, 0.8);
    border-bottom: none;
}

.red-sidebar-color .user-panel {
    color: #fff;
}

.red-sidebar-color .txtOnline {
    color: #fff;
}

.red-sidebar-color .sidemenu-container .sidemenu>li.open>a,
.red-sidebar-color .sidemenu-container .sidemenu>li:hover>a,
.red-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a,
.red-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a {
    background-color: #e44f56;
    color: #fff;
}

.red-sidebar-color .sidemenu-container .sidemenu>li.open>a>.arrow.open:before,
.red-sidebar-color .sidemenu-container .sidemenu>li.open>a>.arrow:before,
.red-sidebar-color .sidemenu-container .sidemenu>li.open>a>i,
.red-sidebar-color .sidemenu-container .sidemenu>li:hover>a>.arrow.open:before,
.red-sidebar-color .sidemenu-container .sidemenu>li:hover>a>.arrow:before,
.red-sidebar-color .sidemenu-container .sidemenu>li:hover>a>i,
.red-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>.arrow.open:before,
.red-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>.arrow:before,
.red-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>i,
.red-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>.arrow.open:before,
.red-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>.arrow:before,
.red-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>i {
    color: #fff;
}

.red-sidebar-color .sidemenu-container .sidemenu .sub-menu,
.red-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu {
    background-color: #b54b50;
}

.red-sidebar-color .sidemenu-container .sidemenu .sub-menu>li>a,
.red-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li>a {
    color: rgba(255, 255, 255, 0.8);
}

.red-sidebar-color .sidemenu-container .sidemenu .sub-menu>li.active>a,
.red-sidebar-color .sidemenu-container .sidemenu .sub-menu>li.open>a,
.red-sidebar-color .sidemenu-container .sidemenu .sub-menu>li:hover>a,
.red-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.active>a,
.red-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.open>a,
.red-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li:hover>a {
    color: #fff;
}

.red-sidebar-color .sidemenu-container .sidemenu>li.active.open>a>.arrow.open:before,
.red-sidebar-color .sidemenu-container .sidemenu>li.active.open>a>.arrow:before,
.red-sidebar-color .sidemenu-container .sidemenu>li.active.open>a>i,
.red-sidebar-color .sidemenu-container .sidemenu>li.active>a>.arrow.open:before,
.red-sidebar-color .sidemenu-container .sidemenu>li.active>a>.arrow:before,
.red-sidebar-color .sidemenu-container .sidemenu>li.active>a>i,
.red-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a>.arrow.open:before,
.red-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a>.arrow:before,
.red-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a>i,
.red-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>.arrow.open:before,
.red-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>.arrow:before,
.red-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>i {
    color: #fff;
}

.red-sidebar-color .page-container {
    background-color: #e44f56;
}

.red-sidebar-color .sidemenu-container .sidemenu>li.heading>h3,
.red-sidebar-color .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.heading>h3 {
    color: #fff;
}

.red-sidebar-color .sidemenu-hover-submenu li:hover a>.arrow {
    border-right: 8px solid #fff;
}


/***************** Logo Theme **********************/

.logo-white .page-header.navbar .page-logo {
    background: #ffffff;
}

.logo-white .page-logo a {
    color: #000;
}

.logo-dark .page-header.navbar .page-logo {
    background: #222c3c;
}

.logo-blue .page-header.navbar .page-logo {
    background: #1880c9;
}

.logo-indigo .page-header.navbar .page-logo {
    background: #6673fc;
}

.logo-cyan .page-header.navbar .page-logo {
    background: #4abad2;
}

.logo-red .page-header.navbar .page-logo {
    background: #e44f56;
}

.logo-green .page-header.navbar .page-logo {
    background: #3fcc7e;
}


/*************** Header Theme ******************/


/* Header White */

.header-white .page-header.navbar {
    background-color: #ffffff;
}

.header-white form.search-form-opened {
    float: left;
    width: 100%;
    max-width: 200px;
    background-color: transparent;
    color: #fff;
    border-radius: 30px;
    margin: 10px 0 0 10px;
    border: 1px solid #bdbdbd;
}

.header-white .page-header.navbar .top-menu .navbar-nav>li.dropdown-language>.dropdown-toggle>.langname,
.header-white .page-header.navbar .top-menu .navbar-nav>li.dropdown-user>.dropdown-toggle>.username,
.header-white .page-header.navbar .top-menu .navbar-nav>li.dropdown-user>.dropdown-toggle>i {
    color: #000;
}

.header-white .page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-toggle>i {
    color: #000;
}

.header-white .language-switch a {
    color: #000;
}

.header-white .menu-toggler i.icon-menu {
    color: #000000;
    font-size: 18px;
}

.header-white .page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>h3,
.header-white .page-header.navbar .hor-menu .navbar-nav>li>a {
    color: #555;
}

.header-white .page-header.navbar .hor-menu .navbar-nav>li>a>i {
    color: #555;
}

.header-white .page-header.navbar .hor-menu .navbar-nav>li.open>a,
.header-white .page-header.navbar .hor-menu .navbar-nav>li:hover>a,
.header-white .page-header.navbar .hor-menu .navbar-nav>li>a:hover {
    color: #555;
    background: none !important;
}

.header-white .page-header.navbar .hor-menu .navbar-nav>li.open>a>i,
.header-white .page-header.navbar .hor-menu .navbar-nav>li:hover>a>i,
.header-white .page-header.navbar .hor-menu .navbar-nav>li>a:hover>i {
    color: #555;
}

.header-white .page-header.navbar .hor-menu .navbar-nav>li.active>a,
.header-white .page-header.navbar .hor-menu .navbar-nav>li.current>a {
    color: #4680ff;
    background-color: transparent;
}

.header-white .page-header.navbar .hor-menu .navbar-nav>li.active>a>i,
.header-white .page-header.navbar .hor-menu .navbar-nav>li.current>a>i {
    color: #4680ff;
}

.header-white .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li>a,
.header-white .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li>a>i {
    color: #444;
}

.header-white .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu {
    box-shadow: 5px 5px rgba(63, 79, 98, 0.2);
    background: #ffffff;
}


/* .header-white .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li>a,
  .header-white .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li>a>i {
      color: #444 !important;
  } */

.header-white .page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>a:hover {
    text-decoration: none;
    color: #46a2ff;
}

.header-white .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li:hover>a {
    color: #000000;
}

.header-white .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.active>a,
.header-white .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.active>a:hover,
.header-white .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.current>a,
.header-white .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.current>a:hover {
    color: #46a2ff;
    background: transparent;
}

.header-white #headerSettingButton {
    color: #555;
}

.header-white .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.active>a:hover>i,
.header-white .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.active>a>i,
.header-white .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.current>a:hover>i,
.header-white .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.current>a>i,
.header-white .page-header.navbar .hor-menu .navbar-nav>li .active>a:after {
    color: #4680ff;
}


/* Header Dark */

.header-dark .page-header.navbar {
    background-color: #222c3c;
}

.header-dark form.search-form-opened .btn.submit {
    color: #fff;
}

.header-dark .search-form-opened input[type='text'] {
    color: #fff;
}

.header-dark form.search-form-opened {
    background-color: rgba(255, 255, 255, 0.3);
    border: 0;
}

.header-dark .page-header .search-form-opened .input-group .form-control::-moz-placeholder {
    color: #efefef;
    opacity: 1;
}

.header-dark .page-header .search-form-opened .input-group .form-control:-ms-input-placeholder {
    color: #efefef;
    opacity: 1;
}

.header-dark .page-header .search-form-opened .input-group .form-control::-webkit-input-placeholder {
    color: #efefef;
    opacity: 1;
}

.header-dark .page-header.navbar .top-menu .navbar-nav>li.dropdown-language>.dropdown-toggle>.langname,
.header-dark .page-header.navbar .top-menu .navbar-nav>li.dropdown-user>.dropdown-toggle>.username,
.header-dark .page-header.navbar .top-menu .navbar-nav>li.dropdown-user>.dropdown-toggle>i {
    color: #fff;
}

.header-dark .page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-toggle>i {
    color: #fff;
}

.header-dark .language-switch a {
    color: #fff;
}

.header-dark .menu-toggler i.icon-menu {
    color: #ffffff;
    font-size: 18px;
}

.header-dark .page-header.navbar .hor-menu .navbar-nav>li.active>a,
.header-dark .page-header.navbar .hor-menu .navbar-nav>li.current>a {
    background-color: transparent;
}

.header-dark .dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover {
    background-color: transparent;
}

.header-dark .dropdown-menu>li.active:hover>a,
.header-dark .dropdown-menu>li.active>a,
.header-dark .dropdown-menu>li:hover>a {
    background-color: transparent;
}

.header-dark .language-switch a.dropdown-toggle {
    color: #fff;
}

.header-dark .navbar-custom {
    background: #343d4c;
}


/* header blue */

.header-blue .page-header.navbar {
    background-color: #1880c9;
}

.header-blue form.search-form-opened .btn.submit {
    color: #fff;
}

.header-blue .search-form-opened input[type='text'] {
    color: #fff;
}

.header-blue form.search-form-opened {
    background-color: rgba(255, 255, 255, 0.3);
    border: 0;
}

.header-blue .page-header .search-form-opened .input-group .form-control::-moz-placeholder {
    color: #efefef;
    opacity: 1;
}

.header-blue .page-header .search-form-opened .input-group .form-control:-ms-input-placeholder {
    color: #efefef;
    opacity: 1;
}

.header-blue .page-header .search-form-opened .input-group .form-control::-webkit-input-placeholder {
    color: #efefef;
    opacity: 1;
}

.header-blue .page-header.navbar .top-menu .navbar-nav>li.dropdown-language>.dropdown-toggle>.langname,
.header-blue .page-header.navbar .top-menu .navbar-nav>li.dropdown-user>.dropdown-toggle>.username,
.header-blue .page-header.navbar .top-menu .navbar-nav>li.dropdown-user>.dropdown-toggle>i {
    color: #fff;
}

.header-blue .page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-toggle>i {
    color: #fff;
}

.header-blue .language-switch a {
    color: #fff;
}

.header-blue .menu-toggler i.icon-menu {
    color: #ffffff;
    font-size: 18px;
}

.header-blue .page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>h3,
.header-blue .page-header.navbar .hor-menu .navbar-nav>li>a {
    color: rgba(255, 255, 255, 0.8);
}

.header-blue .page-header.navbar .hor-menu .navbar-nav>li>a>i {
    color: rgba(255, 255, 255, 0.8);
}

.header-blue .page-header.navbar .hor-menu .navbar-nav>li.open>a,
.header-blue .page-header.navbar .hor-menu .navbar-nav>li:hover>a,
.header-blue .page-header.navbar .hor-menu .navbar-nav>li>a:hover {
    color: #fff;
    background: none !important;
}

.header-blue .page-header.navbar .hor-menu .navbar-nav>li.open>a>i,
.header-blue .page-header.navbar .hor-menu .navbar-nav>li:hover>a>i,
.header-blue .page-header.navbar .hor-menu .navbar-nav>li>a:hover>i {
    color: #fff;
}

.header-blue .page-header.navbar .hor-menu .navbar-nav>li.active>a,
.header-blue .page-header.navbar .hor-menu .navbar-nav>li.current>a {
    color: #fff;
    background: none !important;
}

.header-blue .page-header.navbar .hor-menu .navbar-nav>li.active>a>i,
.header-blue .page-header.navbar .hor-menu .navbar-nav>li.current>a>i {
    color: #fff;
}

.header-blue .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu {
    box-shadow: 5px 5px rgba(63, 79, 98, 0.2);
    background: #186fad;
}

.header-blue .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li>a,
.header-blue .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li>a>i {
    color: rgba(255, 255, 255, 0.8);
}

.header-blue .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li:hover>a {
    color: #fff;
    /*  background: #47596e; */
}

.header-blue .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.active>a,
.header-blue .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.active>a:hover,
.header-blue .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.current>a,
.header-blue .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.current>a:hover {
    color: #fff;
    background: transparent;
}

.header-blue .dropdown-menu>li.active:hover>a,
.header-blue .dropdown-menu>li.active>a,
.header-blue .dropdown-menu>li:hover>a {
    background-color: transparent;
}

.header-blue .language-switch a.dropdown-toggle {
    color: #fff;
}

.header-blue .navbar-custom {
    background: #1370b1;
}


/* Header Indigo */

.header-indigo .page-header.navbar {
    background-color: #6673fc;
}

.header-indigo form.search-form-opened .btn.submit {
    color: #fff;
}

.header-indigo .search-form-opened input[type='text'] {
    color: #fff;
}

.header-indigo form.search-form-opened {
    background-color: rgba(255, 255, 255, 0.3);
    border: 0;
}

.header-indigo .page-header .search-form-opened .input-group .form-control::-moz-placeholder {
    color: #efefef;
    opacity: 1;
}

.header-indigo .page-header .search-form-opened .input-group .form-control:-ms-input-placeholder {
    color: #efefef;
    opacity: 1;
}

.header-indigo .page-header .search-form-opened .input-group .form-control::-webkit-input-placeholder {
    color: #efefef;
    opacity: 1;
}

.header-indigo .page-header.navbar .top-menu .navbar-nav>li.dropdown-language>.dropdown-toggle>.langname,
.header-indigo .page-header.navbar .top-menu .navbar-nav>li.dropdown-user>.dropdown-toggle>.username,
.header-indigo .page-header.navbar .top-menu .navbar-nav>li.dropdown-user>.dropdown-toggle>i {
    color: #fff;
}

.header-indigo .page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-toggle>i {
    color: #fff;
}

.header-indigo .language-switch a {
    color: #fff;
}

.header-indigo .menu-toggler i.icon-menu {
    color: #ffffff;
    font-size: 18px;
}

.header-indigo .page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>h3,
.header-indigo .page-header.navbar .hor-menu .navbar-nav>li>a {
    color: rgba(255, 255, 255, 0.8);
}

.header-indigo .page-header.navbar .hor-menu .navbar-nav>li>a>i {
    color: rgba(255, 255, 255, 0.8);
}

.header-indigo .page-header.navbar .hor-menu .navbar-nav>li.open>a,
.header-indigo .page-header.navbar .hor-menu .navbar-nav>li:hover>a,
.header-indigo .page-header.navbar .hor-menu .navbar-nav>li>a:hover {
    color: #fff;
    background: none !important;
}

.header-indigo .page-header.navbar .hor-menu .navbar-nav>li.open>a>i,
.header-indigo .page-header.navbar .hor-menu .navbar-nav>li:hover>a>i,
.header-indigo .page-header.navbar .hor-menu .navbar-nav>li>a:hover>i {
    color: #fff;
}

.header-indigo .page-header.navbar .hor-menu .navbar-nav>li.active>a,
.header-indigo .page-header.navbar .hor-menu .navbar-nav>li.current>a {
    color: #fff;
    background: none !important;
}

.header-indigo .page-header.navbar .hor-menu .navbar-nav>li.active>a>i,
.header-indigo .page-header.navbar .hor-menu .navbar-nav>li.current>a>i {
    color: #fff;
}

.header-indigo .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu {
    box-shadow: 5px 5px rgba(63, 79, 98, 0.2);
    background: #545ed6;
}

.header-indigo .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li>a,
.header-indigo .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li>a>i {
    color: rgba(255, 255, 255, 0.8);
}

.header-indigo .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li:hover>a {
    color: #fff;
    /*  background: #47596e; */
}

.header-indigo .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.active>a,
.header-indigo .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.active>a:hover,
.header-indigo .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.current>a,
.header-indigo .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.current>a:hover {
    color: #fff;
    background: transparent;
}

.header-indigo .dropdown-menu>li.active:hover>a,
.header-indigo .dropdown-menu>li.active>a,
.header-indigo .dropdown-menu>li:hover>a {
    background-color: transparent;
}

.header-indigo .language-switch a.dropdown-toggle {
    color: #fff;
}

.header-indigo .navbar-custom {
    background: #5360e6;
}


/* Header Cyan */

.header-cyan .page-header.navbar {
    background-color: #4abad2;
}

.header-cyan form.search-form-opened .btn.submit {
    color: #fff;
}

.header-cyan .search-form-opened input[type='text'] {
    color: #fff;
}

.header-cyan form.search-form-opened {
    background-color: rgba(255, 255, 255, 0.3);
    border: 0;
}

.header-cyan .page-header .search-form-opened .input-group .form-control::-moz-placeholder {
    color: #efefef;
    opacity: 1;
}

.header-cyan .page-header .search-form-opened .input-group .form-control:-ms-input-placeholder {
    color: #efefef;
    opacity: 1;
}

.header-cyan .page-header .search-form-opened .input-group .form-control::-webkit-input-placeholder {
    color: #efefef;
    opacity: 1;
}

.header-cyan .page-header.navbar .top-menu .navbar-nav>li.dropdown-language>.dropdown-toggle>.langname,
.header-cyan .page-header.navbar .top-menu .navbar-nav>li.dropdown-user>.dropdown-toggle>.username,
.header-cyan .page-header.navbar .top-menu .navbar-nav>li.dropdown-user>.dropdown-toggle>i {
    color: #fff;
}

.header-cyan .page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-toggle>i {
    color: #fff;
}

.header-cyan .language-switch a {
    color: #fff;
}

.header-cyan .menu-toggler i.icon-menu {
    color: #ffffff;
    font-size: 18px;
}

.header-cyan .page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>h3,
.header-cyan .page-header.navbar .hor-menu .navbar-nav>li>a {
    color: rgba(255, 255, 255, 0.8);
}

.header-cyan .page-header.navbar .hor-menu .navbar-nav>li>a>i {
    color: rgba(255, 255, 255, 0.8);
}

.header-cyan .page-header.navbar .hor-menu .navbar-nav>li.open>a,
.header-cyan .page-header.navbar .hor-menu .navbar-nav>li:hover>a,
.header-cyan .page-header.navbar .hor-menu .navbar-nav>li>a:hover {
    color: #fff;
    background: none !important;
}

.header-cyan .page-header.navbar .hor-menu .navbar-nav>li.open>a>i,
.header-cyan .page-header.navbar .hor-menu .navbar-nav>li:hover>a>i,
.header-cyan .page-header.navbar .hor-menu .navbar-nav>li>a:hover>i {
    color: #fff;
}

.header-cyan .page-header.navbar .hor-menu .navbar-nav>li.active>a,
.header-cyan .page-header.navbar .hor-menu .navbar-nav>li.current>a {
    color: #fff;
    background: none !important;
}

.header-cyan .page-header.navbar .hor-menu .navbar-nav>li.active>a>i,
.header-cyan .page-header.navbar .hor-menu .navbar-nav>li.current>a>i {
    color: #fff;
}

.header-cyan .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu {
    box-shadow: 5px 5px rgba(63, 79, 98, 0.2);
    background: #34a3bc;
}

.header-cyan .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li>a,
.header-cyan .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li>a>i {
    color: rgba(255, 255, 255, 0.8);
}

.header-cyan .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li:hover>a {
    color: #fff;
    /*  background: #47596e; */
}

.header-cyan .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.active>a,
.header-cyan .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.active>a:hover,
.header-cyan .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.current>a,
.header-cyan .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.current>a:hover {
    color: #fff;
    background: transparent;
}

.header-cyan .dropdown-menu>li.active:hover>a,
.header-cyan .dropdown-menu>li.active>a,
.header-cyan .dropdown-menu>li:hover>a {
    background-color: transparent;
}

.header-cyan .language-switch a.dropdown-toggle {
    color: #fff;
}

.header-cyan .navbar-custom {
    background: #44a8bd;
}


/* Header Red */

.header-red .page-header.navbar {
    background-color: #e44f56;
}

.header-red form.search-form-opened .btn.submit {
    color: #fff;
}

.header-red .search-form-opened input[type='text'] {
    color: #fff;
}

.header-red form.search-form-opened {
    background-color: rgba(255, 255, 255, 0.3);
    border: 0;
}

.header-red .page-header .search-form-opened .input-group .form-control::-moz-placeholder {
    color: #efefef;
    opacity: 1;
}

.header-red .page-header .search-form-opened .input-group .form-control:-ms-input-placeholder {
    color: #efefef;
    opacity: 1;
}

.header-red .page-header .search-form-opened .input-group .form-control::-webkit-input-placeholder {
    color: #efefef;
    opacity: 1;
}

.header-red .page-header.navbar .top-menu .navbar-nav>li.dropdown-language>.dropdown-toggle>.langname,
.header-red .page-header.navbar .top-menu .navbar-nav>li.dropdown-user>.dropdown-toggle>.username,
.header-red .page-header.navbar .top-menu .navbar-nav>li.dropdown-user>.dropdown-toggle>i {
    color: #fff;
}

.header-red .page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-toggle>i {
    color: #fff;
}

.header-red .language-switch a {
    color: #fff;
}

.header-red .menu-toggler i.icon-menu {
    color: #ffffff;
    font-size: 18px;
}

.header-red .page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>h3,
.header-red .page-header.navbar .hor-menu .navbar-nav>li>a {
    color: rgba(255, 255, 255, 0.8);
}

.header-red .page-header.navbar .hor-menu .navbar-nav>li>a>i {
    color: rgba(255, 255, 255, 0.8);
}

.header-red .page-header.navbar .hor-menu .navbar-nav>li.open>a,
.header-red .page-header.navbar .hor-menu .navbar-nav>li:hover>a,
.header-red .page-header.navbar .hor-menu .navbar-nav>li>a:hover {
    color: #fff;
    background: none !important;
}

.header-red .page-header.navbar .hor-menu .navbar-nav>li.open>a>i,
.header-red .page-header.navbar .hor-menu .navbar-nav>li:hover>a>i,
.header-red .page-header.navbar .hor-menu .navbar-nav>li>a:hover>i {
    color: #fff;
}

.header-red .page-header.navbar .hor-menu .navbar-nav>li.active>a,
.header-red .page-header.navbar .hor-menu .navbar-nav>li.current>a {
    color: #fff;
    background: none !important;
}

.header-red .page-header.navbar .hor-menu .navbar-nav>li.active>a>i,
.header-red .page-header.navbar .hor-menu .navbar-nav>li.current>a>i {
    color: #fff;
}

.header-red .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu {
    box-shadow: 5px 5px rgba(63, 79, 98, 0.2);
    background: #b54b50;
}

.header-red .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li>a,
.header-red .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li>a>i {
    color: rgba(255, 255, 255, 0.8);
}

.header-red .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li:hover>a {
    color: #fff;
    /*  background: #47596e; */
}

.header-red .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.active>a,
.header-red .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.active>a:hover,
.header-red .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.current>a,
.header-red .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.current>a:hover {
    color: #fff;
    background: transparent;
}

.header-red .dropdown-menu>li.active:hover>a,
.header-red .dropdown-menu>li.active>a,
.header-red .dropdown-menu>li:hover>a {
    background-color: transparent;
}

.header-red .language-switch a.dropdown-toggle {
    color: #fff;
}

.header-red .navbar-custom {
    background: #ce444b;
}


/* Header Green */

.header-green .page-header.navbar {
    background-color: #3fcc7e;
}

.header-green form.search-form-opened .btn.submit {
    color: #fff;
}

.header-green .search-form-opened input[type='text'] {
    color: #fff;
}

.header-green form.search-form-opened {
    background-color: rgba(255, 255, 255, 0.3);
    border: 0;
}

.header-green .page-header .search-form-opened .input-group .form-control::-moz-placeholder {
    color: #efefef;
    opacity: 1;
}

.header-green .page-header .search-form-opened .input-group .form-control:-ms-input-placeholder {
    color: #efefef;
    opacity: 1;
}

.header-green .page-header .search-form-opened .input-group .form-control::-webkit-input-placeholder {
    color: #efefef;
    opacity: 1;
}

.header-green .page-header.navbar .top-menu .navbar-nav>li.dropdown-language>.dropdown-toggle>.langname,
.header-green .page-header.navbar .top-menu .navbar-nav>li.dropdown-user>.dropdown-toggle>.username,
.header-green .page-header.navbar .top-menu .navbar-nav>li.dropdown-user>.dropdown-toggle>i {
    color: #fff;
}

.header-green .page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-toggle>i {
    color: #fff;
}

.header-green .language-switch a {
    color: #fff;
}

.header-green .menu-toggler i.icon-menu {
    color: #ffffff;
    font-size: 18px;
}

.header-green .page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>h3,
.header-green .page-header.navbar .hor-menu .navbar-nav>li>a {
    color: rgba(255, 255, 255, 0.8);
}

.header-green .page-header.navbar .hor-menu .navbar-nav>li>a>i {
    color: rgba(255, 255, 255, 0.8);
}

.header-green .page-header.navbar .hor-menu .navbar-nav>li.open>a,
.header-green .page-header.navbar .hor-menu .navbar-nav>li:hover>a,
.header-green .page-header.navbar .hor-menu .navbar-nav>li>a:hover {
    color: #fff;
    background: none !important;
}

.header-green .page-header.navbar .hor-menu .navbar-nav>li.open>a>i,
.header-green .page-header.navbar .hor-menu .navbar-nav>li:hover>a>i,
.header-green .page-header.navbar .hor-menu .navbar-nav>li>a:hover>i {
    color: #fff;
}

.header-green .page-header.navbar .hor-menu .navbar-nav>li.active>a,
.header-green .page-header.navbar .hor-menu .navbar-nav>li.current>a {
    color: #fff;
    background: none !important;
}

.header-green .page-header.navbar .hor-menu .navbar-nav>li.active>a>i,
.header-green .page-header.navbar .hor-menu .navbar-nav>li.current>a>i {
    color: #fff;
}

.header-green .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu {
    box-shadow: 5px 5px rgba(63, 79, 98, 0.2);
    background: #3fa961;
}

.header-green .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li>a,
.header-green .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li>a>i {
    color: rgba(255, 255, 255, 0.8);
}

.header-green .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li:hover>a {
    color: #fff;
    /*  background: #47596e; */
}

.header-green .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.active>a,
.header-green .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.active>a:hover,
.header-green .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.current>a,
.header-green .page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.current>a:hover {
    color: #fff;
    background: transparent;
}

.header-green .dropdown-menu>li.active:hover>a,
.header-green .dropdown-menu>li.active>a,
.header-green .dropdown-menu>li:hover>a {
    background-color: transparent;
}

.header-green .language-switch a.dropdown-toggle {
    color: #fff;
}

.header-green .navbar-custom {
    background: #33b76e;
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
}

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body,
html {
    height: 100%;
    font-family: 'Helvetica Neue', Helvetica, Arial;
}


/*---------------------------------------------*/

a {
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
}

a:focus {
    outline: none !important;
}

a:hover {
    text-decoration: none;
    color: #6a7dfe;
    color: -webkit-linear-gradient(to right, #21d4fd, #b721ff);
    color: -o-linear-gradient(to right, #21d4fd, #b721ff);
    color: -moz-linear-gradient(to right, #21d4fd, #b721ff);
    color: linear-gradient(to right, #21d4fd, #b721ff);
}


/*---------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
}

p {
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
}

ul,
li {
    margin: 0px;
    list-style-type: none;
}


/*---------------------------------------------*/

input {
    outline: none;
    border: none;
}

textarea {
    outline: none;
    border: none;
}

textarea:focus,
input:focus {
    border-color: #8cbbec !important;
}

input:focus::-webkit-input-placeholder {
    color: transparent;
}

input:focus:-moz-placeholder {
    color: transparent;
}

input:focus::-moz-placeholder {
    color: transparent;
}

input:focus:-ms-input-placeholder {
    color: transparent;
}

textarea:focus::-webkit-input-placeholder {
    color: transparent;
}

textarea:focus:-moz-placeholder {
    color: transparent;
}

textarea:focus::-moz-placeholder {
    color: transparent;
}

textarea:focus:-ms-input-placeholder {
    color: transparent;
}

input::-webkit-input-placeholder {
    color: #adadad;
}

input:-moz-placeholder {
    color: #adadad;
}

input::-moz-placeholder {
    color: #adadad;
}

input:-ms-input-placeholder {
    color: #adadad;
}

textarea::-webkit-input-placeholder {
    color: #adadad;
}

textarea:-moz-placeholder {
    color: #adadad;
}

textarea::-moz-placeholder {
    color: #adadad;
}

textarea:-ms-input-placeholder {
    color: #adadad;
}


/*---------------------------------------------*/

button {
    outline: none !important;
    border: none;
    background: red;
}

button:hover {
    cursor: pointer;
}

iframe {
    border: none !important;
}


/*//////////////////////////////////////////////////////////////////
  [ login ]*/

.limiter1 {
    width: 100%;
    margin: 0 auto;
}

.container-login101 {
    width: 100%;
    /*min-height: 100vh;*/
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /*padding: 15px;*/
}

.wrap-login100 {
    width: 390px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    padding: 77px 55px 33px 55px;
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
}


/*------------------------------------------------------------------
  [ Form ]*/

.login100-form {
    width: 100%;
}

.login101-form-title {
    display: block;
    font-family: 'Times New Roman', Times, serif;
    font-size: 30px;
    color: red;
    line-height: 1.2;
    text-align: center;
}

.login101-form-title i {
    font-size: 60px;
}


/*------------------------------------------------------------------
  [ Input ]*/

.wrap-input101 {
    width: 100%;
    position: relative;
    border-bottom: 2px solid transparent;
    margin-bottom: 37px;
}


/*---------------------------------------------*/

.focus-input100 {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
}

.focus-input100::before {
    content: '';
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
    background: #6a7dfe;
    background: -webkit-linear-gradient(to, #21d4fd, #b721ff);
    background: -o-linear-gradient(to, #21d4fd, #b721ff);
    background: -moz-linear-gradient(to, #21d4fd, #b721ff);
    background: linear-gradient(to, #21d4fd, #b721ff);
}

.input100:focus+.focus-input100::after {
    top: -15px;
}

.input100:focus+.focus-input100::before {
    width: 100%;
}

.has-val.input100+.focus-input100::after {
    top: -15px;
}

.has-val.input100+.focus-input100::before {
    width: 100%;
}


/*---------------------------------------------*/

.btn-show-pass {
    font-size: 15px;
    color: #999999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    padding-right: 5px;
    cursor: pointer;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.btn-show-pass:hover {
    color: #6a7dfe;
    color: -webkit-linear-gradient(to right, #21d4fd, #b721ff);
    color: -o-linear-gradient(to right, #21d4fd, #b721ff);
    color: -moz-linear-gradient(to right, #21d4fd, #b721ff);
    color: linear-gradient(to right, #21d4fd, #b721ff);
}

.btn-show-pass.active {
    color: #6a7dfe;
    color: -webkit-linear-gradient(to right, #21d4fd, #b721ff);
    color: -o-linear-gradient(to right, #21d4fd, #b721ff);
    color: -moz-linear-gradient(to right, #21d4fd, #b721ff);
    color: linear-gradient(to right, #21d4fd, #b721ff);
}


/*------------------------------------------------------------------
  [ Button ]*/

.container-login101-form-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 13px;
}

.wrap-login101-form-btn {
    width: 100%;
    display: block;
    position: relative;
    z-index: 1;
    border-radius: 25px;
    overflow: hidden;
    margin: 0 auto;
}

.login101-form-bgbtn {
    position: absolute;
    z-index: -1;
    width: 300%;
    height: 100%;
    background: #a64bf4;
    background: -webkit-linear-gradient(to left, #21d4fd, #b721ff, #21d4fd, #b721ff);
    background: -o-linear-gradient(to left, #21d4fd, #b721ff, #21d4fd, #b721ff);
    background: -moz-linear-gradient(to left, #21d4fd, #b721ff, #21d4fd, #b721ff);
    background: linear-gradient(to left, #21d4fd, #b721ff, #21d4fd, #b721ff);
    top: 0;
    left: -100%;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login101-form-btn {
    font-family: 'Times New Roman', Times, serif;
    font-size: 20px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 50px;
}

.wrap-login101-form-btn:hover .login101-form-bgbtn {
    left: 0;
}


/*------------------------------------------------------------------
  [ Responsive ]*/

@media (max-width: 576px) {
    .wrap-login100 {
        padding: 77px 15px 33px 15px;
    }
}


/*------------------------------------------------------------------
  [ Alert validate ]*/

.validate-input {
    position: relative;
}

.alert-validate::before {
    content: attr(data-validate);
    position: absolute;
    max-width: 70%;
    background-color: #fff;
    border: 1px solid #c80000;
    border-radius: 2px;
    padding: 4px 25px 4px 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0px;
    pointer-events: none;
    color: #c80000;
    font-size: 13px;
    line-height: 1.4;
    text-align: left;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 0.4s;
    -o-transition: opacity 0.4s;
    -moz-transition: opacity 0.4s;
    transition: opacity 0.4s;
}

.alert-validate::after {
    content: '\f06a';
    font-family: FontAwesome;
    font-size: 16px;
    color: #c80000;
    display: block;
    position: absolute;
    background-color: #fff;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 5px;
}

.alert-validate:hover:before {
    visibility: visible;
    opacity: 1;
}

@media (max-width: 992px) {
    .alert-validate::before {
        visibility: visible;
        opacity: 1;
    }
}


/*[ FONT SIZE ]
///////////////////////////////////////////////////////////
*/

.fs-1 {
    font-size: 1px;
}

.fs-2 {
    font-size: 2px;
}

.fs-3 {
    font-size: 3px;
}

.fs-4 {
    font-size: 4px;
}

.fs-5 {
    font-size: 5px;
}

.fs-6 {
    font-size: 6px;
}

.fs-7 {
    font-size: 7px;
}

.fs-8 {
    font-size: 8px;
}

.fs-9 {
    font-size: 9px;
}

.fs-10 {
    font-size: 10px;
}

.fs-11 {
    font-size: 11px;
}

.fs-12 {
    font-size: 12px;
}

.fs-13 {
    font-size: 13px;
}

.fs-14 {
    font-size: 14px;
}

.fs-15 {
    font-size: 15px;
}

.fs-16 {
    font-size: 16px;
}

.fs-17 {
    font-size: 17px;
}

.fs-18 {
    font-size: 18px;
}

.fs-19 {
    font-size: 19px;
}

.fs-20 {
    font-size: 20px;
}

.fs-21 {
    font-size: 21px;
}

.fs-22 {
    font-size: 22px;
}

.fs-23 {
    font-size: 23px;
}

.fs-24 {
    font-size: 24px;
}

.fs-25 {
    font-size: 25px;
}

.fs-26 {
    font-size: 26px;
}

.fs-27 {
    font-size: 27px;
}

.fs-28 {
    font-size: 28px;
}

.fs-29 {
    font-size: 29px;
}

.fs-30 {
    font-size: 30px;
}

.fs-31 {
    font-size: 31px;
}

.fs-32 {
    font-size: 32px;
}

.fs-33 {
    font-size: 33px;
}

.fs-34 {
    font-size: 34px;
}

.fs-35 {
    font-size: 35px;
}

.fs-36 {
    font-size: 36px;
}

.fs-37 {
    font-size: 37px;
}

.fs-38 {
    font-size: 38px;
}

.fs-39 {
    font-size: 39px;
}

.fs-40 {
    font-size: 40px;
}

.fs-41 {
    font-size: 41px;
}

.fs-42 {
    font-size: 42px;
}

.fs-43 {
    font-size: 43px;
}

.fs-44 {
    font-size: 44px;
}

.fs-45 {
    font-size: 45px;
}

.fs-46 {
    font-size: 46px;
}

.fs-47 {
    font-size: 47px;
}

.fs-48 {
    font-size: 48px;
}

.fs-49 {
    font-size: 49px;
}

.fs-50 {
    font-size: 50px;
}

.fs-51 {
    font-size: 51px;
}

.fs-52 {
    font-size: 52px;
}

.fs-53 {
    font-size: 53px;
}

.fs-54 {
    font-size: 54px;
}

.fs-55 {
    font-size: 55px;
}

.fs-56 {
    font-size: 56px;
}

.fs-57 {
    font-size: 57px;
}

.fs-58 {
    font-size: 58px;
}

.fs-59 {
    font-size: 59px;
}

.fs-60 {
    font-size: 60px;
}

.fs-61 {
    font-size: 61px;
}

.fs-62 {
    font-size: 62px;
}

.fs-63 {
    font-size: 63px;
}

.fs-64 {
    font-size: 64px;
}

.fs-65 {
    font-size: 65px;
}

.fs-66 {
    font-size: 66px;
}

.fs-67 {
    font-size: 67px;
}

.fs-68 {
    font-size: 68px;
}

.fs-69 {
    font-size: 69px;
}

.fs-70 {
    font-size: 70px;
}

.fs-71 {
    font-size: 71px;
}

.fs-72 {
    font-size: 72px;
}

.fs-73 {
    font-size: 73px;
}

.fs-74 {
    font-size: 74px;
}

.fs-75 {
    font-size: 75px;
}

.fs-76 {
    font-size: 76px;
}

.fs-77 {
    font-size: 77px;
}

.fs-78 {
    font-size: 78px;
}

.fs-79 {
    font-size: 79px;
}

.fs-80 {
    font-size: 80px;
}

.fs-81 {
    font-size: 81px;
}

.fs-82 {
    font-size: 82px;
}

.fs-83 {
    font-size: 83px;
}

.fs-84 {
    font-size: 84px;
}

.fs-85 {
    font-size: 85px;
}

.fs-86 {
    font-size: 86px;
}

.fs-87 {
    font-size: 87px;
}

.fs-88 {
    font-size: 88px;
}

.fs-89 {
    font-size: 89px;
}

.fs-90 {
    font-size: 90px;
}

.fs-91 {
    font-size: 91px;
}

.fs-92 {
    font-size: 92px;
}

.fs-93 {
    font-size: 93px;
}

.fs-94 {
    font-size: 94px;
}

.fs-95 {
    font-size: 95px;
}

.fs-96 {
    font-size: 96px;
}

.fs-97 {
    font-size: 97px;
}

.fs-98 {
    font-size: 98px;
}

.fs-99 {
    font-size: 99px;
}

.fs-100 {
    font-size: 100px;
}

.fs-101 {
    font-size: 101px;
}

.fs-102 {
    font-size: 102px;
}

.fs-103 {
    font-size: 103px;
}

.fs-104 {
    font-size: 104px;
}

.fs-105 {
    font-size: 105px;
}

.fs-106 {
    font-size: 106px;
}

.fs-107 {
    font-size: 107px;
}

.fs-108 {
    font-size: 108px;
}

.fs-109 {
    font-size: 109px;
}

.fs-110 {
    font-size: 110px;
}

.fs-111 {
    font-size: 111px;
}

.fs-112 {
    font-size: 112px;
}

.fs-113 {
    font-size: 113px;
}

.fs-114 {
    font-size: 114px;
}

.fs-115 {
    font-size: 115px;
}

.fs-116 {
    font-size: 116px;
}

.fs-117 {
    font-size: 117px;
}

.fs-118 {
    font-size: 118px;
}

.fs-119 {
    font-size: 119px;
}

.fs-120 {
    font-size: 120px;
}

.fs-121 {
    font-size: 121px;
}

.fs-122 {
    font-size: 122px;
}

.fs-123 {
    font-size: 123px;
}

.fs-124 {
    font-size: 124px;
}

.fs-125 {
    font-size: 125px;
}

.fs-126 {
    font-size: 126px;
}

.fs-127 {
    font-size: 127px;
}

.fs-128 {
    font-size: 128px;
}

.fs-129 {
    font-size: 129px;
}

.fs-130 {
    font-size: 130px;
}

.fs-131 {
    font-size: 131px;
}

.fs-132 {
    font-size: 132px;
}

.fs-133 {
    font-size: 133px;
}

.fs-134 {
    font-size: 134px;
}

.fs-135 {
    font-size: 135px;
}

.fs-136 {
    font-size: 136px;
}

.fs-137 {
    font-size: 137px;
}

.fs-138 {
    font-size: 138px;
}

.fs-139 {
    font-size: 139px;
}

.fs-140 {
    font-size: 140px;
}

.fs-141 {
    font-size: 141px;
}

.fs-142 {
    font-size: 142px;
}

.fs-143 {
    font-size: 143px;
}

.fs-144 {
    font-size: 144px;
}

.fs-145 {
    font-size: 145px;
}

.fs-146 {
    font-size: 146px;
}

.fs-147 {
    font-size: 147px;
}

.fs-148 {
    font-size: 148px;
}

.fs-149 {
    font-size: 149px;
}

.fs-150 {
    font-size: 150px;
}

.fs-151 {
    font-size: 151px;
}

.fs-152 {
    font-size: 152px;
}

.fs-153 {
    font-size: 153px;
}

.fs-154 {
    font-size: 154px;
}

.fs-155 {
    font-size: 155px;
}

.fs-156 {
    font-size: 156px;
}

.fs-157 {
    font-size: 157px;
}

.fs-158 {
    font-size: 158px;
}

.fs-159 {
    font-size: 159px;
}

.fs-160 {
    font-size: 160px;
}

.fs-161 {
    font-size: 161px;
}

.fs-162 {
    font-size: 162px;
}

.fs-163 {
    font-size: 163px;
}

.fs-164 {
    font-size: 164px;
}

.fs-165 {
    font-size: 165px;
}

.fs-166 {
    font-size: 166px;
}

.fs-167 {
    font-size: 167px;
}

.fs-168 {
    font-size: 168px;
}

.fs-169 {
    font-size: 169px;
}

.fs-170 {
    font-size: 170px;
}

.fs-171 {
    font-size: 171px;
}

.fs-172 {
    font-size: 172px;
}

.fs-173 {
    font-size: 173px;
}

.fs-174 {
    font-size: 174px;
}

.fs-175 {
    font-size: 175px;
}

.fs-176 {
    font-size: 176px;
}

.fs-177 {
    font-size: 177px;
}

.fs-178 {
    font-size: 178px;
}

.fs-179 {
    font-size: 179px;
}

.fs-180 {
    font-size: 180px;
}

.fs-181 {
    font-size: 181px;
}

.fs-182 {
    font-size: 182px;
}

.fs-183 {
    font-size: 183px;
}

.fs-184 {
    font-size: 184px;
}

.fs-185 {
    font-size: 185px;
}

.fs-186 {
    font-size: 186px;
}

.fs-187 {
    font-size: 187px;
}

.fs-188 {
    font-size: 188px;
}

.fs-189 {
    font-size: 189px;
}

.fs-190 {
    font-size: 190px;
}

.fs-191 {
    font-size: 191px;
}

.fs-192 {
    font-size: 192px;
}

.fs-193 {
    font-size: 193px;
}

.fs-194 {
    font-size: 194px;
}

.fs-195 {
    font-size: 195px;
}

.fs-196 {
    font-size: 196px;
}

.fs-197 {
    font-size: 197px;
}

.fs-198 {
    font-size: 198px;
}

.fs-199 {
    font-size: 199px;
}

.fs-200 {
    font-size: 200px;
}


/*[ PADDING ]
///////////////////////////////////////////////////////////
*/

.p-t-0 {
    padding-top: 0px;
}

.p-t-1 {
    padding-top: 1px;
}

.p-t-2 {
    padding-top: 2px;
}

.p-t-3 {
    padding-top: 3px;
}

.p-t-4 {
    padding-top: 4px;
}

.p-t-5 {
    padding-top: 5px;
}

.p-t-6 {
    padding-top: 6px;
}

.p-t-7 {
    padding-top: 7px;
}

.p-t-8 {
    padding-top: 8px;
}

.p-t-9 {
    padding-top: 9px;
}

.p-t-10 {
    padding-top: 10px;
}

.p-t-11 {
    padding-top: 11px;
}

.p-t-12 {
    padding-top: 12px;
}

.p-t-13 {
    padding-top: 13px;
}

.p-t-14 {
    padding-top: 14px;
}

.p-t-15 {
    padding-top: 15px;
}

.p-t-16 {
    padding-top: 16px;
}

.p-t-17 {
    padding-top: 17px;
}

.p-t-18 {
    padding-top: 18px;
}

.p-t-19 {
    padding-top: 19px;
}

.p-t-20 {
    padding-top: 20px;
}

.p-t-21 {
    padding-top: 21px;
}

.p-t-22 {
    padding-top: 22px;
}

.p-t-23 {
    padding-top: 23px;
}

.p-t-24 {
    padding-top: 24px;
}

.p-t-25 {
    padding-top: 25px;
}

.p-t-26 {
    padding-top: 26px;
}

.p-t-27 {
    padding-top: 27px;
}

.p-t-28 {
    padding-top: 28px;
}

.p-t-29 {
    padding-top: 29px;
}

.p-t-30 {
    padding-top: 30px;
}

.p-t-31 {
    padding-top: 31px;
}

.p-t-32 {
    padding-top: 32px;
}

.p-t-33 {
    padding-top: 33px;
}

.p-t-34 {
    padding-top: 34px;
}

.p-t-35 {
    padding-top: 35px;
}

.p-t-36 {
    padding-top: 36px;
}

.p-t-37 {
    padding-top: 37px;
}

.p-t-38 {
    padding-top: 38px;
}

.p-t-39 {
    padding-top: 39px;
}

.p-t-40 {
    padding-top: 40px;
}

.p-t-41 {
    padding-top: 41px;
}

.p-t-42 {
    padding-top: 42px;
}

.p-t-43 {
    padding-top: 43px;
}

.p-t-44 {
    padding-top: 44px;
}

.p-t-45 {
    padding-top: 45px;
}

.p-t-46 {
    padding-top: 46px;
}

.p-t-47 {
    padding-top: 47px;
}

.p-t-48 {
    padding-top: 48px;
}

.p-t-49 {
    padding-top: 49px;
}

.p-t-50 {
    padding-top: 50px;
}

.p-t-51 {
    padding-top: 51px;
}

.p-t-52 {
    padding-top: 52px;
}

.p-t-53 {
    padding-top: 53px;
}

.p-t-54 {
    padding-top: 54px;
}

.p-t-55 {
    padding-top: 55px;
}

.p-t-56 {
    padding-top: 56px;
}

.p-t-57 {
    padding-top: 57px;
}

.p-t-58 {
    padding-top: 58px;
}

.p-t-59 {
    padding-top: 59px;
}

.p-t-60 {
    padding-top: 60px;
}

.p-t-61 {
    padding-top: 61px;
}

.p-t-62 {
    padding-top: 62px;
}

.p-t-63 {
    padding-top: 63px;
}

.p-t-64 {
    padding-top: 64px;
}

.p-t-65 {
    padding-top: 65px;
}

.p-t-66 {
    padding-top: 66px;
}

.p-t-67 {
    padding-top: 67px;
}

.p-t-68 {
    padding-top: 68px;
}

.p-t-69 {
    padding-top: 69px;
}

.p-t-70 {
    padding-top: 70px;
}

.p-t-71 {
    padding-top: 71px;
}

.p-t-72 {
    padding-top: 72px;
}

.p-t-73 {
    padding-top: 73px;
}

.p-t-74 {
    padding-top: 74px;
}

.p-t-75 {
    padding-top: 75px;
}

.p-t-76 {
    padding-top: 76px;
}

.p-t-77 {
    padding-top: 77px;
}

.p-t-78 {
    padding-top: 78px;
}

.p-t-79 {
    padding-top: 79px;
}

.p-t-80 {
    padding-top: 80px;
}

.p-t-81 {
    padding-top: 81px;
}

.p-t-82 {
    padding-top: 82px;
}

.p-t-83 {
    padding-top: 83px;
}

.p-t-84 {
    padding-top: 84px;
}

.p-t-85 {
    padding-top: 85px;
}

.p-t-86 {
    padding-top: 86px;
}

.p-t-87 {
    padding-top: 87px;
}

.p-t-88 {
    padding-top: 88px;
}

.p-t-89 {
    padding-top: 89px;
}

.p-t-90 {
    padding-top: 90px;
}

.p-t-91 {
    padding-top: 91px;
}

.p-t-92 {
    padding-top: 92px;
}

.p-t-93 {
    padding-top: 93px;
}

.p-t-94 {
    padding-top: 94px;
}

.p-t-95 {
    padding-top: 95px;
}

.p-t-96 {
    padding-top: 96px;
}

.p-t-97 {
    padding-top: 97px;
}

.p-t-98 {
    padding-top: 98px;
}

.p-t-99 {
    padding-top: 99px;
}

.p-t-100 {
    padding-top: 100px;
}

.p-t-101 {
    padding-top: 101px;
}

.p-t-102 {
    padding-top: 102px;
}

.p-t-103 {
    padding-top: 103px;
}

.p-t-104 {
    padding-top: 104px;
}

.p-t-105 {
    padding-top: 105px;
}

.p-t-106 {
    padding-top: 106px;
}

.p-t-107 {
    padding-top: 107px;
}

.p-t-108 {
    padding-top: 108px;
}

.p-t-109 {
    padding-top: 109px;
}

.p-t-110 {
    padding-top: 110px;
}

.p-t-111 {
    padding-top: 111px;
}

.p-t-112 {
    padding-top: 112px;
}

.p-t-113 {
    padding-top: 113px;
}

.p-t-114 {
    padding-top: 114px;
}

.p-t-115 {
    padding-top: 115px;
}

.p-t-116 {
    padding-top: 116px;
}

.p-t-117 {
    padding-top: 117px;
}

.p-t-118 {
    padding-top: 118px;
}

.p-t-119 {
    padding-top: 119px;
}

.p-t-120 {
    padding-top: 120px;
}

.p-t-121 {
    padding-top: 121px;
}

.p-t-122 {
    padding-top: 122px;
}

.p-t-123 {
    padding-top: 123px;
}

.p-t-124 {
    padding-top: 124px;
}

.p-t-125 {
    padding-top: 125px;
}

.p-t-126 {
    padding-top: 126px;
}

.p-t-127 {
    padding-top: 127px;
}

.p-t-128 {
    padding-top: 128px;
}

.p-t-129 {
    padding-top: 129px;
}

.p-t-130 {
    padding-top: 130px;
}

.p-t-131 {
    padding-top: 131px;
}

.p-t-132 {
    padding-top: 132px;
}

.p-t-133 {
    padding-top: 133px;
}

.p-t-134 {
    padding-top: 134px;
}

.p-t-135 {
    padding-top: 135px;
}

.p-t-136 {
    padding-top: 136px;
}

.p-t-137 {
    padding-top: 137px;
}

.p-t-138 {
    padding-top: 138px;
}

.p-t-139 {
    padding-top: 139px;
}

.p-t-140 {
    padding-top: 140px;
}

.p-t-141 {
    padding-top: 141px;
}

.p-t-142 {
    padding-top: 142px;
}

.p-t-143 {
    padding-top: 143px;
}

.p-t-144 {
    padding-top: 144px;
}

.p-t-145 {
    padding-top: 145px;
}

.p-t-146 {
    padding-top: 146px;
}

.p-t-147 {
    padding-top: 147px;
}

.p-t-148 {
    padding-top: 148px;
}

.p-t-149 {
    padding-top: 149px;
}

.p-t-150 {
    padding-top: 150px;
}

.p-t-151 {
    padding-top: 151px;
}

.p-t-152 {
    padding-top: 152px;
}

.p-t-153 {
    padding-top: 153px;
}

.p-t-154 {
    padding-top: 154px;
}

.p-t-155 {
    padding-top: 155px;
}

.p-t-156 {
    padding-top: 156px;
}

.p-t-157 {
    padding-top: 157px;
}

.p-t-158 {
    padding-top: 158px;
}

.p-t-159 {
    padding-top: 159px;
}

.p-t-160 {
    padding-top: 160px;
}

.p-t-161 {
    padding-top: 161px;
}

.p-t-162 {
    padding-top: 162px;
}

.p-t-163 {
    padding-top: 163px;
}

.p-t-164 {
    padding-top: 164px;
}

.p-t-165 {
    padding-top: 165px;
}

.p-t-166 {
    padding-top: 166px;
}

.p-t-167 {
    padding-top: 167px;
}

.p-t-168 {
    padding-top: 168px;
}

.p-t-169 {
    padding-top: 169px;
}

.p-t-170 {
    padding-top: 170px;
}

.p-t-171 {
    padding-top: 171px;
}

.p-t-172 {
    padding-top: 172px;
}

.p-t-173 {
    padding-top: 173px;
}

.p-t-174 {
    padding-top: 174px;
}

.p-t-175 {
    padding-top: 175px;
}

.p-t-176 {
    padding-top: 176px;
}

.p-t-177 {
    padding-top: 177px;
}

.p-t-178 {
    padding-top: 178px;
}

.p-t-179 {
    padding-top: 179px;
}

.p-t-180 {
    padding-top: 180px;
}

.p-t-181 {
    padding-top: 181px;
}

.p-t-182 {
    padding-top: 182px;
}

.p-t-183 {
    padding-top: 183px;
}

.p-t-184 {
    padding-top: 184px;
}

.p-t-185 {
    padding-top: 185px;
}

.p-t-186 {
    padding-top: 186px;
}

.p-t-187 {
    padding-top: 187px;
}

.p-t-188 {
    padding-top: 188px;
}

.p-t-189 {
    padding-top: 189px;
}

.p-t-190 {
    padding-top: 190px;
}

.p-t-191 {
    padding-top: 191px;
}

.p-t-192 {
    padding-top: 192px;
}

.p-t-193 {
    padding-top: 193px;
}

.p-t-194 {
    padding-top: 194px;
}

.p-t-195 {
    padding-top: 195px;
}

.p-t-196 {
    padding-top: 196px;
}

.p-t-197 {
    padding-top: 197px;
}

.p-t-198 {
    padding-top: 198px;
}

.p-t-199 {
    padding-top: 199px;
}

.p-t-200 {
    padding-top: 200px;
}

.p-t-201 {
    padding-top: 201px;
}

.p-t-202 {
    padding-top: 202px;
}

.p-t-203 {
    padding-top: 203px;
}

.p-t-204 {
    padding-top: 204px;
}

.p-t-205 {
    padding-top: 205px;
}

.p-t-206 {
    padding-top: 206px;
}

.p-t-207 {
    padding-top: 207px;
}

.p-t-208 {
    padding-top: 208px;
}

.p-t-209 {
    padding-top: 209px;
}

.p-t-210 {
    padding-top: 210px;
}

.p-t-211 {
    padding-top: 211px;
}

.p-t-212 {
    padding-top: 212px;
}

.p-t-213 {
    padding-top: 213px;
}

.p-t-214 {
    padding-top: 214px;
}

.p-t-215 {
    padding-top: 215px;
}

.p-t-216 {
    padding-top: 216px;
}

.p-t-217 {
    padding-top: 217px;
}

.p-t-218 {
    padding-top: 218px;
}

.p-t-219 {
    padding-top: 219px;
}

.p-t-220 {
    padding-top: 220px;
}

.p-t-221 {
    padding-top: 221px;
}

.p-t-222 {
    padding-top: 222px;
}

.p-t-223 {
    padding-top: 223px;
}

.p-t-224 {
    padding-top: 224px;
}

.p-t-225 {
    padding-top: 225px;
}

.p-t-226 {
    padding-top: 226px;
}

.p-t-227 {
    padding-top: 227px;
}

.p-t-228 {
    padding-top: 228px;
}

.p-t-229 {
    padding-top: 229px;
}

.p-t-230 {
    padding-top: 230px;
}

.p-t-231 {
    padding-top: 231px;
}

.p-t-232 {
    padding-top: 232px;
}

.p-t-233 {
    padding-top: 233px;
}

.p-t-234 {
    padding-top: 234px;
}

.p-t-235 {
    padding-top: 235px;
}

.p-t-236 {
    padding-top: 236px;
}

.p-t-237 {
    padding-top: 237px;
}

.p-t-238 {
    padding-top: 238px;
}

.p-t-239 {
    padding-top: 239px;
}

.p-t-240 {
    padding-top: 240px;
}

.p-t-241 {
    padding-top: 241px;
}

.p-t-242 {
    padding-top: 242px;
}

.p-t-243 {
    padding-top: 243px;
}

.p-t-244 {
    padding-top: 244px;
}

.p-t-245 {
    padding-top: 245px;
}

.p-t-246 {
    padding-top: 246px;
}

.p-t-247 {
    padding-top: 247px;
}

.p-t-248 {
    padding-top: 248px;
}

.p-t-249 {
    padding-top: 249px;
}

.p-t-250 {
    padding-top: 250px;
}

.p-b-0 {
    padding-bottom: 0px;
}

.p-b-1 {
    padding-bottom: 1px;
}

.p-b-2 {
    padding-bottom: 2px;
}

.p-b-3 {
    padding-bottom: 3px;
}

.p-b-4 {
    padding-bottom: 4px;
}

.p-b-5 {
    padding-bottom: 5px;
}

.p-b-6 {
    padding-bottom: 6px;
}

.p-b-7 {
    padding-bottom: 7px;
}

.p-b-8 {
    padding-bottom: 8px;
}

.p-b-9 {
    padding-bottom: 9px;
}

.p-b-10 {
    padding-bottom: 10px;
}

.p-b-11 {
    padding-bottom: 11px;
}

.p-b-12 {
    padding-bottom: 12px;
}

.p-b-13 {
    padding-bottom: 13px;
}

.p-b-14 {
    padding-bottom: 14px;
}

.p-b-15 {
    padding-bottom: 15px;
}

.p-b-16 {
    padding-bottom: 16px;
}

.p-b-17 {
    padding-bottom: 17px;
}

.p-b-18 {
    padding-bottom: 18px;
}

.p-b-19 {
    padding-bottom: 19px;
}

.p-b-20 {
    padding-bottom: 20px;
}

.p-b-21 {
    padding-bottom: 21px;
}

.p-b-22 {
    padding-bottom: 22px;
}

.p-b-23 {
    padding-bottom: 23px;
}

.p-b-24 {
    padding-bottom: 24px;
}

.p-b-25 {
    padding-bottom: 25px;
}

.p-b-26 {
    padding-bottom: 26px;
}

.p-b-27 {
    padding-bottom: 27px;
}

.p-b-28 {
    padding-bottom: 28px;
}

.p-b-29 {
    padding-bottom: 29px;
}

.p-b-30 {
    padding-bottom: 30px;
}

.p-b-31 {
    padding-bottom: 31px;
}

.p-b-32 {
    padding-bottom: 32px;
}

.p-b-33 {
    padding-bottom: 33px;
}

.p-b-34 {
    padding-bottom: 34px;
}

.p-b-35 {
    padding-bottom: 35px;
}

.p-b-36 {
    padding-bottom: 36px;
}

.p-b-37 {
    padding-bottom: 37px;
}

.p-b-38 {
    padding-bottom: 38px;
}

.p-b-39 {
    padding-bottom: 39px;
}

.p-b-40 {
    padding-bottom: 40px;
}

.p-b-41 {
    padding-bottom: 41px;
}

.p-b-42 {
    padding-bottom: 42px;
}

.p-b-43 {
    padding-bottom: 43px;
}

.p-b-44 {
    padding-bottom: 44px;
}

.p-b-45 {
    padding-bottom: 45px;
}

.p-b-46 {
    padding-bottom: 46px;
}

.p-b-47 {
    padding-bottom: 47px;
}

.p-b-48 {
    padding-bottom: 48px;
}

.p-b-49 {
    padding-bottom: 49px;
}

.p-b-50 {
    padding-bottom: 50px;
}

.p-b-51 {
    padding-bottom: 51px;
}

.p-b-52 {
    padding-bottom: 52px;
}

.p-b-53 {
    padding-bottom: 53px;
}

.p-b-54 {
    padding-bottom: 54px;
}

.p-b-55 {
    padding-bottom: 55px;
}

.p-b-56 {
    padding-bottom: 56px;
}

.p-b-57 {
    padding-bottom: 57px;
}

.p-b-58 {
    padding-bottom: 58px;
}

.p-b-59 {
    padding-bottom: 59px;
}

.p-b-60 {
    padding-bottom: 60px;
}

.p-b-61 {
    padding-bottom: 61px;
}

.p-b-62 {
    padding-bottom: 62px;
}

.p-b-63 {
    padding-bottom: 63px;
}

.p-b-64 {
    padding-bottom: 64px;
}

.p-b-65 {
    padding-bottom: 65px;
}

.p-b-66 {
    padding-bottom: 66px;
}

.p-b-67 {
    padding-bottom: 67px;
}

.p-b-68 {
    padding-bottom: 68px;
}

.p-b-69 {
    padding-bottom: 69px;
}

.p-b-70 {
    padding-bottom: 70px;
}

.p-b-71 {
    padding-bottom: 71px;
}

.p-b-72 {
    padding-bottom: 72px;
}

.p-b-73 {
    padding-bottom: 73px;
}

.p-b-74 {
    padding-bottom: 74px;
}

.p-b-75 {
    padding-bottom: 75px;
}

.p-b-76 {
    padding-bottom: 76px;
}

.p-b-77 {
    padding-bottom: 77px;
}

.p-b-78 {
    padding-bottom: 78px;
}

.p-b-79 {
    padding-bottom: 79px;
}

.p-b-80 {
    padding-bottom: 80px;
}

.p-b-81 {
    padding-bottom: 81px;
}

.p-b-82 {
    padding-bottom: 82px;
}

.p-b-83 {
    padding-bottom: 83px;
}

.p-b-84 {
    padding-bottom: 84px;
}

.p-b-85 {
    padding-bottom: 85px;
}

.p-b-86 {
    padding-bottom: 86px;
}

.p-b-87 {
    padding-bottom: 87px;
}

.p-b-88 {
    padding-bottom: 88px;
}

.p-b-89 {
    padding-bottom: 89px;
}

.p-b-90 {
    padding-bottom: 90px;
}

.p-b-91 {
    padding-bottom: 91px;
}

.p-b-92 {
    padding-bottom: 92px;
}

.p-b-93 {
    padding-bottom: 93px;
}

.p-b-94 {
    padding-bottom: 94px;
}

.p-b-95 {
    padding-bottom: 95px;
}

.p-b-96 {
    padding-bottom: 96px;
}

.p-b-97 {
    padding-bottom: 97px;
}

.p-b-98 {
    padding-bottom: 98px;
}

.p-b-99 {
    padding-bottom: 99px;
}

.p-b-100 {
    padding-bottom: 100px;
}

.p-b-101 {
    padding-bottom: 101px;
}

.p-b-102 {
    padding-bottom: 102px;
}

.p-b-103 {
    padding-bottom: 103px;
}

.p-b-104 {
    padding-bottom: 104px;
}

.p-b-105 {
    padding-bottom: 105px;
}

.p-b-106 {
    padding-bottom: 106px;
}

.p-b-107 {
    padding-bottom: 107px;
}

.p-b-108 {
    padding-bottom: 108px;
}

.p-b-109 {
    padding-bottom: 109px;
}

.p-b-110 {
    padding-bottom: 110px;
}

.p-b-111 {
    padding-bottom: 111px;
}

.p-b-112 {
    padding-bottom: 112px;
}

.p-b-113 {
    padding-bottom: 113px;
}

.p-b-114 {
    padding-bottom: 114px;
}

.p-b-115 {
    padding-bottom: 115px;
}

.p-b-116 {
    padding-bottom: 116px;
}

.p-b-117 {
    padding-bottom: 117px;
}

.p-b-118 {
    padding-bottom: 118px;
}

.p-b-119 {
    padding-bottom: 119px;
}

.p-b-120 {
    padding-bottom: 120px;
}

.p-b-121 {
    padding-bottom: 121px;
}

.p-b-122 {
    padding-bottom: 122px;
}

.p-b-123 {
    padding-bottom: 123px;
}

.p-b-124 {
    padding-bottom: 124px;
}

.p-b-125 {
    padding-bottom: 125px;
}

.p-b-126 {
    padding-bottom: 126px;
}

.p-b-127 {
    padding-bottom: 127px;
}

.p-b-128 {
    padding-bottom: 128px;
}

.p-b-129 {
    padding-bottom: 129px;
}

.p-b-130 {
    padding-bottom: 130px;
}

.p-b-131 {
    padding-bottom: 131px;
}

.p-b-132 {
    padding-bottom: 132px;
}

.p-b-133 {
    padding-bottom: 133px;
}

.p-b-134 {
    padding-bottom: 134px;
}

.p-b-135 {
    padding-bottom: 135px;
}

.p-b-136 {
    padding-bottom: 136px;
}

.p-b-137 {
    padding-bottom: 137px;
}

.p-b-138 {
    padding-bottom: 138px;
}

.p-b-139 {
    padding-bottom: 139px;
}

.p-b-140 {
    padding-bottom: 140px;
}

.p-b-141 {
    padding-bottom: 141px;
}

.p-b-142 {
    padding-bottom: 142px;
}

.p-b-143 {
    padding-bottom: 143px;
}

.p-b-144 {
    padding-bottom: 144px;
}

.p-b-145 {
    padding-bottom: 145px;
}

.p-b-146 {
    padding-bottom: 146px;
}

.p-b-147 {
    padding-bottom: 147px;
}

.p-b-148 {
    padding-bottom: 148px;
}

.p-b-149 {
    padding-bottom: 149px;
}

.p-b-150 {
    padding-bottom: 150px;
}

.p-b-151 {
    padding-bottom: 151px;
}

.p-b-152 {
    padding-bottom: 152px;
}

.p-b-153 {
    padding-bottom: 153px;
}

.p-b-154 {
    padding-bottom: 154px;
}

.p-b-155 {
    padding-bottom: 155px;
}

.p-b-156 {
    padding-bottom: 156px;
}

.p-b-157 {
    padding-bottom: 157px;
}

.p-b-158 {
    padding-bottom: 158px;
}

.p-b-159 {
    padding-bottom: 159px;
}

.p-b-160 {
    padding-bottom: 160px;
}

.p-b-161 {
    padding-bottom: 161px;
}

.p-b-162 {
    padding-bottom: 162px;
}

.p-b-163 {
    padding-bottom: 163px;
}

.p-b-164 {
    padding-bottom: 164px;
}

.p-b-165 {
    padding-bottom: 165px;
}

.p-b-166 {
    padding-bottom: 166px;
}

.p-b-167 {
    padding-bottom: 167px;
}

.p-b-168 {
    padding-bottom: 168px;
}

.p-b-169 {
    padding-bottom: 169px;
}

.p-b-170 {
    padding-bottom: 170px;
}

.p-b-171 {
    padding-bottom: 171px;
}

.p-b-172 {
    padding-bottom: 172px;
}

.p-b-173 {
    padding-bottom: 173px;
}

.p-b-174 {
    padding-bottom: 174px;
}

.p-b-175 {
    padding-bottom: 175px;
}

.p-b-176 {
    padding-bottom: 176px;
}

.p-b-177 {
    padding-bottom: 177px;
}

.p-b-178 {
    padding-bottom: 178px;
}

.p-b-179 {
    padding-bottom: 179px;
}

.p-b-180 {
    padding-bottom: 180px;
}

.p-b-181 {
    padding-bottom: 181px;
}

.p-b-182 {
    padding-bottom: 182px;
}

.p-b-183 {
    padding-bottom: 183px;
}

.p-b-184 {
    padding-bottom: 184px;
}

.p-b-185 {
    padding-bottom: 185px;
}

.p-b-186 {
    padding-bottom: 186px;
}

.p-b-187 {
    padding-bottom: 187px;
}

.p-b-188 {
    padding-bottom: 188px;
}

.p-b-189 {
    padding-bottom: 189px;
}

.p-b-190 {
    padding-bottom: 190px;
}

.p-b-191 {
    padding-bottom: 191px;
}

.p-b-192 {
    padding-bottom: 192px;
}

.p-b-193 {
    padding-bottom: 193px;
}

.p-b-194 {
    padding-bottom: 194px;
}

.p-b-195 {
    padding-bottom: 195px;
}

.p-b-196 {
    padding-bottom: 196px;
}

.p-b-197 {
    padding-bottom: 197px;
}

.p-b-198 {
    padding-bottom: 198px;
}

.p-b-199 {
    padding-bottom: 199px;
}

.p-b-200 {
    padding-bottom: 200px;
}

.p-b-201 {
    padding-bottom: 201px;
}

.p-b-202 {
    padding-bottom: 202px;
}

.p-b-203 {
    padding-bottom: 203px;
}

.p-b-204 {
    padding-bottom: 204px;
}

.p-b-205 {
    padding-bottom: 205px;
}

.p-b-206 {
    padding-bottom: 206px;
}

.p-b-207 {
    padding-bottom: 207px;
}

.p-b-208 {
    padding-bottom: 208px;
}

.p-b-209 {
    padding-bottom: 209px;
}

.p-b-210 {
    padding-bottom: 210px;
}

.p-b-211 {
    padding-bottom: 211px;
}

.p-b-212 {
    padding-bottom: 212px;
}

.p-b-213 {
    padding-bottom: 213px;
}

.p-b-214 {
    padding-bottom: 214px;
}

.p-b-215 {
    padding-bottom: 215px;
}

.p-b-216 {
    padding-bottom: 216px;
}

.p-b-217 {
    padding-bottom: 217px;
}

.p-b-218 {
    padding-bottom: 218px;
}

.p-b-219 {
    padding-bottom: 219px;
}

.p-b-220 {
    padding-bottom: 220px;
}

.p-b-221 {
    padding-bottom: 221px;
}

.p-b-222 {
    padding-bottom: 222px;
}

.p-b-223 {
    padding-bottom: 223px;
}

.p-b-224 {
    padding-bottom: 224px;
}

.p-b-225 {
    padding-bottom: 225px;
}

.p-b-226 {
    padding-bottom: 226px;
}

.p-b-227 {
    padding-bottom: 227px;
}

.p-b-228 {
    padding-bottom: 228px;
}

.p-b-229 {
    padding-bottom: 229px;
}

.p-b-230 {
    padding-bottom: 230px;
}

.p-b-231 {
    padding-bottom: 231px;
}

.p-b-232 {
    padding-bottom: 232px;
}

.p-b-233 {
    padding-bottom: 233px;
}

.p-b-234 {
    padding-bottom: 234px;
}

.p-b-235 {
    padding-bottom: 235px;
}

.p-b-236 {
    padding-bottom: 236px;
}

.p-b-237 {
    padding-bottom: 237px;
}

.p-b-238 {
    padding-bottom: 238px;
}

.p-b-239 {
    padding-bottom: 239px;
}

.p-b-240 {
    padding-bottom: 240px;
}

.p-b-241 {
    padding-bottom: 241px;
}

.p-b-242 {
    padding-bottom: 242px;
}

.p-b-243 {
    padding-bottom: 243px;
}

.p-b-244 {
    padding-bottom: 244px;
}

.p-b-245 {
    padding-bottom: 245px;
}

.p-b-246 {
    padding-bottom: 246px;
}

.p-b-247 {
    padding-bottom: 247px;
}

.p-b-248 {
    padding-bottom: 248px;
}

.p-b-249 {
    padding-bottom: 249px;
}

.p-b-250 {
    padding-bottom: 250px;
}

.p-l-0 {
    padding-left: 0px;
}

.p-l-1 {
    padding-left: 1px;
}

.p-l-2 {
    padding-left: 2px;
}

.p-l-3 {
    padding-left: 3px;
}

.p-l-4 {
    padding-left: 4px;
}

.p-l-5 {
    padding-left: 5px;
}

.p-l-6 {
    padding-left: 6px;
}

.p-l-7 {
    padding-left: 7px;
}

.p-l-8 {
    padding-left: 8px;
}

.p-l-9 {
    padding-left: 9px;
}

.p-l-10 {
    padding-left: 10px;
}

.p-l-11 {
    padding-left: 11px;
}

.p-l-12 {
    padding-left: 12px;
}

.p-l-13 {
    padding-left: 13px;
}

.p-l-14 {
    padding-left: 14px;
}

.p-l-15 {
    padding-left: 15px;
}

.p-l-16 {
    padding-left: 16px;
}

.p-l-17 {
    padding-left: 17px;
}

.p-l-18 {
    padding-left: 18px;
}

.p-l-19 {
    padding-left: 19px;
}

.p-l-20 {
    padding-left: 20px;
}

.p-l-21 {
    padding-left: 21px;
}

.p-l-22 {
    padding-left: 22px;
}

.p-l-23 {
    padding-left: 23px;
}

.p-l-24 {
    padding-left: 24px;
}

.p-l-25 {
    padding-left: 25px;
}

.p-l-26 {
    padding-left: 26px;
}

.p-l-27 {
    padding-left: 27px;
}

.p-l-28 {
    padding-left: 28px;
}

.p-l-29 {
    padding-left: 29px;
}

.p-l-30 {
    padding-left: 30px;
}

.p-l-31 {
    padding-left: 31px;
}

.p-l-32 {
    padding-left: 32px;
}

.p-l-33 {
    padding-left: 33px;
}

.p-l-34 {
    padding-left: 34px;
}

.p-l-35 {
    padding-left: 35px;
}

.p-l-36 {
    padding-left: 36px;
}

.p-l-37 {
    padding-left: 37px;
}

.p-l-38 {
    padding-left: 38px;
}

.p-l-39 {
    padding-left: 39px;
}

.p-l-40 {
    padding-left: 40px;
}

.p-l-41 {
    padding-left: 41px;
}

.p-l-42 {
    padding-left: 42px;
}

.p-l-43 {
    padding-left: 43px;
}

.p-l-44 {
    padding-left: 44px;
}

.p-l-45 {
    padding-left: 45px;
}

.p-l-46 {
    padding-left: 46px;
}

.p-l-47 {
    padding-left: 47px;
}

.p-l-48 {
    padding-left: 48px;
}

.p-l-49 {
    padding-left: 49px;
}

.p-l-50 {
    padding-left: 50px;
}

.p-l-51 {
    padding-left: 51px;
}

.p-l-52 {
    padding-left: 52px;
}

.p-l-53 {
    padding-left: 53px;
}

.p-l-54 {
    padding-left: 54px;
}

.p-l-55 {
    padding-left: 55px;
}

.p-l-56 {
    padding-left: 56px;
}

.p-l-57 {
    padding-left: 57px;
}

.p-l-58 {
    padding-left: 58px;
}

.p-l-59 {
    padding-left: 59px;
}

.p-l-60 {
    padding-left: 60px;
}

.p-l-61 {
    padding-left: 61px;
}

.p-l-62 {
    padding-left: 62px;
}

.p-l-63 {
    padding-left: 63px;
}

.p-l-64 {
    padding-left: 64px;
}

.p-l-65 {
    padding-left: 65px;
}

.p-l-66 {
    padding-left: 66px;
}

.p-l-67 {
    padding-left: 67px;
}

.p-l-68 {
    padding-left: 68px;
}

.p-l-69 {
    padding-left: 69px;
}

.p-l-70 {
    padding-left: 70px;
}

.p-l-71 {
    padding-left: 71px;
}

.p-l-72 {
    padding-left: 72px;
}

.p-l-73 {
    padding-left: 73px;
}

.p-l-74 {
    padding-left: 74px;
}

.p-l-75 {
    padding-left: 75px;
}

.p-l-76 {
    padding-left: 76px;
}

.p-l-77 {
    padding-left: 77px;
}

.p-l-78 {
    padding-left: 78px;
}

.p-l-79 {
    padding-left: 79px;
}

.p-l-80 {
    padding-left: 80px;
}

.p-l-81 {
    padding-left: 81px;
}

.p-l-82 {
    padding-left: 82px;
}

.p-l-83 {
    padding-left: 83px;
}

.p-l-84 {
    padding-left: 84px;
}

.p-l-85 {
    padding-left: 85px;
}

.p-l-86 {
    padding-left: 86px;
}

.p-l-87 {
    padding-left: 87px;
}

.p-l-88 {
    padding-left: 88px;
}

.p-l-89 {
    padding-left: 89px;
}

.p-l-90 {
    padding-left: 90px;
}

.p-l-91 {
    padding-left: 91px;
}

.p-l-92 {
    padding-left: 92px;
}

.p-l-93 {
    padding-left: 93px;
}

.p-l-94 {
    padding-left: 94px;
}

.p-l-95 {
    padding-left: 95px;
}

.p-l-96 {
    padding-left: 96px;
}

.p-l-97 {
    padding-left: 97px;
}

.p-l-98 {
    padding-left: 98px;
}

.p-l-99 {
    padding-left: 99px;
}

.p-l-100 {
    padding-left: 100px;
}

.p-l-101 {
    padding-left: 101px;
}

.p-l-102 {
    padding-left: 102px;
}

.p-l-103 {
    padding-left: 103px;
}

.p-l-104 {
    padding-left: 104px;
}

.p-l-105 {
    padding-left: 105px;
}

.p-l-106 {
    padding-left: 106px;
}

.p-l-107 {
    padding-left: 107px;
}

.p-l-108 {
    padding-left: 108px;
}

.p-l-109 {
    padding-left: 109px;
}

.p-l-110 {
    padding-left: 110px;
}

.p-l-111 {
    padding-left: 111px;
}

.p-l-112 {
    padding-left: 112px;
}

.p-l-113 {
    padding-left: 113px;
}

.p-l-114 {
    padding-left: 114px;
}

.p-l-115 {
    padding-left: 115px;
}

.p-l-116 {
    padding-left: 116px;
}

.p-l-117 {
    padding-left: 117px;
}

.p-l-118 {
    padding-left: 118px;
}

.p-l-119 {
    padding-left: 119px;
}

.p-l-120 {
    padding-left: 120px;
}

.p-l-121 {
    padding-left: 121px;
}

.p-l-122 {
    padding-left: 122px;
}

.p-l-123 {
    padding-left: 123px;
}

.p-l-124 {
    padding-left: 124px;
}

.p-l-125 {
    padding-left: 125px;
}

.p-l-126 {
    padding-left: 126px;
}

.p-l-127 {
    padding-left: 127px;
}

.p-l-128 {
    padding-left: 128px;
}

.p-l-129 {
    padding-left: 129px;
}

.p-l-130 {
    padding-left: 130px;
}

.p-l-131 {
    padding-left: 131px;
}

.p-l-132 {
    padding-left: 132px;
}

.p-l-133 {
    padding-left: 133px;
}

.p-l-134 {
    padding-left: 134px;
}

.p-l-135 {
    padding-left: 135px;
}

.p-l-136 {
    padding-left: 136px;
}

.p-l-137 {
    padding-left: 137px;
}

.p-l-138 {
    padding-left: 138px;
}

.p-l-139 {
    padding-left: 139px;
}

.p-l-140 {
    padding-left: 140px;
}

.p-l-141 {
    padding-left: 141px;
}

.p-l-142 {
    padding-left: 142px;
}

.p-l-143 {
    padding-left: 143px;
}

.p-l-144 {
    padding-left: 144px;
}

.p-l-145 {
    padding-left: 145px;
}

.p-l-146 {
    padding-left: 146px;
}

.p-l-147 {
    padding-left: 147px;
}

.p-l-148 {
    padding-left: 148px;
}

.p-l-149 {
    padding-left: 149px;
}

.p-l-150 {
    padding-left: 150px;
}

.p-l-151 {
    padding-left: 151px;
}

.p-l-152 {
    padding-left: 152px;
}

.p-l-153 {
    padding-left: 153px;
}

.p-l-154 {
    padding-left: 154px;
}

.p-l-155 {
    padding-left: 155px;
}

.p-l-156 {
    padding-left: 156px;
}

.p-l-157 {
    padding-left: 157px;
}

.p-l-158 {
    padding-left: 158px;
}

.p-l-159 {
    padding-left: 159px;
}

.p-l-160 {
    padding-left: 160px;
}

.p-l-161 {
    padding-left: 161px;
}

.p-l-162 {
    padding-left: 162px;
}

.p-l-163 {
    padding-left: 163px;
}

.p-l-164 {
    padding-left: 164px;
}

.p-l-165 {
    padding-left: 165px;
}

.p-l-166 {
    padding-left: 166px;
}

.p-l-167 {
    padding-left: 167px;
}

.p-l-168 {
    padding-left: 168px;
}

.p-l-169 {
    padding-left: 169px;
}

.p-l-170 {
    padding-left: 170px;
}

.p-l-171 {
    padding-left: 171px;
}

.p-l-172 {
    padding-left: 172px;
}

.p-l-173 {
    padding-left: 173px;
}

.p-l-174 {
    padding-left: 174px;
}

.p-l-175 {
    padding-left: 175px;
}

.p-l-176 {
    padding-left: 176px;
}

.p-l-177 {
    padding-left: 177px;
}

.p-l-178 {
    padding-left: 178px;
}

.p-l-179 {
    padding-left: 179px;
}

.p-l-180 {
    padding-left: 180px;
}

.p-l-181 {
    padding-left: 181px;
}

.p-l-182 {
    padding-left: 182px;
}

.p-l-183 {
    padding-left: 183px;
}

.p-l-184 {
    padding-left: 184px;
}

.p-l-185 {
    padding-left: 185px;
}

.p-l-186 {
    padding-left: 186px;
}

.p-l-187 {
    padding-left: 187px;
}

.p-l-188 {
    padding-left: 188px;
}

.p-l-189 {
    padding-left: 189px;
}

.p-l-190 {
    padding-left: 190px;
}

.p-l-191 {
    padding-left: 191px;
}

.p-l-192 {
    padding-left: 192px;
}

.p-l-193 {
    padding-left: 193px;
}

.p-l-194 {
    padding-left: 194px;
}

.p-l-195 {
    padding-left: 195px;
}

.p-l-196 {
    padding-left: 196px;
}

.p-l-197 {
    padding-left: 197px;
}

.p-l-198 {
    padding-left: 198px;
}

.p-l-199 {
    padding-left: 199px;
}

.p-l-200 {
    padding-left: 200px;
}

.p-l-201 {
    padding-left: 201px;
}

.p-l-202 {
    padding-left: 202px;
}

.p-l-203 {
    padding-left: 203px;
}

.p-l-204 {
    padding-left: 204px;
}

.p-l-205 {
    padding-left: 205px;
}

.p-l-206 {
    padding-left: 206px;
}

.p-l-207 {
    padding-left: 207px;
}

.p-l-208 {
    padding-left: 208px;
}

.p-l-209 {
    padding-left: 209px;
}

.p-l-210 {
    padding-left: 210px;
}

.p-l-211 {
    padding-left: 211px;
}

.p-l-212 {
    padding-left: 212px;
}

.p-l-213 {
    padding-left: 213px;
}

.p-l-214 {
    padding-left: 214px;
}

.p-l-215 {
    padding-left: 215px;
}

.p-l-216 {
    padding-left: 216px;
}

.p-l-217 {
    padding-left: 217px;
}

.p-l-218 {
    padding-left: 218px;
}

.p-l-219 {
    padding-left: 219px;
}

.p-l-220 {
    padding-left: 220px;
}

.p-l-221 {
    padding-left: 221px;
}

.p-l-222 {
    padding-left: 222px;
}

.p-l-223 {
    padding-left: 223px;
}

.p-l-224 {
    padding-left: 224px;
}

.p-l-225 {
    padding-left: 225px;
}

.p-l-226 {
    padding-left: 226px;
}

.p-l-227 {
    padding-left: 227px;
}

.p-l-228 {
    padding-left: 228px;
}

.p-l-229 {
    padding-left: 229px;
}

.p-l-230 {
    padding-left: 230px;
}

.p-l-231 {
    padding-left: 231px;
}

.p-l-232 {
    padding-left: 232px;
}

.p-l-233 {
    padding-left: 233px;
}

.p-l-234 {
    padding-left: 234px;
}

.p-l-235 {
    padding-left: 235px;
}

.p-l-236 {
    padding-left: 236px;
}

.p-l-237 {
    padding-left: 237px;
}

.p-l-238 {
    padding-left: 238px;
}

.p-l-239 {
    padding-left: 239px;
}

.p-l-240 {
    padding-left: 240px;
}

.p-l-241 {
    padding-left: 241px;
}

.p-l-242 {
    padding-left: 242px;
}

.p-l-243 {
    padding-left: 243px;
}

.p-l-244 {
    padding-left: 244px;
}

.p-l-245 {
    padding-left: 245px;
}

.p-l-246 {
    padding-left: 246px;
}

.p-l-247 {
    padding-left: 247px;
}

.p-l-248 {
    padding-left: 248px;
}

.p-l-249 {
    padding-left: 249px;
}

.p-l-250 {
    padding-left: 250px;
}

.p-r-0 {
    padding-right: 0px;
}

.p-r-1 {
    padding-right: 1px;
}

.p-r-2 {
    padding-right: 2px;
}

.p-r-3 {
    padding-right: 3px;
}

.p-r-4 {
    padding-right: 4px;
}

.p-r-5 {
    padding-right: 5px;
}

.p-r-6 {
    padding-right: 6px;
}

.p-r-7 {
    padding-right: 7px;
}

.p-r-8 {
    padding-right: 8px;
}

.p-r-9 {
    padding-right: 9px;
}

.p-r-10 {
    padding-right: 10px;
}

.p-r-11 {
    padding-right: 11px;
}

.p-r-12 {
    padding-right: 12px;
}

.p-r-13 {
    padding-right: 13px;
}

.p-r-14 {
    padding-right: 14px;
}

.p-r-15 {
    padding-right: 15px;
}

.p-r-16 {
    padding-right: 16px;
}

.p-r-17 {
    padding-right: 17px;
}

.p-r-18 {
    padding-right: 18px;
}

.p-r-19 {
    padding-right: 19px;
}

.p-r-20 {
    padding-right: 20px;
}

.p-r-21 {
    padding-right: 21px;
}

.p-r-22 {
    padding-right: 22px;
}

.p-r-23 {
    padding-right: 23px;
}

.p-r-24 {
    padding-right: 24px;
}

.p-r-25 {
    padding-right: 25px;
}

.p-r-26 {
    padding-right: 26px;
}

.p-r-27 {
    padding-right: 27px;
}

.p-r-28 {
    padding-right: 28px;
}

.p-r-29 {
    padding-right: 29px;
}

.p-r-30 {
    padding-right: 30px;
}

.p-r-31 {
    padding-right: 31px;
}

.p-r-32 {
    padding-right: 32px;
}

.p-r-33 {
    padding-right: 33px;
}

.p-r-34 {
    padding-right: 34px;
}

.p-r-35 {
    padding-right: 35px;
}

.p-r-36 {
    padding-right: 36px;
}

.p-r-37 {
    padding-right: 37px;
}

.p-r-38 {
    padding-right: 38px;
}

.p-r-39 {
    padding-right: 39px;
}

.p-r-40 {
    padding-right: 40px;
}

.p-r-41 {
    padding-right: 41px;
}

.p-r-42 {
    padding-right: 42px;
}

.p-r-43 {
    padding-right: 43px;
}

.p-r-44 {
    padding-right: 44px;
}

.p-r-45 {
    padding-right: 45px;
}

.p-r-46 {
    padding-right: 46px;
}

.p-r-47 {
    padding-right: 47px;
}

.p-r-48 {
    padding-right: 48px;
}

.p-r-49 {
    padding-right: 49px;
}

.p-r-50 {
    padding-right: 50px;
}

.p-r-51 {
    padding-right: 51px;
}

.p-r-52 {
    padding-right: 52px;
}

.p-r-53 {
    padding-right: 53px;
}

.p-r-54 {
    padding-right: 54px;
}

.p-r-55 {
    padding-right: 55px;
}

.p-r-56 {
    padding-right: 56px;
}

.p-r-57 {
    padding-right: 57px;
}

.p-r-58 {
    padding-right: 58px;
}

.p-r-59 {
    padding-right: 59px;
}

.p-r-60 {
    padding-right: 60px;
}

.p-r-61 {
    padding-right: 61px;
}

.p-r-62 {
    padding-right: 62px;
}

.p-r-63 {
    padding-right: 63px;
}

.p-r-64 {
    padding-right: 64px;
}

.p-r-65 {
    padding-right: 65px;
}

.p-r-66 {
    padding-right: 66px;
}

.p-r-67 {
    padding-right: 67px;
}

.p-r-68 {
    padding-right: 68px;
}

.p-r-69 {
    padding-right: 69px;
}

.p-r-70 {
    padding-right: 70px;
}

.p-r-71 {
    padding-right: 71px;
}

.p-r-72 {
    padding-right: 72px;
}

.p-r-73 {
    padding-right: 73px;
}

.p-r-74 {
    padding-right: 74px;
}

.p-r-75 {
    padding-right: 75px;
}

.p-r-76 {
    padding-right: 76px;
}

.p-r-77 {
    padding-right: 77px;
}

.p-r-78 {
    padding-right: 78px;
}

.p-r-79 {
    padding-right: 79px;
}

.p-r-80 {
    padding-right: 80px;
}

.p-r-81 {
    padding-right: 81px;
}

.p-r-82 {
    padding-right: 82px;
}

.p-r-83 {
    padding-right: 83px;
}

.p-r-84 {
    padding-right: 84px;
}

.p-r-85 {
    padding-right: 85px;
}

.p-r-86 {
    padding-right: 86px;
}

.p-r-87 {
    padding-right: 87px;
}

.p-r-88 {
    padding-right: 88px;
}

.p-r-89 {
    padding-right: 89px;
}

.p-r-90 {
    padding-right: 90px;
}

.p-r-91 {
    padding-right: 91px;
}

.p-r-92 {
    padding-right: 92px;
}

.p-r-93 {
    padding-right: 93px;
}

.p-r-94 {
    padding-right: 94px;
}

.p-r-95 {
    padding-right: 95px;
}

.p-r-96 {
    padding-right: 96px;
}

.p-r-97 {
    padding-right: 97px;
}

.p-r-98 {
    padding-right: 98px;
}

.p-r-99 {
    padding-right: 99px;
}

.p-r-100 {
    padding-right: 100px;
}

.p-r-101 {
    padding-right: 101px;
}

.p-r-102 {
    padding-right: 102px;
}

.p-r-103 {
    padding-right: 103px;
}

.p-r-104 {
    padding-right: 104px;
}

.p-r-105 {
    padding-right: 105px;
}

.p-r-106 {
    padding-right: 106px;
}

.p-r-107 {
    padding-right: 107px;
}

.p-r-108 {
    padding-right: 108px;
}

.p-r-109 {
    padding-right: 109px;
}

.p-r-110 {
    padding-right: 110px;
}

.p-r-111 {
    padding-right: 111px;
}

.p-r-112 {
    padding-right: 112px;
}

.p-r-113 {
    padding-right: 113px;
}

.p-r-114 {
    padding-right: 114px;
}

.p-r-115 {
    padding-right: 115px;
}

.p-r-116 {
    padding-right: 116px;
}

.p-r-117 {
    padding-right: 117px;
}

.p-r-118 {
    padding-right: 118px;
}

.p-r-119 {
    padding-right: 119px;
}

.p-r-120 {
    padding-right: 120px;
}

.p-r-121 {
    padding-right: 121px;
}

.p-r-122 {
    padding-right: 122px;
}

.p-r-123 {
    padding-right: 123px;
}

.p-r-124 {
    padding-right: 124px;
}

.p-r-125 {
    padding-right: 125px;
}

.p-r-126 {
    padding-right: 126px;
}

.p-r-127 {
    padding-right: 127px;
}

.p-r-128 {
    padding-right: 128px;
}

.p-r-129 {
    padding-right: 129px;
}

.p-r-130 {
    padding-right: 130px;
}

.p-r-131 {
    padding-right: 131px;
}

.p-r-132 {
    padding-right: 132px;
}

.p-r-133 {
    padding-right: 133px;
}

.p-r-134 {
    padding-right: 134px;
}

.p-r-135 {
    padding-right: 135px;
}

.p-r-136 {
    padding-right: 136px;
}

.p-r-137 {
    padding-right: 137px;
}

.p-r-138 {
    padding-right: 138px;
}

.p-r-139 {
    padding-right: 139px;
}

.p-r-140 {
    padding-right: 140px;
}

.p-r-141 {
    padding-right: 141px;
}

.p-r-142 {
    padding-right: 142px;
}

.p-r-143 {
    padding-right: 143px;
}

.p-r-144 {
    padding-right: 144px;
}

.p-r-145 {
    padding-right: 145px;
}

.p-r-146 {
    padding-right: 146px;
}

.p-r-147 {
    padding-right: 147px;
}

.p-r-148 {
    padding-right: 148px;
}

.p-r-149 {
    padding-right: 149px;
}

.p-r-150 {
    padding-right: 150px;
}

.p-r-151 {
    padding-right: 151px;
}

.p-r-152 {
    padding-right: 152px;
}

.p-r-153 {
    padding-right: 153px;
}

.p-r-154 {
    padding-right: 154px;
}

.p-r-155 {
    padding-right: 155px;
}

.p-r-156 {
    padding-right: 156px;
}

.p-r-157 {
    padding-right: 157px;
}

.p-r-158 {
    padding-right: 158px;
}

.p-r-159 {
    padding-right: 159px;
}

.p-r-160 {
    padding-right: 160px;
}

.p-r-161 {
    padding-right: 161px;
}

.p-r-162 {
    padding-right: 162px;
}

.p-r-163 {
    padding-right: 163px;
}

.p-r-164 {
    padding-right: 164px;
}

.p-r-165 {
    padding-right: 165px;
}

.p-r-166 {
    padding-right: 166px;
}

.p-r-167 {
    padding-right: 167px;
}

.p-r-168 {
    padding-right: 168px;
}

.p-r-169 {
    padding-right: 169px;
}

.p-r-170 {
    padding-right: 170px;
}

.p-r-171 {
    padding-right: 171px;
}

.p-r-172 {
    padding-right: 172px;
}

.p-r-173 {
    padding-right: 173px;
}

.p-r-174 {
    padding-right: 174px;
}

.p-r-175 {
    padding-right: 175px;
}

.p-r-176 {
    padding-right: 176px;
}

.p-r-177 {
    padding-right: 177px;
}

.p-r-178 {
    padding-right: 178px;
}

.p-r-179 {
    padding-right: 179px;
}

.p-r-180 {
    padding-right: 180px;
}

.p-r-181 {
    padding-right: 181px;
}

.p-r-182 {
    padding-right: 182px;
}

.p-r-183 {
    padding-right: 183px;
}

.p-r-184 {
    padding-right: 184px;
}

.p-r-185 {
    padding-right: 185px;
}

.p-r-186 {
    padding-right: 186px;
}

.p-r-187 {
    padding-right: 187px;
}

.p-r-188 {
    padding-right: 188px;
}

.p-r-189 {
    padding-right: 189px;
}

.p-r-190 {
    padding-right: 190px;
}

.p-r-191 {
    padding-right: 191px;
}

.p-r-192 {
    padding-right: 192px;
}

.p-r-193 {
    padding-right: 193px;
}

.p-r-194 {
    padding-right: 194px;
}

.p-r-195 {
    padding-right: 195px;
}

.p-r-196 {
    padding-right: 196px;
}

.p-r-197 {
    padding-right: 197px;
}

.p-r-198 {
    padding-right: 198px;
}

.p-r-199 {
    padding-right: 199px;
}

.p-r-200 {
    padding-right: 200px;
}

.p-r-201 {
    padding-right: 201px;
}

.p-r-202 {
    padding-right: 202px;
}

.p-r-203 {
    padding-right: 203px;
}

.p-r-204 {
    padding-right: 204px;
}

.p-r-205 {
    padding-right: 205px;
}

.p-r-206 {
    padding-right: 206px;
}

.p-r-207 {
    padding-right: 207px;
}

.p-r-208 {
    padding-right: 208px;
}

.p-r-209 {
    padding-right: 209px;
}

.p-r-210 {
    padding-right: 210px;
}

.p-r-211 {
    padding-right: 211px;
}

.p-r-212 {
    padding-right: 212px;
}

.p-r-213 {
    padding-right: 213px;
}

.p-r-214 {
    padding-right: 214px;
}

.p-r-215 {
    padding-right: 215px;
}

.p-r-216 {
    padding-right: 216px;
}

.p-r-217 {
    padding-right: 217px;
}

.p-r-218 {
    padding-right: 218px;
}

.p-r-219 {
    padding-right: 219px;
}

.p-r-220 {
    padding-right: 220px;
}

.p-r-221 {
    padding-right: 221px;
}

.p-r-222 {
    padding-right: 222px;
}

.p-r-223 {
    padding-right: 223px;
}

.p-r-224 {
    padding-right: 224px;
}

.p-r-225 {
    padding-right: 225px;
}

.p-r-226 {
    padding-right: 226px;
}

.p-r-227 {
    padding-right: 227px;
}

.p-r-228 {
    padding-right: 228px;
}

.p-r-229 {
    padding-right: 229px;
}

.p-r-230 {
    padding-right: 230px;
}

.p-r-231 {
    padding-right: 231px;
}

.p-r-232 {
    padding-right: 232px;
}

.p-r-233 {
    padding-right: 233px;
}

.p-r-234 {
    padding-right: 234px;
}

.p-r-235 {
    padding-right: 235px;
}

.p-r-236 {
    padding-right: 236px;
}

.p-r-237 {
    padding-right: 237px;
}

.p-r-238 {
    padding-right: 238px;
}

.p-r-239 {
    padding-right: 239px;
}

.p-r-240 {
    padding-right: 240px;
}

.p-r-241 {
    padding-right: 241px;
}

.p-r-242 {
    padding-right: 242px;
}

.p-r-243 {
    padding-right: 243px;
}

.p-r-244 {
    padding-right: 244px;
}

.p-r-245 {
    padding-right: 245px;
}

.p-r-246 {
    padding-right: 246px;
}

.p-r-247 {
    padding-right: 247px;
}

.p-r-248 {
    padding-right: 248px;
}

.p-r-249 {
    padding-right: 249px;
}

.p-r-250 {
    padding-right: 250px;
}


/*[ MARGIN ]
///////////////////////////////////////////////////////////
*/

.m-t-0 {
    margin-top: 0px;
}

.m-t-1 {
    margin-top: 1px;
}

.m-t-2 {
    margin-top: 2px;
}

.m-t-3 {
    margin-top: 3px;
}

.m-t-4 {
    margin-top: 4px;
}

.m-t-5 {
    margin-top: 5px;
}

.m-t-6 {
    margin-top: 6px;
}

.m-t-7 {
    margin-top: 7px;
}

.m-t-8 {
    margin-top: 8px;
}

.m-t-9 {
    margin-top: 9px;
}

.m-t-10 {
    margin-top: 10px;
}

.m-t-11 {
    margin-top: 11px;
}

.m-t-12 {
    margin-top: 12px;
}

.m-t-13 {
    margin-top: 13px;
}

.m-t-14 {
    margin-top: 14px;
}

.m-t-15 {
    margin-top: 15px;
}

.m-t-16 {
    margin-top: 16px;
}

.m-t-17 {
    margin-top: 17px;
}

.m-t-18 {
    margin-top: 18px;
}

.m-t-19 {
    margin-top: 19px;
}

.m-t-20 {
    margin-top: 20px;
}

.m-t-21 {
    margin-top: 21px;
}

.m-t-22 {
    margin-top: 22px;
}

.m-t-23 {
    margin-top: 23px;
}

.m-t-24 {
    margin-top: 24px;
}

.m-t-25 {
    margin-top: 25px;
}

.m-t-26 {
    margin-top: 26px;
}

.m-t-27 {
    margin-top: 27px;
}

.m-t-28 {
    margin-top: 28px;
}

.m-t-29 {
    margin-top: 29px;
}

.m-t-30 {
    margin-top: 30px;
}

.m-t-31 {
    margin-top: 31px;
}

.m-t-32 {
    margin-top: 32px;
}

.m-t-33 {
    margin-top: 33px;
}

.m-t-34 {
    margin-top: 34px;
}

.m-t-35 {
    margin-top: 35px;
}

.m-t-36 {
    margin-top: 36px;
}

.m-t-37 {
    margin-top: 37px;
}

.m-t-38 {
    margin-top: 38px;
}

.m-t-39 {
    margin-top: 39px;
}

.m-t-40 {
    margin-top: 40px;
}

.m-t-41 {
    margin-top: 41px;
}

.m-t-42 {
    margin-top: 42px;
}

.m-t-43 {
    margin-top: 43px;
}

.m-t-44 {
    margin-top: 44px;
}

.m-t-45 {
    margin-top: 45px;
}

.m-t-46 {
    margin-top: 46px;
}

.m-t-47 {
    margin-top: 47px;
}

.m-t-48 {
    margin-top: 48px;
}

.m-t-49 {
    margin-top: 49px;
}

.m-t-50 {
    margin-top: 50px;
}

.m-t-51 {
    margin-top: 51px;
}

.m-t-52 {
    margin-top: 52px;
}

.m-t-53 {
    margin-top: 53px;
}

.m-t-54 {
    margin-top: 54px;
}

.m-t-55 {
    margin-top: 55px;
}

.m-t-56 {
    margin-top: 56px;
}

.m-t-57 {
    margin-top: 57px;
}

.m-t-58 {
    margin-top: 58px;
}

.m-t-59 {
    margin-top: 59px;
}

.m-t-60 {
    margin-top: 60px;
}

.m-t-61 {
    margin-top: 61px;
}

.m-t-62 {
    margin-top: 62px;
}

.m-t-63 {
    margin-top: 63px;
}

.m-t-64 {
    margin-top: 64px;
}

.m-t-65 {
    margin-top: 65px;
}

.m-t-66 {
    margin-top: 66px;
}

.m-t-67 {
    margin-top: 67px;
}

.m-t-68 {
    margin-top: 68px;
}

.m-t-69 {
    margin-top: 69px;
}

.m-t-70 {
    margin-top: 70px;
}

.m-t-71 {
    margin-top: 71px;
}

.m-t-72 {
    margin-top: 72px;
}

.m-t-73 {
    margin-top: 73px;
}

.m-t-74 {
    margin-top: 74px;
}

.m-t-75 {
    margin-top: 75px;
}

.m-t-76 {
    margin-top: 76px;
}

.m-t-77 {
    margin-top: 77px;
}

.m-t-78 {
    margin-top: 78px;
}

.m-t-79 {
    margin-top: 79px;
}

.m-t-80 {
    margin-top: 80px;
}

.m-t-81 {
    margin-top: 81px;
}

.m-t-82 {
    margin-top: 82px;
}

.m-t-83 {
    margin-top: 83px;
}

.m-t-84 {
    margin-top: 84px;
}

.m-t-85 {
    margin-top: 85px;
}

.m-t-86 {
    margin-top: 86px;
}

.m-t-87 {
    margin-top: 87px;
}

.m-t-88 {
    margin-top: 88px;
}

.m-t-89 {
    margin-top: 89px;
}

.m-t-90 {
    margin-top: 90px;
}

.m-t-91 {
    margin-top: 91px;
}

.m-t-92 {
    margin-top: 92px;
}

.m-t-93 {
    margin-top: 93px;
}

.m-t-94 {
    margin-top: 94px;
}

.m-t-95 {
    margin-top: 95px;
}

.m-t-96 {
    margin-top: 96px;
}

.m-t-97 {
    margin-top: 97px;
}

.m-t-98 {
    margin-top: 98px;
}

.m-t-99 {
    margin-top: 99px;
}

.m-t-100 {
    margin-top: 100px;
}

.m-t-101 {
    margin-top: 101px;
}

.m-t-102 {
    margin-top: 102px;
}

.m-t-103 {
    margin-top: 103px;
}

.m-t-104 {
    margin-top: 104px;
}

.m-t-105 {
    margin-top: 105px;
}

.m-t-106 {
    margin-top: 106px;
}

.m-t-107 {
    margin-top: 107px;
}

.m-t-108 {
    margin-top: 108px;
}

.m-t-109 {
    margin-top: 109px;
}

.m-t-110 {
    margin-top: 110px;
}

.m-t-111 {
    margin-top: 111px;
}

.m-t-112 {
    margin-top: 112px;
}

.m-t-113 {
    margin-top: 113px;
}

.m-t-114 {
    margin-top: 114px;
}

.m-t-115 {
    margin-top: 115px;
}

.m-t-116 {
    margin-top: 116px;
}

.m-t-117 {
    margin-top: 117px;
}

.m-t-118 {
    margin-top: 118px;
}

.m-t-119 {
    margin-top: 119px;
}

.m-t-120 {
    margin-top: 120px;
}

.m-t-121 {
    margin-top: 121px;
}

.m-t-122 {
    margin-top: 122px;
}

.m-t-123 {
    margin-top: 123px;
}

.m-t-124 {
    margin-top: 124px;
}

.m-t-125 {
    margin-top: 125px;
}

.m-t-126 {
    margin-top: 126px;
}

.m-t-127 {
    margin-top: 127px;
}

.m-t-128 {
    margin-top: 128px;
}

.m-t-129 {
    margin-top: 129px;
}

.m-t-130 {
    margin-top: 130px;
}

.m-t-131 {
    margin-top: 131px;
}

.m-t-132 {
    margin-top: 132px;
}

.m-t-133 {
    margin-top: 133px;
}

.m-t-134 {
    margin-top: 134px;
}

.m-t-135 {
    margin-top: 135px;
}

.m-t-136 {
    margin-top: 136px;
}

.m-t-137 {
    margin-top: 137px;
}

.m-t-138 {
    margin-top: 138px;
}

.m-t-139 {
    margin-top: 139px;
}

.m-t-140 {
    margin-top: 140px;
}

.m-t-141 {
    margin-top: 141px;
}

.m-t-142 {
    margin-top: 142px;
}

.m-t-143 {
    margin-top: 143px;
}

.m-t-144 {
    margin-top: 144px;
}

.m-t-145 {
    margin-top: 145px;
}

.m-t-146 {
    margin-top: 146px;
}

.m-t-147 {
    margin-top: 147px;
}

.m-t-148 {
    margin-top: 148px;
}

.m-t-149 {
    margin-top: 149px;
}

.m-t-150 {
    margin-top: 150px;
}

.m-t-151 {
    margin-top: 151px;
}

.m-t-152 {
    margin-top: 152px;
}

.m-t-153 {
    margin-top: 153px;
}

.m-t-154 {
    margin-top: 154px;
}

.m-t-155 {
    margin-top: 155px;
}

.m-t-156 {
    margin-top: 156px;
}

.m-t-157 {
    margin-top: 157px;
}

.m-t-158 {
    margin-top: 158px;
}

.m-t-159 {
    margin-top: 159px;
}

.m-t-160 {
    margin-top: 160px;
}

.m-t-161 {
    margin-top: 161px;
}

.m-t-162 {
    margin-top: 162px;
}

.m-t-163 {
    margin-top: 163px;
}

.m-t-164 {
    margin-top: 164px;
}

.m-t-165 {
    margin-top: 165px;
}

.m-t-166 {
    margin-top: 166px;
}

.m-t-167 {
    margin-top: 167px;
}

.m-t-168 {
    margin-top: 168px;
}

.m-t-169 {
    margin-top: 169px;
}

.m-t-170 {
    margin-top: 170px;
}

.m-t-171 {
    margin-top: 171px;
}

.m-t-172 {
    margin-top: 172px;
}

.m-t-173 {
    margin-top: 173px;
}

.m-t-174 {
    margin-top: 174px;
}

.m-t-175 {
    margin-top: 175px;
}

.m-t-176 {
    margin-top: 176px;
}

.m-t-177 {
    margin-top: 177px;
}

.m-t-178 {
    margin-top: 178px;
}

.m-t-179 {
    margin-top: 179px;
}

.m-t-180 {
    margin-top: 180px;
}

.m-t-181 {
    margin-top: 181px;
}

.m-t-182 {
    margin-top: 182px;
}

.m-t-183 {
    margin-top: 183px;
}

.m-t-184 {
    margin-top: 184px;
}

.m-t-185 {
    margin-top: 185px;
}

.m-t-186 {
    margin-top: 186px;
}

.m-t-187 {
    margin-top: 187px;
}

.m-t-188 {
    margin-top: 188px;
}

.m-t-189 {
    margin-top: 189px;
}

.m-t-190 {
    margin-top: 190px;
}

.m-t-191 {
    margin-top: 191px;
}

.m-t-192 {
    margin-top: 192px;
}

.m-t-193 {
    margin-top: 193px;
}

.m-t-194 {
    margin-top: 194px;
}

.m-t-195 {
    margin-top: 195px;
}

.m-t-196 {
    margin-top: 196px;
}

.m-t-197 {
    margin-top: 197px;
}

.m-t-198 {
    margin-top: 198px;
}

.m-t-199 {
    margin-top: 199px;
}

.m-t-200 {
    margin-top: 200px;
}

.m-t-201 {
    margin-top: 201px;
}

.m-t-202 {
    margin-top: 202px;
}

.m-t-203 {
    margin-top: 203px;
}

.m-t-204 {
    margin-top: 204px;
}

.m-t-205 {
    margin-top: 205px;
}

.m-t-206 {
    margin-top: 206px;
}

.m-t-207 {
    margin-top: 207px;
}

.m-t-208 {
    margin-top: 208px;
}

.m-t-209 {
    margin-top: 209px;
}

.m-t-210 {
    margin-top: 210px;
}

.m-t-211 {
    margin-top: 211px;
}

.m-t-212 {
    margin-top: 212px;
}

.m-t-213 {
    margin-top: 213px;
}

.m-t-214 {
    margin-top: 214px;
}

.m-t-215 {
    margin-top: 215px;
}

.m-t-216 {
    margin-top: 216px;
}

.m-t-217 {
    margin-top: 217px;
}

.m-t-218 {
    margin-top: 218px;
}

.m-t-219 {
    margin-top: 219px;
}

.m-t-220 {
    margin-top: 220px;
}

.m-t-221 {
    margin-top: 221px;
}

.m-t-222 {
    margin-top: 222px;
}

.m-t-223 {
    margin-top: 223px;
}

.m-t-224 {
    margin-top: 224px;
}

.m-t-225 {
    margin-top: 225px;
}

.m-t-226 {
    margin-top: 226px;
}

.m-t-227 {
    margin-top: 227px;
}

.m-t-228 {
    margin-top: 228px;
}

.m-t-229 {
    margin-top: 229px;
}

.m-t-230 {
    margin-top: 230px;
}

.m-t-231 {
    margin-top: 231px;
}

.m-t-232 {
    margin-top: 232px;
}

.m-t-233 {
    margin-top: 233px;
}

.m-t-234 {
    margin-top: 234px;
}

.m-t-235 {
    margin-top: 235px;
}

.m-t-236 {
    margin-top: 236px;
}

.m-t-237 {
    margin-top: 237px;
}

.m-t-238 {
    margin-top: 238px;
}

.m-t-239 {
    margin-top: 239px;
}

.m-t-240 {
    margin-top: 240px;
}

.m-t-241 {
    margin-top: 241px;
}

.m-t-242 {
    margin-top: 242px;
}

.m-t-243 {
    margin-top: 243px;
}

.m-t-244 {
    margin-top: 244px;
}

.m-t-245 {
    margin-top: 245px;
}

.m-t-246 {
    margin-top: 246px;
}

.m-t-247 {
    margin-top: 247px;
}

.m-t-248 {
    margin-top: 248px;
}

.m-t-249 {
    margin-top: 249px;
}

.m-t-250 {
    margin-top: 250px;
}

.m-b-0 {
    margin-bottom: 0px;
}

.m-b-1 {
    margin-bottom: 1px;
}

.m-b-2 {
    margin-bottom: 2px;
}

.m-b-3 {
    margin-bottom: 3px;
}

.m-b-4 {
    margin-bottom: 4px;
}

.m-b-5 {
    margin-bottom: 5px;
}

.m-b-6 {
    margin-bottom: 6px;
}

.m-b-7 {
    margin-bottom: 7px;
}

.m-b-8 {
    margin-bottom: 8px;
}

.m-b-9 {
    margin-bottom: 9px;
}

.m-b-10 {
    margin-bottom: 10px;
}

.m-b-11 {
    margin-bottom: 11px;
}

.m-b-12 {
    margin-bottom: 12px;
}

.m-b-13 {
    margin-bottom: 13px;
}

.m-b-14 {
    margin-bottom: 14px;
}

.m-b-15 {
    margin-bottom: 15px;
}

.m-b-16 {
    margin-bottom: 16px;
}

.m-b-17 {
    margin-bottom: 17px;
}

.m-b-18 {
    margin-bottom: 18px;
}

.m-b-19 {
    margin-bottom: 19px;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-b-21 {
    margin-bottom: 21px;
}

.m-b-22 {
    margin-bottom: 22px;
}

.m-b-23 {
    margin-bottom: 23px;
}

.m-b-24 {
    margin-bottom: 24px;
}

.m-b-25 {
    margin-bottom: 25px;
}

.m-b-26 {
    margin-bottom: 26px;
}

.m-b-27 {
    margin-bottom: 27px;
}

.m-b-28 {
    margin-bottom: 28px;
}

.m-b-29 {
    margin-bottom: 29px;
}

.m-b-30 {
    margin-bottom: 30px;
}

.m-b-31 {
    margin-bottom: 31px;
}

.m-b-32 {
    margin-bottom: 32px;
}

.m-b-33 {
    margin-bottom: 33px;
}

.m-b-34 {
    margin-bottom: 34px;
}

.m-b-35 {
    margin-bottom: 35px;
}

.m-b-36 {
    margin-bottom: 36px;
}

.m-b-37 {
    margin-bottom: 37px;
}

.m-b-38 {
    margin-bottom: 38px;
}

.m-b-39 {
    margin-bottom: 39px;
}

.m-b-40 {
    margin-bottom: 40px;
}

.m-b-41 {
    margin-bottom: 41px;
}

.m-b-42 {
    margin-bottom: 42px;
}

.m-b-43 {
    margin-bottom: 43px;
}

.m-b-44 {
    margin-bottom: 44px;
}

.m-b-45 {
    margin-bottom: 45px;
}

.m-b-46 {
    margin-bottom: 46px;
}

.m-b-47 {
    margin-bottom: 47px;
}

.m-b-48 {
    margin-bottom: 48px;
}

.m-b-49 {
    margin-bottom: 49px;
}

.m-b-50 {
    margin-bottom: 50px;
}

.m-b-51 {
    margin-bottom: 51px;
}

.m-b-52 {
    margin-bottom: 52px;
}

.m-b-53 {
    margin-bottom: 53px;
}

.m-b-54 {
    margin-bottom: 54px;
}

.m-b-55 {
    margin-bottom: 55px;
}

.m-b-56 {
    margin-bottom: 56px;
}

.m-b-57 {
    margin-bottom: 57px;
}

.m-b-58 {
    margin-bottom: 58px;
}

.m-b-59 {
    margin-bottom: 59px;
}

.m-b-60 {
    margin-bottom: 60px;
}

.m-b-61 {
    margin-bottom: 61px;
}

.m-b-62 {
    margin-bottom: 62px;
}

.m-b-63 {
    margin-bottom: 63px;
}

.m-b-64 {
    margin-bottom: 64px;
}

.m-b-65 {
    margin-bottom: 65px;
}

.m-b-66 {
    margin-bottom: 66px;
}

.m-b-67 {
    margin-bottom: 67px;
}

.m-b-68 {
    margin-bottom: 68px;
}

.m-b-69 {
    margin-bottom: 69px;
}

.m-b-70 {
    margin-bottom: 70px;
}

.m-b-71 {
    margin-bottom: 71px;
}

.m-b-72 {
    margin-bottom: 72px;
}

.m-b-73 {
    margin-bottom: 73px;
}

.m-b-74 {
    margin-bottom: 74px;
}

.m-b-75 {
    margin-bottom: 75px;
}

.m-b-76 {
    margin-bottom: 76px;
}

.m-b-77 {
    margin-bottom: 77px;
}

.m-b-78 {
    margin-bottom: 78px;
}

.m-b-79 {
    margin-bottom: 79px;
}

.m-b-80 {
    margin-bottom: 80px;
}

.m-b-81 {
    margin-bottom: 81px;
}

.m-b-82 {
    margin-bottom: 82px;
}

.m-b-83 {
    margin-bottom: 83px;
}

.m-b-84 {
    margin-bottom: 84px;
}

.m-b-85 {
    margin-bottom: 85px;
}

.m-b-86 {
    margin-bottom: 86px;
}

.m-b-87 {
    margin-bottom: 87px;
}

.m-b-88 {
    margin-bottom: 88px;
}

.m-b-89 {
    margin-bottom: 89px;
}

.m-b-90 {
    margin-bottom: 90px;
}

.m-b-91 {
    margin-bottom: 91px;
}

.m-b-92 {
    margin-bottom: 92px;
}

.m-b-93 {
    margin-bottom: 93px;
}

.m-b-94 {
    margin-bottom: 94px;
}

.m-b-95 {
    margin-bottom: 95px;
}

.m-b-96 {
    margin-bottom: 96px;
}

.m-b-97 {
    margin-bottom: 97px;
}

.m-b-98 {
    margin-bottom: 98px;
}

.m-b-99 {
    margin-bottom: 99px;
}

.m-b-100 {
    margin-bottom: 100px;
}

.m-b-101 {
    margin-bottom: 101px;
}

.m-b-102 {
    margin-bottom: 102px;
}

.m-b-103 {
    margin-bottom: 103px;
}

.m-b-104 {
    margin-bottom: 104px;
}

.m-b-105 {
    margin-bottom: 105px;
}

.m-b-106 {
    margin-bottom: 106px;
}

.m-b-107 {
    margin-bottom: 107px;
}

.m-b-108 {
    margin-bottom: 108px;
}

.m-b-109 {
    margin-bottom: 109px;
}

.m-b-110 {
    margin-bottom: 110px;
}

.m-b-111 {
    margin-bottom: 111px;
}

.m-b-112 {
    margin-bottom: 112px;
}

.m-b-113 {
    margin-bottom: 113px;
}

.m-b-114 {
    margin-bottom: 114px;
}

.m-b-115 {
    margin-bottom: 115px;
}

.m-b-116 {
    margin-bottom: 116px;
}

.m-b-117 {
    margin-bottom: 117px;
}

.m-b-118 {
    margin-bottom: 118px;
}

.m-b-119 {
    margin-bottom: 119px;
}

.m-b-120 {
    margin-bottom: 120px;
}

.m-b-121 {
    margin-bottom: 121px;
}

.m-b-122 {
    margin-bottom: 122px;
}

.m-b-123 {
    margin-bottom: 123px;
}

.m-b-124 {
    margin-bottom: 124px;
}

.m-b-125 {
    margin-bottom: 125px;
}

.m-b-126 {
    margin-bottom: 126px;
}

.m-b-127 {
    margin-bottom: 127px;
}

.m-b-128 {
    margin-bottom: 128px;
}

.m-b-129 {
    margin-bottom: 129px;
}

.m-b-130 {
    margin-bottom: 130px;
}

.m-b-131 {
    margin-bottom: 131px;
}

.m-b-132 {
    margin-bottom: 132px;
}

.m-b-133 {
    margin-bottom: 133px;
}

.m-b-134 {
    margin-bottom: 134px;
}

.m-b-135 {
    margin-bottom: 135px;
}

.m-b-136 {
    margin-bottom: 136px;
}

.m-b-137 {
    margin-bottom: 137px;
}

.m-b-138 {
    margin-bottom: 138px;
}

.m-b-139 {
    margin-bottom: 139px;
}

.m-b-140 {
    margin-bottom: 140px;
}

.m-b-141 {
    margin-bottom: 141px;
}

.m-b-142 {
    margin-bottom: 142px;
}

.m-b-143 {
    margin-bottom: 143px;
}

.m-b-144 {
    margin-bottom: 144px;
}

.m-b-145 {
    margin-bottom: 145px;
}

.m-b-146 {
    margin-bottom: 146px;
}

.m-b-147 {
    margin-bottom: 147px;
}

.m-b-148 {
    margin-bottom: 148px;
}

.m-b-149 {
    margin-bottom: 149px;
}

.m-b-150 {
    margin-bottom: 150px;
}

.m-b-151 {
    margin-bottom: 151px;
}

.m-b-152 {
    margin-bottom: 152px;
}

.m-b-153 {
    margin-bottom: 153px;
}

.m-b-154 {
    margin-bottom: 154px;
}

.m-b-155 {
    margin-bottom: 155px;
}

.m-b-156 {
    margin-bottom: 156px;
}

.m-b-157 {
    margin-bottom: 157px;
}

.m-b-158 {
    margin-bottom: 158px;
}

.m-b-159 {
    margin-bottom: 159px;
}

.m-b-160 {
    margin-bottom: 160px;
}

.m-b-161 {
    margin-bottom: 161px;
}

.m-b-162 {
    margin-bottom: 162px;
}

.m-b-163 {
    margin-bottom: 163px;
}

.m-b-164 {
    margin-bottom: 164px;
}

.m-b-165 {
    margin-bottom: 165px;
}

.m-b-166 {
    margin-bottom: 166px;
}

.m-b-167 {
    margin-bottom: 167px;
}

.m-b-168 {
    margin-bottom: 168px;
}

.m-b-169 {
    margin-bottom: 169px;
}

.m-b-170 {
    margin-bottom: 170px;
}

.m-b-171 {
    margin-bottom: 171px;
}

.m-b-172 {
    margin-bottom: 172px;
}

.m-b-173 {
    margin-bottom: 173px;
}

.m-b-174 {
    margin-bottom: 174px;
}

.m-b-175 {
    margin-bottom: 175px;
}

.m-b-176 {
    margin-bottom: 176px;
}

.m-b-177 {
    margin-bottom: 177px;
}

.m-b-178 {
    margin-bottom: 178px;
}

.m-b-179 {
    margin-bottom: 179px;
}

.m-b-180 {
    margin-bottom: 180px;
}

.m-b-181 {
    margin-bottom: 181px;
}

.m-b-182 {
    margin-bottom: 182px;
}

.m-b-183 {
    margin-bottom: 183px;
}

.m-b-184 {
    margin-bottom: 184px;
}

.m-b-185 {
    margin-bottom: 185px;
}

.m-b-186 {
    margin-bottom: 186px;
}

.m-b-187 {
    margin-bottom: 187px;
}

.m-b-188 {
    margin-bottom: 188px;
}

.m-b-189 {
    margin-bottom: 189px;
}

.m-b-190 {
    margin-bottom: 190px;
}

.m-b-191 {
    margin-bottom: 191px;
}

.m-b-192 {
    margin-bottom: 192px;
}

.m-b-193 {
    margin-bottom: 193px;
}

.m-b-194 {
    margin-bottom: 194px;
}

.m-b-195 {
    margin-bottom: 195px;
}

.m-b-196 {
    margin-bottom: 196px;
}

.m-b-197 {
    margin-bottom: 197px;
}

.m-b-198 {
    margin-bottom: 198px;
}

.m-b-199 {
    margin-bottom: 199px;
}

.m-b-200 {
    margin-bottom: 200px;
}

.m-b-201 {
    margin-bottom: 201px;
}

.m-b-202 {
    margin-bottom: 202px;
}

.m-b-203 {
    margin-bottom: 203px;
}

.m-b-204 {
    margin-bottom: 204px;
}

.m-b-205 {
    margin-bottom: 205px;
}

.m-b-206 {
    margin-bottom: 206px;
}

.m-b-207 {
    margin-bottom: 207px;
}

.m-b-208 {
    margin-bottom: 208px;
}

.m-b-209 {
    margin-bottom: 209px;
}

.m-b-210 {
    margin-bottom: 210px;
}

.m-b-211 {
    margin-bottom: 211px;
}

.m-b-212 {
    margin-bottom: 212px;
}

.m-b-213 {
    margin-bottom: 213px;
}

.m-b-214 {
    margin-bottom: 214px;
}

.m-b-215 {
    margin-bottom: 215px;
}

.m-b-216 {
    margin-bottom: 216px;
}

.m-b-217 {
    margin-bottom: 217px;
}

.m-b-218 {
    margin-bottom: 218px;
}

.m-b-219 {
    margin-bottom: 219px;
}

.m-b-220 {
    margin-bottom: 220px;
}

.m-b-221 {
    margin-bottom: 221px;
}

.m-b-222 {
    margin-bottom: 222px;
}

.m-b-223 {
    margin-bottom: 223px;
}

.m-b-224 {
    margin-bottom: 224px;
}

.m-b-225 {
    margin-bottom: 225px;
}

.m-b-226 {
    margin-bottom: 226px;
}

.m-b-227 {
    margin-bottom: 227px;
}

.m-b-228 {
    margin-bottom: 228px;
}

.m-b-229 {
    margin-bottom: 229px;
}

.m-b-230 {
    margin-bottom: 230px;
}

.m-b-231 {
    margin-bottom: 231px;
}

.m-b-232 {
    margin-bottom: 232px;
}

.m-b-233 {
    margin-bottom: 233px;
}

.m-b-234 {
    margin-bottom: 234px;
}

.m-b-235 {
    margin-bottom: 235px;
}

.m-b-236 {
    margin-bottom: 236px;
}

.m-b-237 {
    margin-bottom: 237px;
}

.m-b-238 {
    margin-bottom: 238px;
}

.m-b-239 {
    margin-bottom: 239px;
}

.m-b-240 {
    margin-bottom: 240px;
}

.m-b-241 {
    margin-bottom: 241px;
}

.m-b-242 {
    margin-bottom: 242px;
}

.m-b-243 {
    margin-bottom: 243px;
}

.m-b-244 {
    margin-bottom: 244px;
}

.m-b-245 {
    margin-bottom: 245px;
}

.m-b-246 {
    margin-bottom: 246px;
}

.m-b-247 {
    margin-bottom: 247px;
}

.m-b-248 {
    margin-bottom: 248px;
}

.m-b-249 {
    margin-bottom: 249px;
}

.m-b-250 {
    margin-bottom: 250px;
}

.m-l-0 {
    margin-left: 0px;
}

.m-l-1 {
    margin-left: 1px;
}

.m-l-2 {
    margin-left: 2px;
}

.m-l-3 {
    margin-left: 3px;
}

.m-l-4 {
    margin-left: 4px;
}

.m-l-5 {
    margin-left: 5px;
}

.m-l-6 {
    margin-left: 6px;
}

.m-l-7 {
    margin-left: 7px;
}

.m-l-8 {
    margin-left: 8px;
}

.m-l-9 {
    margin-left: 9px;
}

.m-l-10 {
    margin-left: 10px;
}

.m-l-11 {
    margin-left: 11px;
}

.m-l-12 {
    margin-left: 12px;
}

.m-l-13 {
    margin-left: 13px;
}

.m-l-14 {
    margin-left: 14px;
}

.m-l-15 {
    margin-left: 15px;
}

.m-l-16 {
    margin-left: 16px;
}

.m-l-17 {
    margin-left: 17px;
}

.m-l-18 {
    margin-left: 18px;
}

.m-l-19 {
    margin-left: 19px;
}

.m-l-20 {
    margin-left: 20px;
}

.m-l-21 {
    margin-left: 21px;
}

.m-l-22 {
    margin-left: 22px;
}

.m-l-23 {
    margin-left: 23px;
}

.m-l-24 {
    margin-left: 24px;
}

.m-l-25 {
    margin-left: 25px;
}

.m-l-26 {
    margin-left: 26px;
}

.m-l-27 {
    margin-left: 27px;
}

.m-l-28 {
    margin-left: 28px;
}

.m-l-29 {
    margin-left: 29px;
}

.m-l-30 {
    margin-left: 30px;
}

.m-l-31 {
    margin-left: 31px;
}

.m-l-32 {
    margin-left: 32px;
}

.m-l-33 {
    margin-left: 33px;
}

.m-l-34 {
    margin-left: 34px;
}

.m-l-35 {
    margin-left: 35px;
}

.m-l-36 {
    margin-left: 36px;
}

.m-l-37 {
    margin-left: 37px;
}

.m-l-38 {
    margin-left: 38px;
}

.m-l-39 {
    margin-left: 39px;
}

.m-l-40 {
    margin-left: 40px;
}

.m-l-41 {
    margin-left: 41px;
}

.m-l-42 {
    margin-left: 42px;
}

.m-l-43 {
    margin-left: 43px;
}

.m-l-44 {
    margin-left: 44px;
}

.m-l-45 {
    margin-left: 45px;
}

.m-l-46 {
    margin-left: 46px;
}

.m-l-47 {
    margin-left: 47px;
}

.m-l-48 {
    margin-left: 48px;
}

.m-l-49 {
    margin-left: 49px;
}

.m-l-50 {
    margin-left: 50px;
}

.m-l-51 {
    margin-left: 51px;
}

.m-l-52 {
    margin-left: 52px;
}

.m-l-53 {
    margin-left: 53px;
}

.m-l-54 {
    margin-left: 54px;
}

.m-l-55 {
    margin-left: 55px;
}

.m-l-56 {
    margin-left: 56px;
}

.m-l-57 {
    margin-left: 57px;
}

.m-l-58 {
    margin-left: 58px;
}

.m-l-59 {
    margin-left: 59px;
}

.m-l-60 {
    margin-left: 60px;
}

.m-l-61 {
    margin-left: 61px;
}

.m-l-62 {
    margin-left: 62px;
}

.m-l-63 {
    margin-left: 63px;
}

.m-l-64 {
    margin-left: 64px;
}

.m-l-65 {
    margin-left: 65px;
}

.m-l-66 {
    margin-left: 66px;
}

.m-l-67 {
    margin-left: 67px;
}

.m-l-68 {
    margin-left: 68px;
}

.m-l-69 {
    margin-left: 69px;
}

.m-l-70 {
    margin-left: 70px;
}

.m-l-71 {
    margin-left: 71px;
}

.m-l-72 {
    margin-left: 72px;
}

.m-l-73 {
    margin-left: 73px;
}

.m-l-74 {
    margin-left: 74px;
}

.m-l-75 {
    margin-left: 75px;
}

.m-l-76 {
    margin-left: 76px;
}

.m-l-77 {
    margin-left: 77px;
}

.m-l-78 {
    margin-left: 78px;
}

.m-l-79 {
    margin-left: 79px;
}

.m-l-80 {
    margin-left: 80px;
}

.m-l-81 {
    margin-left: 81px;
}

.m-l-82 {
    margin-left: 82px;
}

.m-l-83 {
    margin-left: 83px;
}

.m-l-84 {
    margin-left: 84px;
}

.m-l-85 {
    margin-left: 85px;
}

.m-l-86 {
    margin-left: 86px;
}

.m-l-87 {
    margin-left: 87px;
}

.m-l-88 {
    margin-left: 88px;
}

.m-l-89 {
    margin-left: 89px;
}

.m-l-90 {
    margin-left: 90px;
}

.m-l-91 {
    margin-left: 91px;
}

.m-l-92 {
    margin-left: 92px;
}

.m-l-93 {
    margin-left: 93px;
}

.m-l-94 {
    margin-left: 94px;
}

.m-l-95 {
    margin-left: 95px;
}

.m-l-96 {
    margin-left: 96px;
}

.m-l-97 {
    margin-left: 97px;
}

.m-l-98 {
    margin-left: 98px;
}

.m-l-99 {
    margin-left: 99px;
}

.m-l-100 {
    margin-left: 100px;
}

.m-l-101 {
    margin-left: 101px;
}

.m-l-102 {
    margin-left: 102px;
}

.m-l-103 {
    margin-left: 103px;
}

.m-l-104 {
    margin-left: 104px;
}

.m-l-105 {
    margin-left: 105px;
}

.m-l-106 {
    margin-left: 106px;
}

.m-l-107 {
    margin-left: 107px;
}

.m-l-108 {
    margin-left: 108px;
}

.m-l-109 {
    margin-left: 109px;
}

.m-l-110 {
    margin-left: 110px;
}

.m-l-111 {
    margin-left: 111px;
}

.m-l-112 {
    margin-left: 112px;
}

.m-l-113 {
    margin-left: 113px;
}

.m-l-114 {
    margin-left: 114px;
}

.m-l-115 {
    margin-left: 115px;
}

.m-l-116 {
    margin-left: 116px;
}

.m-l-117 {
    margin-left: 117px;
}

.m-l-118 {
    margin-left: 118px;
}

.m-l-119 {
    margin-left: 119px;
}

.m-l-120 {
    margin-left: 120px;
}

.m-l-121 {
    margin-left: 121px;
}

.m-l-122 {
    margin-left: 122px;
}

.m-l-123 {
    margin-left: 123px;
}

.m-l-124 {
    margin-left: 124px;
}

.m-l-125 {
    margin-left: 125px;
}

.m-l-126 {
    margin-left: 126px;
}

.m-l-127 {
    margin-left: 127px;
}

.m-l-128 {
    margin-left: 128px;
}

.m-l-129 {
    margin-left: 129px;
}

.m-l-130 {
    margin-left: 130px;
}

.m-l-131 {
    margin-left: 131px;
}

.m-l-132 {
    margin-left: 132px;
}

.m-l-133 {
    margin-left: 133px;
}

.m-l-134 {
    margin-left: 134px;
}

.m-l-135 {
    margin-left: 135px;
}

.m-l-136 {
    margin-left: 136px;
}

.m-l-137 {
    margin-left: 137px;
}

.m-l-138 {
    margin-left: 138px;
}

.m-l-139 {
    margin-left: 139px;
}

.m-l-140 {
    margin-left: 140px;
}

.m-l-141 {
    margin-left: 141px;
}

.m-l-142 {
    margin-left: 142px;
}

.m-l-143 {
    margin-left: 143px;
}

.m-l-144 {
    margin-left: 144px;
}

.m-l-145 {
    margin-left: 145px;
}

.m-l-146 {
    margin-left: 146px;
}

.m-l-147 {
    margin-left: 147px;
}

.m-l-148 {
    margin-left: 148px;
}

.m-l-149 {
    margin-left: 149px;
}

.m-l-150 {
    margin-left: 150px;
}

.m-l-151 {
    margin-left: 151px;
}

.m-l-152 {
    margin-left: 152px;
}

.m-l-153 {
    margin-left: 153px;
}

.m-l-154 {
    margin-left: 154px;
}

.m-l-155 {
    margin-left: 155px;
}

.m-l-156 {
    margin-left: 156px;
}

.m-l-157 {
    margin-left: 157px;
}

.m-l-158 {
    margin-left: 158px;
}

.m-l-159 {
    margin-left: 159px;
}

.m-l-160 {
    margin-left: 160px;
}

.m-l-161 {
    margin-left: 161px;
}

.m-l-162 {
    margin-left: 162px;
}

.m-l-163 {
    margin-left: 163px;
}

.m-l-164 {
    margin-left: 164px;
}

.m-l-165 {
    margin-left: 165px;
}

.m-l-166 {
    margin-left: 166px;
}

.m-l-167 {
    margin-left: 167px;
}

.m-l-168 {
    margin-left: 168px;
}

.m-l-169 {
    margin-left: 169px;
}

.m-l-170 {
    margin-left: 170px;
}

.m-l-171 {
    margin-left: 171px;
}

.m-l-172 {
    margin-left: 172px;
}

.m-l-173 {
    margin-left: 173px;
}

.m-l-174 {
    margin-left: 174px;
}

.m-l-175 {
    margin-left: 175px;
}

.m-l-176 {
    margin-left: 176px;
}

.m-l-177 {
    margin-left: 177px;
}

.m-l-178 {
    margin-left: 178px;
}

.m-l-179 {
    margin-left: 179px;
}

.m-l-180 {
    margin-left: 180px;
}

.m-l-181 {
    margin-left: 181px;
}

.m-l-182 {
    margin-left: 182px;
}

.m-l-183 {
    margin-left: 183px;
}

.m-l-184 {
    margin-left: 184px;
}

.m-l-185 {
    margin-left: 185px;
}

.m-l-186 {
    margin-left: 186px;
}

.m-l-187 {
    margin-left: 187px;
}

.m-l-188 {
    margin-left: 188px;
}

.m-l-189 {
    margin-left: 189px;
}

.m-l-190 {
    margin-left: 190px;
}

.m-l-191 {
    margin-left: 191px;
}

.m-l-192 {
    margin-left: 192px;
}

.m-l-193 {
    margin-left: 193px;
}

.m-l-194 {
    margin-left: 194px;
}

.m-l-195 {
    margin-left: 195px;
}

.m-l-196 {
    margin-left: 196px;
}

.m-l-197 {
    margin-left: 197px;
}

.m-l-198 {
    margin-left: 198px;
}

.m-l-199 {
    margin-left: 199px;
}

.m-l-200 {
    margin-left: 200px;
}

.m-l-201 {
    margin-left: 201px;
}

.m-l-202 {
    margin-left: 202px;
}

.m-l-203 {
    margin-left: 203px;
}

.m-l-204 {
    margin-left: 204px;
}

.m-l-205 {
    margin-left: 205px;
}

.m-l-206 {
    margin-left: 206px;
}

.m-l-207 {
    margin-left: 207px;
}

.m-l-208 {
    margin-left: 208px;
}

.m-l-209 {
    margin-left: 209px;
}

.m-l-210 {
    margin-left: 210px;
}

.m-l-211 {
    margin-left: 211px;
}

.m-l-212 {
    margin-left: 212px;
}

.m-l-213 {
    margin-left: 213px;
}

.m-l-214 {
    margin-left: 214px;
}

.m-l-215 {
    margin-left: 215px;
}

.m-l-216 {
    margin-left: 216px;
}

.m-l-217 {
    margin-left: 217px;
}

.m-l-218 {
    margin-left: 218px;
}

.m-l-219 {
    margin-left: 219px;
}

.m-l-220 {
    margin-left: 220px;
}

.m-l-221 {
    margin-left: 221px;
}

.m-l-222 {
    margin-left: 222px;
}

.m-l-223 {
    margin-left: 223px;
}

.m-l-224 {
    margin-left: 224px;
}

.m-l-225 {
    margin-left: 225px;
}

.m-l-226 {
    margin-left: 226px;
}

.m-l-227 {
    margin-left: 227px;
}

.m-l-228 {
    margin-left: 228px;
}

.m-l-229 {
    margin-left: 229px;
}

.m-l-230 {
    margin-left: 230px;
}

.m-l-231 {
    margin-left: 231px;
}

.m-l-232 {
    margin-left: 232px;
}

.m-l-233 {
    margin-left: 233px;
}

.m-l-234 {
    margin-left: 234px;
}

.m-l-235 {
    margin-left: 235px;
}

.m-l-236 {
    margin-left: 236px;
}

.m-l-237 {
    margin-left: 237px;
}

.m-l-238 {
    margin-left: 238px;
}

.m-l-239 {
    margin-left: 239px;
}

.m-l-240 {
    margin-left: 240px;
}

.m-l-241 {
    margin-left: 241px;
}

.m-l-242 {
    margin-left: 242px;
}

.m-l-243 {
    margin-left: 243px;
}

.m-l-244 {
    margin-left: 244px;
}

.m-l-245 {
    margin-left: 245px;
}

.m-l-246 {
    margin-left: 246px;
}

.m-l-247 {
    margin-left: 247px;
}

.m-l-248 {
    margin-left: 248px;
}

.m-l-249 {
    margin-left: 249px;
}

.m-l-250 {
    margin-left: 250px;
}

.m-r-0 {
    margin-right: 0px;
}

.m-r-1 {
    margin-right: 1px;
}

.m-r-2 {
    margin-right: 2px;
}

.m-r-3 {
    margin-right: 3px;
}

.m-r-4 {
    margin-right: 4px;
}

.m-r-5 {
    margin-right: 5px;
}

.m-r-6 {
    margin-right: 6px;
}

.m-r-7 {
    margin-right: 7px;
}

.m-r-8 {
    margin-right: 8px;
}

.m-r-9 {
    margin-right: 9px;
}

.m-r-10 {
    margin-right: 10px;
}

.m-r-11 {
    margin-right: 11px;
}

.m-r-12 {
    margin-right: 12px;
}

.m-r-13 {
    margin-right: 13px;
}

.m-r-14 {
    margin-right: 14px;
}

.m-r-15 {
    margin-right: 15px;
}

.m-r-16 {
    margin-right: 16px;
}

.m-r-17 {
    margin-right: 17px;
}

.m-r-18 {
    margin-right: 18px;
}

.m-r-19 {
    margin-right: 19px;
}

.m-r-20 {
    margin-right: 20px;
}

.m-r-21 {
    margin-right: 21px;
}

.m-r-22 {
    margin-right: 22px;
}

.m-r-23 {
    margin-right: 23px;
}

.m-r-24 {
    margin-right: 24px;
}

.m-r-25 {
    margin-right: 25px;
}

.m-r-26 {
    margin-right: 26px;
}

.m-r-27 {
    margin-right: 27px;
}

.m-r-28 {
    margin-right: 28px;
}

.m-r-29 {
    margin-right: 29px;
}

.m-r-30 {
    margin-right: 30px;
}

.m-r-31 {
    margin-right: 31px;
}

.m-r-32 {
    margin-right: 32px;
}

.m-r-33 {
    margin-right: 33px;
}

.m-r-34 {
    margin-right: 34px;
}

.m-r-35 {
    margin-right: 35px;
}

.m-r-36 {
    margin-right: 36px;
}

.m-r-37 {
    margin-right: 37px;
}

.m-r-38 {
    margin-right: 38px;
}

.m-r-39 {
    margin-right: 39px;
}

.m-r-40 {
    margin-right: 40px;
}

.m-r-41 {
    margin-right: 41px;
}

.m-r-42 {
    margin-right: 42px;
}

.m-r-43 {
    margin-right: 43px;
}

.m-r-44 {
    margin-right: 44px;
}

.m-r-45 {
    margin-right: 45px;
}

.m-r-46 {
    margin-right: 46px;
}

.m-r-47 {
    margin-right: 47px;
}

.m-r-48 {
    margin-right: 48px;
}

.m-r-49 {
    margin-right: 49px;
}

.m-r-50 {
    margin-right: 50px;
}

.m-r-51 {
    margin-right: 51px;
}

.m-r-52 {
    margin-right: 52px;
}

.m-r-53 {
    margin-right: 53px;
}

.m-r-54 {
    margin-right: 54px;
}

.m-r-55 {
    margin-right: 55px;
}

.m-r-56 {
    margin-right: 56px;
}

.m-r-57 {
    margin-right: 57px;
}

.m-r-58 {
    margin-right: 58px;
}

.m-r-59 {
    margin-right: 59px;
}

.m-r-60 {
    margin-right: 60px;
}

.m-r-61 {
    margin-right: 61px;
}

.m-r-62 {
    margin-right: 62px;
}

.m-r-63 {
    margin-right: 63px;
}

.m-r-64 {
    margin-right: 64px;
}

.m-r-65 {
    margin-right: 65px;
}

.m-r-66 {
    margin-right: 66px;
}

.m-r-67 {
    margin-right: 67px;
}

.m-r-68 {
    margin-right: 68px;
}

.m-r-69 {
    margin-right: 69px;
}

.m-r-70 {
    margin-right: 70px;
}

.m-r-71 {
    margin-right: 71px;
}

.m-r-72 {
    margin-right: 72px;
}

.m-r-73 {
    margin-right: 73px;
}

.m-r-74 {
    margin-right: 74px;
}

.m-r-75 {
    margin-right: 75px;
}

.m-r-76 {
    margin-right: 76px;
}

.m-r-77 {
    margin-right: 77px;
}

.m-r-78 {
    margin-right: 78px;
}

.m-r-79 {
    margin-right: 79px;
}

.m-r-80 {
    margin-right: 80px;
}

.m-r-81 {
    margin-right: 81px;
}

.m-r-82 {
    margin-right: 82px;
}

.m-r-83 {
    margin-right: 83px;
}

.m-r-84 {
    margin-right: 84px;
}

.m-r-85 {
    margin-right: 85px;
}

.m-r-86 {
    margin-right: 86px;
}

.m-r-87 {
    margin-right: 87px;
}

.m-r-88 {
    margin-right: 88px;
}

.m-r-89 {
    margin-right: 89px;
}

.m-r-90 {
    margin-right: 90px;
}

.m-r-91 {
    margin-right: 91px;
}

.m-r-92 {
    margin-right: 92px;
}

.m-r-93 {
    margin-right: 93px;
}

.m-r-94 {
    margin-right: 94px;
}

.m-r-95 {
    margin-right: 95px;
}

.m-r-96 {
    margin-right: 96px;
}

.m-r-97 {
    margin-right: 97px;
}

.m-r-98 {
    margin-right: 98px;
}

.m-r-99 {
    margin-right: 99px;
}

.m-r-100 {
    margin-right: 100px;
}

.m-r-101 {
    margin-right: 101px;
}

.m-r-102 {
    margin-right: 102px;
}

.m-r-103 {
    margin-right: 103px;
}

.m-r-104 {
    margin-right: 104px;
}

.m-r-105 {
    margin-right: 105px;
}

.m-r-106 {
    margin-right: 106px;
}

.m-r-107 {
    margin-right: 107px;
}

.m-r-108 {
    margin-right: 108px;
}

.m-r-109 {
    margin-right: 109px;
}

.m-r-110 {
    margin-right: 110px;
}

.m-r-111 {
    margin-right: 111px;
}

.m-r-112 {
    margin-right: 112px;
}

.m-r-113 {
    margin-right: 113px;
}

.m-r-114 {
    margin-right: 114px;
}

.m-r-115 {
    margin-right: 115px;
}

.m-r-116 {
    margin-right: 116px;
}

.m-r-117 {
    margin-right: 117px;
}

.m-r-118 {
    margin-right: 118px;
}

.m-r-119 {
    margin-right: 119px;
}

.m-r-120 {
    margin-right: 120px;
}

.m-r-121 {
    margin-right: 121px;
}

.m-r-122 {
    margin-right: 122px;
}

.m-r-123 {
    margin-right: 123px;
}

.m-r-124 {
    margin-right: 124px;
}

.m-r-125 {
    margin-right: 125px;
}

.m-r-126 {
    margin-right: 126px;
}

.m-r-127 {
    margin-right: 127px;
}

.m-r-128 {
    margin-right: 128px;
}

.m-r-129 {
    margin-right: 129px;
}

.m-r-130 {
    margin-right: 130px;
}

.m-r-131 {
    margin-right: 131px;
}

.m-r-132 {
    margin-right: 132px;
}

.m-r-133 {
    margin-right: 133px;
}

.m-r-134 {
    margin-right: 134px;
}

.m-r-135 {
    margin-right: 135px;
}

.m-r-136 {
    margin-right: 136px;
}

.m-r-137 {
    margin-right: 137px;
}

.m-r-138 {
    margin-right: 138px;
}

.m-r-139 {
    margin-right: 139px;
}

.m-r-140 {
    margin-right: 140px;
}

.m-r-141 {
    margin-right: 141px;
}

.m-r-142 {
    margin-right: 142px;
}

.m-r-143 {
    margin-right: 143px;
}

.m-r-144 {
    margin-right: 144px;
}

.m-r-145 {
    margin-right: 145px;
}

.m-r-146 {
    margin-right: 146px;
}

.m-r-147 {
    margin-right: 147px;
}

.m-r-148 {
    margin-right: 148px;
}

.m-r-149 {
    margin-right: 149px;
}

.m-r-150 {
    margin-right: 150px;
}

.m-r-151 {
    margin-right: 151px;
}

.m-r-152 {
    margin-right: 152px;
}

.m-r-153 {
    margin-right: 153px;
}

.m-r-154 {
    margin-right: 154px;
}

.m-r-155 {
    margin-right: 155px;
}

.m-r-156 {
    margin-right: 156px;
}

.m-r-157 {
    margin-right: 157px;
}

.m-r-158 {
    margin-right: 158px;
}

.m-r-159 {
    margin-right: 159px;
}

.m-r-160 {
    margin-right: 160px;
}

.m-r-161 {
    margin-right: 161px;
}

.m-r-162 {
    margin-right: 162px;
}

.m-r-163 {
    margin-right: 163px;
}

.m-r-164 {
    margin-right: 164px;
}

.m-r-165 {
    margin-right: 165px;
}

.m-r-166 {
    margin-right: 166px;
}

.m-r-167 {
    margin-right: 167px;
}

.m-r-168 {
    margin-right: 168px;
}

.m-r-169 {
    margin-right: 169px;
}

.m-r-170 {
    margin-right: 170px;
}

.m-r-171 {
    margin-right: 171px;
}

.m-r-172 {
    margin-right: 172px;
}

.m-r-173 {
    margin-right: 173px;
}

.m-r-174 {
    margin-right: 174px;
}

.m-r-175 {
    margin-right: 175px;
}

.m-r-176 {
    margin-right: 176px;
}

.m-r-177 {
    margin-right: 177px;
}

.m-r-178 {
    margin-right: 178px;
}

.m-r-179 {
    margin-right: 179px;
}

.m-r-180 {
    margin-right: 180px;
}

.m-r-181 {
    margin-right: 181px;
}

.m-r-182 {
    margin-right: 182px;
}

.m-r-183 {
    margin-right: 183px;
}

.m-r-184 {
    margin-right: 184px;
}

.m-r-185 {
    margin-right: 185px;
}

.m-r-186 {
    margin-right: 186px;
}

.m-r-187 {
    margin-right: 187px;
}

.m-r-188 {
    margin-right: 188px;
}

.m-r-189 {
    margin-right: 189px;
}

.m-r-190 {
    margin-right: 190px;
}

.m-r-191 {
    margin-right: 191px;
}

.m-r-192 {
    margin-right: 192px;
}

.m-r-193 {
    margin-right: 193px;
}

.m-r-194 {
    margin-right: 194px;
}

.m-r-195 {
    margin-right: 195px;
}

.m-r-196 {
    margin-right: 196px;
}

.m-r-197 {
    margin-right: 197px;
}

.m-r-198 {
    margin-right: 198px;
}

.m-r-199 {
    margin-right: 199px;
}

.m-r-200 {
    margin-right: 200px;
}

.m-r-201 {
    margin-right: 201px;
}

.m-r-202 {
    margin-right: 202px;
}

.m-r-203 {
    margin-right: 203px;
}

.m-r-204 {
    margin-right: 204px;
}

.m-r-205 {
    margin-right: 205px;
}

.m-r-206 {
    margin-right: 206px;
}

.m-r-207 {
    margin-right: 207px;
}

.m-r-208 {
    margin-right: 208px;
}

.m-r-209 {
    margin-right: 209px;
}

.m-r-210 {
    margin-right: 210px;
}

.m-r-211 {
    margin-right: 211px;
}

.m-r-212 {
    margin-right: 212px;
}

.m-r-213 {
    margin-right: 213px;
}

.m-r-214 {
    margin-right: 214px;
}

.m-r-215 {
    margin-right: 215px;
}

.m-r-216 {
    margin-right: 216px;
}

.m-r-217 {
    margin-right: 217px;
}

.m-r-218 {
    margin-right: 218px;
}

.m-r-219 {
    margin-right: 219px;
}

.m-r-220 {
    margin-right: 220px;
}

.m-r-221 {
    margin-right: 221px;
}

.m-r-222 {
    margin-right: 222px;
}

.m-r-223 {
    margin-right: 223px;
}

.m-r-224 {
    margin-right: 224px;
}

.m-r-225 {
    margin-right: 225px;
}

.m-r-226 {
    margin-right: 226px;
}

.m-r-227 {
    margin-right: 227px;
}

.m-r-228 {
    margin-right: 228px;
}

.m-r-229 {
    margin-right: 229px;
}

.m-r-230 {
    margin-right: 230px;
}

.m-r-231 {
    margin-right: 231px;
}

.m-r-232 {
    margin-right: 232px;
}

.m-r-233 {
    margin-right: 233px;
}

.m-r-234 {
    margin-right: 234px;
}

.m-r-235 {
    margin-right: 235px;
}

.m-r-236 {
    margin-right: 236px;
}

.m-r-237 {
    margin-right: 237px;
}

.m-r-238 {
    margin-right: 238px;
}

.m-r-239 {
    margin-right: 239px;
}

.m-r-240 {
    margin-right: 240px;
}

.m-r-241 {
    margin-right: 241px;
}

.m-r-242 {
    margin-right: 242px;
}

.m-r-243 {
    margin-right: 243px;
}

.m-r-244 {
    margin-right: 244px;
}

.m-r-245 {
    margin-right: 245px;
}

.m-r-246 {
    margin-right: 246px;
}

.m-r-247 {
    margin-right: 247px;
}

.m-r-248 {
    margin-right: 248px;
}

.m-r-249 {
    margin-right: 249px;
}

.m-r-250 {
    margin-right: 250px;
}

.m-l-r-auto {
    margin-left: auto;
    margin-right: auto;
}

.m-l-auto {
    margin-left: auto;
}

.m-r-auto {
    margin-right: auto;
}


/*[ TEXT ]
///////////////////////////////////////////////////////////
*/


/* ------------------------------------ */

.text-white {
    color: white;
}

.text-black {
    color: black;
}

.text-hov-white:hover {
    color: white;
}


/* ------------------------------------ */

.text-up {
    text-transform: uppercase;
}


/* ------------------------------------ */

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-middle {
    vertical-align: middle;
}


/* ------------------------------------ */

.lh-1-0 {
    line-height: 1;
}

.lh-1-1 {
    line-height: 1.1;
}

.lh-1-2 {
    line-height: 1.2;
}

.lh-1-3 {
    line-height: 1.3;
}

.lh-1-4 {
    line-height: 1.4;
}

.lh-1-5 {
    line-height: 1.5;
}

.lh-1-6 {
    line-height: 1.6;
}

.lh-1-7 {
    line-height: 1.7;
}

.lh-1-8 {
    line-height: 1.8;
}

.lh-1-9 {
    line-height: 1.9;
}

.lh-2-0 {
    line-height: 2;
}

.lh-2-1 {
    line-height: 2.1;
}

.lh-2-2 {
    line-height: 2.2;
}

.lh-2-3 {
    line-height: 2.3;
}

.lh-2-4 {
    line-height: 2.4;
}

.lh-2-5 {
    line-height: 2.5;
}

.lh-2-6 {
    line-height: 2.6;
}

.lh-2-7 {
    line-height: 2.7;
}

.lh-2-8 {
    line-height: 2.8;
}

.lh-2-9 {
    line-height: 2.9;
}


/*[ SHAPE ]
///////////////////////////////////////////////////////////
*/


/*[ Display ]
-----------------------------------------------------------
*/

.dis-none {
    display: none;
}

.dis-block {
    display: block;
}

.dis-inline {
    display: inline;
}

.dis-inline-block {
    display: inline-block;
}

.dis-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
}


/*[ Position ]
-----------------------------------------------------------
*/

.pos-relative {
    position: relative;
}

.pos-absolute {
    position: absolute;
}

.pos-fixed {
    position: fixed;
}


/*[ float ]
-----------------------------------------------------------
*/

.float-l {
    float: left;
}

.float-r {
    float: right;
}


/*[ Width & Height ]
-----------------------------------------------------------
*/

.sizefull {
    width: 100%;
    height: 100%;
}

.w-full {
    width: 100%;
}

.h-full {
    height: 100%;
}

.max-w-full {
    max-width: 100%;
}

.max-h-full {
    max-height: 100%;
}

.min-w-full {
    min-width: 100%;
}

.min-h-full {
    min-height: 100%;
}


/*[ Top Bottom Left Right ]
-----------------------------------------------------------
*/

.top-0 {
    top: 0;
}

.bottom-0 {
    bottom: 0;
}

.left-0 {
    left: 0;
}

.right-0 {
    right: 0;
}

.top-auto {
    top: auto;
}

.bottom-auto {
    bottom: auto;
}

.left-auto {
    left: auto;
}

.right-auto {
    right: auto;
}


/*[ Opacity ]
-----------------------------------------------------------
*/

.op-0-0 {
    opacity: 0;
}

.op-0-1 {
    opacity: 0.1;
}

.op-0-2 {
    opacity: 0.2;
}

.op-0-3 {
    opacity: 0.3;
}

.op-0-4 {
    opacity: 0.4;
}

.op-0-5 {
    opacity: 0.5;
}

.op-0-6 {
    opacity: 0.6;
}

.op-0-7 {
    opacity: 0.7;
}

.op-0-8 {
    opacity: 0.8;
}

.op-0-9 {
    opacity: 0.9;
}

.op-1-0 {
    opacity: 1;
}


/*[ Background ]
-----------------------------------------------------------
*/

.bgwhite {
    background-color: white;
}

.bgblack {
    background-color: black;
}


/*[ Wrap Picture ]
-----------------------------------------------------------
*/

.wrap-pic-w img {
    width: 100%;
}

.wrap-pic-max-w img {
    max-width: 100%;
}


/* ------------------------------------ */

.wrap-pic-h img {
    height: 100%;
}

.wrap-pic-max-h img {
    max-height: 100%;
}


/* ------------------------------------ */

.wrap-pic-cir {
    border-radius: 50%;
    overflow: hidden;
}

.wrap-pic-cir img {
    width: 100%;
}


/*[ Hover ]
-----------------------------------------------------------
*/

.hov-pointer:hover {
    cursor: pointer;
}


/* ------------------------------------ */

.hov-img-zoom {
    display: block;
    overflow: hidden;
}

.hov-img-zoom img {
    width: 100%;
    -webkit-transition: all 0.6s;
    -o-transition: all 0.6s;
    -moz-transition: all 0.6s;
    transition: all 0.6s;
}

.hov-img-zoom:hover img {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
}


/*[  ]
-----------------------------------------------------------
*/

.bo-cir {
    border-radius: 50%;
}

.of-hidden {
    overflow: hidden;
}

.visible-false {
    visibility: hidden;
}

.visible-true {
    visibility: visible;
}


/*[ Transition ]
-----------------------------------------------------------
*/

.trans-0-1 {
    -webkit-transition: all 0.1s;
    -o-transition: all 0.1s;
    -moz-transition: all 0.1s;
    transition: all 0.1s;
}

.trans-0-2 {
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
}

.trans-0-3 {
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.trans-0-4 {
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.trans-0-5 {
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}

.trans-0-6 {
    -webkit-transition: all 0.6s;
    -o-transition: all 0.6s;
    -moz-transition: all 0.6s;
    transition: all 0.6s;
}

.trans-0-9 {
    -webkit-transition: all 0.9s;
    -o-transition: all 0.9s;
    -moz-transition: all 0.9s;
    transition: all 0.9s;
}

.trans-1-0 {
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    -moz-transition: all 1s;
    transition: all 1s;
}


/*[ Layout ]
///////////////////////////////////////////////////////////
*/


/*[ Flex ]
-----------------------------------------------------------
*/


/* ------------------------------------ */

.flex-w {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
}


/* ------------------------------------ */

.flex-l {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-start;
}

.flex-r {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-end;
}

.flex-c {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
}

.flex-sa {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-around;
}

.flex-sb {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
}


/* ------------------------------------ */

.flex-t {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -ms-align-items: flex-start;
    align-items: flex-start;
}

.flex-b {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -ms-align-items: flex-end;
    align-items: flex-end;
}

.flex-m {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -ms-align-items: center;
    align-items: center;
}

.flex-str {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -ms-align-items: stretch;
    align-items: stretch;
}


/* ------------------------------------ */

.flex-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    -o-flex-direction: row;
    flex-direction: row;
}

.flex-row-rev {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row-reverse;
    -moz-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    -o-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.flex-col {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
}

.flex-col-rev {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    -o-flex-direction: column-reverse;
    flex-direction: column-reverse;
}


/* ------------------------------------ */

.flex-c-m {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
}

.flex-c-t {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    -ms-align-items: flex-start;
    align-items: flex-start;
}

.flex-c-b {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    -ms-align-items: flex-end;
    align-items: flex-end;
}

.flex-c-str {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    -ms-align-items: stretch;
    align-items: stretch;
}

.flex-l-m {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-start;
    -ms-align-items: center;
    align-items: center;
}

.flex-r-m {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-end;
    -ms-align-items: center;
    align-items: center;
}

.flex-sa-m {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-around;
    -ms-align-items: center;
    align-items: center;
}

.flex-sb-m {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    -ms-align-items: center;
    align-items: center;
}


/* ------------------------------------ */

.flex-col-l {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    -ms-align-items: flex-start;
    align-items: flex-start;
}

.flex-col-r {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    -ms-align-items: flex-end;
    align-items: flex-end;
}

.flex-col-c {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    -ms-align-items: center;
    align-items: center;
}

.flex-col-l-m {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    -ms-align-items: flex-start;
    align-items: flex-start;
    justify-content: center;
}

.flex-col-r-m {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    -ms-align-items: flex-end;
    align-items: flex-end;
    justify-content: center;
}

.flex-col-c-m {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    -ms-align-items: center;
    align-items: center;
    justify-content: center;
}

.flex-col-str {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    -ms-align-items: stretch;
    align-items: stretch;
}

.flex-col-sb {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    justify-content: space-between;
}


/* ------------------------------------ */

.flex-col-rev-l {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    -o-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -ms-align-items: flex-start;
    align-items: flex-start;
}

.flex-col-rev-r {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    -o-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -ms-align-items: flex-end;
    align-items: flex-end;
}

.flex-col-rev-c {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    -o-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -ms-align-items: center;
    align-items: center;
}

.flex-col-rev-str {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    -o-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -ms-align-items: stretch;
    align-items: stretch;
}


/*[ Absolute ]
-----------------------------------------------------------
*/

.ab-c-m {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.ab-c-t {
    position: absolute;
    top: 0px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}

.ab-c-b {
    position: absolute;
    bottom: 0px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}

.ab-l-m {
    position: absolute;
    left: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.ab-r-m {
    position: absolute;
    right: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.ab-t-l {
    position: absolute;
    left: 0px;
    top: 0px;
}

.ab-t-r {
    position: absolute;
    right: 0px;
    top: 0px;
}

.ab-b-l {
    position: absolute;
    left: 0px;
    bottom: 0px;
}

.ab-b-r {
    position: absolute;
    right: 0px;
    bottom: 0px;
}

.tek-menu-item {
    display: flex !important;
    align-items: center;
}

.tek-menu-item i {
    width: 24px;
}

.tek-menu-item i:first-child {
    margin-right: 0.5rem !important;
}


/* CSS PRINTER  */

.printer__header-right-title p,
.printer__header__body-title-Size {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 600;
    white-space: nowrap;
}

.printer__header__body-time p {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    white-space: nowrap;
    font-weight: 500;
}

.printer__mid__content {
    padding-left: 10px;
}

.patient-left p,
.gender-left p,
.birthday-left p,
.employee-left p,
.number-order-top p,
.number-order-top p,
.printer__bot-title p,
.printer__header__body-number p,
.patientcode-left p,
.price-top p,
.registered-left p,
.registered-right p {
    font-family: 'Roboto, Helvetica, Arial, sans-serif';
    padding: 0;
    margin: 0;
    white-space: nowrap;
    font-weight: 500;
}

.patient-right p,
.gender-right p,
.birthday-right p,
.employee-right p,
.number-order-bottom p,
.text-UT-content p,
.patientcode-right p,
.price-bottom p {
    font-family: 'Roboto, Helvetica, Arial, sans-serif';
    white-space: pre-wrap;
    font-weight: 700;
    letter-spacing: 0.7px;
    padding: 0 0 -15px 0;
    margin: 0 0 0 0;
    /* line-height: 2; */
}

label.checkbox-inline input[type='checkbox'] {
    margin-right: 5px;
    transform: translateY(2px);
}

.btn__search {
    padding: 9px 34px;
    margin-top: 25px;
    margin-left: 13px;
    position: relative;
}

.fa__search {
    position: absolute;
    top: 42%;
    right: 54%;
    color: #ffffff;
}

.btn__export {
    padding: 9px 20px;
}

.export__examination {
    margin-top: 25px;
}

.mg-t {
    margin-top: 25px;
}

.span__line {
    font-weight: 500;
    font-size: 38px;
    margin-top: 10px;
}


/* CSS BTN  */

.edit__search {
    padding: 10px 20px;
}

.edi__export,
.edit__add {
    padding: 10px 20px;
}

.btn__search-mt {
    padding: 9px 34px;
}

.patient__content {
    position: relative;
}

.patient__search {
    position: absolute;
    top: 50%;
    right: 5%;
    color: #2297f7 !important;
    border: none;
    background: transparent;
}

.form-group>label {
    font-weight: 700;
}

.btn__printer__VCB {
    padding: 8px 20px;
}

.code__errors {
    position: absolute;
    top: 70%;
    left: 3%;
    margin-bottom: 5px;
}

.modal__title h2 {
    font-weight: 700;
    line-height: 80px;
    letter-spacing: 1.2px;
    color: #4F4F4F;
}

.ant-pagination a {
    line-height: 2.2;
}

th.ant-table-cell {
    background: #1686DD !important;
    color: #fff !important;
}
.ant-input,
.ant-input-affix-wrapper,
.ant-input-search-button {
    border-color: #5C5C5C !important;
}
.ant-picker,
.ant-select-selector {
    border-color: #5C5C5C !important;
}

#role-detail .ant-collapse-header {
    display: flex;
    align-items: center;
}